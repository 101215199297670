import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";
import format from "date-fns/format";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const parseDate = (fromDate, toDate, title) => {
  const from = format(new Date(fromDate), "dd/MM/yyyy");
  const to = format(new Date(toDate), "dd/MM/yyyy");
  if (fromDate && toDate) {
    return `${title}: ${from} - ${to}`;
  } else if (fromDate) {
    return `${title} desde: ${from}`;
  }
  return `${title} hasta: ${to}`;
};

const chips = props => {
  const { classes } = props;
  const creationDate = parseDate(props.creationFrom, props.creationTo, "Fecha creación");
  const incomeDate = parseDate(props.incomeFrom, props.incomeTo, "Fecha ingreso");
  const assignedDate = parseDate(
    props.assignedFrom,
    props.assignedTo,
    "Fecha asignación"
  );

  return (
    <>
      {(props.creationFrom || props.creationTo) && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={creationDate}
            onDelete={() => {
              props.removeCreationDate();
            }}
          />
        </li>
      )}
      {(props.incomeFrom || props.incomeTo) && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={incomeDate}
            onDelete={() => {
              props.removeIncomeDate();
            }}
          />
        </li>
      )}
      {(props.assignedFrom || props.assignedTo) && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={assignedDate}
            onDelete={() => {
              props.removeAssignedDate();
            }}
          />
        </li>
      )}
    </>
  );
};

export default withStyles(styles)(chips);
