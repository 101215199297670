import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import Title from "./Title";
import AddIcon from "@mui/icons-material/Add";

const styles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
});

const TableSearch = props => {
  const { classes, group } = props;

  return group ? (
    <>
      <Title group={group} />
      <Toolbar className={classNames(classes.root)}>
        <div></div>
        <div className={classes.actions}>
          <Tooltip title="Buscar">
            <IconButton aria-label="Buscar" size="large">
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Input
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
          disableUnderline
          fullWidth
          placeholder="Buscar"
        />

        {props.roles && props.roles.includes("asignarGrupo") ? (
          <Tooltip title="Agregar cuadrilla" disableFocusListener>
            <IconButton
              aria-label="agregar-cuadrilla"
              onClick={props.openAddGangModal}
              size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </Toolbar>
    </>
  ) : null;
};

export default withStyles(styles)(TableSearch);
