import { Component } from "react";
import { connect } from "react-redux";

import Groups from "./groupsListContainer/GroupsList";
import ApiError from "../components/UI/ApiError";
import { getGroups } from "../store/ducks/groups";
import { getCities } from "../store/ducks/cities";
import { getStores } from "../store/ducks/storesList";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

class GroupsContainer extends Component {
  componentDidMount() {
    this.props.getStores();
    this.props.getGroups();
    this.props.getCities();
  }

  render() {
    const { error } = this.props;
    return error ? (
      <ApiError error="Ocurrió un error al obtener los grupos" />
    ) : (
      <Groups {...this.props} />
    );
  }
}

const actionNames = ["STORES_LIST", "GROUPS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
  roles: state.user.roles,
});

const mapDispatchToProps = dispatch => ({
  getStores: () => dispatch(getStores()),
  getGroups: () => dispatch(getGroups()),
  getCities: () => dispatch(getCities()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
