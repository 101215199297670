import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { Link } from "react-router-dom";

const drawerButton = props => (
  <ListItem button component={Link} to={props.path} onClick={props.onClose || null}>
    <ListItemIcon>{props.icon}</ListItemIcon>
    <ListItemText primary={props.name} />
  </ListItem>
);

export default drawerButton;
