import { Button } from "@mui/material";
import { Component } from "react";
import "./styles.css";

export default class CountDownBtn extends Component {
  constructor(props) {
    super(props);
    const { seconds, initContent } = this.props;
    this.state = {
      seconds: seconds,
      content: initContent,
      disabled: false,
    };
  }

  static defaultProps = {
    seconds: 1199,
    initContent: "Generar Pin",
    waitingContent: "{seconds}",
  };

  componentDidMount() {
    if (this.props.seconds <= 0) {
      this.resetBtn();
    } else {
      this.countDown();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer = () => {
    clearInterval(this.timer);
  };

  resetBtn = () => {
    const { initContent } = this.props;
    this.props.onFinished();
    this.clearTimer();
    this.setState({
      content: initContent,
      disabled: false,
    });
  };

  countDown = () => {
    const { seconds, waitingContent, onSubmit } = this.props;
    let leftSeconds = seconds;
    const formatWaitingContent = "".replace.bind(waitingContent, "{seconds}");
    this.setState({
      disabled: true,
      content: formatWaitingContent(leftSeconds),
    });
    onSubmit();
    this.timer = setInterval(() => {
      leftSeconds--;
      this.setState({
        content: formatWaitingContent(leftSeconds),
      });
      if (leftSeconds <= 0) {
        this.resetBtn();
      }
    }, 1000);
  };

  render() {
    const { ...other } = this.props;
    const { content, disabled } = this.state;

    return (
      <Button
        size="small"
        disabled={disabled}
        style={{ width: 145 }}
        onClick={this.countDown}
        {...other}>
        {content}
      </Button>
    );
  }
}
