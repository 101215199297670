/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { getTask } from "../store/ducks/task";
import ApiError from "../components/UI/ApiError";
import { getGroups } from "../store/ducks/groups";
import { getSidewalkUnits } from "../store/ducks/sidewalkUnits";
import { getSidewalkTypes } from "../store/ducks/sidewalkTypes";
import { getSidewalkTiles } from "../store/ducks/sidewalkTiles";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

import Task from "./taskSidewalkValidationContainer/Task";
import { getGangs } from "../store/ducks/gangs";

const TaskSidewalkValidationContainer = props => {
  const params = useParams();

  useEffect(() => {
    props.getGroups();
    props.getTask(params.id);
    props.getSidewalkUnits();
    props.getSidewalkTypes();
    props.getSidewalkTiles();
    props.getGangs();
  }, [params]);

  const { error } = props;
  return !error ? <Task {...props} /> : <ApiError error={error} />;
};

const actionNames = ["TASK", "SIDEWALK_TYPES", "SIDEWALK_UNITS", "STORES"];

const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  task: state.task,
  roles: state.user.roles,
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getTask: id => dispatch(getTask(id)),
  getGangs: () => dispatch(getGangs()),
  getGroups: () => dispatch(getGroups()),
  getSidewalkUnits: () => dispatch(getSidewalkUnits()),
  getSidewalkTypes: () => dispatch(getSidewalkTypes()),
  getSidewalkTiles: () => dispatch(getSidewalkTiles()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskSidewalkValidationContainer);
