import { orderBy } from "lodash";
import { stores as axios } from "../../axios";

const types = {
  STORES_LIST_REQUEST: "STORES_LIST_REQUEST",
  STORES_LIST_SUCCESS: "STORES_LIST_SUCCESS",
  STORES_LIST_FAILURE: "STORES_LIST_FAILURE",
};

const request = () => ({
  type: types.STORES_LIST_REQUEST,
});

const success = stores => ({
  type: types.STORES_LIST_SUCCESS,
  stores,
});

const failure = () => ({
  type: types.STORES_LIST_FAILURE,
  message: "Ha ocurrido un error al intentar obtener los depósitos",
});

export const getStores = () => {
  return async (dispatch, getState) => {
    const { storesList } = getState();
    if (storesList && storesList.length) return;

    dispatch(request());

    try {
      const { data } = await axios.get(`/`);
      dispatch(success(orderBy(data, "name")));
    } catch (error) {
      dispatch(failure());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.STORES_LIST_SUCCESS:
      return action.stores;
    default:
      return state;
  }
};

export default reducer;
