const types = {
  TASK_FILTERS_SEARCH: "TASK_FILTERS_SEARCH",
  TASK_FILTERS_CLEAR: "TASK_FILTERS_CLEAR",
};

export const search = fields => ({
  type: types.TASK_FILTERS_SEARCH,
  fields,
});

export const clear = () => ({
  type: types.TASK_FILTERS_CLEAR,
});

const initialState = {
  fields: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_FILTERS_SEARCH:
      return { ...state, fields: action.fields };
    case types.TASK_FILTERS_CLEAR:
      return { ...state, fields: null };
    default:
      return state;
  }
};

export default reducer;
