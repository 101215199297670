import format from "date-fns/format";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

const styles = theme => ({
  button: {
    margin: theme.spacing(0.2),
  },
  iconContainer: { display: "flex", justifyContent: "center" },
  icon: { marginRight: 10 },
  spinner: {
    display: "flex",
    justifyContent: "center",
    width: 48,
    height: 48,
    alignItems: "center",
  },
});

const body = props => {
  const { hidrojets, page, rowsPerPage, classes } = props;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, hidrojets.length - page * rowsPerPage);
  return (
    <TableBody>
      {hidrojets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(h => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={h.id}>
            <TableCell padding="checkbox">
              <Checkbox
                color="default"
                onClick={() => props.onCheck(h.id)}
                checked={h.selected}
              />
            </TableCell>
            <TableCell component="th" scope="row" padding="none">
              {h.task.codigo_ot}
            </TableCell>
            <TableCell>{h.task.grupo.nombre || "-"}</TableCell>
            <TableCell>{h.cuadrilla.cuadrilla || "-"}</TableCell>
            <TableCell>{h.task.ubicacion}</TableCell>
            <TableCell>
              {h.task_date
                ? format(new Date(h.task_date?.replace(/ /g, "T")), "dd/MM/yyyy")
                : ""}
            </TableCell>
            <TableCell>
              {h.created_at
                ? format(
                    new Date(h.created_at?.replace(/ /g, "T")),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                : ""}
            </TableCell>
            <TableCell>
              {h.validated_at
                ? format(
                    new Date(h.validated_at?.replace(/ /g, "T")),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                : ""}
              <div>
                <small>{h.validated_by ? h.validated_by.name : ""}</small>
              </div>
            </TableCell>
            <TableCell>{h.task.observation || "-"}</TableCell>
            <TableCell>{h.module || "0"}</TableCell>
            <TableCell padding="none"></TableCell>
            <TableCell padding="none">
              <div className={classes.iconContainer}>
                <IconButton
                  edge="end"
                  aria-label="see"
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.PUBLIC_URL}/tarea/${h.task.id}`}
                  size="large">
                  <Tooltip title="Ver Tarea">
                    <VisibilityIcon />
                  </Tooltip>
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => props.openEditModal(h)}
                  style={h.state === 1 ? { color: "#a5a5a5" } : null}
                  size="large">
                  <Tooltip title={h.validated_by ? "Editar validación" : "Validar"}>
                    {h.state === 1 ? <CheckIcon /> : <EditIcon />}
                  </Tooltip>
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 48 * emptyRows }}>
          <TableCell colSpan={11} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default withStyles(styles)(body);
