import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ValidateModal = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar consumo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Se agregará el siguiente consumo como validado:
        </DialogContentText>
        {props.article ? (
          <>
            <Typography variant="subtitle1">
              Material: {props.article.name}
              {props.article.code ? ` (${props.article.code})` : ""}
            </Typography>
            <Typography variant="subtitle1">Cantidad: {props.amount}</Typography>
            <Typography variant="subtitle1">Cuadrilla: {props.gang.name}</Typography>
            <Typography variant="subtitle1">Depósito: {props.store.name}</Typography>
            <Typography variant="subtitle1">Procedencia: {props.origin.name}</Typography>
          </>
        ) : null}
        {props.article && props.amount > props.article.amount ? (
          <DialogContentText style={{ marginTop: 10 }}>
            Como la cantidad seleccionada ({props.amount}) es mayor a la cantidad actual
            de la cuadrilla ({props.article.amount}), se realizará primero una carga de{" "}
            {props.amount - props.article.amount} materiales y luego se creará un consumo
            con la cantidad seleccionada.
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.sending}
          style={{ color: "black" }}>
          Cancelar
        </Button>
        <Button onClick={props.addConsumption} disabled={props.sending} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidateModal;
