/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react-hooks/exhaustive-deps */

import { IconButton, Popover, Tooltip, Paper } from "@mui/material";

import { Delete, Image } from "@mui/icons-material";
import { useState } from "react";

export default props => {
  const {
    item,
    form: { setValue, watch },
  } = props;

  const croquis = watch("step1.croquis");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Paper
      elevation={0}
      variant="outlined"
      style={{
        padding: 10,
        marginTop: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}>
      {item instanceof File ? item.name : item}
      <div>
        {(
          item instanceof File ? !item.type !== "application/pdf" : !item.includes(".pdf")
        ) ? (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}>
            <img
              height={100}
              style={{ margin: 20 }}
              src={
                item instanceof File
                  ? URL.createObjectURL(item)
                  : `${process.env.REACT_APP_IMAGES_URI}/${item}`
              }
              alt="croquis"
            />
          </Popover>
        ) : null}

        {(
          item instanceof File ? !item.type !== "application/pdf" : !item.includes(".pdf")
        ) ? (
          <Tooltip title="Ver">
            <IconButton
              color="error"
              onClick={handleClick}
              aria-label="upload picture"
              component="span"
              size="large">
              <Image />
            </IconButton>
          </Tooltip>
        ) : null}

        <IconButton
          color="secondary"
          aria-label="upload picture"
          component="span"
          onClick={() => {
            setValue(
              "step1.croquis",
              croquis.filter(a => a !== item)
            );
          }}
          size="large">
          <Delete />
        </IconButton>
      </div>
    </Paper>
  );
};
