import { stores as axios } from "../../axios";

const types = {
  STORES_REQUEST: "STORES_REQUEST",
  STORES_SUCCESS: "STORES_SUCCESS",
  STORES_FAILURE: "STORES_FAILURE",
};

const request = () => ({
  type: types.STORES_REQUEST,
});

const success = stores => ({
  type: types.STORES_SUCCESS,
  stores,
});

const failure = () => ({
  type: types.STORES_FAILURE,
  message: "Ha ocurrido un error al intentar obtener los depósitos",
});

export const getStores = () => {
  return async (dispatch, getState) => {
    const { user } = getState();
    dispatch(request());
    try {
      const { data } = await axios.get(`/users/${user.id}`);
      dispatch(success(data.stores));
    } catch (error) {
      dispatch(failure());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.STORES_SUCCESS:
      return action.stores;
    default:
      return state;
  }
};

export default reducer;
