import { connect } from "react-redux";

import {
  closeModal,
  changeComment,
  confirm,
  toggleActionRequired,
  changeCertificate,
} from "../../../../store/ducks/taskListApproveTask";
import ApproveDialog from "./approveDialogContainer/ApproveDialog";

const mapStateToProps = state => ({
  openModal: state.taskListApproveTask.openModal,
  loading: state.taskListApproveTask.loading,
  task: state.taskListApproveTask.task,
  comment: state.taskListApproveTask.comment,
  certificate: state.taskListApproveTask.certificate,
  actionRequired: state.taskListApproveTask.actionRequired,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  confirm: () => dispatch(confirm()),
  changeComment: comment => dispatch(changeComment(comment)),
  changeCertificate: certificate => dispatch(changeCertificate(certificate)),
  toggleActionRequired: () => dispatch(toggleActionRequired()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveDialog);
