import { Component } from "react";
import { connect } from "react-redux";

import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";
import ApiError from "../components/UI/ApiError";
import { getGroups } from "../store/ducks/groups";
import { getTaskTypes } from "../store/ducks/taskTypes";
import { getTasksValidation } from "../store/ducks/tasksValidation";

import TaskList from "./taskValidationListContainer/TaskList";

class TaskValidationListContainer extends Component {
  componentDidMount() {
    this.props.getTasks();
    this.props.getGroups();
    this.props.getTaskTypes();
  }

  render() {
    const { error } = this.props;
    return !error ? <TaskList {...this.props} /> : <ApiError error={error} />;
  }
}

const actionNames = ["TASKS_VALIDATION", "TASK_TYPES", "GROUPS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(getGroups()),
  getTaskTypes: () => dispatch(getTaskTypes()),
  getTasks: () => dispatch(getTasksValidation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskValidationListContainer);
