const types = {
  TASK_LIST_ORDER_SELECT: "TASK_LIST_ORDER_SELECT",
  TASK_LIST_ORDER_OPEN_MODAL: "TASK_LIST_ORDER_OPEN_MODAL",
  TASK_LIST_ORDER_CLOSE_MODAL: "TASK_LIST_ORDER_CLOSE_MODAL",
  TASK_LIST_ORDER_TOGGLE_ORDER: "TASK_LIST_ORDER_TOGGLE_ORDER",
};

export const openModal = () => ({
  type: types.TASK_LIST_ORDER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_ORDER_CLOSE_MODAL,
});

export const toggleOrder = () => ({
  type: types.TASK_LIST_ORDER_TOGGLE_ORDER,
});

export const handleChange = selected => ({
  type: types.TASK_LIST_ORDER_SELECT,
  selected,
});

const initialState = {
  openModal: false,
  selected: "creation",
  descOrder: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_ORDER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_ORDER_SELECT:
      return { ...state, selected: action.selected };
    case types.TASK_LIST_ORDER_TOGGLE_ORDER:
      return { ...state, descOrder: !state.descOrder };
    case types.TASK_LIST_ORDER_CLOSE_MODAL:
      return { ...state, openModal: false };
    default:
      return state;
  }
};

export default reducer;
