import Typography from "@mui/material/Typography";
import { memo } from "react";

export default memo(({ text, variant = "h4", ...props }) => {
  return (
    <div style={{ marginBottom: 15 }} {...props}>
      <Typography variant={variant} style={{ fontWeight: 300, marginBottom: 8 }}>
        {text}
      </Typography>
    </div>
  );
});
