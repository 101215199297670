import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = () => ({
  form: {
    // minWidth: 380,
    // maxHeight: 290,
    width: "100%",
  },
});

const StatesDialog = props => {
  const { classes, closeModal, openModal, save } = props;
  const [states, setStates] = useState(props.states);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({ states });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openModal, states, save]);

  const checkboxes = [
    { name: "Pendiente", id: "1" },
    { name: "Asignada", id: "11" },
    { name: "Programada", id: "2" },
    { name: "Pausada", id: "3" },
    { name: "Ejecución", id: "4" },
    { name: "Recibida", id: "5" },
    { name: "Validada", id: "6" },
    { name: "Cancelada", id: "7" },
    { name: "Inspección", id: "8" },
    { name: "Aprobada", id: "9" },
    { name: "Rechazada", id: "10" },
  ];

  const handleChange = event => {
    setStates({
      ...states,
      [event.target.name]: event.target.checked,
    });
  };

  const toggleSelectAll = event => {
    const array = Object.keys(states);
    const newStates = { ...states };
    for (let i = 1; i <= array.length; i++) {
      newStates[i] = event.target.checked;
    }
    setStates({
      ...newStates,
    });
  };

  const initialize = () => {
    setStates(props.states);
  };

  const allSelected = () => {
    const array = Object.keys(states);
    return array.length === array.filter(key => states[key]).length;
  };

  const all = allSelected();
  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      fullWidth
      maxWidth="xs"
      fullScreen={isMobile}
      TransitionProps={{
        onEnter: initialize,
      }}>
      <DialogTitle>Estados</DialogTitle>
      <DialogContent dividers>
        <FormControl component="fieldset" className={classes.form}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="Todos"
                  color="default"
                  onChange={toggleSelectAll}
                  checked={all}
                />
              }
              label="Todos"
            />
            <Divider />
            {checkboxes.map(item => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    color="default"
                    name={item.id}
                    onChange={handleChange}
                    checked={states[item.id]}
                  />
                }
                label={item.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button color="primary" onClick={() => save({ states })}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(StatesDialog);
