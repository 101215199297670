/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from "react";

import { connect, useDispatch } from "react-redux";
import withStyles from "@mui/styles/withStyles";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

import { getSidewalkTypes } from "../../../../store/ducks/sidewalkTypes";
import { getSidewalkUnits } from "../../../../store/ducks/sidewalkUnits";

import axios from "../../../../axios";
import { getTask } from "../../../../store/ducks/taskDetails";
import { enqueueError, enqueueSuccess } from "../../../../store/ducks/notifications";
import { LoadingButton } from "@mui/lab";
import { useMediaQuery, useTheme } from "@mui/material";
import { getSidewalkTiles } from "../../../../store/ducks/sidewalkTiles";
import { orderBy } from "lodash";

const styles = theme => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

const requiresFillingOptions = [
  { value: 0, name: "No" },
  { value: 1, name: "Si" },
];

const allowedUnits = {
  1: [1, 2, 3],
  2: [1, 2, 3],
  3: [1, 2, 3],
  4: [1, 2, 3],
  5: [1, 2, 3],
};

const AddSidewalkModal = props => {
  const { task, sidewalk, getSidewalkTypes, getSidewalkUnits } = props;

  const dispatch = useDispatch();

  const [unit, setUnit] = useState();
  const [type, setType] = useState();
  const [amount, setAmount] = useState();
  const [article, setArticle] = useState();
  const [observations, setObservations] = useState();
  const [requiresFilling, setRequiresFilling] = useState();

  const [loading, setLoading] = useState();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isValid = amount && unit && type;

  const existingTypes = [5];

  const onSubmit = async () => {
    setLoading(true);
    try {
      await axios.patch(`sidewalk/${sidewalk.id}`, {
        amount,
        state: 1,
        observations,
        type_id: type.id,
        unit_id: unit.id,
        article_id: article ? article.id : null,
        requires_filling: requiresFilling ? 1 : 0,
        article_description: article ? article.name : null,
      });
      setLoading(false);
      dispatch(getTask(task.id));
      props.onClose();
      dispatch(enqueueSuccess("La información de vereda fue actualizada correctamente."));
    } catch {
      dispatch(enqueueError("Ocurrió un error al enviar la información de vereda."));
      setLoading(false);
    }
  };

  useEffect(() => {
    getSidewalkTypes();
    getSidewalkUnits();
  }, [getSidewalkTypes, getSidewalkUnits]);

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isValid) {
          onSubmit();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isValid, amount, unit, type, article, requiresFilling]);

  useEffect(() => {
    if (props.open && !!sidewalk) {
      const article = props.sidewalkTiles.find(
        a => a.id_articulo === sidewalk.article_id
      );

      setUnit(sidewalk.unit);
      setType(sidewalk.type);
      setAmount(sidewalk.amount);
      setObservations(sidewalk.observations);
      setRequiresFilling(sidewalk.requires_filling);
      setArticle(article ? { id: article.id_articulo, name: article.descripcion } : null);
    }
  }, [props.open, sidewalk]);

  const requiresFillingValue = requiresFillingOptions.find(
    f => f.value === requiresFilling
  );

  const tilesOptions = useMemo(() => {
    let data = [];
    if (type && type.id === 3) {
      data = props.sidewalkTiles.filter(
        a => !!a.categorias.find(c => c.id_categoria === 6)
      );
    }
    if (type && type.id === 4) {
      data = props.sidewalkTiles.filter(
        a => !!a.categorias.find(c => c.id_categoria === 7)
      );
    }
    return orderBy(
      data.map(a => ({ id: a.id_articulo, name: a.descripcion })),
      "name"
    );
  }, [type, props.sidewalkTiles]);

  return (
    <Dialog
      fullWidth
      open={props.open}
      disableRestoreFocus
      fullScreen={isMobile}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Editar datos de vereda</DialogTitle>
      <DialogContent>
        {props.loading ? (
          <div className={props.classes.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Autocomplete
              options={requiresFillingOptions}
              getOptionLabel={option => option.name}
              onChange={(_, value) => {
                setRequiresFilling(value.value);
              }}
              value={requiresFillingValue}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Requiere Aporte"
                  style={{ marginTop: 15, marginBottom: 20 }}
                />
              )}
            />

            <Autocomplete
              value={type}
              getOptionLabel={option => option.name}
              noOptionsText="No se encontraron tipos de vereda"
              options={props.sidewalkTypes.filter(s => !existingTypes.includes(s.id))}
              onChange={(_, value) => {
                setType(value);
                setUnit(null);
                setArticle(null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Tipo de Vereda"
                  style={{ marginTop: 15, marginBottom: 20 }}
                />
              )}
            />

            {type && [3, 4].includes(type.id) ? (
              <Autocomplete
                value={article}
                options={tilesOptions}
                getOptionLabel={option => option.name}
                noOptionsText="No se encontró la baldosa buscada"
                onChange={(_, value) => {
                  setArticle(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Baldosa"
                    style={{ marginTop: 15, marginBottom: 10 }}
                  />
                )}
              />
            ) : null}

            <Autocomplete
              value={unit}
              options={props.sidewalkUnits.filter(s => s.id !== 3)}
              getOptionLabel={option => option.name}
              getOptionDisabled={o =>
                type ? !allowedUnits[type.id].includes(o.id) : true
              }
              noOptionsText="No se encontraron unidades de medidas"
              onChange={(_, value) => {
                setUnit(value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Unidad de Medida"
                  style={{ marginTop: 15, marginBottom: 20 }}
                />
              )}
            />

            <TextField
              fullWidth
              type="number"
              margin="dense"
              value={amount}
              label={"Cantidad"}
              style={{ marginTop: 15, marginBottom: 20 }}
              onChange={e => setAmount(e.target.value)}
            />

            <TextField
              fullWidth
              margin="dense"
              value={observations}
              label={"Observaciones"}
              style={{ marginTop: 15, marginBottom: 20 }}
              onChange={e => setObservations(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "black" }}
          onClick={() => {
            props.onClose();
          }}
          color="primary">
          Cancelar
        </Button>
        <LoadingButton
          loading={loading}
          onClick={onSubmit}
          disabled={!isValid || loading}
          color="primary">
          Editar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  sidewalkTypes: state.sidewalkTypes,
  sidewalkUnits: state.sidewalkUnits,
  sidewalkTiles: state.sidewalkTiles,
});

const mapDispatchToProps = dispatch => ({
  getSidewalkTypes: () => dispatch(getSidewalkTypes()),
  getSidewalkUnits: () => dispatch(getSidewalkUnits()),
  getSidewalkTiles: () => dispatch(getSidewalkTiles()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddSidewalkModal));
