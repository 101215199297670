import { memo } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

const head = memo(() => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Nombre</TableCell>
        <TableCell>Tareas en ejecución</TableCell>
        <TableCell>Tareas programadas</TableCell>
        <TableCell>Tareas asignadas</TableCell>
        <TableCell align="left">Tareas pausadas</TableCell>
        <TableCell align="right">Acciones</TableCell>
      </TableRow>
    </TableHead>
  );
});

export default head;
