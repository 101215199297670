import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import withStyles from "@mui/styles/withStyles";
import ValidateIcon from "@mui/icons-material/TouchApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { memo, useMemo } from "react";

const styles = theme => ({
  spinner: {
    width: 48,
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(0.2),
  },
  icon: { marginRight: 10 },
  iconContainer: { display: "flex", justifyContent: "center", textAlign: "center" },
});

const Body = memo(props => {
  const { tasks, page, rowsPerPage } = props;

  const emptyRows = useMemo(
    () => rowsPerPage - Math.min(rowsPerPage, tasks.length - page * rowsPerPage),
    [rowsPerPage, tasks, page]
  );

  const pageTasks = useMemo(
    () =>
      tasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(a => {
        return (
          <TableRow
            hover
            aria-checked={a.selected}
            tabIndex={-1}
            key={a.id}
            selected={a.selected}>
            <TableCell>
              <div>{a.otCode}</div>
              <small>{a.type}</small>
            </TableCell>
            <TableCell>{a.group.name || "-"}</TableCell>
            <TableCell>{a.gang?.name || "-"}</TableCell>
            <TableCell>{a.address}</TableCell>
            <TableCell>{a.task || "-"}</TableCell>
            <TableCell>{a.module || "-"}</TableCell>
            <TableCell padding="none">
              <IconButton
                edge="end"
                aria-label="see"
                target="_blank"
                rel="noreferrer"
                href={`${process.env.PUBLIC_URL}/tarea/${a.id}`}
                size="large">
                <Tooltip title="Ver Tarea">
                  <VisibilityIcon />
                </Tooltip>
              </IconButton>

              <Tooltip title="Validar materiales">
                <IconButton
                  component={Link}
                  className={props.classes.button}
                  to={`/validacion/materiales/${a.id}`}
                  style={!a.hasPendingArticles ? { color: "#b1b1b1" } : null}
                  size="large">
                  <ValidateIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, tasks, page]
  );

  return (
    <TableBody>
      {pageTasks}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={7} />
        </TableRow>
      )}
    </TableBody>
  );
});

export default withStyles(styles)(Body);
