import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { DialogContentText, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const styles = () => ({
  form: {
    // minWidth: 380,
    // maxHeight: 290,
  },
});

const DownloadTaskssDialog = ({ handleCancel, openModal, handleSubmit, isLoading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={isMobile}
      open={openModal}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm">
      <DialogTitle>Descargar Planilla de Tareas</DialogTitle>

      <DialogContent>
        <DialogContentText dividers>
          La planilla de tareas será generada teniendo encuenta los filtros actualmente
          establecidos en la pantalla.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button style={{ color: "black" }} onClick={handleCancel}>
          Cancelar
        </Button>

        <LoadingButton
          component="div"
          title="Guardar"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}
          style={{ width: 100, height: 36 }}>
          Descargar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(DownloadTaskssDialog);
