import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import { memo } from "react";

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  subtitle: {
    marginTop: 10,
  },
});

const title = memo(({ classes, group }) => {
  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h3">
        {group.nombre}
      </Typography>
      <Typography className={classes.subtitle} component="p">
        Listado de cuadrillas
      </Typography>
    </div>
  );
});

export default withStyles(styles)(title);
