import { connect } from "react-redux";

import {
  openModal,
  removeOptions,
} from "../../../../../store/ducks/taskListContractorFilter";

import Contractor from "./contractorContainer/Contractor";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  options: state.taskListContractorFilter.selectedContractors,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeOptions: () => {
    dispatch(removeOptions());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Contractor);
