/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from "react-redux";
import { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { LoadingButton } from "@mui/lab";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ReplayIcon from "@mui/icons-material/Replay";

import Title from "../Title";
import { setNewOrderTask, saveNewOrder } from "../../../../store/ducks/gangTasksReorder";

const tooltipStyle = { marginTop: -10 };

const Button = memo(props => (
  <Tooltip title={props.title || ""} style={tooltipStyle}>
    <span>
      <IconButton disabled={props.disabled} onClick={props.onClick} size="large">
        {props.children}
      </IconButton>
    </span>
  </Tooltip>
));

const ReorderHeader = ({ hasReordered, setNewOrder, saveNewOrder, tasks, ...props }) => {
  const navigate = useNavigate();
  const onReset = useCallback(() => setNewOrder(null), [setNewOrder]);
  const onCancel = useCallback(() => navigate("/cuadrillas"), [navigate]);

  const title = useMemo(() => `Próximas tareas ${tasks ? `(${tasks})` : ""}`, [tasks]);

  return (
    <Grid container justifyContent="space-between" alignItems="baseline">
      <Grid item>
        <Grid container direction="row">
          <Title text={title} variant="h6" />
        </Grid>
      </Grid>

      <Grid item>
        <div>
          <Button
            title="Cancelar"
            style={{ color: "black" }}
            onClick={onCancel}
            component="div">
            <ClearIcon />
          </Button>

          <Button
            title="Restaurar"
            disabled={!hasReordered}
            onClick={onReset}
            component="div">
            <ReplayIcon />
          </Button>

          <Tooltip title="Guardar">
            <span>
              <LoadingButton
                component="span"
                style={{ minWidth: 48 }}
                loading={props.isLoading}
                onClick={() => saveNewOrder(navigate)}
                disabled={!hasReordered || props.isLoading}>
                <SaveIcon />
              </LoadingButton>
            </span>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  tasks: state.gangTasksReorder.tasks.length,
  isLoading: state.gangTasksReorder.isLoading,
  hasReordered: state.gangTasksReorder.newTasks,
});

const mapDispatchToProps = dispatch => ({
  setNewOrder: tasks => dispatch(setNewOrderTask(tasks)),
  saveNewOrder: navigate => dispatch(saveNewOrder(navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReorderHeader);
