import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

import { orderBy } from "lodash";

const styles = theme => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

const TaskArticlesDialog = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const articles = useMemo(() => orderBy(props.articles, "name"), [props.articles]);

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Materiales tarea {props.odt}</DialogTitle>
      <DialogContent>
        {props.loading ? (
          <div className={props.classes.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Material</TableCell>
                <TableCell>Código</TableCell>
                <TableCell align="right">Cantidad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map(article => (
                <TableRow key={article.id}>
                  <TableCell component="th" scope="row">
                    {article.name}
                  </TableCell>
                  <TableCell>{article.code || "-"}</TableCell>
                  <TableCell align="right">{article.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={props.loading} onClick={props.onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(TaskArticlesDialog);
