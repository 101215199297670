import { nomenclador as axios } from "../../axios";

const types = {
  SIDEWALK_TILES_REQUEST: "SIDEWALK_TILES_REQUEST",
  SIDEWALK_TILES_SUCCESS: "SIDEWALK_TILES_SUCCESS",
  SIDEWALK_TILES_FAILURE: "SIDEWALK_TILES_FAILURE",
};

const request = () => ({
  type: types.SIDEWALK_TILES_REQUEST,
});

const success = units => ({
  type: types.SIDEWALK_TILES_SUCCESS,
  units,
});

const failure = () => ({
  type: types.SIDEWALK_TILES_FAILURE,
  message: "Ha ocurrido un error al intentar la información de vereda",
});

export const getSidewalkTiles = () => {
  return async dispatch => {
    dispatch(request());
    try {
      const { data } = await axios.get(`/articulos?categorias=6,7`);
      dispatch(success(data));
    } catch (error) {
      console.log(error);
      dispatch(failure());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.SIDEWALK_TILES_SUCCESS:
      return action.units;
    default:
      return state;
  }
};

export default reducer;
