import { connect } from "react-redux";

import { removeStates, openModal } from "../../../../../store/ducks/taskListStateFilter";
import States from "./statesContainer/States";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  states: state.taskListStateFilter.states,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeStates: () => {
    dispatch(removeStates());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(States);
