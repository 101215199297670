import { connect } from "react-redux";

import { openModal } from "../../../store/ducks/taskArticles";
import { checkTask } from "../../../store/ducks/selectedTasks";
import { confirmSimpleDownload } from "../../../store/ducks/downloadTasks";
import { checkAllTasks } from "../../../store/ducks/downloadTasksPagination";
import { mapSelectedHidrojetsForDownload } from "../../../store/ducks/hidrojetList";
import { openModal as openEditModal } from "../../../store/ducks/taskHidrojetEditModal";

import HidrojetTable from "./hidrojetTableContainer/HidrojetTable";

const mapStateToProps = state => ({
  hidrojets: mapSelectedHidrojetsForDownload(state),
  selectedCount: state.selectedTasks.length,
  loading: state.downloadTasks.loadingTask,
  total: state.downloadTasksPagination.total,
  loadingPage: state.downloadTasksPagination.loading,
  pendingPages: state.downloadTasksPagination.pendingPages,
});

const mapDispatchToProps = dispatch => ({
  checkTask: taskId => dispatch(checkTask(taskId)),
  checkAll: taskIds => dispatch(checkAllTasks(taskIds)),
  openEditModal: module => dispatch(openEditModal(module)),
  onDownload: taskId => dispatch(confirmSimpleDownload(taskId)),
  openArticlesDialog: (taskId, odt) => dispatch(openModal(taskId, odt)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HidrojetTable);
