import { connect } from "react-redux";

import { toggleShowAll, changeArticleFilter } from "../../../store/ducks/articlesFilters";
import { openModal as openAddModal } from "../../../store/ducks/addModal";
import {
  openErrorModal,
  openSidewalkValidationModal,
  openModal as openFinishValidationModal,
} from "../../../store/ducks/finishValidationModal";
import TaskArticles from "./taskArticlesContainer/TaskArticles";
import { canConfirm } from "../../../store/ducks/task";

const mapStateToProps = state => ({
  task: state.task,
  canConfirm: canConfirm(state),
  showAll: state.articlesFilters.showAll,
  articleName: state.articlesFilters.name,
});

const mapDispatchToProps = dispatch => ({
  toggleShowAll: () => dispatch(toggleShowAll()),
  changeArticleFilter: article => dispatch(changeArticleFilter(article)),
  openAddModal: () => dispatch(openAddModal()),
  openFinishValidationModal: () => dispatch(openFinishValidationModal()),
  openSidewalkModal: () => dispatch(openSidewalkValidationModal()),
  openErrorModal: () => dispatch(openErrorModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskArticles);
