/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { connect } from "react-redux";

import { getTask } from "../store/ducks/task";
import ApiError from "../components/UI/ApiError";
import { getStores } from "../store/ducks/stores";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

import Task from "./taskValidationContainer/Task";
import { useParams } from "react-router-dom";

const DownloadTasksContainer = props => {
  const params = useParams();
  useEffect(() => {
    props.getTask(params.id);
    props.getStores();
  }, [params]);

  const { error } = props;
  return !error ? <Task {...props} /> : <ApiError error={error} />;
};

const actionNames = ["TASK", "STORES"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  task: state.task,
  roles: state.user.roles,
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getTask: id => dispatch(getTask(id)),
  getStores: () => dispatch(getStores()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadTasksContainer);
