import { connect } from "react-redux";

import { mapSelectedTasks } from "../../../store/ducks/tasksValidation";
import TaskTable from "./taskTableContainer/TaskTable";

const mapStateToProps = state => ({
  tasks: mapSelectedTasks(state),
  roles: state.user.roles,
});

export default connect(mapStateToProps)(TaskTable);
