const types = {
  TASK_LIST_GANGS_SUCCESS: "TASK_LIST_GANGS_SUCCESS",
};

export const successTaskListGangs = (gangs, groupGangs) => ({
  type: types.TASK_LIST_GANGS_SUCCESS,
  gangs: [
    ...gangs
      .filter(g => groupGangs.data.find(gang => gang.id === g._id))
      .map(g => ({
        id: g._id,
        name: g.name,
        group: { id: `${groupGangs.data.find(gang => gang.id === g._id).grupo_id}` },
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
  ],
});

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.TASK_LIST_GANGS_SUCCESS:
      return action.gangs;
    default:
      return state;
  }
};

export default reducer;
