import Skeleton from "@mui/material/Skeleton";
import withStyles from "@mui/styles/withStyles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import "../../../../components/Tasks/states.css";

const styles = theme => ({
  title: {
    fontWeight: 400,
  },
  container: {
    paddingBottom: 0,
  },
  contentContainer: {
    paddingBottom: 0,
    paddingTop: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },

  listIcon: {
    minWidth: 40,
  },
  actions: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 15,
    paddingLeft: 15,
  },
  contentText: {
    marginTop: 7.75,
    marginBottom: 7.75,
  },
});

const GroupSkeletonCard = ({ classes }) => {
  return (
    <Card variant="outlined">
      <CardHeader
        className={classes.container}
        title={
          <Typography variant="h6" className={classes.title}>
            <Skeleton animation="wave" width="85%" />
          </Typography>
        }
      />
      <CardContent className={classes.contentContainer}>
        <Skeleton
          animation="wave"
          width="45%"
          height={40}
          className={classes.contentText}
        />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(GroupSkeletonCard);
