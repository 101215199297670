import {
  andMapper,
  gteDateMapper,
  lteDateMapper,
  equalMapper,
  likeMapper,
} from "../mappers";

export const getHidrojetFilters = ({ hidrojetListFilter: state }) => {
  const filters = [];

  const keys = Object.keys(state);
  keys.forEach(k => {
    const value = state[k];

    if (HidrojetFilterskeys.includes(k) && value) {
      const name = HidrojetFilters[k].key;
      const mapped = HidrojetFilters[k].mapper(name, value);
      mapped && filters.push(mapped);
    }
  });

  return filters.length ? andMapper(filters) : null;
};

const HidrojetFilters = {
  dateFrom: {
    key: "TASK_DATE",
    mapper: gteDateMapper,
  },
  dateTo: {
    key: "TASK_DATE",
    mapper: lteDateMapper,
  },
  state: {
    key: "STATE",
    mapper: equalMapper,
  },
};

const HidrojetFilterskeys = Object.keys(HidrojetFilters);

export const getTaskFilters = ({ hidrojetListFilter: state }) => {
  const filters = [];

  const keys = Object.keys(state);
  keys.forEach(k => {
    const value = state[k];

    if (TasksFilterskeys.includes(k) && value) {
      const name = TaskFilters[k].key;
      const mapped = TaskFilters[k].mapper(name, value);
      mapped && filters.push(mapped);
    }
  });

  return filters.length ? andMapper(filters) : null;
};

const TaskFilters = {
  odt: {
    key: "CODIGO_OT",
    mapper: likeMapper,
  },
  group: {
    key: "GRUPO_ID",
    mapper: (key, value) => equalMapper(key, String(value)),
  },
};

const TasksFilterskeys = Object.keys(TaskFilters);
