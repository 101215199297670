import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { ArrowBackOutlined } from "@mui/icons-material";
import { Card, Grid, IconButton, Tooltip } from "@mui/material";

const Title = memo(props => {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(`/grupos`);
  }, [navigate]);

  return (
    <Card variant="outlined" style={{ padding: 15 }}>
      <Grid container alignItems="center">
        <Grid item>
          <Tooltip title="Volver">
            <IconButton
              component="span"
              onClick={goBack}
              style={{ marginRight: 5 }}
              size="large">
              <ArrowBackOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="h5" style={{ fontWeight: 300 }}>
            {props.isEdit ? "Editar grupo" : "Nuevo grupo"}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
});

export default Title;
