import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

const styles = theme => ({
  tableContainer: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    marginBottom: 20,
  },
  toolbar: { paddingRight: 0 },
});

const taskComment = props => {
  return (
    <div className={props.classes.tableContainer}>
      <Toolbar className={props.classes.toolbar}>
        <Typography variant="h5">Observaciones</Typography>
      </Toolbar>
      <Toolbar className={props.classes.toolbar}>
        <Typography>{props.text}</Typography>
      </Toolbar>
    </div>
  );
};

export default withStyles(styles)(taskComment);
