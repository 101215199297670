import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ReorderTaskCard from "./ReorderTaskCard";

export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: "10px",
  };

  return (
    <div style={style} ref={setNodeRef} {...attributes}>
      <ReorderTaskCard
        {...props}
        activatorNodeRef={setActivatorNodeRef}
        listeners={{ ...listeners }}
      />
    </div>
  );
}
