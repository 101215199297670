import { connect } from "react-redux";

import {
  closeModal,
  changeComment,
  confirm,
} from "../../../../store/ducks/taskListCancelTask";
import CancelDialog from "./cancelDialogContainer/CancelDialog";

const mapStateToProps = state => ({
  openModal: state.taskListCancelTask.openModal,
  loading: state.taskListCancelTask.loading,
  task: state.taskListCancelTask.task,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  confirm: () => dispatch(confirm()),
  changeComment: comment => dispatch(changeComment(comment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelDialog);
