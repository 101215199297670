import { removeAccents } from "../../utils/removeAccents";

const types = {
  GANGS_LIST_CHANGE_SEARCH: "GANGS_LIST_CHANGE_SEARCH",
  GANGS_LIST_SUCCESS: "GANGS_LIST_SUCCESS",
};

export const changeSearchGangs = search => ({
  type: types.GANGS_LIST_CHANGE_SEARCH,
  search,
});

export const successGangsList = (gangs, groupGangs) => ({
  type: types.GANGS_LIST_SUCCESS,
  gangs: [
    ...groupGangs.data
      .filter(gang => !!gangs.find(g => g._id === gang.id && g.active))
      .map(g => {
        const gangData = gangs.find(ga => ga._id === g.id);

        return {
          ...g,
          name: g.cuadrilla,
          appVersion: g.app_version,
          image: gangData?.image
            ? `${process.env.REACT_APP_GANGS_IMAGES_URI}/${gangData.image}`
            : null,
        };
      })
      .sort((a, b) => a.cuadrilla.localeCompare(b.cuadrilla)),
  ],
});

const initialState = { search: "", gangs: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GANGS_LIST_CHANGE_SEARCH:
      return { ...state, search: action.search };

    case types.GANGS_LIST_SUCCESS:
      return { ...state, gangs: action.gangs };

    default:
      return state;
  }
};

export default reducer;

export const getSearchedGangs = state => {
  const search = removeAccents((state.gangList.search || "").toLowerCase()).split(" ");
  return state.gangList.gangs.filter(({ name, appVersion, grupo }) =>
    search.every(
      word =>
        removeAccents(name?.toLowerCase()).includes(word) ||
        removeAccents(grupo?.nombre?.toLowerCase()).includes(word) ||
        appVersion?.toLowerCase().includes(word)
    )
  );
};
