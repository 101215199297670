/* eslint-disable jsx-a11y/no-autofocus */
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { TextField, Grid } from "@mui/material";

import Map from "./map";
import { calculateGeoPoint } from "../../../../../geocode";

const uniqAtt = (elements, pathToAtt, postProcess = () => true) => {
  const dictionary = {};
  const uniques = [];

  const pathParts = pathToAtt.split(".");

  for (const element of elements) {
    const att = pathParts.reduce((att, p) => att[p], element);
    const post = postProcess(att);

    if (!dictionary[post]) uniques.push(element);
    dictionary[post] = true;
  }

  return uniques;
};

// regex = todo lo que no es parentesis digitos parentesis al final del string
const postProcess = name =>
  name
    .trim()
    .match(/[^(\d*)$]*/g)
    .join("")
    .trim();

export const NewTaskStep2 = props => {
  const {
    form: {
      control,
      setError,
      setValue,
      register,
      setFocus,
      getValues,
      clearErrors,
      formState: { errors },
    },
  } = props;

  const [marker, setMarker] = useState(null);
  const cities = useSelector(state => state.cities);
  const filteredCities = uniqAtt(cities, "name", postProcess);

  useEffect(() => {
    register("step2.localidad", { required: true });
    const { step2 } = getValues();
    if (step2.position) setMarker(step2.position);
    setFocus("step2.calle");
  }, [register, getValues, setFocus]);

  const onLocationChange = useCallback(async () => {
    if (!errors.step2 || !Object.values(errors.step2).filter(a => a).length) {
      const fields = getValues("step2");
      const position = await calculateGeoPoint(fields);
      if (position) {
        setMarker(position);
        setValue("step2.position", position);
      }
    }
  }, [errors, setMarker, setValue, getValues]);

  const onMarkerMove = useCallback(
    position => {
      if (position) {
        setMarker(position);
        setValue("step2.custom_geo", true);
        setValue("step2.position", position);
      }
    },
    [setMarker, setValue]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step2.calle"
          rules={{
            required: "Debes ingresar la calle",
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              required
              fullWidth
              autoFocus
              {...field}
              label="Calle"
              margin="dense"
              variant="outlined"
              onChange={e => {
                if (field.onChange) field.onChange(e);
                onLocationChange();
              }}
              error={errors.step2?.calle}
              helperText={errors.step2?.calle?.message}
              inputProps={{ maxLength: 200, autoFocus: true }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step2.altura"
          rules={{
            required: "Debes ingresar la altura",
            maxLength: { value: 10, message: "Debe tener menos de 10 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              required
              fullWidth
              {...field}
              label="Altura"
              margin="dense"
              variant="outlined"
              onChange={e => {
                if (field.onChange) field.onChange(e);
                onLocationChange();
              }}
              error={errors.step2?.altura}
              inputProps={{ maxLength: 10 }}
              helperText={errors.step2?.altura?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step2.entre"
          rules={{
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Entre"
              margin="dense"
              variant="outlined"
              onChange={e => {
                if (field.onChange) field.onChange(e);
                onLocationChange();
              }}
              error={errors.step2?.entre}
              inputProps={{ maxLength: 200 }}
              helperText={errors.step2?.entre?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step2.y"
          rules={{
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Y"
              margin="dense"
              variant="outlined"
              onChange={e => {
                if (field.onChange) field.onChange(e);
                onLocationChange();
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          options={filteredCities}
          noOptionsText="Sin Resultados"
          style={{ width: "100%" }}
          value={getValues("step2.localidad")}
          getOptionLabel={option => option.name}
          onChange={(e, value) => {
            e.preventDefault();
            e.stopPropagation();
            if (value) {
              clearErrors("step2");
              setValue("step2.localidad", value);
              onLocationChange();
            } else {
              setError("step2.localidad", {
                type: "required",
                message: "Debes indicar la localidad de la tarea.",
              });
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              required
              margin="dense"
              label="Localidad"
              variant="outlined"
              error={errors.step2?.localidad}
              helperText={errors.step2?.localidad?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Controller
          control={control}
          name="step2.contact_phone"
          rules={{
            required: false,
            minLength: { value: 5, message: "Debe tener al menos 5 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              label="Teléfono de contacto"
              error={errors.step2?.contact_phone}
              inputProps={{ maxLength: 200, minLength: 5 }}
              helperText={errors.step2?.contact_phone?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Controller
          control={control}
          name="step2.aclaracion"
          rules={{
            required: true,
            minLength: { value: 5, message: "Debe tener al menos 5 caracteres." },
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              fullWidth
              variant="outlined"
              label="Aclaraciones"
              error={errors.step2?.aclaracion}
              inputProps={{ maxLength: 200, minLength: 5 }}
              helperText={errors.step2?.aclaracion?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Map marker={marker} onMarkerMove={onMarkerMove} />
      </Grid>
    </Grid>
  );
};

export default NewTaskStep2;
