/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { Grid, Button } from "@mui/material";

import Step1 from "./fields";
import parseData from "../parseData";
import axios from "../../../../axios";
import Step1Validator from "./fields/validator";
import { enqueueSuccess, enqueueError } from "../../../../store/ducks/notifications";

const steps = {
  1: Step1,
};

const validators = {
  1: Step1Validator,
};

export default ({ form, isEdit, ...props }) => {
  const {
    getValues,
    formState: { errors },
  } = form;
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const Step = steps[step];

  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(`/grupos`);
  }, [navigate]);

  const group = `step${step}`;

  const isValid = validators[step]
    ? validators[step](getValues() || {}) &&
      !Object.values(errors[group] || {}).filter(a => a).length
    : true;

  const dispatch = useDispatch();

  useEffect(() => {
    const listener = event => {
      const activeElement = document.activeElement;
      if (
        activeElement &&
        (["SELECT"].includes(activeElement.tagName) ||
          [...activeElement.classList].some(className =>
            [
              "MuiSelect-select",
              "MuiMenuItem-root",
              "MuiInputBase-root",
              "MuiFormControl-root",
              "MuiIconButton-edgeEnd",
            ].includes(className)
          ))
      )
        return;
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (!!isValid) {
          onSubmit();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isValid, setStep, step]);

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    if (isEdit) {
      axios
        .patch(`/groups/${props.group.id}`, parseData(getValues()))
        .then(() => {
          navigate(`/grupos/`);
          dispatch(enqueueSuccess("El grupo fue actualizado correctamente."));
        })
        .catch(() => {
          dispatch(
            enqueueError(
              "Ocurrió un error al actualizar el grupo. Por favor intenta nuevamente."
            )
          );
        })
        .finally(() => setLoading(false));
    } else {
      axios
        .post("/groups", parseData(getValues()))
        .then(response => {
          const { grupo } = response.data;
          navigate(`/grupos/${grupo.id}/`);
          dispatch(enqueueSuccess("El grupo fue creado correctamente."));
        })
        .catch(() => {
          dispatch(
            enqueueError(
              "Ocurrió un error al crear el grupo. Por favor intenta nuevamente."
            )
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <form>
      <Grid container spacing={10}>
        <Grid item style={{ paddingBottom: 20, width: "100%" }}>
          <Step form={form} />

          <Button
            fullWidth
            disableElevation
            onClick={goBack}
            variant="outlined"
            style={{ marginBottom: 15 }}>
            Cancelar
          </Button>

          <LoadingButton
            fullWidth
            color="primary"
            disableElevation
            onClick={onSubmit}
            variant="contained"
            loading={loading}
            style={{ marginBottom: 15 }}
            disabled={!isValid || !!Object.keys(form.formState.errors).length}>
            Guardar
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
