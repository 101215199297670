const types = {
  GANGS_PIN_SET: "GANGS_PIN_SET",
  GANGS_PIN_CLEAR: "GANGS_PIN_CLEAR",
};

export const setPin = (gang, pin, timeout) => ({
  type: types.GANGS_PIN_SET,
  gang,
  pin,
  timeout,
});

export const clearPin = gang => ({
  type: types.GANGS_PIN_CLEAR,
  gang,
});

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GANGS_PIN_SET:
      return { ...state, [action.gang.id]: { pin: action.pin, timeout: action.timeout } };

    case types.GANGS_PIN_CLEAR:
      delete state[action.gang.id];
      return { ...state };

    default:
      return state;
  }
};

export default reducer;
