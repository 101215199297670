import axios, { gangs as gangsAxios } from "../../axios";

const types = {
  TASK_DETAILS_REQUEST: "TASK_DETAILS_REQUEST",
  TASK_DETAILS_SUCCESS: "TASK_DETAILS_SUCCESS",
  TASK_DETAILS_FAILURE: "TASK_DETAILS_FAILURE",
  TASK_DETAILS_CLEARED: "TASK_DETAILS_CLEARED",
};

const requestTask = () => ({
  type: types.TASK_DETAILS_REQUEST,
});

const successTask = (task, actions, changes, parentTask) => ({
  type: types.TASK_DETAILS_SUCCESS,
  actions,
  changes,
  task,
  parentTask,
});

const failedTask = () => ({
  type: types.TASK_DETAILS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener la tarea",
});

export const clearTask = () => ({
  type: types.TASK_DETAILS_CLEARED,
});

const parseTask = (task, gangs = []) => {
  return {
    ...task,
    materiales: task.materiales.map(article => ({
      ...article,
      gang: gangs.find(gang => gang._id === article.id_cuadrilla),
    })),
  };
};

export const getTask = id => {
  return async dispatch => {
    dispatch(requestTask());
    try {
      const promises = [
        gangsAxios.get("/"),
        axios.get(`/tasks/${id}`),
        axios.get(`/tasks/${id}/actions`),
        axios.get(`/tasks/${id}/changes`),
      ];
      const [gangs, task, actions, changes] = await Promise.all(promises);

      let parentTask = null;
      if (task.data.data.parent_id) {
        parentTask = await axios.get(`/tasks/${task.data.data.parent_id}`);
        parentTask = parentTask.data.data;
      }

      dispatch(
        successTask(
          parseTask(task.data.data, gangs.data),
          actions.data.actions,
          changes.data.data,
          parentTask
        )
      );
    } catch (error) {
      console.log(error);
      dispatch(failedTask());
    }
  };
};

const initialState = { task: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_DETAILS_SUCCESS:
      return {
        ...state,
        task: action.task,
        actions: action.actions,
        changes: action.changes,
        parentTask: action.parentTask,
      };
    case types.TASK_DETAILS_CLEARED:
      return { ...state, task: null, parentTask: null };
    default:
      return state;
  }
};

export default reducer;
