/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";
import { MenuItem, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const styles = theme => ({
  form: {
    // maxWidth: 450,
    // maxHeight: 290,
    marginBottom: theme.spacing(2),
  },
  input: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
});

const SidewalkDialog = props => {
  const { classes, closeModal, openModal, save } = props;

  const [withSidewalk, setWithSidewalk] = useState(null);
  const [sidewalkType, setsidewalkType] = useState(null);
  const [sidewalkUnit, setsidewalkUnit] = useState(null);
  const [sidewalkState, setSidewalkState] = useState(null);
  const [sidewalkMaxAmount, setsidewalkMaxAmount] = useState(null);
  const [sidewalkMinAmount, setsidewalkMinAmount] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const initialize = () => {
    setsidewalkType(props.sidewalkType);
    setsidewalkUnit(props.sidewalkUnit);
    setWithSidewalk(props.withSidewalk);
    setSidewalkState(props.sidewalkState);
    setsidewalkMaxAmount(props.sidewalkMaxAmount);
    setsidewalkMinAmount(props.sidewalkMinAmount);
  };

  useEffect(() => {
    props.getSidewalkUnits();
    props.getSidewalkTypes();
  }, []);

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({
          withSidewalk,
          sidewalkType,
          sidewalkUnit,
          sidewalkState,
          sidewalkMaxAmount,
          sidewalkMinAmount,
        });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [
    save,
    openModal,
    withSidewalk,
    sidewalkType,
    sidewalkUnit,
    sidewalkState,
    sidewalkMaxAmount,
    sidewalkMinAmount,
  ]);

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}
      TransitionProps={{
        onEnter: initialize,
        onExited: initialize,
      }}>
      <DialogTitle>Vereda</DialogTitle>
      <DialogContent dividers>
        <Typography
          component="p"
          variant="body2"
          color="textSecondary"
          style={{ marginBottom: 20 }}>
          Ingrese los datos de vereda por los que desea filtrar.
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Estado"
                  variant="outlined"
                  SelectProps={{
                    native: isMobile,
                  }}
                  value={sidewalkState}
                  id="standard-sidewalkState"
                  onChange={e => {
                    setSidewalkState(e.target.value);
                  }}
                  disabled={withSidewalk === "0"}>
                  {[
                    { value: null, label: "Sin especificar" },
                    { value: "0", label: "Sin validar" },
                    { value: "1", label: "Validado" },
                  ].map(option =>
                    isMobile ? (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ) : (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  SelectProps={{
                    native: isMobile,
                  }}
                  value={sidewalkType}
                  variant="outlined"
                  id="standard-type"
                  label="Tipo de Vereda"
                  onChange={e => {
                    setsidewalkType(e.target.value);
                  }}
                  disabled={withSidewalk === "0"}>
                  <MenuItem key={0} value={null}>
                    Sin especificar
                  </MenuItem>
                  {props.types.map(u =>
                    isMobile ? (
                      <option key={u.id} value={u.id}>
                        {u.name}
                      </option>
                    ) : (
                      <MenuItem key={u.id} value={u.id}>
                        {u.name}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  SelectProps={{
                    native: isMobile,
                  }}
                  variant="outlined"
                  id="standard-unit"
                  value={sidewalkUnit}
                  label="Unidad de Medida"
                  onChange={e => {
                    setsidewalkUnit(e.target.value);
                  }}
                  disabled={withSidewalk === "0"}>
                  <MenuItem key={0} value={null}>
                    Sin especificar
                  </MenuItem>
                  {props.units.map(u =>
                    isMobile ? (
                      <option key={u.id} value={u.id}>
                        {u.name}
                      </option>
                    ) : (
                      <MenuItem key={u.id} value={u.id}>
                        {u.name}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="standard-min"
                  variant="outlined"
                  label="Cant. Mínima"
                  sidewalkType="number"
                  inputProps={{ min: 0 }}
                  style={{ marginTop: 10 }}
                  value={sidewalkMinAmount}
                  disabled={withSidewalk === "0"}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setsidewalkMinAmount(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  min={0}
                  fullWidth
                  id="standard-max"
                  variant="outlined"
                  label="Cant. Máxima"
                  sidewalkType="number"
                  inputProps={{ min: 0 }}
                  style={{ marginTop: 10 }}
                  value={sidewalkMaxAmount}
                  disabled={withSidewalk === "0"}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setsidewalkMaxAmount(e.target.value)}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() =>
            save({
              withSidewalk,
              sidewalkType,
              sidewalkUnit,
              sidewalkState,
              sidewalkMaxAmount,
              sidewalkMinAmount,
            })
          }>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(SidewalkDialog);
