import format from "date-fns/format";
import parse from "date-fns/parse";

export const parseFromApi = gangs => {
  const statements = [];
  gangs.forEach(gang => {
    gang.declaraciones.forEach(gangStatement => {
      const statement = JSON.parse(gangStatement.declaracion);
      statements.push({
        id: `${gangStatement.id}${gangStatement.cuadrilla_id}`,
        gang: { id: gang.id, name: gang.cuadrilla },
        ...statement,
        date: format(parse(statement.date, "yyyy-M-d", new Date()), "dd/MM/yyyy"),
      });
    });
  });
  return statements;
};
