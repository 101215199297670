import { connect } from "react-redux";
import { SnackbarProvider } from "notistack";
import { Routes, Route } from "react-router-dom";

import esLocale from "date-fns/locale/es";

import CssBaseline from "@mui/material/CssBaseline";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import Notifier from "./components/UI/Notifier";

import AuthError from "./components/UI/AuthError";
import RoleError from "./components/UI/RoleError";
import Layout from "./components/UI/Layout/Layout";
import AppLoading from "./components/UI/AppLoading";
import Map from "./pages/MapContainer";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import Versioning from "./pages/Versioning";
import NewTask from "./pages/newTaskContainer";
import NewGroup from "./pages/NewGroupContainer";
import EditTask from "./pages/EditTaskContainer";
import TaskList from "./pages/TaskListContainer";
import GangsList from "./pages/GangsListContainer";
import EditGroup from "./pages/EditGroupContainer";
import GroupsList from "./pages/GroupsListContainer";
import GroupGangs from "./pages/GroupGangsContainer";
import TaskDetails from "./pages/TaskDetailsContainer";
import DownloadTasks from "./pages/DownloadTasksContainer";
// import StatementList from "./pages/StatementListContainer";
import TaskValidation from "./pages/TaskValidationContainer";
import HidrojetListContainer from "./pages/HidrojetListContainer";
import GangsReorderTasks from "./pages/gangsReorderTasksContainer";
import TaskValidationList from "./pages/TaskValidationListContainer";
import TaskSidewalkValidation from "./pages/TaskSidewalkValidationContainer";
import TaskSidewalkListValidation from "./pages/TaskSidewalkValidationListContainer";
import { blue as color } from "@mui/material/colors";
import { Page } from "./components/Router";

const theme = createTheme({
  palette: {
    primary: color,
    background: {
      default: "#F5F5F5",
    },
  },
});

const App = props => {
  const { roles } = props;
  let content = null;
  if (props.authError) {
    content = <AuthError />;
  } else if (props.roleError) {
    content = (
      <Layout>
        <RoleError />
      </Layout>
    );
  } else {
    content = (
      <>
        <Notifier />
        <Layout>
          <Routes>
            {roles && roles.find(role => role === "planilla") ? (
              <Route
                path="/planilla"
                exact
                element={
                  <Page title="Descargar planilla">
                    <DownloadTasks />
                  </Page>
                }
              />
            ) : null}

            {roles && roles.find(role => role === "validarMateriales") ? (
              <Route
                path="/validacion"
                exact
                element={
                  <Page title="Validación de materiales">
                    <TaskValidationList />
                  </Page>
                }
              />
            ) : null}

            {roles && roles.find(role => role === "validarVereda") ? (
              <Route
                exact
                path="/validacion/vereda"
                element={
                  <Page title="Validación de veredas">
                    <TaskSidewalkListValidation />
                  </Page>
                }
              />
            ) : null}

            {roles && roles.find(role => role === "validarHidrojet") ? (
              <Route
                exact
                path="/validacion/hidrojet"
                element={
                  <Page title="Validación de tareas de hidrojet">
                    <HidrojetListContainer />
                  </Page>
                }
              />
            ) : null}

            {roles && roles.find(role => role === "validarMateriales") ? (
              <Route
                path="/validacion/materiales/:id"
                exact
                element={
                  <Page title="Validación de materiales tarea :id">
                    <TaskValidation />
                  </Page>
                }
              />
            ) : null}

            {roles && roles.find(role => role === "validarVereda") ? (
              <Route
                exact
                path="/validacion/vereda/:id"
                element={
                  <Page title="Validación de vereda tarea :id">
                    <TaskSidewalkValidation />
                  </Page>
                }
              />
            ) : null}

            <Route path="/cuadrillas" exact element={<GangsList />} />

            {roles && roles.find(role => role === "reordenarTareas") ? (
              <Route
                exact
                element={
                  <Page title="Reordenar tareas">
                    <GangsReorderTasks />
                  </Page>
                }
                path="/cuadrillas/:id/tareas/reordenar"
              />
            ) : null}

            {roles && roles.find(role => ["crearGrupos"].includes(role)) ? (
              <Route
                path="/grupos/nuevo"
                exact
                element={
                  <Page title="Nuevo grupo">
                    <NewGroup />
                  </Page>
                }
              />
            ) : null}

            {roles &&
            roles.find(role =>
              ["cambiarGrupo", "editarGrupo, asignarGrupo, desasignarGrupo"].includes(
                role
              )
            ) ? (
              <Route
                path="/grupos"
                exact
                element={
                  <Page title="Grupos">
                    <GroupsList />
                  </Page>
                }
              />
            ) : null}

            {roles && roles.find(role => ["crearGrupos"].includes(role)) ? (
              <Route
                path="/grupos/:id/editar"
                exact
                element={
                  <Page title="Editar grupo">
                    <EditGroup />
                  </Page>
                }
              />
            ) : null}

            {roles &&
            roles.find(role =>
              ["cambiarGrupo", "editarGrupo, asignarGrupo, desasignarGrupo"].includes(
                role
              )
            ) ? (
              <Route
                path="/grupos/:id"
                element={
                  <Page title="Cuadrillas de grupo">
                    <GroupGangs />
                  </Page>
                }
              />
            ) : null}

            <Route
              path="/tarea/:id/editar"
              element={
                <Page title="Editar tarea">
                  <EditTask />
                </Page>
              }
            />
            <Route
              path="/tarea/:id"
              element={
                <Page title="Editar tarea">
                  <TaskDetails />
                </Page>
              }
            />

            <Route
              path="/nueva-tarea"
              exact
              element={
                <Page title="Nueva tarea">
                  <NewTask />
                </Page>
              }
            />
            <Route
              path="/mapa"
              exact
              element={
                <Page title="Mapa">
                  <Map />
                </Page>
              }
            />
            <Route
              path="/actualizaciones"
              exact
              element={
                <Page title="Actualizaciones">
                  <Versioning />
                </Page>
              }
            />
            <Route
              index
              element={
                <Page title="Listado">
                  <TaskList />
                </Page>
              }
            />
          </Routes>
        </Layout>
      </>
    );
  }

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              {!props.loading ? content : <AppLoading />}
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

const mapStateToProps = state => ({
  roles: state.user.roles,
  loading: state.keycloak.loading,
  authError: state.keycloak.authError,
  roleError: state.keycloak.roleError,
});

export default connect(mapStateToProps)(App);
