import axios from "../../axios";

import { enqueueError, enqueueSuccess } from "./notifications";

const types = {
  FINISHED_SIDEWALK_UNDO_MODAL_OPEN: "FINISHED_SIDEWALK_UNDO_MODAL_OPEN",
  FINISHED_SIDEWALK_UNDO_MODAL_CLOSE: "FINISHED_SIDEWALK_UNDO_MODAL_CLOSE",
  FINISH_SIDEWALK_VALIDATION_MODAL_OPEN: "FINISH_SIDEWALK_VALIDATION_MODAL_OPEN",
  FINISH_SIDEWALK_VALIDATION_MODAL_CLOSE: "FINISH_SIDEWALK_VALIDATION_MODAL_CLOSE",

  FINISHED_SIDEWALK_CONFIRMATION_MODAL_OPEN: "FINISHED_SIDEWALK_CONFIRMATION_MODAL_OPEN",
  FINISHED_SIDEWALK_CONFIRMATION_MODAL_CLOSE:
    "FINISHED_SIDEWALK_CONFIRMATION_MODAL_CLOSE",

  FINISH_SIDEWALK_VALIDATION_MATERIALS_MODAL_OPEN:
    "FINISH_SIDEWALK_VALIDATION_MATERIALS_MODAL_OPEN",
  FINISH_SIDEWALK_VALIDATION_MATERIALS_MODAL_CLOSE:
    "FINISH_SIDEWALK_VALIDATION_MATERIALS_MODAL_CLOSE",

  FINISH_SIDEWALK_VALIDATION_MODAL_REQUEST: "FINISH_SIDEWALK_VALIDATION_MODAL_REQUEST",
  FINISH_SIDEWALK_VALIDATION_MODAL_SUCCESS: "FINISH_SIDEWALK_VALIDATION_MODAL_SUCCESS",
  FINISH_SIDEWALK_VALIDATION_MODAL_FAILURE: "FINISH_SIDEWALK_VALIDATION_MODAL_FAILURE",
  FINISH_SIDEWALK_VALIDATION_MODAL_ERROR_OPEN:
    "FINISH_SIDEWALK_VALIDATION_MODAL_ERROR_OPEN",
};

export const openUndoModal = sidewalk => async dispatch => {
  dispatch({
    type: types.FINISHED_SIDEWALK_UNDO_MODAL_OPEN,
    sidewalk,
  });
};

export const closeUndoModal = () => ({
  type: types.FINISHED_SIDEWALK_UNDO_MODAL_CLOSE,
});

export const openConfirmationModal = sidewalk => async dispatch => {
  dispatch({
    type: types.FINISHED_SIDEWALK_CONFIRMATION_MODAL_OPEN,
    sidewalk,
  });
};

export const closeConfirmationModal = () => ({
  type: types.FINISHED_SIDEWALK_CONFIRMATION_MODAL_CLOSE,
});

export const openModal = () => async dispatch => {
  dispatch({
    type: types.FINISH_SIDEWALK_VALIDATION_MODAL_OPEN,
  });
};

export const closeModal = () => ({
  type: types.FINISH_SIDEWALK_VALIDATION_MODAL_CLOSE,
});

export const openValidateMaterialsModal = () => async dispatch => {
  dispatch({
    type: types.FINISH_SIDEWALK_VALIDATION_MATERIALS_MODAL_OPEN,
  });
};

export const closeValidateMaterialsModal = () => ({
  type: types.FINISH_SIDEWALK_VALIDATION_MATERIALS_MODAL_CLOSE,
});

export const openErrorModal = () => ({
  type: types.FINISH_SIDEWALK_VALIDATION_MODAL_ERROR_OPEN,
});

const request = () => ({
  type: types.FINISH_SIDEWALK_VALIDATION_MODAL_REQUEST,
});

const success = () => ({
  type: types.FINISH_SIDEWALK_VALIDATION_MODAL_SUCCESS,
});

const failure = () => ({
  type: types.FINISH_SIDEWALK_VALIDATION_MODAL_FAILURE,
});

export const confirmSidewalk = () => async (dispatch, getState) => {
  const { finishSidewalkValidationModal } = getState();
  const { sidewalk } = finishSidewalkValidationModal;
  dispatch(request());

  try {
    sidewalk.state = 1;
    await axios.patch(`/sidewalk/${sidewalk.id}/`, sidewalk);
    dispatch(success());
    dispatch(closeConfirmationModal());
    dispatch(enqueueSuccess("La información de la vereda fue validada correctamente"));
  } catch (error) {
    sidewalk.state = 0;
    dispatch(failure());
    dispatch(enqueueError("Ocurrió un error al confirmar la validación"));
  }
};

export const confirmUndoSidewalk = () => async (dispatch, getState) => {
  const { finishSidewalkValidationModal } = getState();
  const { sidewalk } = finishSidewalkValidationModal;
  dispatch(request());

  try {
    sidewalk.state = 0;
    await axios.patch(`/sidewalk/${sidewalk.id}/`, sidewalk);
    dispatch(success());
    dispatch(closeUndoModal());
    dispatch(enqueueSuccess("Se canceló la validación de la vereda correctamente"));
  } catch (error) {
    sidewalk.state = 1;
    dispatch(failure());
    dispatch(enqueueError("Ocurrió un error al confirmar la validación"));
  }
};

const initialState = {
  sending: false,
  confirmed: false,
  modalOpen: false,
  undoModalOpen: false,
  modalErrorOpen: false,
  confirmationModalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FINISHED_SIDEWALK_UNDO_MODAL_OPEN:
      return { ...state, undoModalOpen: true, sidewalk: action.sidewalk };

    case types.FINISHED_SIDEWALK_UNDO_MODAL_CLOSE:
      return { ...state, undoModalOpen: false };

    case types.FINISHED_SIDEWALK_CONFIRMATION_MODAL_OPEN:
      return { ...state, confirmationModalOpen: true, sidewalk: action.sidewalk };

    case types.FINISHED_SIDEWALK_CONFIRMATION_MODAL_CLOSE:
      return { ...state, confirmationModalOpen: false };

    case types.FINISH_SIDEWALK_VALIDATION_MODAL_OPEN:
      return { ...state, modalOpen: true };

    case types.FINISH_SIDEWALK_VALIDATION_MATERIALS_MODAL_OPEN:
      return { ...state, modalMaterialsOpen: true };

    case types.FINISH_SIDEWALK_VALIDATION_MODAL_CLOSE:
      return { ...state, modalOpen: false, modalErrorOpen: false };

    case types.FINISH_SIDEWALK_VALIDATION_MATERIALS_MODAL_CLOSE:
      return { ...state, modalMaterialsOpen: false };

    case types.FINISH_SIDEWALK_VALIDATION_MODAL_ERROR_OPEN:
      return { ...state, modalErrorOpen: true };

    case types.FINISH_SIDEWALK_VALIDATION_MODAL_REQUEST:
      return { ...state, sending: true };

    case types.FINISH_SIDEWALK_VALIDATION_MODAL_SUCCESS:
      return { ...state, sending: false, modalOpen: false };

    case types.FINISH_SIDEWALK_VALIDATION_MODAL_FAILURE:
      return { ...state, sending: false };

    default:
      return state;
  }
};

export default reducer;
