import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Grid } from "@mui/material";

const styles = theme => ({
  title: {
    flex: "0 0 auto",
  },
  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3.5),
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  actions: {
    color: theme.palette.text.secondary,
    marginLeft: "auto",
    marginRight: theme.spacing(3),
  },
  searchIcon: {
    color: "rgba(0,0,0,0.54)",
    marginRight: 7,
  },
  groupSelect: {
    margin: theme.spacing(1),
    width: "96%",
  },
});

const toolbar = props => {
  const { classes } = props;
  return (
    <Toolbar
      className={classNames(classes.toolbar, {
        [classes.highlight]: false,
      })}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item xs={12} lg={8}>
          <div style={{ display: "flex" }}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              fullWidth
              disableUnderline
              value={props.odt}
              placeholder="Buscar por odt"
              onChange={e => props.changeOdt(e.target.value)}
            />
          </div>
        </Grid>

        <Grid item xs={12} lg={1}>
          <FormControl className={classes.groupSelect}>
            <InputLabel id="group-select">Vereda</InputLabel>
            <Select
              disableUnderline
              variant="standard"
              value={props.requiresSidewalk}
              onChange={e => props.changeSidewalk(e.target.value)}>
              <MenuItem value={""}>Todos</MenuItem>
              <MenuItem key={"0"} value={1}>
                No
              </MenuItem>
              <MenuItem key={"1"} value={2}>
                Sí
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={1}>
          <FormControl className={classes.groupSelect}>
            <InputLabel id="group-select">Aporte</InputLabel>
            <Select
              disableUnderline
              variant="standard"
              value={props.requiresFilling}
              onChange={e => props.changeFilling(e.target.value)}>
              <MenuItem value={""}>Todos</MenuItem>
              <MenuItem key={"0"} value={1}>
                No
              </MenuItem>
              <MenuItem key={"1"} value={2}>
                Sí
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={1}>
          <FormControl className={classes.groupSelect}>
            <InputLabel id="group-select">Limpieza</InputLabel>
            <Select
              disableUnderline
              variant="standard"
              value={props.requiresCleaning}
              onChange={e => props.changeCleaning(e.target.value)}>
              <MenuItem value={""}>Todos</MenuItem>
              <MenuItem key={"0"} value={1}>
                No
              </MenuItem>
              <MenuItem key={"1"} value={2}>
                Sí
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={1}>
          <FormControl className={classes.groupSelect}>
            <InputLabel id="group-select">Grupo</InputLabel>
            <Select
              disableUnderline
              value={props.group}
              variant="standard"
              onChange={e => props.changeGroup(e.target.value)}>
              <MenuItem value={0}>Todos</MenuItem>
              {props.groups.map(group => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default withStyles(styles)(toolbar);
