/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";

import { getGroups } from "../../../../store/ducks/groups";

export const EditTaskStep3 = props => {
  const {
    showGroups,
    form: { register, setValue, control, getValues, watch, clearErrors },
  } = props;

  const dispatch = useDispatch();
  const type = watch("step1.tipo");
  const grupoValue = watch("step3.grupo");
  const storeValue = watch("step3.stores");

  const stores = useSelector(state => state.storesList);
  const groups = useSelector(state => state.groups.groups);
  const isLoading = useSelector(state => state.groups.isLoading);

  useEffect(() => {
    const {
      step2: { localidad },
    } = getValues();
    dispatch(getGroups(localidad.id));
    register("step3.grupo");
    register("step3.stores");
  }, [register, dispatch, getValues]);

  // useEffect(() => {
  //   // setValue("step3.grupo", showGroups ? grupoValue : null);
  // }, [showGroups]);

  let storesIds = grupoValue ? grupoValue.stores : props.task.stores;
  storesIds = typeof storesIds === "string" ? JSON.parse(storesIds) : storesIds;

  const groupsOptions = useMemo(
    () =>
      groups.filter(
        g => !type || !g.taskTypes || !g.taskTypes.length || g.taskTypes.includes(type.id)
      ),
    [groups, type]
  );

  const storesOptions = useMemo(
    () =>
      grupoValue || props.task.group
        ? stores.filter(s => grupoValue.stores && storesIds.includes(s._id) && s.active)
        : [],
    [grupoValue, props.task, stores]
  );

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        {showGroups ? (
          <Grid item xs={12}>
            <Autocomplete
              required
              control={control}
              disableClearable
              value={grupoValue}
              variant="outlined"
              isLoading={isLoading}
              options={groupsOptions}
              loadingText="Cargando..."
              styles={{ width: "100%" }}
              noOptionsText="Sin Resultados"
              getOptionLabel={option => option.name}
              onChange={(e, value) => {
                e.stopPropagation();
                setValue("step3.grupo", value);
                clearErrors("step3");
                if (value && value.fills_pipeline_information && type.etapa_id === 1) {
                  setValue("step3.requires_pipeline_information", true);
                } else {
                  setValue("step3.requires_pipeline_information", false);
                }
                if (value && value.stores && value.stores.length) {
                  setValue(
                    "step3.stores",
                    stores.filter(s => value.stores.includes(s._id))
                  );
                } else {
                  setValue("step3.stores", []);
                }
              }}
              defaultValue={{ id: props.task.grupo_id, name: props.task.grupo.nombre }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label="Grupo"
                  error={!grupoValue}
                  variant="outlined"
                  helperText={!grupoValue ? "Seleccione el grupo de la tarea." : null}
                />
              )}
            />
          </Grid>
        ) : null}

        {grupoValue && grupoValue.has_contractors ? (
          <Grid item xs={12} lg={12}>
            <FormControl style={{ width: "100%" }} margin="dense" variant="outlined">
              <InputLabel id="input-for-contractor">Distribución</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={true}
                    label="Distribución"
                    labelId="input-for-contractor">
                    <MenuItem value={null}>Sin especificar</MenuItem>
                    <MenuItem value={false}>Fuerza propia</MenuItem>
                    <MenuItem value={true}>Subcontratista</MenuItem>
                  </Select>
                )}
                name="step3.for_contractor"
                control={control}
              />
            </FormControl>
          </Grid>
        ) : null}

        {grupoValue && grupoValue.fills_pipeline_information ? (
          <Grid item xs={12} lg={12}>
            <FormControl style={{ width: "100%" }} margin="dense" variant="outlined">
              <InputLabel id="input-red">Requiere datos de Red</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={true}
                    labelId="input-red"
                    label="Requiere datos de Red">
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Sí</MenuItem>
                  </Select>
                )}
                name="step3.requires_pipeline_information"
                control={control}
              />
            </FormControl>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Autocomplete
            required
            multiple
            control={control}
            disableClearable
            variant="outlined"
            value={storeValue}
            options={storesOptions}
            defaultValue={storeValue}
            styles={{ width: "100%" }}
            noOptionsText="Sin Resultados"
            disabled={!storesOptions || !storesOptions.length}
            onChange={(e, value) => {
              e.stopPropagation();
              setValue("step3.stores", value);
            }}
            getOptionLabel={option => `${option.name} - ${option.type.name}`}
            renderInput={params => (
              <TextField
                {...params}
                required
                variant="outlined"
                label="Depósitos Asignados"
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EditTaskStep3;
