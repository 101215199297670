import { Component } from "react";
import { connect } from "react-redux";

import ApiError from "../components/UI/ApiError";
import { getGroups } from "../store/ducks/groups";
import { getTasks } from "../store/ducks/hidrojetList";

import HidrojetList from "./hidrojetListContainer/HidrojetList";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

class HidrojetListContainer extends Component {
  componentDidMount() {
    this.props.getTasks(1, true);
    this.props.getGroups();
  }

  render() {
    const { error } = this.props;
    return !error ? <HidrojetList {...this.props} /> : <ApiError error={error} />;
  }
}

const actionNames = ["HIDROJET_LIST", "GROUPS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(getGroups()),
  getTasks: (page, reset) => dispatch(getTasks(page, reset)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HidrojetListContainer);
