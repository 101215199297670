import escapeStringRegexp from "escape-string-regexp";
import subDays from "date-fns/subDays";
import axios from "../../axios";

import { parseFromApi as parse } from "../../utils/MapTaskParser";

const types = {
  MAP_TASKS_REQUEST: "MAP_TASKS_REQUEST",
  MAP_TASKS_SUCCESS: "MAP_TASKS_SUCCESS",
  MAP_TASKS_FAILURE: "MAP_TASKS_FAILURE",
  MAP_TASKS_UPDATE_SHEET: "MAP_TASKS_UPDATE_SHEET",
  MAP_TASKS_UPDATE_SUMMARY: "MAP_TASKS_UPDATE_SUMMARY",
};

const request = () => ({
  type: types.MAP_TASKS_REQUEST,
});

const success = tasks => ({
  type: types.MAP_TASKS_SUCCESS,
  tasks: {
    pending: parse(tasks.pending),
    scheduled: parse(tasks.scheduled),
    paused: parse(tasks.paused),
    executing: parse(tasks.executing),
    assigned: parse(tasks.assigned),
  },
});

const failed = () => ({
  type: types.MAP_TASKS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las tareas",
});

export const getTasks = () => {
  return async dispatch => {
    dispatch(request());
    try {
      const [
        pendingResponse,
        scheduledResponse,
        pausedResponse,
        executingResponse,
        assignedResponse,
      ] = await Promise.all([
        axios.get("/tasks/state/1"),
        axios.get("/tasks/state/2"),
        axios.get("/tasks/state/3"),
        axios.get("/tasks/state/4"),
        axios.get("/tasks/state/11"),
      ]);

      const { data: pending } = pendingResponse;
      const { data: scheduled } = scheduledResponse;
      const { data: paused } = pausedResponse;
      const { data: executing } = executingResponse;
      const { data: assigned } = assignedResponse;

      dispatch(
        success({
          pending: pending.data,
          scheduled: scheduled.data,
          paused: paused.data,
          executing: executing.data,
          assigned: assigned.data,
        })
      );
    } catch (error) {
      dispatch(failed());
    }
  };
};

const initialState = {
  pending: [],
  scheduled: [],
  paused: [],
  executing: [],
  assigned: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAP_TASKS_SUCCESS:
      return action.tasks;
    default:
      return state;
  }
};

export default reducer;

const genRegex = string => new RegExp(`.*${escapeStringRegexp(string)}.*`, "i");

export const getSelectedTasks = state => {
  let tasks = [];
  const groups = state.groups.groups.filter(g => g.selected);
  if (state.mapInfoPanel.pendingTasks) {
    tasks = [...tasks, ...state.mapTasks.pending];
  }
  if (state.mapInfoPanel.assignedTasks) {
    tasks = [...tasks, ...state.mapTasks.assigned];
  }
  if (state.mapInfoPanel.programmedTasks) {
    tasks = [...tasks, ...state.mapTasks.scheduled];
  }
  if (state.mapInfoPanel.executingTasks) {
    tasks = [...tasks, ...state.mapTasks.executing];
  }
  if (state.mapInfoPanel.pausedTasks) {
    tasks = [...tasks, ...state.mapTasks.paused];
  }
  const fields = state.taskFilters.fields;
  return tasks.filter(
    t =>
      groups.find(g => g.id === t.group.id) &&
      (!fields ||
        (t.otCode.match(genRegex(fields.odt)) &&
          t.task.match(genRegex(fields.task)) &&
          t.street.match(genRegex(fields.street)) &&
          t.number.match(genRegex(fields.number)) &&
          t.between.match(genRegex(fields.between)) &&
          t.and.match(genRegex(fields.and)) &&
          (!fields.type || fields.type.id === t.typeId) &&
          (!fields.from || subDays(fields.from, 1) < t.date) &&
          (!fields.to || fields.to > t.date) &&
          (!fields.taskGang || fields.taskGang.id === t.gangId)))
  );
};
