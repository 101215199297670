import throttle from "lodash/throttle";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";

import createRootReducer from "./reducers";
import { loadState, saveState } from "./localStorage";

const publicUrl = document.createElement("a");
publicUrl.href = process.env.PUBLIC_URL;

export default function configureStore() {
  const persistedState = loadState();
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(), // root reducer with router state
    persistedState,
    composeEnhancer(applyMiddleware(thunkMiddleware))
  );
  store.subscribe(
    throttle(() => {
      const state = store.getState();
      saveState({
        taskListShowAllInfo: state.taskListShowAllInfo,
        taskListOrder: { ...state.taskListOrder, openModal: false },
        taskListTaskFilter: { ...state.taskListTaskFilter, openModal: false },
        taskListStateFilter: { ...state.taskListStateFilter, openModal: false },
        taskListTypesFilter: { ...state.taskListTypesFilter, openModal: false },
        taskListDateFilters: { ...state.taskListDateFilters, openModal: false },
        taskListAddressFilter: { ...state.taskListAddressFilter, openModal: false },
        taskListSidewalkFilter: { ...state.taskListSidewalkFilter, openModal: false },
        taskListHistoricFilter: { ...state.taskListHistoricFilter, openModal: false },
        taskListHidrojetFilter: { ...state.taskListHidrojetFilter, openModal: false },
        taskListContractorFilter: { ...state.taskListContractorFilter, openModal: false },

        taskListTaskConditionsFilter: {
          ...state.taskListTaskConditionsFilter,
          openModal: false,
        },

        taskListGroupFilter: {
          ...state.taskListGroupFilter,
          dialogGroups: [],
          openModal: false,
        },
        taskListGangFilter: {
          ...state.taskListGangFilter,
          gangs: [],
          dialogGangs: [],
          openModal: false,
        },
        taskListCityFilter: {
          ...state.taskListCityFilter,
          dialogCities: [],
          openModal: false,
        },
        taskListStateChangesFilter: {
          ...state.taskListStateChangesFilter,
          openModal: false,
        },
      });
    }, 1000)
  );
  return { store, history };
}
