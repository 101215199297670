/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import ApiError from "../components/UI/ApiError";
import GangsList from "./groupGangsContainer/GroupGangs";
import { getGroupGangs } from "../store/ducks/groupGangs";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

const GroupsContainer = props => {
  const params = useParams();

  useEffect(() => {
    props.getGroupGangs(params.id);
  }, [params]);

  const { error } = props;
  return error ? (
    <ApiError error="Ocurrió un error al obtener los grupos" />
  ) : (
    <GangsList {...props} />
  );
};

const actionNames = ["GROUP_GANGS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  roles: state.user.roles,
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getGroupGangs: id => dispatch(getGroupGangs(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
