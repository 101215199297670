export const parseFromApi = stock => {
  return stock.map(origin => ({
    origin: {
      id: origin.id_procedencia,
      name: origin.procedencia,
    },
    stock: origin.stock.map(article => ({
      id: Number(article.id_articulo),
      name: article.descripcion,
      code: article.cod_equivalente,
      amount: Math.min(Number(article.disponible), Number(article.stock)),
      max: Math.min(Number(article.disponible), Number(article.stock)),
      unit: article.unidad_medida,
    })),
  }));
};
