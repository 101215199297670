import axios from "../../axios";
import { enqueueSuccess, enqueueError } from "./notifications";

const types = {
  GANGS_TASK_REORDER_REQUEST: "GANGS_TASK_REORDER_REQUEST",
  GANGS_TASK_REORDER_SUCCESS: "GANGS_TASK_REORDER_SUCCESS",
  GANGS_TASK_REORDER_FAILURE: "GANGS_TASK_REORDER_FAILURE",
  GANGS_TASK_REORDER_SET_LOADING: "GANGS_TASK_REORDER_SET_LOADING",
  GANGS_TASK_REORDER_SET_NEW_ORDER: "GANGS_TASK_REORDER_SET_NEW_ORDER",
};

const request = () => ({
  type: types.GANGS_TASK_REORDER_REQUEST,
});

const setLoading = value => ({
  type: types.GANGS_TASK_REORDER_SET_LOADING,
  value,
});

const success = (gang, tasks, taskInExecution) => ({
  type: types.GANGS_TASK_REORDER_SUCCESS,
  taskInExecution,
  tasks,
  gang,
});

const failure = () => ({
  type: types.GANGS_TASK_REORDER_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las tareas de la cuadrilla",
});

export const getReorerTasks = gangId => {
  return async dispatch => {
    dispatch(request());
    try {
      const {
        data: { gang, tasks, task_in_execution },
      } = await axios.get(`/gangs/${gangId}/tasks`);
      dispatch(success(gang, tasks, task_in_execution));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const setNewOrderTask = tasks => ({
  type: types.GANGS_TASK_REORDER_SET_NEW_ORDER,
  tasks,
});

export const saveNewOrder = navigate => {
  return async (dispatch, getState) => {
    const {
      gangTasksReorder: { newTasks, gang },
    } = getState();

    dispatch(setLoading(true));

    try {
      const data = newTasks.reduce(function (acc, cur, i) {
        acc[cur.id] = i;
        return acc;
      }, {});
      await axios.post(`/gangs/${gang.id}/tasks/reorder`, { tareas: data });
      dispatch(enqueueSuccess("Tareas reordenadas correctamente."));
      if (navigate) navigate("/cuadrillas");
    } catch (error) {
      dispatch(enqueueError("Ocurrió un error al reordenar las tareas."));
    }

    dispatch(setLoading(false));
  };
};

const initialState = {
  tasks: [],
  gang: null,
  newTasks: null,
  taskInExecution: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GANGS_TASK_REORDER_SUCCESS:
      return {
        ...state,
        newTasks: null,
        gang: action.gang,
        tasks: action.tasks,
        taskInExecution: action.taskInExecution,
      };

    case types.GANGS_TASK_REORDER_SET_LOADING:
      return { ...state, isLoading: action.value };

    case types.GANGS_TASK_REORDER_SET_NEW_ORDER:
      return {
        ...state,
        newTasks: action.tasks,
      };

    default:
      return state;
  }
};

export default reducer;

export const getTasks = ({ gangTasksReorder }) => {
  return gangTasksReorder.newTasks || gangTasksReorder.tasks;
};
