/* eslint-disable no-empty */

import withStyles from "@mui/styles/withStyles";

import { anexoOld as axios } from "../../../../axios";
import { useSelector, useDispatch } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import { setPin, clearPin } from "../../../../store/ducks/gangsPin";

import CountDownButton from "./countDownButton";
import { useState } from "react";

const styles = () => ({
  button: {
    width: 145,
  },
});

const getEnd = timeout => {
  const date = new Date();
  date.setMilliseconds(date.getMilliseconds() + timeout);
  return date;
};

const getTimeout = endDate =>
  ((endDate.getTime() - new Date().getTime()) / 1000).toFixed(0);

const GangCard = ({ gang, classes }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const data = useSelector(({ gangsPin }) => gangsPin[gang.id]);

  const getPin = async () => {
    setLoading(true);
    try {
      const {
        data: { pin, timeout },
      } = await axios.get(`/cuadrilla/${gang.id}/generar-pin`);
      dispatch(setPin(gang, pin, getEnd(timeout * 1000)));
    } catch (error) {}
    setLoading(false);
  };

  return data ? (
    <CountDownButton
      seconds={getTimeout(data.timeout)}
      waitingContent={`${data?.pin} ({seconds} seg.)`}
      onSubmit={() => {}}
      onFinished={() => {
        dispatch(clearPin(gang));
      }}
    />
  ) : (
    <Button
      size="small"
      style={{ color: "black" }}
      className={classes.button}
      onClick={() => {
        getPin();
      }}>
      {!isLoading ? "Generar Pin" : <CircularProgress size={21} />}
    </Button>
  );
};

export default withStyles(styles)(GangCard);
