import { connect } from "react-redux";

import { openModal as openOrderModal } from "../../../../store/ducks/taskListOrder";
import { toggleShowAllInfo } from "../../../../store/ducks/taskListShowAllInfo";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";

import { toggleOrder } from "../../../../store/ducks/taskListOrder";
import ViewOptions from "./viewOptionsContainer/ViewOptions";

const mapStateToProps = state => ({
  showAllInfo: state.taskListShowAllInfo.showAllInfo,
  selected: state.taskListOrder.selected,
  descOrder: state.taskListOrder.descOrder,
});

const mapDispatchToProps = dispatch => ({
  openOrderModal: () => dispatch(openOrderModal()),
  toggleShowAllInfo: () => dispatch(toggleShowAllInfo()),
  toggleOrder: () => {
    dispatch(toggleOrder());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOptions);
