import escapeStringRegexp from "escape-string-regexp";

const types = {
  MAP_INFO_PANEL_CHANGE_SEARCH: "MAP_INFO_PANEL_CHANGE_SEARCH",
  MAP_INFO_PANEL_TOGGLE_MUTE: "MAP_INFO_PANEL_TOGGLE_MUTE",
  MAP_INFO_PANEL_OPEN: "MAP_INFO_PANEL_OPEN",
  MAP_INFO_PANEL_CLOSE: "MAP_INFO_PANEL_CLOSE",
  MAP_INFO_PANEL_TOGGLE_GANGS: "MAP_INFO_PANEL_TOGGLE_GANGS",
  MAP_INFO_PANEL_TOGGLE_PENDING_TASKS: "MAP_INFO_PANEL_TOGGLE_PENDING_TASKS",
  MAP_INFO_PANEL_TOGGLE_ASSIGNED_TASKS: "MAP_INFO_PANEL_TOGGLE_ASSIGNED_TASKS",
  MAP_INFO_PANEL_TOGGLE_PROGRAMMED_TASKS: "MAP_INFO_PANEL_TOGGLE_PROGRAMMED_TASKS",
  MAP_INFO_PANEL_TOGGLE_EXECUTING_TASKS: "MAP_INFO_PANEL_TOGGLE_EXECUTING_TASKS",
  MAP_INFO_PANEL_TOGGLE_PAUSED_TASKS: "MAP_INFO_PANEL_TOGGLE_PAUSED_TASKS",
};

export const changeSearch = search => ({
  type: types.MAP_INFO_PANEL_CHANGE_SEARCH,
  search,
});

export const toggleMute = () => ({
  type: types.MAP_INFO_PANEL_TOGGLE_MUTE,
});

export const openPanel = () => ({
  type: types.MAP_INFO_PANEL_OPEN,
});

export const closePanel = () => ({
  type: types.MAP_INFO_PANEL_CLOSE,
});

export const toggleGangs = () => ({
  type: types.MAP_INFO_PANEL_TOGGLE_GANGS,
});

export const togglePendingTasks = () => ({
  type: types.MAP_INFO_PANEL_TOGGLE_PENDING_TASKS,
});

export const toggleAssignedTasks = () => ({
  type: types.MAP_INFO_PANEL_TOGGLE_ASSIGNED_TASKS,
});

export const toggleProgrammedTasks = () => ({
  type: types.MAP_INFO_PANEL_TOGGLE_PROGRAMMED_TASKS,
});

export const toggleExecutingTasks = () => ({
  type: types.MAP_INFO_PANEL_TOGGLE_EXECUTING_TASKS,
});

export const togglePausedTasks = () => ({
  type: types.MAP_INFO_PANEL_TOGGLE_PAUSED_TASKS,
});

const initialState = {
  search: "",
  muted: true,
  gangs: true,
  pendingTasks: true,
  assignedTasks: true,
  programmedTasks: true,
  executingTasks: true,
  pausedTasks: true,
  open: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAP_INFO_PANEL_CHANGE_SEARCH:
      return { ...state, search: action.search };
    case types.MAP_INFO_PANEL_TOGGLE_MUTE:
      return { ...state, muted: !state.muted };
    case types.MAP_INFO_PANEL_OPEN:
      return { ...state, open: true };
    case types.MAP_INFO_PANEL_CLOSE:
      return { ...state, open: false };
    case types.MAP_INFO_PANEL_TOGGLE_GANGS:
      return { ...state, gangs: !state.gangs };
    case types.MAP_INFO_PANEL_TOGGLE_PENDING_TASKS:
      return { ...state, pendingTasks: !state.pendingTasks };
    case types.MAP_INFO_PANEL_TOGGLE_ASSIGNED_TASKS:
      return { ...state, assignedTasks: !state.assignedTasks };
    case types.MAP_INFO_PANEL_TOGGLE_PROGRAMMED_TASKS:
      return { ...state, programmedTasks: !state.programmedTasks };
    case types.MAP_INFO_PANEL_TOGGLE_EXECUTING_TASKS:
      return { ...state, executingTasks: !state.executingTasks };
    case types.MAP_INFO_PANEL_TOGGLE_PAUSED_TASKS:
      return { ...state, pausedTasks: !state.pausedTasks };
    default:
      return state;
  }
};

export const getGangs = state => {
  const groups = state.groups.groups.filter(g => g.selected);
  const regex = new RegExp(`.*${escapeStringRegexp(state.mapInfoPanel.search)}.*`, "i");
  return state.gangs
    .filter(
      gang =>
        (gang.name.match(regex) || gang.initials.match(regex)) &&
        groups.find(g => g.id === gang.group)
    )
    .sort((a, b) => {
      if (a.state === "Alerta") {
        return -1;
      } else if (b.state === "Alerta") {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
};

export const getSound = state => {
  const groups = state.groups.groups.filter(g => g.selected);
  return state.gangs
    .filter(g => groups.find(group => group.id === g.group))
    .find(gang => gang.sound && !state.mapInfoPanel.muted);
};

export default reducer;
