import { Component } from "react";
import { connect } from "react-redux";
import socketIO from "socket.io-client";

import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";
import ApiError from "../components/UI/ApiError";
import { getPathName, getHost } from "../utils/url";

import { getGangs, updateGang } from "../store/ducks/gangs";
import { getTasks as getMapTasks } from "../store/ducks/mapTasks";
import { getGroups } from "../store/ducks/groups";
import { getTaskTypes } from "../store/ducks/taskTypes";

import Map from "./mapContainer/Map";

class MapContainer extends Component {
  componentDidMount() {
    this.props.getMapTasks();
    this.props.getGangs();
    this.props.getGroups();
    this.props.getTaskTypes();
    const pathname = getPathName();
    const socket = socketIO(getHost(), {
      path: pathname !== "/" ? `${pathname}/socket.io` : "",
    });
    socket.on("gangUpdate", gang => this.props.updateGang(gang));
  }

  render() {
    const { error } = this.props;
    return !error ? <Map {...this.props} /> : <ApiError error={error} />;
  }
}

const actionNames = ["GANGS", "MAP_TASKS", "GROUPS", "TASK_TYPES"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
  center: state.map.center,
  zoom: state.map.zoom,
});

const mapDispatchToProps = dispatch => ({
  getGangs: () => dispatch(getGangs()),
  getGroups: () => dispatch(getGroups()),
  getMapTasks: () => dispatch(getMapTasks()),
  getTaskTypes: () => dispatch(getTaskTypes()),
  updateGang: gang => dispatch(updateGang(gang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
