import axios from "../../axios";

const types = {
  SIDEWALK_TYPES_REQUEST: "SIDEWALK_TYPES_REQUEST",
  SIDEWALK_TYPES_SUCCESS: "SIDEWALK_TYPES_SUCCESS",
  SIDEWALK_TYPES_FAILURE: "SIDEWALK_TYPES_FAILURE",
};

const request = () => ({
  type: types.SIDEWALK_TYPES_REQUEST,
});

const success = values => ({
  type: types.SIDEWALK_TYPES_SUCCESS,
  types: values,
});

const failure = () => ({
  type: types.SIDEWALK_TYPES_FAILURE,
  message: "Ha ocurrido un error al intentar la información de vereda",
});

export const getSidewalkTypes = () => {
  return async dispatch => {
    dispatch(request());
    try {
      const { data } = await axios.get(`/sidewalk/types`);
      dispatch(success(data.data));
    } catch (error) {
      dispatch(failure());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.SIDEWALK_TYPES_SUCCESS:
      return action.types;
    default:
      return state;
  }
};

export default reducer;
