import Typography from "@mui/material/Typography";

import { memo } from "react";
import { connect } from "react-redux";

const title = memo(props => {
  return (
    <div style={{ marginBottom: 15 }}>
      <Typography variant="h4" style={{ fontWeight: 300, marginBottom: 8 }}>
        Listado de tareas
      </Typography>
      <Typography
        style={{
          fontSize: "0.9rem",
          fontWeight: 350,
          marginTop: 12,
          color: "#333",
          marginBottom: 15,
        }}>
        {props.paginator && !props.loading
          ? `${props.paginator.total} resultados`
          : "..."}
      </Typography>
    </div>
  );
});

const mapStateToProps = state => ({
  loading: state.tasksList.isFetching,
  paginator: state.tasksList.paginator,
});

export default connect(mapStateToProps)(title);
