import { memo } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBackOutlined, MoreVert } from "@mui/icons-material";
import { Card, CardHeader, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import ActionsContainer from "../../../taskListContainer/taskList/tasks/taskCard/ActionsContainer";

const Title = memo(({ task, roles }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleEdit = useCallback(() => {
    navigate(`/tarea/${task.id}/editar`);
    handleClose();
  }, [handleClose, navigate, task]);

  return (
    <Card variant="outlined" style={{ padding: 5 }}>
      <CardHeader
        avatar={
          <Tooltip title="Volver">
            <IconButton
              size="small"
              component="span"
              onClick={() => {
                navigate("/");
              }}
              style={{ marginRight: 5 }}>
              <ArrowBackOutlined />
            </IconButton>
          </Tooltip>
        }
        action={
          roles && roles.find(role => role === "tecnico") && !task.historic ? (
            <>
              <IconButton onClick={handleClick} size="small" style={{ marginTop: 7 }}>
                <MoreVert />
              </IconButton>
              <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={handleClose}
                open={Boolean(anchorEl)}>
                <MenuItem onClick={handleEdit}>Editar</MenuItem>
                <ActionsContainer handleClose={handleClose} task={task} />
              </Menu>
            </>
          ) : null
        }
        titleTypographyProps={{ style: { fontWeight: 400, fontSize: 18 } }}
        title={`Tarea: ${task.codigo_ot}`}
      />
    </Card>
  );
});

export default Title;
