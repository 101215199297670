/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import { useMediaQuery, useTheme } from "@mui/material";

const styles = () => ({
  form: {
    width: "100%",
  },
});

const OrderDialog = ({
  handleCancel,
  openModal,
  handleSubmit,
  handleChange,
  selected,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openModal, handleSubmit]);

  return (
    <Dialog fullScreen={isMobile} open={openModal} onClose={handleCancel}>
      <DialogTitle>Criterio de orden</DialogTitle>
      <DialogContent dividers>
        <RadioGroup value={selected} onChange={handleChange}>
          <FormControlLabel
            value="creation"
            control={<Radio />}
            label="Fecha de creación"
          />
          <FormControlLabel
            value="expiration"
            control={<Radio />}
            label="Fecha de vencimiento"
          />
          <FormControlLabel
            value="income_ot"
            control={<Radio />}
            label="Fecha de ingreso de OT"
          />
          <FormControlLabel
            value="state_change"
            control={<Radio />}
            label="Fecha de último cambio de estado"
          />
          <FormControlLabel value="ot" control={<Radio />} label="Código OT" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={handleCancel}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(OrderDialog);
