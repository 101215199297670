import { Component } from "react";
import OrderDialog from "./orderDialogContainer/OrderDialog";

import { connect } from "react-redux";
import { closeModal, handleChange } from "../../../../store/ducks/taskListOrder";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";

class OrderDialogContainer extends Component {
  state = { selected: this.props.selected };

  handleChange = event => {
    this.setState({ selected: event.target.value });
  };

  handleSubmit = () => {
    this.props.closeModal();
    this.props.handleChange(this.state.selected);
  };

  handleCancel = () => {
    this.props.closeModal();
    this.setState({ selected: this.props.selected });
  };

  render() {
    return (
      <OrderDialog
        {...this.props}
        selected={this.state.selected}
        handleCancel={this.handleCancel}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.taskListOrder.openModal,
  selected: state.taskListOrder.selected,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  handleChange: selected => {
    dispatch(handleChange(selected));
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDialogContainer);
