/* eslint-disable no-case-declarations */
import { enqueueError } from "./notifications";
import { getTasksFromGraphql } from "./tasksList";

const types = {
  TASK_LIST_GANG_FILTER_OPEN_MODAL: "TASK_LIST_GANG_FILTER_OPEN_MODAL",
  TASK_LIST_GANG_FILTER_CLOSE_MODAL: "TASK_LIST_GANG_FILTER_CLOSE_MODAL",
  TASK_LIST_GANG_FILTER_SAVE: "TASK_LIST_GANG_FILTER_SAVE",
  TASK_LIST_GANG_FILTER_TOGGLE: "TASK_LIST_GANG_FILTER_TOGGLE",
  TASK_LIST_GANG_FILTER_TOGGLE_ALL: "TASK_LIST_GANG_FILTER_TOGGLE_ALL",
  TASK_LIST_GANG_FILTER_REMOVE: "TASK_LIST_GANG_FILTER_REMOVE",
  TASK_LIST_GANG_SET_ACTIVE_GANGS: "TASK_LIST_GANG_SET_ACTIVE_GANGS",
  TASK_LIST_GANG_SUCCESS: "TASK_LIST_GANG_SUCCESS",
  TASK_LIST_GANG_INITIALIZE: "TASK_LIST_GANG_INITIALIZE",
};

export const openModal = () => ({
  type: types.TASK_LIST_GANG_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_GANG_FILTER_CLOSE_MODAL,
});

export const save = () => (dispatch, getState) => {
  const {
    taskListGangFilter: { dialogGangs },
  } = getState();
  if (dialogGangs.filter(g => g.selected).length) {
    dispatch({
      type: types.TASK_LIST_GANG_FILTER_SAVE,
    });
    dispatch(getTasksFromGraphql());
  } else {
    dispatch(enqueueError("Debe seleccionar alguna cuadrilla"));
  }
};

export const removeGangs = () => ({
  type: types.TASK_LIST_GANG_FILTER_REMOVE,
});

export const initialize = () => ({
  type: types.TASK_LIST_GANG_INITIALIZE,
});

export const successTaskFilter = (gangs, groupGangs) => ({
  type: types.TASK_LIST_GANG_SUCCESS,
  gangs: [
    {
      id: "no-gang",
      name: "Tareas sin cuadrilla",
    },
    {
      id: "no-group",
      name: "Tareas con cuadrilla sin grupo",
    },
    {
      id: "other-group",
      name: "Tareas con cuadrilla de otro grupo",
    },
    ...gangs
      .filter(g => groupGangs.data.find(gang => gang.id === g._id))
      .map(g => ({
        id: g._id,
        name: g.name,
        active: g.active,
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
  ],
});

export const setActiveGangs = (gangs = []) => ({
  type: types.TASK_LIST_GANG_SET_ACTIVE_GANGS,
  gangs,
});

export const toggle = event => ({
  type: types.TASK_LIST_GANG_FILTER_TOGGLE,
  gang: event.target.name,
  state: event.target.checked,
});

export const toggleAll = event => ({
  type: types.TASK_LIST_GANG_FILTER_TOGGLE_ALL,
  state: event.target.checked,
});

const initialState = {
  openModal: false,
  gangs: [],
  selectedGangs: [],
  dialogGangs: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_GANG_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_GANG_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_GANG_FILTER_SAVE:
      const selected = state.dialogGangs.filter(g => g.selected).map(g => g.id);
      return {
        ...state,
        openModal: false,
        selectedGangs: selected.length === state.gangs.length ? [] : selected,
      };
    case types.TASK_LIST_GANG_FILTER_TOGGLE:
      return {
        ...state,
        dialogGangs: state.dialogGangs.map(g =>
          g.id === action.gang ? { ...g, selected: action.state } : g
        ),
      };
    case types.TASK_LIST_GANG_FILTER_TOGGLE_ALL:
      return {
        ...state,
        dialogGangs: state.dialogGangs.map(g => ({
          ...g,
          selected: action.state,
        })),
      };
    case types.TASK_LIST_GANG_SET_ACTIVE_GANGS:
      return {
        ...state,
        selectedGangs: action.gangs,
      };
    case types.TASK_LIST_GANG_FILTER_REMOVE:
      return {
        ...state,
        selectedGangs: [],
      };
    case types.TASK_LIST_GANG_SUCCESS:
      return {
        ...state,
        gangs: action.gangs,
      };
    case types.TASK_LIST_GANG_INITIALIZE:
      return {
        ...state,
        dialogGangs: state.gangs.map(g =>
          state.selectedGangs.length
            ? { ...g, selected: state.selectedGangs.includes(g.id) }
            : { ...g, selected: true }
        ),
      };
    default:
      return state;
  }
};

export default reducer;
