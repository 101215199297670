import { connect } from "react-redux";
import AddGangDialog from "./AddGangToGroupDialog";

import {
  initialize,
  closeModal,
  addGangToGroup,
} from "../../../store/ducks/addGangToGroupModal";
import { PureComponent } from "react";

const mapStateToProps = state => ({
  group: state.groupGangs.group,
  gangs: state.addGangToGroupModal.gangs,
  loading: state.addGangToGroupModal.loading,
  sending: state.addGangToGroupModal.sending,
  openModal: state.addGangToGroupModal.modalOpen,
});

const mapDispatchToProps = dispatch => ({
  initialize: () => dispatch(initialize()),
  closeModal: () => dispatch(closeModal()),
  add: group => dispatch(addGangToGroup(group)),
});

class AddGangDialogContainer extends PureComponent {
  componentDidMount() {
    this.props.initialize();
  }

  render() {
    return <AddGangDialog {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGangDialogContainer);
