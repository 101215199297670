/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import withStyles from "@mui/styles/withStyles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupIcon from "@mui/icons-material/LocationCity";
import GangIcon from "@mui/icons-material/Person";
import TaskIcon from "@mui/icons-material/Description";
import CommentsIcon from "@mui/icons-material/Assignment";
import DatesIcon from "@mui/icons-material/Today";
import TimerIcon from "@mui/icons-material/Timer";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import { format, addMinutes } from "date-fns";
import { BuildOutlined, Map } from "@mui/icons-material";
import AssistantIcon from "@mui/icons-material/Assistant";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";

import { useMemo, useState } from "react";

const dateParser = date => {
  date = new Date(date.replace(/ /g, "T"));
  return format(addMinutes(date, date.getTimezoneOffset()), "dd/MM/yyyy");
};

const styles = theme => ({
  title: {
    fontWeight: 400,
    paddingTop: " 0!important",
    paddingLeft: " 0!important",
  },
  container: {
    paddingBottom: 0,
  },
  dropdown: {
    minWidth: 80,
  },
  contentContainer: {
    paddingBottom: 0,
    paddingTop: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },

  listIcon: {
    minWidth: 40,
  },
  actions: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 15,
    paddingLeft: 15,
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
});

export const getInitials = type => {
  if (type.trim().indexOf(" ") === -1) {
    return type.substring(0, 2).toUpperCase();
  }
  let initials = type.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

export const getStateClass = state => {
  if (state === "Programada") {
    return "Programada-T";
  } else if (state === "Validada") {
    return "Materiales-validados";
  }
  return state;
};

const getDepositos = (taskStores = "[]", stores) =>
  taskStores && stores && stores.length
    ? JSON.parse(taskStores)
        .map(id => stores.find(store => store._id === id))
        .filter(store => !!store)
        .map(({ name, type }) => `${name} - ${type.name}`)
        .join(", ")
    : "-";

const TaskCard = ({ task, classes, stores }) => {
  const [copied, setCopied] = useState(false);

  const taskText = useMemo(() => {
    let text = "";
    if (task.modulo) text += `MOD ${task.modulo}. `;
    text += task.tarea;
    return text;
  }, [task]);

  const inspector = useMemo(() => {
    if (task.tarea_accion && task.tarea_accion.length) {
      const lastReception = task.tarea_accion.reverse().find(ta => ta.estado_id === 5);
      if (lastReception) return lastReception.inspector;
    }
    return undefined;
  }, [task]);

  return (
    <Card variant="outlined">
      <CardHeader
        className={classes.container}
        title={
          <Typography variant="h6" className={classes.title}>
            {task.full_address}
          </Typography>
        }
        subheader={
          <>
            <Tooltip key="ot" title={copied ? "Se copió la OT" : "Código OT"}>
              <span
                onClick={() => {
                  navigator.clipboard.writeText(task.codigo_ot).then(function () {
                    setCopied(true);
                  });
                  setTimeout(() => {
                    setCopied(false);
                  }, 5000);
                }}
                style={{ cursor: "pointer" }}>
                {" "}
                {task.codigo_ot}
              </span>
            </Tooltip>
            -{" "}
            <Tooltip key="fecha_asignada" title="Fecha de asignación">
              <span>{dateParser(task.fecha_asignada)}</span>
            </Tooltip>
            <>
              <span> - </span>
              <Typography variant="caption">
                Ult mod {dateParser(task.updated_at)}
              </Typography>
            </>
            {task.certificate ? (
              <div key="certificate" style={{ marginTop: 10 }}>
                {" "}
                <Tooltip title="Certificado">
                  <span>{task.certificate}</span>
                </Tooltip>
              </div>
            ) : null}
          </>
        }
      />
      <CardContent className={classes.contentContainer}>
        <List component="nav" disablePadding dense>
          {task.operative_zone ? (
            <ListItem dense disableGutters key="group">
              <Tooltip title="Zona de operación">
                <ListItemIcon className={classes.listIcon}>
                  <Map />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={task.operative_zone || "-"} />
            </ListItem>
          ) : null}

          <ListItem dense disableGutters key="group">
            <Tooltip title="Grupo">
              <ListItemIcon className={classes.listIcon}>
                <GroupIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.grupo ? task.grupo.nombre : "-"} />
          </ListItem>
          <ListItem dense disableGutters key="gang">
            <Tooltip title="Cuadrilla">
              <ListItemIcon className={classes.listIcon}>
                <GangIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.cuadrilla ? task.cuadrilla.cuadrilla : "-"} />
          </ListItem>
          {inspector ? (
            <ListItem dense disableGutters key="inspector">
              <Tooltip title="Inspector">
                <ListItemIcon className={classes.listIcon}>
                  <SearchIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={inspector || "-"} />
            </ListItem>
          ) : null}
          <ListItem dense disableGutters key="task">
            <Tooltip title="Tarea a realizar">
              <ListItemIcon className={classes.listIcon}>
                <TaskIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={taskText} />
          </ListItem>

          <ListItem dense disableGutters key="authorizer">
            <Tooltip title="Autorizado por">
              <ListItemIcon className={classes.listIcon}>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.approved_by || "-"} />
          </ListItem>

          <ListItem dense disableGutters key="addstamp">
            <Tooltip title="Fecha de creación">
              <ListItemIcon className={classes.listIcon}>
                <DatesIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={dateParser(task.created_at)} />
          </ListItem>
          <ListItem dense disableGutters key="expirationDate">
            <Tooltip title="Fecha de vencimiento">
              <ListItemIcon className={classes.listIcon}>
                <TimerIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={dateParser(task.vencimiento)} />
          </ListItem>
          <ListItem dense disableGutters key="articles">
            <Tooltip title="Materiales">
              <ListItemIcon className={classes.listIcon}>
                <BuildOutlined />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={
                task.valida_materiales ? "Requiere materiales" : "No requiere materiales"
              }
            />
          </ListItem>

          <ListItem dense disableGutters key="cleaning">
            <Tooltip title="Limpieza">
              <ListItemIcon className={classes.listIcon}>
                <HowToVoteIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={
                task.requires_cleaning ? "Requiere limpieza" : "No requiere limpieza"
              }
            />
          </ListItem>

          <ListItem dense disableGutters key="pipeline">
            <Tooltip title="Datos de Red">
              <ListItemIcon className={classes.listIcon}>
                <AssistantIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={
                task.requires_pipeline_information
                  ? "Requiere datos de Red"
                  : "No requiere datos de Red"
              }
            />
          </ListItem>

          {task.for_contractor !== null && task.for_contractor !== undefined ? (
            <ListItem dense disableGutters key="distribution">
              <Tooltip title="Distribución">
                <ListItemIcon className={classes.listIcon}>
                  <AssignmentIndIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={task.for_contractor ? "Subcontratista" : "Fuerza Propia"}
              />
            </ListItem>
          ) : null}

          <ListItem dense disableGutters key="stores">
            <Tooltip title="Depósitos asignados">
              <ListItemIcon className={classes.listIcon}>
                <HomeIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={getDepositos(task.stores, stores)} />
          </ListItem>

          {task.contact_phone ? (
            <ListItem dense disableGutters key="phone">
              <Tooltip title="Télefono de contacto">
                <ListItemIcon className={classes.listIcon}>
                  <PhoneOutlinedIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={task.contact_phone} />
            </ListItem>
          ) : null}

          <ListItem dense disableGutters key="observations">
            <Tooltip title="Observaciones">
              <ListItemIcon className={classes.listIcon}>
                <CommentsIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.observaciones || "-"} />
          </ListItem>
        </List>
      </CardContent>
      <CardActions className={classes.actions}>
        <div style={{ marginLeft: "auto" }} />
        {task.parent_id ? (
          <Tooltip title="Tarea Complementaria" key="complementary">
            <p className={[classes.stateIcon, "complementary"]}>CO</p>
          </Tooltip>
        ) : null}
        {task.fallida ? (
          <Tooltip title="Fallida" key="failed">
            <p className={[classes.stateIcon, "failed"]}>F</p>
          </Tooltip>
        ) : null}
        <Tooltip title={task.tipo.tipo} key="type">
          <p className={[classes.stateIcon, "type"]}>{getInitials(task.tipo.tipo)}</p>
        </Tooltip>
        <Tooltip title={task.estado.estado} key="state">
          <p className={[classes.stateIcon, getStateClass(task.estado.estado)]}>
            {getInitials(task.estado.estado)}
          </p>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(TaskCard);
