import axios from "../../axios";
import { enqueueError } from "./notifications";

const types = {
  GANG_GROUPS_HISTORY_REQUEST: "GANG_GROUPS_HISTORY_REQUEST",
  GANG_GROUPS_HISTORY_SUCCESS: "GANG_GROUPS_HISTORY_SUCCESS",
  GANG_GROUPS_HISTORY_FAILURE: "GANG_GROUPS_HISTORY_FAILURE",
  GANG_GROUPS_HISTORY_MODAL_OPEN: "GANG_GROUPS_HISTORY_MODAL_OPEN",
  GANG_GROUPS_HISTORY_MODAL_CLOSE: "GANG_GROUPS_HISTORY_MODAL_CLOSE",
};

export const openModal = gang => dispatch => {
  dispatch({
    type: types.GANG_GROUPS_HISTORY_MODAL_OPEN,
    gang,
  });
  dispatch(getGangGroupsHistory(gang));
};

const request = () => ({
  type: types.GANG_GROUPS_HISTORY_REQUEST,
});

const success = (history, gang) => ({
  type: types.GANG_GROUPS_HISTORY_SUCCESS,
  history,
  gang,
});

const failure = () => ({
  type: types.GANG_GROUPS_HISTORY_FAILURE,
});

export const closeModal = () => ({
  type: types.GANG_GROUPS_HISTORY_MODAL_CLOSE,
});

export const getGangGroupsHistory = gang => async dispatch => {
  dispatch(request());
  try {
    const { data } = await axios.get(`/gangs/${gang.id}/groupChanges`);
    dispatch(success(data.data, gang));
  } catch (error) {
    const errorMessage =
      "Ha ocurrido un error al intentar obtener los cambios de grupos de las cuadrillas";
    dispatch(failure());
    dispatch(enqueueError(errorMessage));
  }
};

const initialState = {
  modalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GANG_GROUPS_HISTORY_REQUEST:
      return { ...state, isLoading: true };

    case types.GANG_GROUPS_HISTORY_FAILURE:
      return { ...state, isLoading: false, modalOpen: false };

    case types.GANG_GROUPS_HISTORY_SUCCESS:
      return { ...state, isLoading: false, history: action.history, gangs: action.gangs };

    case types.GANG_GROUPS_HISTORY_MODAL_OPEN:
      return { ...state, modalOpen: true, gang: action.gang };

    case types.GANG_GROUPS_HISTORY_MODAL_CLOSE:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export default reducer;
