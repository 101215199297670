const setAddresses = (tasks = []) => {
  tasks.forEach(
    t =>
      (t.address = `Calle ${t.calle || ""} Altura ${t.altura || ""} ${t.entre || ""} ${
        t.y || ""
      }`)
  );
  return tasks;
};

export default setAddresses;
