/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useNavigate } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupIcon from "@mui/icons-material/LocationCity";
import GangIcon from "@mui/icons-material/Person";
import TaskIcon from "@mui/icons-material/Description";
import CommentsIcon from "@mui/icons-material/Assignment";
import DatesIcon from "@mui/icons-material/Today";
import TimerIcon from "@mui/icons-material/Timer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SearchIcon from "@mui/icons-material/Search";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import { format, addMinutes } from "date-fns";
import "../../../../components/Tasks/states.css";

import ActionsContainer from "./taskCard/ActionsContainer";
import { Link } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";

const dateParser = date => {
  date = new Date(date.replace(/ /g, "T"));
  return format(addMinutes(date, date.getTimezoneOffset()), "dd/MM/yyyy");
};

const styles = theme => ({
  title: {
    fontWeight: 400,
  },
  container: {
    paddingBottom: 0,
  },
  dropdown: {
    minWidth: 80,
  },
  contentContainer: {
    paddingBottom: 0,
    paddingTop: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  listIcon: {
    minWidth: 40,
  },
  actions: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 15,
    paddingLeft: 15,
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
});

const getInitials = type => {
  if (type.trim().indexOf(" ") === -1) {
    return type.substring(0, 2).toUpperCase();
  }
  let initials = type.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

const getStateClass = state => {
  if (state === "Programada") {
    return "Programada-T";
  } else if (state === "Validada") {
    return "Materiales-validados";
  }
  return state;
};

const TaskCard = memo(({ task, classes, showAllInfo, roles }) => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowTask = useCallback(
    e => {
      e.preventDefault();
      navigate(`/tarea/${task.id}`);
    },
    [navigate, task]
  );

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleEdit = useCallback(
    e => {
      e.preventDefault();
      navigate(`/tarea/${task.id}/editar`);
      handleClose();
    },
    [navigate, task, handleClose]
  );

  const taskText = useMemo(() => {
    let taskText = "";
    if (task.modulo) taskText += `MOD ${task.modulo}. `;
    taskText += task.tarea;
    return taskText;
  }, [task]);

  return (
    <Card variant="outlined">
      <CardHeader
        className={classes.container}
        title={
          <Typography variant="h6" className={classes.title}>
            {task.ubicacion}
          </Typography>
        }
        subheader={
          <>
            <Tooltip title={copied ? "Se copió la OT" : "Código OT"}>
              <span
                onClick={() => {
                  navigator.clipboard.writeText(task.codigo_ot).then(function () {
                    setCopied(true);
                  });
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
                style={{ cursor: "pointer" }}>
                {" "}
                {task.codigo_ot}
              </span>
            </Tooltip>{" "}
            <Tooltip title="Fecha de asignación">
              <span>{dateParser(task.fecha_asignada)}</span>
            </Tooltip>
            {(expanded || showAllInfo) && (
              <>
                <span> - </span>
                <Typography variant="caption">
                  Ult mod {dateParser(task.updated_at)}
                </Typography>
              </>
            )}
            {task.certificate ? (
              <div style={{ marginTop: 10 }}>
                {" "}
                <Tooltip title="Certificado">
                  <span>{task.certificate}</span>
                </Tooltip>
              </div>
            ) : null}
          </>
        }
        action={
          <>
            {!showAllInfo && (
              <IconButton onClick={handleExpandClick} size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            )}
            {roles && roles.find(role => role === "tecnico") && !task.historic ? (
              <>
                <IconButton onClick={handleClick} size="large">
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  keepMounted
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  open={Boolean(anchorEl)}>
                  <MenuItem className={classes.dropdown} onClick={handleEdit}>
                    Editar
                  </MenuItem>
                  <ActionsContainer handleClose={handleClose} task={task} />
                </Menu>
              </>
            ) : null}
          </>
        }
      />
      <CardContent className={classes.contentContainer}>
        <List component="nav" disablePadding dense>
          <ListItem dense disableGutters>
            <Tooltip title="Grupo">
              <ListItemIcon className={classes.listIcon}>
                <GroupIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.grupo ? task.grupo.nombre : "-"} />
          </ListItem>
          <ListItem dense disableGutters>
            <Tooltip title="Cuadrilla">
              <ListItemIcon className={classes.listIcon}>
                <GangIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.cuadrilla ? task.cuadrilla.cuadrilla : "-"} />
          </ListItem>
          <ListItem dense disableGutters>
            <Tooltip title="Tarea a realizar">
              <ListItemIcon className={classes.listIcon}>
                <TaskIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={taskText} />
          </ListItem>
          {task.historic ? (
            <ListItem dense disableGutters>
              <Tooltip title="Esta archivada">
                <ListItemIcon className={classes.listIcon}>
                  <FileCopyIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Tarea Archivada" />
            </ListItem>
          ) : null}
          <Collapse in={expanded || showAllInfo} timeout="auto" unmountOnExit>
            <ListItem dense disableGutters>
              <Tooltip title="Fecha de creación">
                <ListItemIcon className={classes.listIcon}>
                  <DatesIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={dateParser(task.created_at)} />
            </ListItem>
            <ListItem dense disableGutters>
              <Tooltip title="Fecha de vencimiento">
                <ListItemIcon className={classes.listIcon}>
                  <TimerIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={dateParser(task.vencimiento)} />
            </ListItem>

            {task.ultimaRecepcion &&
            task.ultimaRecepcion.length &&
            task.ultimaRecepcion[0].inspector ? (
              <ListItem dense disableGutters>
                <Tooltip title="Inspector">
                  <ListItemIcon className={classes.listIcon}>
                    <SearchIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={task.ultimaRecepcion[0].inspector || "-"} />
              </ListItem>
            ) : null}

            {task.for_contractor !== null && task.for_contractor !== undefined ? (
              <ListItem dense disableGutters>
                <Tooltip title="Distribución">
                  <ListItemIcon className={classes.listIcon}>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={task.for_contractor ? "Subcontratista" : "Fuerza Propia"}
                />
              </ListItem>
            ) : null}

            {task.contact_phone ? (
              <ListItem dense disableGutters>
                <Tooltip title="Télefono de contacto">
                  <ListItemIcon className={classes.listIcon}>
                    <PhoneOutlinedIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={task.contact_phone} />
              </ListItem>
            ) : null}

            <ListItem dense disableGutters>
              <Tooltip title="Observaciones">
                <ListItemIcon className={classes.listIcon}>
                  <CommentsIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  task.observaciones
                    ? task.observaciones
                    : task.ultimaRecepcion && task.ultimaRecepcion.length
                    ? task.ultimaRecepcion[0].observaciones
                    : "-"
                }
              />
            </ListItem>

            {task.croquis && task.croquis.length ? (
              <ListItem dense disableGutters>
                <Tooltip title="Croquis">
                  <ListItemIcon className={classes.listIcon}>
                    <AttachFileIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={
                    <Link
                      download
                      underline="none"
                      style={{ color: "#000000de" }}
                      href={`${process.env.REACT_APP_IMAGES_URI}/${task.croquis[0].path}`}>
                      Descargar
                    </Link>
                  }
                />
              </ListItem>
            ) : null}
          </Collapse>
        </List>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          size="small"
          style={{ color: "black" }}
          onClick={handleShowTask}
          href={`${process.env.PUBLIC_URL}/tarea/${task.id}`}>
          Ver detalles
        </Button>
        <div style={{ marginLeft: "auto" }} />
        {task.parent_id ? (
          <Tooltip title="Tarea Complementaria">
            <p className={[classes.stateIcon, "complementary"]}>CO</p>
          </Tooltip>
        ) : null}

        {task.fallida ? (
          <Tooltip title="Fallida">
            <p className={[classes.stateIcon, "failed"]}>F</p>
          </Tooltip>
        ) : null}

        <Tooltip title={task.tipo.tipo}>
          <p className={[classes.stateIcon, "type"]}>{getInitials(task.tipo.tipo)}</p>
        </Tooltip>
        <Tooltip title={task.estado.estado}>
          <p className={[classes.stateIcon, getStateClass(task.estado.estado)]}>
            {getInitials(task.estado.estado)}
          </p>
        </Tooltip>
      </CardActions>
    </Card>
  );
});

export default withStyles(styles)(TaskCard);
