import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from "@mui/material";
import { CardHeader } from "@mui/material";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getInitials } from "../detailCard";
import { format } from "date-fns";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export const getConsumeState = type => {
  switch (type) {
    case 0:
      return "Sin validar";
    case 1:
      return "Validado";
    case 2:
      return "Objetado";
    case 3:
      return "Consolidado";
    case 4:
      return "Objetado (pañol)";
    default:
      break;
  }
};

export const getStateClass = state => {
  if (state === 3) {
    return "Programada-T";
  } else if (state === 1) {
    return "Materiales-validados";
  }
  return state;
};

export default ({ task }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const width = window.innerWidth;
  const isMobile = width <= 650;

  return (
    <Card variant="outlined" style={{ marginTop: 10 }}>
      <CardHeader
        titleTypographyProps={{ variant: "h6", style: { fontWeight: 400 } }}
        title="Materiales"
        action={
          <>
            {
              <IconButton
                onClick={handleExpandClick}
                disabled={!task.materiales.length}
                size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            }
          </>
        }
      />
      <CardContent>
        {!expanded ? (
          <Typography style={{ fontWeight: 100, marginTop: -10 }}>{`La tarea posee ${
            task.materiales.length
          } ${
            task.materiales.length !== 1 ? "materiales asociados." : "material asociado."
          }`}</Typography>
        ) : null}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List>
            {task.materiales.map((material, index) => (
              <>
                <ListItem key={material.id} style={{ padding: "20px 0px" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <Tooltip title={getConsumeState(Number(material.estado_consumo))}>
                        <p
                          className={[
                            classes.stateIcon,
                            getStateClass(Number(material.estado_consumo)),
                          ]}>
                          {getInitials(getConsumeState(Number(material.estado_consumo)))}
                        </p>
                      </Tooltip>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<div>{`${material.descripcion || "-"}`}</div>}
                    secondary={
                      <div>
                        <div>{`${material.deposito}`}</div>
                      </div>
                    }></ListItemText>

                  {!isMobile ? (
                    <ListItemSecondaryAction style={{ textAlign: "right" }}>
                      <ListItemText
                        primary={"Fecha de consumo"}
                        secondary={format(
                          new Date(material.addstamp?.replace(/ /g, "T")),
                          "dd/MM/yyyy HH:mm:ss"
                        )}
                      />
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
                <Grid
                  key="extra"
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ paddingLeft: 55, paddingRight: 20, marginBottom: 20 }}>
                  {isMobile ? (
                    <Grid item xs={12}>
                      <div style={isMobile ? null : { textAlign: "left" }}>
                        <Typography variant="body2">Fecha de Consumo</Typography>
                        <Typography variant="body2" style={{ color: "#0000008a" }}>
                          {format(
                            new Date(material.addstamp?.replace(/ /g, "T")),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                        </Typography>
                      </div>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={6}>
                    <div style={isMobile ? null : { textAlign: "left" }}>
                      <Typography variant="body2">Cantidad</Typography>
                      <Typography variant="body2" style={{ color: "#0000008a" }}>
                        {material.cantidad || "Sin especificar"}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div style={isMobile ? null : { textAlign: "right" }}>
                      <Typography variant="body2">Cuadrilla</Typography>
                      <Typography variant="body2" style={{ color: "#0000008a" }}>
                        {material.gang?.name || "Sin especificar"}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <div style={isMobile ? null : { textAlign: "left" }}>
                      <Typography variant="body2">Procedencia</Typography>
                      <Typography variant="body2" style={{ color: "#0000008a" }}>
                        {material.procedencia || "Sin especificar"}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div style={isMobile ? null : { textAlign: "center" }}>
                      <Typography variant="body2">Código AySA</Typography>
                      <Typography variant="body2" style={{ color: "#0000008a" }}>
                        {material.cod_equivalente || "Sin especificar"}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div style={isMobile ? null : { textAlign: "right" }}>
                      <Typography variant="body2">Código Bejerman</Typography>
                      <Typography variant="body2" style={{ color: "#0000008a" }}>
                        {material.cod_bejerman || "Sin especificar"}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <Typography variant="body2">Observaciones</Typography>
                      <Typography variant="body2" style={{ color: "#0000008a" }}>
                        {material.observacion_consumo ||
                          "No hay observaciones registradas."}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                {index !== task.materiales.length - 1 ? <Divider key={index} /> : null}
              </>
            ))}
          </List>
        </Collapse>
      </CardContent>
    </Card>
  );
};
