import axios from "../../axios";
import { getGangs } from "./gangs";
import { enqueueError, enqueueMessage } from "./notifications";

const types = {
  EDIT_GANG_MODAL_OPEN: "EDIT_GANG_MODAL_OPEN",
  EDIT_GANG_MODAL_CLOSE: "EDIT_GANG_MODAL_CLOSE",

  EDIT_GANG_MODAL_REQUEST: "EDIT_GANG_MODAL_REQUEST",
  EDIT_GANG_MODAL_SUCCESS: "EDIT_GANG_MODAL_SUCCESS",
  EDIT_GANG_MODAL_FAILURE: "EDIT_GANG_MODAL_FAILURE",
};

export const openModal = gang => ({
  type: types.EDIT_GANG_MODAL_OPEN,
  gang,
});

export const closeModal = () => ({
  type: types.EDIT_GANG_MODAL_CLOSE,
});

const request = () => ({
  type: types.EDIT_GANG_MODAL_REQUEST,
});

const success = () => ({
  type: types.EDIT_GANG_MODAL_SUCCESS,
});

const failure = () => ({
  type: types.EDIT_GANG_MODAL_FAILURE,
});

export const finishEdition =
  (data = {}) =>
  async (dispatch, getState) => {
    const {
      editGang: { gang },
    } = getState();
    dispatch(request());
    try {
      await axios.patch(`/gangs/${gang.id}/`, data);
      dispatch(success());
      dispatch(getGangs());
      dispatch(enqueueMessage("La cuadrilla fue actualizada correctamente."));
    } catch (error) {
      dispatch(enqueueError("Ocurrió un error al actualizar la cuadrilla."));
      dispatch(failure());
    }
  };

const initialState = {
  task: null,
  sending: false,
  modalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_GANG_MODAL_OPEN:
      return { ...state, modalOpen: true, gang: action.gang };

    case types.EDIT_GANG_MODAL_CLOSE:
      return { ...state, modalOpen: false, modalErrorOpen: false };

    case types.EDIT_GANG_MODAL_REQUEST:
      return { ...state, sending: true };

    case types.EDIT_GANG_MODAL_SUCCESS:
      return { ...state, sending: false, modalOpen: false, gang: null };

    case types.EDIT_GANG_MODAL_FAILURE:
      return { ...state, sending: false };
    default:
      return state;
  }
};

export default reducer;
