import { connect } from "react-redux";

import { openModal as openDownloadMaterialsModal } from "../../../../store/ducks/taskListDownloadMaterials";
import { openModal as openDownloadTasksModal } from "../../../../store/ducks/taskListDownloadTasks";
import { openModal as openDownloadAbsaReport } from "../../../../store/ducks/taskListDownloadAbsa";

import DownloadList from "./DownloadListContainer/index";

const mapDispatchToProps = dispatch => ({
  openDownloadMaterialsModal: () => dispatch(openDownloadMaterialsModal()),
  openDownloadAbsaReport: () => dispatch(openDownloadAbsaReport()),
  openDownloadTasksModal: () => dispatch(openDownloadTasksModal()),
});

export default connect(null, mapDispatchToProps)(DownloadList);
