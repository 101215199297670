/* eslint-disable react/no-unescaped-entities */
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { CheckCircleTwoTone } from "@mui/icons-material";

const styles = () => ({
  root: {
    textAlign: "center",
  },
  icon: { fontSize: 120, color: "#2196f3" },
  title: { marginTop: 15 },
});

const Result = memo(props => {
  const { classes, task } = props;
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <CheckCircleTwoTone size={50} className={classes.icon} />
      <Typography className={classes.title} variant="h5">
        La tarea fue cargada correctamente.
        <div style={{ marginTop: 30 }}>
          <Button
            color="success"
            style={{ marginLeft: 5, color: "black" }}
            onClick={props.onRestart}>
            Cargar otra tarea
          </Button>
          <Button color="primary" onClick={() => navigate(`/tarea/${task.id}`)}>
            Ver tarea creada
          </Button>

          <Button style={{ marginLeft: 5, color: "black" }} onClick={() => navigate("/")}>
            Volver al listado
          </Button>
        </div>
      </Typography>
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(Result);
