import { connect } from "react-redux";

import { toggleMute } from "../../../store/ducks/mapInfoPanel";
import MuteButton from "./muteButtonContainer/MuteButton";

const mapStateToProps = state => ({
  muted: state.mapInfoPanel.muted,
});

const mapDispatchToProps = dispatch => ({
  toggleMute: () => dispatch(toggleMute()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MuteButton);
