import { connect } from "react-redux";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";
import { save, closeModal } from "../../../../store/ducks/taskListHidrojetFilter";

import HidrojetContainer from "./hidrojetDialogContainer/HidrojetDialog";

const mapStateToProps = state => ({
  openModal: state.taskListHidrojetFilter.openModal,

  dateTo: state.taskListHidrojetFilter.dateTo,
  dateFrom: state.taskListHidrojetFilter.dateFrom,

  withHidrojet: state.taskListHidrojetFilter.withHidrojet,
  hidrojetState: state.taskListHidrojetFilter.hidrojetState,
  hidrojetMinModule: state.taskListHidrojetFilter.hidrojetMinModule,
  hidrojetMaxModule: state.taskListHidrojetFilter.hidrojetMaxModule,
});

const mapDispatchToProps = dispatch => ({
  save: state => {
    dispatch(save(state));
    dispatch(getTasksFromGraphql());
  },
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HidrojetContainer);
