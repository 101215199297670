import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const chips = props => {
  const { classes } = props;
  const showShow = Array.isArray(props.odt) ? props.odt.length : props.odt;

  return (
    <>
      {showShow ? (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={
              !Array.isArray(props.odt)
                ? `ODT ${props.odt}`
                : props.odt.length === 1
                ? `ODT ${props.odt[0]}`
                : `ODT (${props.odt.length})`
            }
            onDelete={() => {
              props.removeOdt();
            }}
          />
        </li>
      ) : null}
      {props.task && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={`Tarea: ${props.task}`}
            onDelete={() => {
              props.removeTask();
            }}
          />
        </li>
      )}
      {props.comment && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={`Observaciones: ${props.comment}`}
            onDelete={() => {
              props.removeComment();
            }}
          />
        </li>
      )}

      {props.certificate && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={`Certificado: ${props.certificate}`}
            onDelete={() => {
              props.removeCertificate();
            }}
          />
        </li>
      )}

      {props.operationZone && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={`Zona de operación: ${props.operationZone}`}
            onDelete={() => {
              props.removeOperativeZone();
            }}
          />
        </li>
      )}
    </>
  );
};

export default withStyles(styles)(chips);
