const types = {
  AUTH_START: "AUTH_START",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",
  ROLE_ERROR: "ROLE_ERROR",
};

export const authStart = () => ({
  type: types.AUTH_START,
});

export const authSuccess = keycloak => ({
  type: types.AUTH_SUCCESS,
  keycloak,
});

export const authError = () => ({
  type: types.AUTH_ERROR,
});

export const roleError = () => ({
  type: types.ROLE_ERROR,
});

const initialState = {
  kc: null,
  authError: false,
  roleError: false,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_START:
      return { ...state, authError: false, loading: true };
    case types.AUTH_SUCCESS:
      return {
        ...state,
        kc: action.keycloak,
        authError: false,
        loading: false,
      };
    case types.AUTH_ERROR:
      return { ...state, authError: true, loading: false };
    case types.ROLE_ERROR:
      return { ...state, roleError: true, loading: false };
    default:
      return state;
  }
};

export default reducer;
