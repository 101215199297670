import { Alert } from "@mui/material";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";

import Title from "./taskDetails/title";
import MapCard from "./taskDetails/mapCard";
import DetailCard from "./taskDetails/detailCard";
import TaskImages from "./taskDetails/imagesCard";
import CroquisCard from "./taskDetails/croquisCard";

import ChangesCard from "./taskDetails/changesCard";
import HistoryCard from "./taskDetails/historyCard";
import Preloader from "../../components/UI/Preloader";
import SidewalkCard from "./taskDetails/sidewalkCard";
import MaterialsCard from "./taskDetails/materialsCard";
import PipelinesCard from "./taskDetails/pipelinesCard";
import EditPipelineDialog from "./taskDetails/editPipelineDialog";
import HidrojetModulesCard from "./taskDetails/hidrojetModulesCard";
import ParentTaskCard from "./taskDetails/parentTaskCard/parentTaskCard";
import EditHidrojetModulesDialog from "./taskDetails/editHidrojetModulesDialog";

import AssignDialogContainer from "../taskListContainer/taskList/tasks/AssignDialogContainer";
import CancelDialogContainer from "../taskListContainer/taskList/tasks/CancelDialogContainer";
import RejectDialogContainer from "../taskListContainer/taskList/tasks/RejectDialogContainer";
import ApproveDialogContainer from "../taskListContainer/taskList/tasks/ApproveDialogContainer";
import PendingDialogContainer from "../taskListContainer/taskList/tasks/PendingDialogContainer";
import ScheduleDialogContainer from "../taskListContainer/taskList/tasks/ScheduleDialogContainer";
import InspectionDialogContainer from "../taskListContainer/taskList/tasks/InspectionDialogContainer";
import NewCivilTaskDialogContainer from "../taskListContainer/taskList/tasks/CivilTaskDialogContainer";
import { useEffect } from "react";

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

const TaskDetails = props => {
  const { classes, ...otherProps } = props;

  useEffect(() => {
    if (props.task)
      document.title = `Tareas | ${props.task.codigo_ot} | Gral Saneamiento`;
  }, [props.task]);

  return !props.task ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Title {...otherProps} />

      <ParentTaskCard {...otherProps} />

      {props.task.historic ? (
        <Paper variant="outlined" elevation={0} style={{ marginTop: 15 }}>
          <Alert severity="info">
            Esta tarea se encuentra archivada. Por este motivo no puede realizarse ninguna
            acción sobre la misma.
          </Alert>
        </Paper>
      ) : null}

      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={12} md={7} key="details">
          <DetailCard {...otherProps} />
          <HidrojetModulesCard {...otherProps} />
          <MaterialsCard {...otherProps} />

          <SidewalkCard {...otherProps} />

          {props.task.requires_pipeline_information && props.task.pipelines.length ? (
            <PipelinesCard {...otherProps} />
          ) : null}
          <HistoryCard {...otherProps} />
          <ChangesCard {...otherProps} />
        </Grid>
        <Grid item xs={12} md={5} key="side">
          <MapCard {...otherProps} />
          <TaskImages {...otherProps} />
          <CroquisCard {...otherProps} />
        </Grid>
      </Grid>

      <EditPipelineDialog />
      <EditHidrojetModulesDialog isDetails={true} />
      <ScheduleDialogContainer />
      <AssignDialogContainer />
      <CancelDialogContainer />
      <PendingDialogContainer />
      <InspectionDialogContainer />
      <ApproveDialogContainer />
      <RejectDialogContainer />
      <NewCivilTaskDialogContainer />
    </div>
  );
};

export default withStyles(styles)(TaskDetails);
