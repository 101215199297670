import axios from "../../axios";
import { getCities } from "./cities";

const types = {
  EDIT_TASK_REQUEST: "EDIT_TASK_REQUEST",
  EDIT_TASK_SUCCESS: "EDIT_TASK_SUCCESS",
  EDIT_TASK_FAILURE: "EDIT_TASK_FAILURE",
  EDIT_TASK_CLEARED: "EDIT_TASK_CLEARED",
};

const requestTask = () => ({
  type: types.EDIT_TASK_REQUEST,
});

const successTask = task => ({
  type: types.EDIT_TASK_SUCCESS,
  task,
});

const failedTask = () => ({
  type: types.EDIT_TASK_FAILURE,
  message: "Ha ocurrido un error al intentar obtener la tarea",
});

export const clearEditTask = () => ({
  type: types.EDIT_TASK_CLEARED,
});

export const getEditTask = id => {
  return async dispatch => {
    dispatch(requestTask());
    try {
      const { data } = await axios.get(`/tasks/${id}`);
      dispatch(successTask(data.data));

      if (data.data.estado_id === 1) {
        dispatch(getCities());
      } else {
        dispatch(getCities(data.data.grupo_id));
      }
    } catch (error) {
      console.log(error);
      dispatch(failedTask());
    }
  };
};

export const cleanEditTask = () => ({
  type: types.EDIT_TASK_CLEARED,
});

const initialState = { task: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_TASK_SUCCESS:
      return { ...state, task: action.task };
    case types.EDIT_TASK_CLEARED:
      return { ...state, task: null };
    default:
      return state;
  }
};

export default reducer;
