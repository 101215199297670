import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import {
  TableBody,
  Table,
  TableRow,
  TableCell,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const styles = () => ({
  text: {
    textAlign: "center",
  },
  subtext: {
    fontSize: 13,
  },
  confirmBtn: {
    width: 110,
  },
  input: {
    marginTop: 10,
    marginBottom: 20,
  },
});

const StateChangeTasksDialog = props => {
  const { closeModal, openModal, tasks } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleShowTask = task => {
    const win = window.open(`${process.env.PUBLIC_URL}/tarea/${task.id}`, "_blank");
    win.focus();
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}
      fullWidth={true}
      maxWidth="sm">
      <DialogTitle>Tareas pasadas a estado Pendiente</DialogTitle>
      <DialogContent>
        {tasks ? (
          <Table>
            <TableBody>
              {tasks.map((task, key) => (
                <TableRow key={key}>
                  <TableCell>{task.codigo_ot}</TableCell>
                  <TableCell align="center">{task.address || ""}</TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleShowTask(task)}>Ver</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(StateChangeTasksDialog);
