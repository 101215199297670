import axios from "../../axios";
import { enqueueError, enqueueMessage } from "./notifications";
import { getGroupGangs } from "./groupGangs";

const types = {
  ADD_GANG_TO_GROUP_MODAL_OPEN: "ADD_GANG_TO_GROUP_MODAL_OPEN",
  ADD_GANG_TO_GROUP_MODAL_CLOSE: "ADD_GANG_TO_GROUP_MODAL_CLOSE",
  ADD_GANG_TO_GROUP_MODAL_REQUEST: "ADD_GANG_TO_GROUP_MODAL_REQUEST",
  ADD_GANG_TO_GROUP_MODAL_SUCCESS: "ADD_GANG_TO_GROUP_MODAL_SUCCESS",
  ADD_GANG_TO_GROUP_MODAL_FAILURE: "ADD_GANG_TO_GROUP_MODAL_FAILURE",

  ADD_GANG_TO_GROUP_MODAL_GANGS_REQUEST: "ADD_GANGS_TO_GROUP_MODAL_GANGS_REQUEST",
  ADD_GANG_TO_GROUP_MODAL_GANGS_SUCCESS: "ADD_GANGS_TO_GROUP_MODAL_GANGS_SUCCESS",
  ADD_GANG_TO_GROUP_MODAL_GANGS_FAILURE: "ADD_GANGS_TO_GROUP_MODAL_GANGS_FAILURE",
};

export const openModal = gang => ({
  type: types.ADD_GANG_TO_GROUP_MODAL_OPEN,
  gang,
});

export const closeModal = () => ({
  type: types.ADD_GANG_TO_GROUP_MODAL_CLOSE,
});

const requestAsignation = () => ({
  type: types.ADD_GANG_TO_GROUP_MODAL_REQUEST,
});

const successAsignation = () => ({
  type: types.ADD_GANG_TO_GROUP_MODAL_SUCCESS,
});

const failureAsignation = () => ({
  type: types.ADD_GANG_TO_GROUP_MODAL_FAILURE,
});

const requestGangs = () => ({
  type: types.ADD_GANG_TO_GROUP_MODAL_GANGS_REQUEST,
});

const successGangs = gangs => ({
  type: types.ADD_GANG_TO_GROUP_MODAL_GANGS_SUCCESS,
  gangs,
});

const failureGangs = () => ({
  type: types.ADD_GANG_TO_GROUP_MODAL_GANGS_FAILURE,
});

export const initialize = () => async dispatch => {
  try {
    dispatch(requestGangs());
    const { data } = await axios.get(`/gangs/ungrouped`);
    dispatch(successGangs(data.data.sort((a, b) => -b.name.localeCompare(a.name))));
  } catch (error) {
    dispatch(failureGangs());
    dispatch(enqueueError("Ocurrió un error al agregar a la cuadrilla al grupo."));
  }
};

export const addGangToGroup = gang => async (dispatch, getState) => {
  const {
    groupGangs: { group },
  } = getState();

  dispatch(requestAsignation());
  try {
    await axios.post(`/gangs/${gang._id}/assignGroup`, { grupo: group.id });
    dispatch(getGroupGangs(group.id, true));

    dispatch(
      enqueueMessage(`La cuadrilla fue agregada correctamente al grupo ${group.nombre}.`)
    );

    dispatch(successAsignation());
  } catch (error) {
    dispatch(failureAsignation());
    dispatch(enqueueError("Ocurrió un error al agregar a la cuadrilla al grupo."));
  }
};

const initialState = {
  modalOpen: false,
  sending: false,
  gangs: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_GANG_TO_GROUP_MODAL_OPEN:
      return { ...state, modalOpen: true };
    case types.ADD_GANG_TO_GROUP_MODAL_CLOSE:
      return { ...state, modalOpen: false };
    case types.ADD_GANG_TO_GROUP_MODAL_REQUEST:
      return { ...state, sending: true };
    case types.ADD_GANG_TO_GROUP_MODAL_SUCCESS:
      return { ...state, sending: false, modalOpen: false };
    case types.ADD_GANG_TO_GROUP_MODAL_FAILURE:
      return { ...state, sending: false };
    case types.ADD_GANG_TO_GROUP_MODAL_GANGS_REQUEST:
      return { ...state, loading: true };
    case types.ADD_GANG_TO_GROUP_MODAL_GANGS_SUCCESS:
      return { ...state, loading: false, gangs: action.gangs };
    case types.ADD_GANG_TO_GROUP_MODAL_GANGS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
