import { gql } from "apollo-boost";

export const GET_GANGS = gql`
  query GET_GANGS($where: GangsWhereWhereConditions = null) {
    gangs(where: $where) {
      id
      cuadrilla
      observations
      tareasEnEjecucionCount
      tareasAsignadasCount
      tareasProgramadasCount
      tareasPausadasCount
    }
  }
`;
