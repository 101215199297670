import { connect } from "react-redux";

import {
  closeModal,
  save,
  toggle,
  initialize,
  toggleAll,
} from "../../../../store/ducks/taskListGroupFilter";
import GroupsDialog from "./groupsDialogContainer/GroupsDialog";

const mapStateToProps = state => ({
  openModal: state.taskListGroupFilter.openModal,
  groups: state.taskListGroupFilter.dialogGroups,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: () => dispatch(save()),
  initialize: () => dispatch(initialize()),
  toggle: event => dispatch(toggle(event)),
  toggleAll: event => dispatch(toggleAll(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsDialog);
