import { connect } from "react-redux";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";
import { save, closeModal } from "../../../../store/ducks/taskListAddressFilter";
import AddressDialog from "./addressDialogContainer/AddressDialog";

const mapStateToProps = state => ({
  openModal: state.taskListAddressFilter.openModal,
  street: state.taskListAddressFilter.street,
  number: state.taskListAddressFilter.number,
  between: state.taskListAddressFilter.between,
  and: state.taskListAddressFilter.and,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: state => {
    dispatch(save(state));
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressDialog);
