import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const parseStates = states => {
  return Object.keys(states)
    .map(key => ({ id: key, value: states[key] }))
    .filter(s => s.value);
};

const chips = props => {
  const { classes } = props;
  const states = parseStates(props.states);

  return states.length !== 11 ? (
    <li>
      <Chip
        onClick={props.openModal}
        className={classes.chip}
        label={`Estados: ${states.length}`}
        onDelete={() => {
          props.removeStates();
        }}
      />
    </li>
  ) : null;
};

export default withStyles(styles)(chips);
