import axios from "../../axios";

const types = {
  SIDEWALK_UNITS_REQUEST: "SIDEWALK_UNITS_REQUEST",
  SIDEWALK_UNITS_SUCCESS: "SIDEWALK_UNITS_SUCCESS",
  SIDEWALK_UNITS_FAILURE: "SIDEWALK_UNITS_FAILURE",
};

const request = () => ({
  type: types.SIDEWALK_UNITS_REQUEST,
});

const success = units => ({
  type: types.SIDEWALK_UNITS_SUCCESS,
  units,
});

const failure = () => ({
  type: types.SIDEWALK_UNITS_FAILURE,
  message: "Ha ocurrido un error al intentar la información de vereda",
});

export const getSidewalkUnits = () => {
  return async dispatch => {
    dispatch(request());
    try {
      const { data } = await axios.get(`/sidewalk/units`);
      dispatch(success(data.data));
    } catch (error) {
      dispatch(failure());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.SIDEWALK_UNITS_SUCCESS:
      return action.units;
    default:
      return state;
  }
};

export default reducer;
