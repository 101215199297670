import apollo from "../../apollo";
import { GET_TASKS } from "../../graphql/tasks/queries";

const types = {
  NEW_TASK_REQUEST: "NEW_TASK_REQUEST",
  NEW_TASK_SUCCESS: "NEW_TASK_SUCCESS",
  NEW_TASK_FAILURE: "NEW_TASK_FAILURE",
  NEW_TASK_CLEAR_TASK_EXISTANCE: "NEW_TASK_CLEAR_TASK_EXISTANCE",
};

const request = () => ({
  type: types.NEW_TASK_REQUEST,
});

const success = payload => ({
  type: types.NEW_TASK_SUCCESS,
  payload,
});

const failure = () => ({
  type: types.NEW_TASK_FAILURE,
});

export const clearTaskExistance = () => ({
  type: types.NEW_TASK_CLEAR_TASK_EXISTANCE,
});

export const checkTaskExistance =
  (odt, id = null) =>
  async dispatch => {
    dispatch(request());
    try {
      const filter = { column: "CODIGO_OT", value: odt };
      const { data } = await apollo.query({
        query: GET_TASKS,
        variables: {
          $where: filter,
          where: filter,
        },
      });
      if (id) {
        dispatch(
          success(!!data.tasks.data.filter(task => Number(task.id) !== Number(id)).length)
        );
      } else {
        dispatch(success(!!data.tasks.data.length));
      }
    } catch (error) {
      dispatch(failure());
    }
  };

const initialState = {
  loading: false,
  existTask: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NEW_TASK_REQUEST:
      return { ...state, loading: true };
    case types.NEW_TASK_CLEAR_TASK_EXISTANCE:
      return { ...state, existTask: false };
    case types.NEW_TASK_SUCCESS:
      return { ...state, loading: false, existTask: action.payload };
    case types.NEW_TASK_FAILURE:
      return { ...state, loading: false, existTask: false };
    default:
      return state;
  }
};

export default reducer;
