import orderBy from "lodash/orderBy";
import axios from "../../axios";

const types = {
  USERS_REQUEST: "USERS_REQUEST",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_FAILURE: "USERS_FAILURE",
};

const requestUsers = () => ({
  type: types.USERS_REQUEST,
});

const successUsers = users => ({
  type: types.USERS_SUCCESS,
  users,
});

const failedUsers = () => ({
  type: types.USERS_FAILURE,
});

export const getUsers = () => {
  return async dispatch => {
    dispatch(requestUsers());
    try {
      const { data } = await axios.get("/users");
      dispatch(successUsers(orderBy(data, "name")));
    } catch (error) {
      dispatch(failedUsers());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.USERS_SUCCESS:
      return action.users;
    default:
      return state;
  }
};

export default reducer;
