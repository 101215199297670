import withStyles from "@mui/styles/withStyles";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TasksIcon from "@mui/icons-material/Build";
import Typography from "@mui/material/Typography";
import { CardContent } from "@mui/material";
import { memo } from "react";

const styles = theme => ({
  icon: {
    fontSize: 100,
  },
  container: { marginTop: theme.spacing(3) },
  textContainer: { marginTop: theme.spacing(3) },
});

const emptyTasks = memo(({ classes }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container justifyContent="center" className={classes.container}>
          <TasksIcon fontSize="inherit" color="disabled" className={classes.icon} />
        </Grid>
        <Grid container justifyContent="center" className={classes.textContainer}>
          <Typography variant="h5" style={{ fontWeight: 300, marginBottom: 8 }}>
            La cuadrilla no tiene próximas tareas
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
});

export default withStyles(styles)(emptyTasks);
