import { connect } from "react-redux";

import { closeModal } from "../../../store/ducks/downloadTasks";
import { mapSelectedHidrojetsForDownload } from "../../../store/ducks/hidrojetList";
import DownloadDialog from "./downloadDialogContainer/DownloadDialog";

const mapStateToProps = state => ({
  selected: state.selectedTasks,
  open: state.downloadTasks.open,
  loading: state.downloadTasks.loading,
  hidrojets: mapSelectedHidrojetsForDownload(state),
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDialog);
