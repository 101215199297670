import { Component } from "react";
import { connect } from "react-redux";

import { checkGangStates, getFilteredGangs } from "../../../../store/ducks/gangs";
import { handleMoveClick } from "../../../../store/ducks/map";

import Gangs from "./gangsContainer/Gangs";

class GangsContainer extends Component {
  componentDidMount() {
    this.interval = setInterval(() => this.props.checkGangStates(), 60000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return <Gangs {...this.props} />;
  }
}

const mapStateToProps = state => ({
  gangs: getFilteredGangs(state),
});

const mapDispatchToProps = dispatch => ({
  checkGangStates: () => dispatch(checkGangStates()),
  handleMoveClick: coordinates => dispatch(handleMoveClick(coordinates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GangsContainer);
