import MenuItem from "@mui/material/MenuItem";
import ButtonBase from "@mui/material/ButtonBase";

const appsButton = () => (
  <MenuItem component={ButtonBase} href="https://gral.com.ar">
    Mis aplicaciones
  </MenuItem>
);

export default appsButton;
