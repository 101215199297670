import apollo from "../../apollo";
import { GET_TASKS } from "../../graphql/tasks/queries";
import { getTaskOrderCriteria } from "../../graphql/tasks/order";
import {
  getTasksFilters,
  getGangsFilters,
  getAuditsFilters,
  getSidewalkFilters,
  getHidrojetFilters,
  getStateChangeFilters,
  getHasPipelinesFilters,
} from "../../graphql/tasks/filters";

import { removeGangs } from "./taskListGangFilter";
import { removeTypes } from "./taskListTypesFilter";
import { removeCities } from "./taskListCityFilter";
import { removeDates } from "./taskListDateFilters";
import { removeStates } from "./taskListStateFilter";
import { removeGroups } from "./taskListGroupFilter";
import { removeAddress } from "./taskListAddressFilter";
import { removeTaskFilters } from "./taskListTaskFilter";
import { removeHistoric } from "./taskListHistoricFilter";
import { removeHidrojet } from "./taskListHidrojetFilter";
import { removeSidewalk } from "./taskListSidewalkFilter";
import { removeStateChanges } from "./taskListStateChangesFilter";
import { removeTaskConditionsFilters } from "./taskListTaskConditionsFilter";
import { removeOptions as removeContractor } from "./taskListContractorFilter";

const types = {
  GRAPHQL_TASKS_REQUEST: "GRAPHQL_TASKS_REQUEST",
  GRAPHQL_TASKS_SUCCESS: "GRAPHQL_TASKS_SUCCESS",
  GRAPHQL_TASKS_FAILURE: "GRAPHQL_TASKS_FAILURE",
  GRAPHQL_TASKS_UPDATE_TASK: "GRAPHQL_TASKS_UPDATE_TASK",
};

const parseVariables = object => {
  Object.keys(object).forEach(k => (object[`$${k}`] = object[k]));
  return object;
};

export const resetFilters = () => async dispatch => {
  await dispatch(removeCities());
  await dispatch(removeTypes());
  await dispatch(removeGangs());
  await dispatch(removeDates());
  await dispatch(removeStates());
  await dispatch(removeGroups());
  await dispatch(removeAddress());
  await dispatch(removeSidewalk());
  await dispatch(removeHidrojet());
  await dispatch(removeHistoric());
  await dispatch(removeContractor());
  await dispatch(removeTaskFilters());
  await dispatch(removeStateChanges());
  await dispatch(removeTaskConditionsFilters());
};

const requestTasks = () => ({
  type: types.GRAPHQL_TASKS_REQUEST,
});

const successTasks = (tasks, paginator) => ({
  type: types.GRAPHQL_TASKS_SUCCESS,
  tasks,
  paginator,
});

const failedTasks = () => ({
  type: types.GRAPHQL_TASKS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener el listado de tareas",
});

export const getTasksFromGraphqlCurrentPage = () => (dispatch, getState) => {
  const {
    tasksList: { paginator },
  } = getState();
  if (paginator && paginator.currentPage) {
    dispatch(getTasksFromGraphql(paginator.currentPage));
  }
};

export const getTasksFromGraphql = (page = 1) => {
  return async (dispatch, getStore) => {
    window.scrollTo(0, 0);
    const store = getStore();
    dispatch(requestTasks());
    try {
      const filters = getTasksFilters(store);
      const gangsFilters = getGangsFilters(store);
      const sidewalkFilters = getSidewalkFilters(store);
      const hidrojetFilters = getHidrojetFilters(store);
      const stateChangeFilters = getStateChangeFilters(store);
      const auditsFilters = getAuditsFilters(store);
      const pipelineFilters = getHasPipelinesFilters(store);

      const orderCriteria = getTaskOrderCriteria(
        store.taskListOrder.selected,
        store.taskListOrder.descOrder
      );

      const { data } = await apollo.query({
        query: GET_TASKS,
        variables: parseVariables({
          page,
          where: filters,
          order_by: orderCriteria,
          hasAudits: auditsFilters,
          hasCuadrilla: gangsFilters,
          hasSidewalks: sidewalkFilters,
          hasHidrojets: hidrojetFilters,
          hasPipelines: pipelineFilters,
          hasTareaAccion: stateChangeFilters,
        }),
      });
      const { tasks } = data;
      dispatch(successTasks(tasks.data, tasks.paginatorInfo));
    } catch (error) {
      console.log(error);
      dispatch(failedTasks());
    }
  };
};

const initialState = { tasks: [], isFetching: true, paginator: null, error: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GRAPHQL_TASKS_REQUEST:
      return { ...state, isFetching: true };
    case types.GRAPHQL_TASKS_FAILURE:
      return { ...state, isFetching: false, error: true };
    case types.GRAPHQL_TASKS_UPDATE_TASK:
      return {
        ...state,
        tasks: state.tasks.map(t =>
          Number(t.id) === action.task.id ? { ...t, estado: action.task.estado } : t
        ),
      };
    case types.GRAPHQL_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.tasks,
        paginator: action.paginator,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default reducer;
