const types = {
  DOWNLOAD_TASKS_FILTER_CHANGE_ODT: "DOWNLOAD_TASKS_FILTER_CHANGE_ODT",
  DOWNLOAD_TASKS_FILTER_CLEAN_FILTERS: "DOWNLOAD_TASKS_FILTER_CLEAN_FILTERS",
  DOWNLOAD_TASKS_FILTER_CHANGE_SIDEWALK: "DOWNLOAD_TASKS_FILTER_CHANGE_SIDEWALK",
  DOWNLOAD_TASKS_FILTER_CHANGE_CLEANING: "DOWNLOAD_TASKS_FILTER_CHANGE_CLEANING",
  DOWNLOAD_TASKS_FILTER_CHANGE_FILLING: "DOWNLOAD_TASKS_FILTER_CHANGE_FILLING",
  DOWNLOAD_TASKS_FILTER_CHANGE_GROUP: "DOWNLOAD_TASKS_FILTER_CHANGE_GROUP",
  DOWNLOAD_TASKS_FILTER_CHANGE_TASK_TYPE: "DOWNLOAD_TASKS_FILTER_CHANGE_TASK_TYPE",
  DOWNLOAD_TASKS_FILTER_CHANGE_DATE_FROM: "DOWNLOAD_TASKS_FILTER_CHANGE_DATE_FROM",
  DOWNLOAD_TASKS_FILTER_CHANGE_DATE_TO: "DOWNLOAD_TASKS_FILTER_CHANGE_DATE_TO",
  DOWNLOAD_TASKS_FILTER_CHANGE_HIDE_DOWNLOADED:
    "DOWNLOAD_TASKS_FILTER_CHANGE_HIDE_DOWNLOADED",
  DOWNLOAD_TASKS_FILTER_TOGGLE_SHOW_SELECTED_TASKS:
    "DOWNLOAD_TASKS_FILTER_TOGGLE_SHOW_SELECTED_TASKS",
};

export const clearFilters = () => ({
  type: types.DOWNLOAD_TASKS_FILTER_CLEAN_FILTERS,
});

export const changeOdt = odt => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_ODT,
  odt,
});

export const changeCleaning = requiresCleaning => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_CLEANING,
  requiresCleaning,
});

export const changeSidewalk = requiresSidewalk => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_SIDEWALK,
  requiresSidewalk,
});

export const changeFilling = requiresFilling => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_FILLING,
  requiresFilling,
});

export const changeGroup = group => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_GROUP,
  group,
});

export const changeTaskType = taskType => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_TASK_TYPE,
  taskType,
});

export const changeDateFrom = date => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_DATE_FROM,
  date,
});

export const changeDateTo = date => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_DATE_TO,
  date,
});

export const changeHideDownloaded = value => ({
  type: types.DOWNLOAD_TASKS_FILTER_CHANGE_HIDE_DOWNLOADED,
  value,
});

export const toggleShowSelectedTasks = () => ({
  type: types.DOWNLOAD_TASKS_FILTER_TOGGLE_SHOW_SELECTED_TASKS,
});

const initialState = {
  odt: "",
  group: "",
  taskType: "",

  dateTo: null,
  dateFrom: null,
  requiresFilling: "",
  requiresSidewalk: "",
  requiresCleaning: "",

  hideDonwloaded: true,
  showSelectedTasks: false,
};

const reducer = (state = { ...initialState }, action) => {
  let date;
  switch (action.type) {
    case types.DOWNLOAD_TASKS_FILTER_CLEAN_FILTERS:
      return { ...initialState, group: state.group };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_ODT:
      return { ...state, odt: action.odt };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_GROUP:
      return { ...state, group: action.group };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_CLEANING:
      return { ...state, requiresCleaning: action.requiresCleaning };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_SIDEWALK:
      return { ...state, requiresSidewalk: action.requiresSidewalk };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_FILLING:
      return { ...state, requiresFilling: action.requiresFilling };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_DATE_FROM:
      date = action.date;
      if (date) {
        date.setHours(0, 0, 0);
      }
      return { ...state, dateFrom: date };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_TASK_TYPE:
      return { ...state, taskType: action.taskType };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_DATE_TO:
      date = action.date;
      if (date) {
        date.setHours(23, 59, 59);
      }
      return { ...state, dateTo: date };

    case types.DOWNLOAD_TASKS_FILTER_CHANGE_HIDE_DOWNLOADED:
      return { ...state, hideDonwloaded: action.value };

    case types.DOWNLOAD_TASKS_FILTER_TOGGLE_SHOW_SELECTED_TASKS:
      return { ...state, showSelectedTasks: !state.showSelectedTasks };
    default:
      return state;
  }
};

export default reducer;
