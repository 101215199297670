import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DatePicker from "@mui/lab/MobileDatePicker";
import { Divider, TextField, useMediaQuery, useTheme } from "@mui/material";

const styles = () => ({
  form: {
    // minWidth: 450,
    // maxHeight: 380,
  },
  dates: {
    marginTop: 20,
  },
  states: {
    width: "100%",
  },
});

const StateChangesDialog = props => {
  const { classes, closeModal, openModal, save } = props;
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [states, setStates] = useState(props.states);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const checkboxes = [
    { name: "Pendiente", id: "1" },
    { name: "Validada", id: "6" },
    { name: "Asignada", id: "11" },
    { name: "Cancelada", id: "7" },
    { name: "Programada", id: "2" },
    { name: "Inspección", id: "8" },
    { name: "Pausada", id: "3" },
    { name: "Aprobada", id: "9" },
    { name: "Ejecución", id: "4" },
    { name: "Rechazada", id: "10" },
    { name: "Recibida", id: "5" },
  ];

  const handleChange = event => {
    setStates({
      ...states,
      [event.target.name]: event.target.checked,
    });
  };

  const initialize = () => {
    setDateFrom(props.dateFrom);
    setDateTo(props.dateTo);
    setStates(props.states);
  };

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({ dateFrom, dateTo, statesChanges: { ...states } });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openModal, dateFrom, dateTo, states, save]);

  const allSelected = checkboxes.filter(g => states[g.id]).length === checkboxes.length;

  return (
    <Dialog
      fullScreen={isMobile}
      open={openModal}
      onClose={closeModal}
      TransitionProps={{
        onEnter: initialize,
        onExited: initialize,
      }}>
      <DialogTitle>Cambios de estado</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Se filtrarán las tareas que hayan tenido los estados seleccionados entre las
          fechas deseadas.
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <Grid container spacing={2} className={classes.dates}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha desde"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={dateFrom}
                  onChange={setDateFrom}
                  DialogProps={{ disableRestoreFocus: true }}
                  todayText="Hoy"
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha hasta"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={dateTo}
                  onChange={setDateTo}
                  DialogProps={{ disableRestoreFocus: true }}
                  todayText="Hoy"
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.dates}>
              <Grid key={0} item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Todas"
                      color="default"
                      onChange={() => {
                        if (allSelected) {
                          setStates(
                            Object.keys(states).reduce((acc, key) => {
                              acc[key] = false;
                              return acc;
                            }, {})
                          );
                        } else {
                          setStates(
                            Object.keys(states).reduce((acc, key) => {
                              acc[key] = true;
                              return acc;
                            }, {})
                          );
                        }
                      }}
                      checked={allSelected}
                    />
                  }
                  label="Todas"
                />
                <Divider />
              </Grid>

              {checkboxes.map(item => (
                <Grid key={item.id} item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={item.id}
                        color="default"
                        onChange={handleChange}
                        checked={states[item.id]}
                      />
                    }
                    label={item.name}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => save({ dateFrom, dateTo, statesChanges: { ...states } })}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(StateChangesDialog);
