import { connect } from "react-redux";

import { closeModal, editArticle } from "../../../store/ducks/editModal";
import EditConfirmationModal from "./editConfirmationModalContainer/EditConfirmationModal";

const mapStateToProps = state => ({
  open: state.editModal.confirmationOpen,
  article: state.editModal.article,
  amount: state.editModal.amount,
  gangStock: state.editModal.gangStock,
  sending: state.editModal.sending,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  editArticle: () => dispatch(editArticle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditConfirmationModal);
