import { Component } from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

class TypeFilter extends Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    return (
      <List
        component="nav"
        disablePadding
        subheader={
          <>
            <Grid container>
              <Grid item xs>
                <ListSubheader disableSticky>Filtros mapa</ListSubheader>
              </Grid>
              <Grid item>
                <IconButton
                  disableRipple
                  onClick={this.handleClick}
                  size="small"
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                  }}>
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </Grid>
          </>
        }>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <FormGroup style={{ paddingLeft: 16 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.gangs}
                  onChange={this.props.toggleGangs}
                  color="default"
                />
              }
              label="Cuadrillas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.pendingTasks}
                  onChange={this.props.togglePendingTasks}
                  color="default"
                />
              }
              label="Tareas pendientes"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.assignedTasks}
                  onChange={this.props.toggleAssignedTasks}
                  color="default"
                />
              }
              label="Tareas asignadas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.programmedTasks}
                  onChange={this.props.toggleProgrammedTasks}
                  color="default"
                />
              }
              label="Tareas programadas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.executingTasks}
                  onChange={this.props.toggleExecutingTasks}
                  color="default"
                />
              }
              label="Tareas en ejecución"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.pausedTasks}
                  onChange={this.props.togglePausedTasks}
                  color="default"
                />
              }
              label="Tareas pausadas"
            />
          </FormGroup>
        </Collapse>
      </List>
    );
  }
}

export default TypeFilter;
