/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const FinishValidationModal = props => {
  const hasArticles = props.task.articles.length;
  const [selectedState, setSelected] = useState(8);

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Finalizar validación {hasArticles ? "" : "sin materiales"}
      </DialogTitle>
      <DialogContent>
        {hasArticles ? null : (
          <DialogContentText style={{ fontSize: 18 }}>
            Al confirmar esta acción la tarea con ODT #{props.task?.otCode}, la cual no
            tiene materiales asociados, pasará a estar validada.
          </DialogContentText>
        )}

        <DialogContentText style={{ fontSize: 18 }}>
          Para finalizar deberás indicar el estado al que deseas llevar a la tarea:
        </DialogContentText>

        <FormControl style={{ width: "100%" }} margin="dense" variant="outlined">
          <InputLabel id="input-requires-materials">Nuevo Estado</InputLabel>
          <Select
            label="Nuevo Estado"
            value={selectedState}
            onChange={e => setSelected(e.target.value)}>
            <MenuItem value="6">Validada</MenuItem>
            <MenuItem value="8">Inspección</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.sending}
          style={{ color: "black" }}>
          Cancelar
        </Button>
        <Button
          color="primary"
          disabled={props.sending || !props.canConfirm}
          onClick={() => props.confirm(selectedState, navigate)}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishValidationModal;
