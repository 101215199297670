import { connect } from "react-redux";

import {
  change,
  closeModal,
  confirmEdit,
} from "../../../../store/ducks/taskPipelineEditModal";

import EditModal from "./modal";

const mapStateToProps = state => ({
  loading: false,
  task: state.task,

  pipeline: state.taskPipelineEditModal.pipeline,

  depth: state.taskPipelineEditModal.depth,
  diameter: state.taskPipelineEditModal.diameter,
  material: state.taskPipelineEditModal.material,
  has_sidewalk: state.taskPipelineEditModal.has_sidewalk,
  street_orientation: state.taskPipelineEditModal.street_orientation,
  municipal_line_distance: state.taskPipelineEditModal.municipal_line_distance,

  open: state.taskPipelineEditModal.modalOpen,
  sending: state.taskPipelineEditModal.sending,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  confirmEdit: () => dispatch(confirmEdit()),
  change: pipeline => dispatch(change(pipeline)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
