import { connect } from "react-redux";

import { removeGroups, openModal } from "../../../../../store/ducks/taskListGroupFilter";
import Groups from "./groupsContainer/Groups";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  groups: state.taskListGroupFilter.selectedGroups,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeGroups: () => {
    dispatch(removeGroups());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
