import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import { useMediaQuery, useTheme } from "@mui/material";

const styles = theme => ({
  form: {
    width: "100%",
  },
  input: {
    marginTop: theme.spacing(3),
  },
  confirmBtn: {
    width: 110,
  },
  action: {
    marginTop: theme.spacing(2),
  },
});

const ApproveDialog = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { classes, closeModal, openModal } = props;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openModal}
      fullScreen={isMobile}
      onClose={closeModal}>
      <DialogTitle>Aprobar tarea {props.task?.codigo_ot}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Dirección: {props.task?.ubicacion}
        </Typography>

        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <TextField
              value={props.certificate}
              onChange={e => props.changeCertificate(e.target.value)}
              className={classes.input}
              label="Certificado"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              value={props.comment}
              onChange={e => props.changeComment(e.target.value)}
              className={classes.input}
              label="Observaciones"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <FormControlLabel
              className={classes.action}
              control={
                <Checkbox
                  color="default"
                  checked={props.actionRequired}
                  onChange={props.toggleActionRequired}
                />
              }
              label="Requiere acción"
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal} disabled={props.loading}>
          Cancelar
        </Button>
        <LoadingButton loading={props.loading} color="primary" onClick={props.confirm}>
          Aprobar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ApproveDialog);
