import { connect } from "react-redux";

import Toolbar from "./Toolbar";
import { changeSearchGroupGangs } from "../../../store/ducks/groupGangs";
import { openModal } from "../../../store/ducks/addGangToGroupModal";

const mapStateToProps = state => ({
  value: state.groupGangs.search,
  group: state.groupGangs.group,
  roles: state.user.roles,
});

const mapDispatchToProps = dispatch => ({
  onChange: value => dispatch(changeSearchGroupGangs(value)),
  openAddGangModal: () => dispatch(openModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
