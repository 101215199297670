import { Component } from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { FixedSizeList } from "react-window";
import format from "date-fns/format";

class TaskList extends Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  renderRow = ({ index, style }) => {
    return (
      <ListItem
        style={style}
        key={index}
        button
        title={this.props.tasks[index].address}
        onClick={() =>
          this.props.handleMoveClick(this.props.tasks[index].location.coordinates)
        }>
        <div className={`icon ${this.props.tasks[index].state.class} gang-list`}>
          <p>
            <i className="fas fa-wrench"></i>
          </p>
        </div>
        <ListItemText
          primary={<Typography noWrap>{this.props.tasks[index].address}</Typography>}
          disableTypography
          secondary={
            <div>
              <Typography variant="body2" color="textSecondary" style={{ margin: 0 }}>
                {this.props.tasks[index].type}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ margin: 0 }}>
                Vencimiento: {format(this.props.tasks[index].dueDate, "dd/MM/yyyy")}
              </Typography>
            </div>
          }
        />
      </ListItem>
    );
  };

  render() {
    return (
      <List
        component="nav"
        disablePadding
        subheader={
          <>
            <Grid container>
              <Grid item xs>
                <ListSubheader disableSticky>Tareas</ListSubheader>
              </Grid>
              <Grid item>
                <IconButton
                  disableRipple
                  onClick={this.handleClick}
                  size="small"
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                  }}>
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </Grid>
          </>
        }>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <FixedSizeList
            height={600}
            // width={280}
            itemSize={80}
            itemCount={this.props.tasks.length}>
            {this.renderRow}
          </FixedSizeList>
        </Collapse>
      </List>
    );
  }
}

export default TaskList;
