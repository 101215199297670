import { memo } from "react";
import Typography from "@mui/material/Typography";

const title = memo(() => {
  return (
    <>
      <Typography variant="h5" component="h3">
        Módulos de hidrojet
      </Typography>
      <Typography component="p">Seleccione las tareas que desea descargar.</Typography>
    </>
  );
});

export default title;
