import { connect } from "react-redux";

import { removeGangs, openModal } from "../../../../../store/ducks/taskListGangFilter";
import Gangs from "./gangsContainer/Gangs";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  gangs: state.taskListGangFilter.selectedGangs,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeGangs: () => {
    dispatch(removeGangs());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Gangs);
