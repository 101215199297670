import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./styles.css";

export default ({ task }) =>
  task.sidewalk_photos && task.sidewalk_photos.length ? (
    <ImageGallery
      lazyLoad
      showNav={true}
      showBullets={true}
      showThumbnails={false}
      showPlayButton={false}
      style={{ borderRadius: 5 }}
      items={task.sidewalk_photos.map(photo => ({
        original: `${process.env.REACT_APP_IMAGES_URI}/${photo.path}`,
      }))}
    />
  ) : null;
