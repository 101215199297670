import { concat } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { keycloak } from ".";

// Auth

const { REACT_APP_TAREAS_GRAPHQL_API_URI } = process.env;

const withToken = setContext(async (_, { headers }) => {
  const authenticated = keycloak.authenticated;

  if (authenticated) {
    try {
      await keycloak.updateToken();
    } catch (error) {
      return keycloak.login();
    }
  }
  return { headers: { ...headers, Authorization: `Bearer ${keycloak.token}` } };
});

const resetToken = onError(async ({ networkError, operation, forward }) => {
  if (networkError?.statusCode) {
    await keycloak.updateToken();

    const oldHeaders = operation.getContext().headers;
    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: `Bearer ${keycloak.token}`,
      },
    });

    return forward(operation);
  }
});

const authFlowLink = withToken.concat(resetToken);
const httpLink = new HttpLink({ uri: REACT_APP_TAREAS_GRAPHQL_API_URI });

export default new ApolloClient({
  link: concat(authFlowLink, httpLink),
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});
