import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const DemoCarousel = props => {
  return (
    <Carousel showStatus={false} dynamicHeight showThumbs={false}>
      {props.images.map(image => (
        <img
          alt="tarea"
          key={image.path}
          style={{ height: 375, objectFit: "contain" }}
          src={`${process.env.REACT_APP_IMAGES_URI}/${image.path}`}
        />
      ))}
    </Carousel>
  );
};

export default DemoCarousel;
