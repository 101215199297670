import { Component } from "react";
import { connect } from "react-redux";

import { getGangs } from "../store/ducks/gangs";
import { getGroups } from "../store/ducks/groups";
import { getCities } from "../store/ducks/cities";
import { getStores } from "../store/ducks/storesList";
import { getTaskTypes } from "../store/ducks/taskTypes";
import { getTasksFromGraphql } from "../store/ducks/tasksList";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

import ApiError from "../components/UI/ApiError";
import TaskList from "./taskListContainer/TaskList";

class TaskListContainer extends Component {
  async componentDidMount() {
    const promises = [
      this.props.getTypes(),
      this.props.getGangs(),
      this.props.getCities(),
      this.props.getGroups(),
      this.props.getStores(),
    ];
    await Promise.all(promises);
    this.props.getTasks();
  }

  render() {
    const { error, tasksError } = this.props;
    return error || tasksError ? (
      <ApiError error="Ocurrió un error al obtener las tareas" />
    ) : (
      <TaskList {...this.props} />
    );
  }
}

const actionNames = ["GROUPS", "TASK_TYPES", "GANGS", "CITIES", "STORES_LIST"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
  tasksError: state.tasksList.error,
});

const mapDispatchToProps = dispatch => ({
  getGangs: () => dispatch(getGangs()),
  getStores: () => dispatch(getStores()),
  getGroups: () => dispatch(getGroups()),
  getCities: () => dispatch(getCities()),
  getTypes: () => dispatch(getTaskTypes()),
  getTasks: state => dispatch(getTasksFromGraphql(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskListContainer);
