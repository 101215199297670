import { useEffect, useMemo, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { InputAdornment, TextField } from "@mui/material";

import { useDebounce } from "../../../../../utils/useDebounce";
import { removeAccents } from "../../../../../utils/removeAccents";

const styles = () => ({
  form: {
    // minWidth: 380,
    // maxHeight: 290,
    width: "100%",
  },
});

const GroupsDialog = props => {
  const { classes, closeModal, openModal, save, groups } = props;

  const theme = useTheme();
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 600);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({ groups: groups.filter(({ selected }) => selected) });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openModal, groups, save]);

  const allSelected = props.groups.filter(g => g.selected).length === props.groups.length;

  const groupsOptions = useMemo(
    () =>
      groups.filter(g =>
        searchDebounced
          ? searchDebounced
              .split(" ")
              .every(word =>
                removeAccents(g.name.toLowerCase()).includes(
                  removeAccents(word.toLowerCase())
                )
              )
          : true
      ),
    [groups, searchDebounced]
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}
      TransitionProps={{
        onEnter: props.initialize,
      }}>
      <DialogTitle>Grupos</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Buscar"
          value={search}
          variant="standard"
          id="outlined-start-adornment"
          onChange={e => setSearch(e.target.value)}
          style={{ width: "100%", marginBottom: 15, marginTop: -10 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <FormControl component="fieldset" className={classes.form}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  name="Todos"
                  onChange={props.toggleAll}
                  checked={allSelected}
                />
              }
              label="Todos"
            />
            <Divider />
            {groupsOptions.map(group => (
              <FormControlLabel
                key={group.id}
                control={
                  <Checkbox
                    color="default"
                    name={`${group.id}`}
                    onChange={props.toggle}
                    checked={group.selected}
                  />
                }
                label={group.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() =>
            props.save({ groups: groups.filter(({ selected }) => selected) })
          }>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(GroupsDialog);
