import { connect } from "react-redux";

import { closePanel } from "../../../store/ducks/mapInfoPanel";
import InfoPanel from "./infoPanelContainer/InfoPanel";

const mapStateToProps = state => ({
  open: state.mapInfoPanel.open,
});

const mapDispatchToProps = dispatch => ({
  closePanel: () => dispatch(closePanel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPanel);
