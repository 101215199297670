import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import withStyles from "@mui/styles/withStyles";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = () => ({
  form: {
    width: "100%",
  },
});

const TaskTypesDialog = props => {
  const { classes, closeModal, openModal, save, types } = props;

  const allSelected = types.filter(c => c.selected).length === types.length;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({ types: types.filter(({ selected }) => selected) });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openModal, types, save]);

  return (
    <Dialog
      open={openModal}
      fullWidth
      maxWidth="xs"
      onClose={closeModal}
      fullScreen={isMobile}
      TransitionProps={{
        onEnter: props.initialize,
      }}>
      <DialogTitle>Tipo de Tareas</DialogTitle>
      <DialogContent dividers>
        <FormControl component="fieldset" className={classes.form}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="Todos"
                  onChange={props.toggleAll}
                  checked={allSelected}
                  color="default"
                />
              }
              label="Todos"
            />
            <Divider />
            {props.types.map(type => (
              <FormControlLabel
                key={type.id}
                control={
                  <Checkbox
                    name={type.id}
                    color="default"
                    onChange={props.toggle}
                    checked={type.selected}
                  />
                }
                label={type.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => save({ types: types.filter(({ selected }) => selected) })}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(props => <TaskTypesDialog {...props} />);
