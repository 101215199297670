import { gql } from "apollo-boost";

export const GET_TASKS = gql`
  query GET_TASKS(
    $page: Int = 0
    $first: Int = 25
    $hasAudits: WhereConditions = null
    $hasSidewalks: WhereConditions = null
    $hasPipelines: WhereConditions = null
    $hasHidrojets: WhereConditions = null
    $hasCuadrilla: WhereConditions = null
    $hasTareaAccion: WhereConditions = null
    $where: TasksWhereWhereConditions = null
    $order_by: [TasksOrderByOrderByClause!] = [{ field: CREATED_AT, order: DESC }]
  ) {
    tasks(
      page: $page
      first: $first
      where: $where
      order_by: $order_by
      hasAudits: $hasAudits
      hasPipelines: $hasPipelines
      hasSidewalks: $hasSidewalks
      hasHidrojets: $hasHidrojets
      hasCuadrilla: $hasCuadrilla
      hasTareaAccion: $hasTareaAccion
    ) {
      data {
        id
        localidad {
          id
        }
        for_contractor
        parent_id
        stores
        historic
        codigo_ot
        has_materials
        created_at
        updated_at
        ingreso_ot
        fecha_asignada
        requiere_accion
        certificate
        valida_materiales
        notRequiredArticles {
          id_articulo
        }
        sidewalks {
          state
        }
        modulo
        vencimiento
        observaciones
        ubicacion
        tarea
        croquis {
          path
        }
        fallida
        grupo {
          id
          nombre
        }
        cuadrilla {
          id
          cuadrilla
        }
        tipo {
          id
          tipo
        }
        estado {
          id
          estado
        }
        ultimaRecepcion {
          inspector
          observaciones
        }
      }
      paginatorInfo {
        hasMorePages
        currentPage
        perPage
        total
      }
    }
  }
`;

export const GET_TASKS_FOR_ABSA = gql`
  query GET_TASKS(
    $page: Int = 0
    $first: Int = 250
    $hasAudits: WhereConditions = null
    $hasSidewalks: WhereConditions = null
    $hasPipelines: WhereConditions = null
    $hasHidrojets: WhereConditions = null
    $hasCuadrilla: WhereConditions = null
    $hasTareaAccion: WhereConditions = null
    $where: TasksWhereWhereConditions = null
  ) {
    tasks(
      page: $page
      first: $first
      where: $where
      hasAudits: $hasAudits
      hasSidewalks: $hasSidewalks
      hasPipelines: $hasPipelines
      hasCuadrilla: $hasCuadrilla
      hasHidrojets: $hasHidrojets
      hasTareaAccion: $hasTareaAccion
    ) {
      data {
        id
        latitud
        longitud
        codigo_ot
        created_at
        ubicacion
        cuadrilla {
          id
          cuadrilla
        }
        pipelines {
          depth
          diameter
          material
          has_sidewalk
          street_orientation
          municipal_line_distance
        }
        ultimaRecepcion {
          inspector
          created_at
          estado_id
        }
      }
    }
  }
`;

export const GET_TASKS_IDS = gql`
  query GET_TASKS_IDS(
    $hasAudits: WhereConditions = null
    $hasSidewalks: WhereConditions = null
    $hasHidrojets: WhereConditions = null
    $hasPipelines: WhereConditions = null
    $hasCuadrilla: WhereConditions = null
    $hasTareaAccion: WhereConditions = null
    $where: TasksFullWhereWhereConditions = null
  ) {
    tasksFull(
      where: $where
      hasAudits: $hasAudits
      hasSidewalks: $hasSidewalks
      hasPipelines: $hasPipelines
      hasCuadrilla: $hasCuadrilla
      hasHidrojets: $hasHidrojets
      hasTareaAccion: $hasTareaAccion
    ) {
      id
    }
  }
`;
