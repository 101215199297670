import { Fragment, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = () => ({
  form: {
    width: "100%",
    // minWidth: 400,
    // maxHeight: 300,
  },
});

const ContractDialog = props => {
  const { classes, closeModal, openModal, save, options } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const allSelected =
    props.options.filter(g => g.selected).length === props.options.length;

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({ gangs: options.filter(({ selected }) => selected) });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openModal, options, save]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}
      TransitionProps={{
        onEnter: props.initialize,
      }}>
      <DialogTitle>Distribución</DialogTitle>
      <DialogContent dividers>
        <FormControl component="fieldset" className={classes.form}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox name="Todas" onChange={props.toggleAll} checked={allSelected} />
              }
              label="Todas"
            />
            <Divider />
            {props.options.map((option, i) => (
              <Fragment key={option.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      name={option.id}
                      onChange={props.toggle}
                      checked={option.selected}
                    />
                  }
                  label={option.name}
                />
                {i === 0 ? <Divider /> : null}
              </Fragment>
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => save({ options: options.filter(({ selected }) => selected) })}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ContractDialog);
