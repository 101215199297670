import { connect } from "react-redux";
import StateChangedTasksDialog from "./StateChangeTasksDialog";
import { closeModal } from "../../../store/ducks/statedChangedTasksModal";

const mapStateToProps = state => ({
  openModal: state.statedChangedTasksModal.modalOpen,
  tasks: state.statedChangedTasksModal.tasks,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StateChangedTasksDialog);
