import { orderBy } from "lodash";
import apollo from "../../apollo";
import axios from "../../axios";
import { GET_LOCATIONS } from "../../graphql/locations/queries";

const types = {
  CITIES_REQUEST: "CITIES_REQUEST",
  CITIES_SUCCESS: "CITIES_SUCCESS",
  CITIES_FAILURE: "CITIES_FAILURE",
};

const requestCities = () => ({
  type: types.CITIES_REQUEST,
});

const successCities = cities => ({
  type: types.CITIES_SUCCESS,
  cities,
});

const failedCities = () => ({
  type: types.CITIES_FAILURE,
});

export const getCities = (group = null) => {
  return async dispatch => {
    dispatch(requestCities());
    const hasGrupos = group ? { column: "grupo_id", value: group } : null;
    try {
      const { data } = await apollo.query({
        query: GET_LOCATIONS,
        variables: {
          hasGrupos,
          $hasGrupos: hasGrupos,
        },
      });

      const locations = data.locations.map(location => ({
        id: location.id,
        name: `${location.nombre} ${location.seccion ? `(${location.seccion})` : ""}`,
        selected: true,
      }));
      dispatch(successCities(orderBy(locations, "name")));
    } catch (error) {
      dispatch(failedCities());
    }
  };
};

export const getAllCities = () => {
  return async dispatch => {
    dispatch(requestCities());
    try {
      const { data } = await axios.get("/locations");
      const locations = data.data.map(location => ({
        id: location.id,
        name: `${location.nombre} ${location.seccion ? `(${location.seccion})` : ""}`,
        selected: true,
      }));
      dispatch(successCities(orderBy(locations, "name")));
    } catch (error) {
      dispatch(failedCities());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.CITIES_SUCCESS:
      return action.cities;
    default:
      return state;
  }
};

export default reducer;
