import { Fragment, memo } from "react";
import withStyles from "@mui/styles/withStyles";
import Icon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  icon: {
    fontSize: 200,
  },
  container: { marginTop: theme.spacing(15) },
  textContainer: { marginTop: theme.spacing(3) },
});

const emptyTasks = memo(({ classes }) => {
  return (
    <Fragment>
      <Grid container justifyContent="center" className={classes.container}>
        <Icon fontSize="inherit" color="disabled" className={classes.icon} />
      </Grid>

      <Grid container justifyContent="center" className={classes.textContainer}>
        <Typography variant="h5" style={{ fontWeight: 300, marginBottom: 8 }}>
          No se encontraron grupos que coincidan con la búsqueda
        </Typography>
      </Grid>
    </Fragment>
  );
});

export default withStyles(styles)(emptyTasks);
