const types = {
  ADD_COMPLEMENTARY_MODAL_OPEN: "ADD_COMPLEMENTARY_MODAL_OPEN",
  ADD_COMPLEMENTARY_MODAL_CLOSE: "ADD_COMPLEMENTARY_MODAL_CLOSE",
};

export const openModal = () => ({
  type: types.ADD_COMPLEMENTARY_MODAL_OPEN,
});

export const closeModal = () => ({
  type: types.ADD_COMPLEMENTARY_MODAL_CLOSE,
});

const initialState = {
  sending: false,
  modalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_COMPLEMENTARY_MODAL_OPEN:
      return { ...state, modalOpen: true };
    case types.ADD_COMPLEMENTARY_MODAL_CLOSE:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export default reducer;
