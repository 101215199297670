/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Grid, Card, Button, CardHeader, CardActions, CardContent } from "@mui/material";

import axios from "../../../../axios";

import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Result from "../result";

import Step1Validator from "./step1/validator";
import Step2Validator from "./step2/validator";

import parseData from "../parseData";

import { enqueueError } from "../../../../store/ducks/notifications";

const steps = {
  1: Step1,
  2: Step2,
  3: Step3,
  4: Result,
};

const validators = {
  1: Step1Validator,
  2: Step2Validator,
  3: ({ grupo }) => !!grupo,
};

const subtitles = {
  1: "Orden de trabajo",
  2: "Domicilio",
  3: "Selección de grupo y depositos",
  4: "",
};

const NewTaskForm = ({ form }) => {
  const {
    getValues,
    formState: { errors },
  } = form;
  const [step, setStep] = useState(1);
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(false);

  const Step = steps[step];
  const subtitle = subtitles[step];

  const group = `step${step}`;
  const isValid = validators[step]
    ? validators[step](getValues(group) || {}) &&
      !Object.values(errors[group] || {}).filter(a => a).length
    : true;

  const dispatch = useDispatch();

  useEffect(() => {
    const listener = event => {
      const activeElement = document.activeElement;
      if (
        activeElement &&
        (["SELECT"].includes(activeElement.tagName) ||
          ["icon-button-file"].includes(activeElement.id) ||
          [...activeElement.classList].some(className =>
            [
              "MuiSelect-select",
              "MuiMenuItem-root",
              "MuiInputBase-root",
              "MuiFormControl-root",
              "MuiIconButton-edgeEnd",
            ].includes(className)
          ))
      )
        return;
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (!!isValid) {
          if (step < 3) {
            setStep(step + 1);
          } else if (step === 3) {
            onSubmit();
          }
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isValid, setStep, step]);

  const onSubmit = () => {
    if (loading || step > 3) return;

    setLoading(true);
    axios
      .post("/tasks", parseData(getValues()), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(result => {
        setTask(result.data.tarea);
        setStep(4);
      })
      .catch(() => {
        dispatch(enqueueError("Ocurrió un error al crear la tarea"));
      })
      .finally(() => setLoading(false));
  };

  const onRestart = useCallback(() => {
    window.open(`${process.env.PUBLIC_URL}/nueva-tarea`, "_blank");
  }, []);

  return (
    <form>
      <Grid container spacing={10}>
        <Grid item style={{ paddingBottom: 20, width: "100%" }}>
          <Card style={{ padding: 15 }} variant="outlined">
            <CardHeader title="Nueva Tarea" subheader={subtitle}></CardHeader>

            <CardContent>
              <Step form={form} task={task} onRestart={onRestart} />
            </CardContent>

            <CardActions style={{ float: "right" }}>
              {step > 1 && step < 4 && (
                <Button style={{ color: "black" }} onClick={() => setStep(step - 1)}>
                  Volver
                </Button>
              )}

              {step < 3 && (
                <Button
                  color="primary"
                  disabled={!isValid}
                  onClick={() => setStep(step + 1)}>
                  Siguiente
                </Button>
              )}

              {step === 3 && (
                <LoadingButton
                  loading={loading}
                  color="primary"
                  disabled={!isValid}
                  onClick={onSubmit}>
                  Finalizar
                </LoadingButton>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewTaskForm;
