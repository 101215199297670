import { enqueueError, enqueueMessage } from "./notifications";
import { getTasksFromGraphqlCurrentPage } from "./tasksList";
import axios from "../../axios";
import { getTask } from "./taskDetails";

const types = {
  TASK_LIST_APPROVE_TASK_OPEN_MODAL: "TASK_LIST_APPROVE_TASK_OPEN_MODAL",
  TASK_LIST_APPROVE_TASK_CLOSE_MODAL: "TASK_LIST_APPROVE_TASK_CLOSE_MODAL",
  TASK_LIST_APPROVE_TASK_CHANGE_COMMENT: "TASK_LIST_APPROVE_TASK_CHANGE_COMMENT",
  TASK_LIST_APPROVE_TASK_CHANGE_CERTIFICATE: "TASK_LIST_APPROVE_TASK_CHANGE_CERTIFICATE",
  TASK_LIST_APPROVE_TASK_REQUEST: "TASK_LIST_APPROVE_TASK_REQUEST",
  TASK_LIST_APPROVE_TASK_SUCCESS: "TASK_LIST_APPROVE_TASK_SUCCESS",
  TASK_LIST_APPROVE_TASK_FAILURE: "TASK_LIST_APPROVE_TASK_FAILURE",
  TASK_LIST_APPROVE_TASK_TOGGLE_ACTION_REQUIRED:
    "TASK_LIST_APPROVE_TASK_TOGGLE_ACTION_REQUIRED",
};

export const openModal = task => ({
  type: types.TASK_LIST_APPROVE_TASK_OPEN_MODAL,
  task,
});

export const closeModal = () => ({
  type: types.TASK_LIST_APPROVE_TASK_CLOSE_MODAL,
});

export const changeComment = comment => ({
  type: types.TASK_LIST_APPROVE_TASK_CHANGE_COMMENT,
  comment,
});

export const changeCertificate = certificate => ({
  type: types.TASK_LIST_APPROVE_TASK_CHANGE_CERTIFICATE,
  certificate,
});

export const toggleActionRequired = () => ({
  type: types.TASK_LIST_APPROVE_TASK_TOGGLE_ACTION_REQUIRED,
});

const request = () => ({
  type: types.TASK_LIST_APPROVE_TASK_REQUEST,
});

const success = () => ({
  type: types.TASK_LIST_APPROVE_TASK_SUCCESS,
});

const failure = () => ({
  type: types.TASK_LIST_APPROVE_TASK_FAILURE,
});

export const confirm = () => async (dispatch, getState) => {
  const {
    taskListApproveTask: { task, comment, actionRequired, certificate },
  } = getState();
  dispatch(request());
  try {
    await axios.post(`/tasks/${task.id}/actions`, {
      certificate,
      estado_id: 9,
      ...(comment && { observaciones: comment }),
      requiere_accion: actionRequired,
    });

    dispatch(enqueueMessage("La tarea se aprobó correctamente"));
    dispatch(getTasksFromGraphqlCurrentPage());
    dispatch(getTask(task.id));
    dispatch(success());
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al aprobar la tarea"));
    dispatch(failure());
  }
};

const initialState = {
  openModal: false,
  loading: false,
  task: null,
  comment: "",
  certificate: "",
  actionRequired: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_APPROVE_TASK_OPEN_MODAL:
      return {
        ...state,
        openModal: true,
        actionRequired: !!action.task.requiere_accion,
        task: action.task,
        comment: "",
      };
    case types.TASK_LIST_APPROVE_TASK_CHANGE_CERTIFICATE:
      return { ...state, certificate: action.certificate };
    case types.TASK_LIST_APPROVE_TASK_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_APPROVE_TASK_CHANGE_COMMENT:
      return { ...state, comment: action.comment };
    case types.TASK_LIST_APPROVE_TASK_REQUEST:
      return { ...state, loading: true, gangError: false };
    case types.TASK_LIST_APPROVE_TASK_SUCCESS:
      return { ...state, loading: false, openModal: false, comment: "", certificate: "" };
    case types.TASK_LIST_APPROVE_TASK_FAILURE:
      return { ...state, loading: false };
    case types.TASK_LIST_APPROVE_TASK_TOGGLE_ACTION_REQUIRED:
      return { ...state, actionRequired: !state.actionRequired };
    default:
      return state;
  }
};

export default reducer;
