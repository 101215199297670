import { memo } from "react";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";

import Title from "./groupsList/Title";
import Groups from "./groupsList/Groups";
import AddButton from "./groupsList/AddButton";
import Search from "./groupsList/search/SearchContainer";
import EditGroupDialog from "./EditGroupDialog/EditGroupDialogContainer";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
});

const GroupsList = memo(props => {
  const { classes, roles, ...otherProps } = props;

  return (
    <div className={classes.root}>
      <EditGroupDialog />

      {roles && roles.find(role => ["crearGrupos"].includes(role)) ? <AddButton /> : null}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Title isFetching={props.isFetching} />
        </Grid>

        <Grid item xs={12}>
          <Search onSearchChange={props.onSearchChange} />
        </Grid>

        <Grid item xs={12}>
          <Groups {...otherProps} />
        </Grid>
      </Grid>
    </div>
  );
});

export default withStyles(styles)(GroupsList);
