import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const chips = props => {
  const { classes } = props;

  return (
    <>
      {props.isComplementary && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={props.isComplementary === "1" ? "Complementaria" : "No complementaria"}
            onDelete={() => {
              props.removeIsComplementary();
            }}
          />
        </li>
      )}

      {props.isFailure && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={props.isFailure === "1" ? "Fallida" : "No fallida"}
            onDelete={() => {
              props.removeIsFailure();
            }}
          />
        </li>
      )}

      {props.isExpired && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={props.isExpired === "1" ? "Vencida" : "No vencida"}
            onDelete={() => {
              props.removeIsExpired();
            }}
          />
        </li>
      )}

      {props.hasPipelineInfo && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={
              props.hasPipelineInfo === "1" ? "Con datos de red" : "Sin datos de red"
            }
            onDelete={() => {
              props.removeHasPipelineInfo();
            }}
          />
        </li>
      )}

      {props.requiresCleaning && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={
              props.requiresCleaning === "1"
                ? "Requiere limpieza"
                : "No requiere limpieza"
            }
            onDelete={() => {
              props.removeRequiresCleaning();
            }}
          />
        </li>
      )}
      {props.requiresMaterials && (
        <li>
          <Chip
            onClick={props.openModal}
            className={classes.chip}
            label={
              props.requiresMaterials === "1"
                ? "Requiere materiales"
                : "No requiere materiales"
            }
            onDelete={() => {
              props.removeRequiresMaterials();
            }}
          />
        </li>
      )}
    </>
  );
};

export default withStyles(styles)(chips);
