import { orderBy } from "lodash";
import axios from "../../axios";

const types = {
  TASK_TYPES_REQUEST: "TASK_TYPES_REQUEST",
  TASK_TYPES_SUCCESS: "TASK_TYPES_SUCCESS",
  TASK_TYPES_FAILURE: "TASK_TYPES_FAILURE",
};

const requestTaskTypes = () => ({
  type: types.TASK_TYPES_REQUEST,
});

const successTaskTypes = taskTypes => ({
  type: types.TASK_TYPES_SUCCESS,
  types: taskTypes,
});

const failedTaskTypes = () => ({
  type: types.TASK_TYPES_FAILURE,
  message: "Ha ocurrido un error al intentar obtener los tipos de tareas",
});

export const getTaskTypes = () => {
  return async dispatch => {
    dispatch(requestTaskTypes());
    try {
      const { data } = await axios.get("/types");
      const types = data.data.map(type => ({
        id: type.id,
        name: type.tipo,
        etapa_id: type.etapa_id,
      }));
      dispatch(successTaskTypes(orderBy(types, "name")));
    } catch (error) {
      dispatch(failedTaskTypes());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.TASK_TYPES_SUCCESS:
      return action.types;
    default:
      return state;
  }
};

export default reducer;
