import { createSelector } from "reselect";
import escapeStringRegexp from "escape-string-regexp";

import axios from "../../axios";

import { parseFromApi } from "../../utils/TaskParser";

const types = {
  TASKS_REQUEST: "TASKS_REQUEST",
  TASKS_SUCCESS: "TASKS_SUCCESS",
  TASKS_FAILURE: "TASKS_FAILURE",
  TASKS_UPDATE_SHEET: "TASKS_UPDATE_SHEET",
  TASKS_UPDATE_SUMMARY: "TASKS_UPDATE_SUMMARY",
};

const requestTasks = () => ({
  type: types.TASKS_REQUEST,
});

const successTasks = tasks => ({
  type: types.TASKS_SUCCESS,
  tasks,
});

const failedTasks = () => ({
  type: types.TASKS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las tareas",
});

export const updateTask = task => ({
  type: types.TASKS_UPDATE_SHEET,
  task,
});

export const updateTasks = tasks => ({
  type: types.TASKS_UPDATE_SUMMARY,
  tasks,
});

export const getTasks = state => {
  return async dispatch => {
    dispatch(requestTasks());
    try {
      const { data } = await axios.get(`/tasks/materials/validation`);
      let tasks = data.data.map(task => parseFromApi(task));
      if (state === 5) {
        tasks = tasks.filter(t => !t.error);
      }
      dispatch(successTasks(tasks));
    } catch (error) {
      dispatch(failedTasks());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.TASKS_SUCCESS:
      return action.tasks;
    case types.TASKS_UPDATE_SHEET:
      return state.map(task =>
        task.id === action.task.id ? { ...task, ...action.task } : task
      );
    case types.TASKS_UPDATE_SUMMARY:
      return state.map(task => {
        const selected = action.tasks.find(t => t.id === task.id);
        return selected ? { ...task, ...selected } : task;
      });
    default:
      return state;
  }
};

const getAllTasks = state => state.tasks;
const getSelectedTasks = state => state.selectedTasks;
const getOdtFilter = state => state.downloadTasksFilter.odt;
const getGroupFilter = state => state.downloadTasksFilter.group;
const getFromFilter = state => state.downloadTasksFilter.dateFrom;
const getToFilter = state => state.downloadTasksFilter.dateTo;
const getShowSelectedTasks = state => state.downloadTasksFilter.showSelectedTasks;

export const mapSelectedTasks = createSelector(
  [getAllTasks, getSelectedTasks, getOdtFilter, getGroupFilter, getShowSelectedTasks],
  (allTasks, selectedTasks, odt, group, showSelectedTasks) => {
    const regex = new RegExp(`.*${escapeStringRegexp(odt)}.*`, "i");
    return allTasks
      .filter(
        t =>
          t.otCode.match(regex) &&
          (group ? t.group.id === group : true) &&
          (showSelectedTasks ? selectedTasks.includes(t.id) : true)
      )
      .map(task => ({
        ...task,
        selected: !!selectedTasks.find(id => id === task.id),
      }));
  }
);

export const mapSelectedTasksForDownload = createSelector(
  [
    getAllTasks,
    getSelectedTasks,
    getOdtFilter,
    getGroupFilter,
    getFromFilter,
    getToFilter,
    getShowSelectedTasks,
  ],
  (allTasks, selectedTasks, odt, group, dateFrom, dateTo, showSelectedTasks) => {
    const regex = new RegExp(`.*${escapeStringRegexp(odt)}.*`, "i");
    return allTasks
      .filter(
        t =>
          t.otCode.match(regex) &&
          (!dateFrom || dateFrom < t.validationDate) &&
          (!dateTo || dateTo > t.validationDate) &&
          (group ? t.group.id === group : true) &&
          (showSelectedTasks ? selectedTasks.includes(t.id) : true)
      )
      .map(task => ({
        ...task,
        selected: !!selectedTasks.find(id => id === task.id),
      }))
      .sort((a, b) => b.validationDate - a.validationDate);
  }
);

export default reducer;
