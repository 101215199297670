import { Marker, Tooltip } from "react-leaflet";
import format from "date-fns/format";
import L from "leaflet";

const timeAlertIcon = `<span class="fa-stack update-alert">
  <i class="fas fa-circle fa-stack-1x circle-icon"></i>
  <i class="fas fa-exclamation-circle fa-stack-1x alert-icon"></i>
</span>`;

const createIcon = gang =>
  L.divIcon({
    className: `icon ${gang.state}`,
    html: `<p>${gang.initials}</p>${gang.timeAlert ? timeAlertIcon : ""}`,
    item: gang,
    iconSize: [32, 32],
  });

const gangMarker = ({ gang, handleMoveClick }) => (
  <Marker
    icon={createIcon(gang)}
    key={gang.id}
    position={gang.location.coordinates}
    onClick={e => handleMoveClick([e.latlng.lat, e.latlng.lng])}>
    <Tooltip direction="top" offset={L.point(0, -16)}>
      <div>
        <b>{gang.name}</b>
        <ul style={{ paddingLeft: 15, paddingRight: 15 }}>
          <li>{gang.state === "Alerta" ? "Ejecutando" : gang.state}</li>
          <li style={{ color: gang.timeAlert ? "#f44336" : "#000" }}>
            {format(
              new Date(gang.location.date?.replace(/ /g, "T")),
              "dd/MM/yyyy HH:mm:ss"
            )}
          </li>
        </ul>
      </div>
    </Tooltip>
  </Marker>
);

export default gangMarker;
