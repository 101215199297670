import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { MenuItem } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = theme => ({
  form: {
    // minWidth: 450,
    // maxHeight: 380,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  dates: {
    marginTop: 20,
  },
  states: {
    width: "100%",
  },
  input: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
});

const TaskConditionsDialog = props => {
  const { classes, closeModal, openModal, save } = props;

  const [isFailure, setFailure] = useState("");
  const [isExpired, setExpired] = useState("");
  const [hasPipelineInfo, setPipelineInfo] = useState("");
  const [isComplementary, setComplementary] = useState("");
  const [requiresCleaning, setRequiresCleaning] = useState("");
  const [requiresMaterials, setRequiresMaterials] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const initialize = () => {
    setFailure(props.isFailure);
    setExpired(props.isExpired);
    setPipelineInfo(props.hasPipelineInfo);
    setComplementary(props.isComplementary);
    setRequiresCleaning(props.requiresCleaning);
    setRequiresMaterials(props.requiresMaterials);
  };

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({
          isFailure,
          isExpired,
          hasPipelineInfo,
          isComplementary,
          requiresCleaning,
          requiresMaterials,
        });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [
    save,
    openModal,
    isFailure,
    isExpired,
    hasPipelineInfo,
    isComplementary,
    requiresCleaning,
    requiresMaterials,
  ]);

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      fullScreen={isMobile}
      open={openModal}
      onClose={closeModal}
      TransitionProps={{
        onEnter: initialize,
        onExited: initialize,
      }}>
      <DialogTitle>Condiciones</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Se filtrarán las tareas de acuerdo a ciertas condiciones que se apliquen a las
          mismas.
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  value={isFailure}
                  SelectProps={{
                    native: isMobile,
                  }}
                  onChange={e => setFailure(e.target.value)}
                  label="Es fallida"
                  variant="outlined"
                  id="standard-hidrojetState">
                  {[
                    { value: null, label: "Sin especificar" },
                    { value: "0", label: "No" },
                    { value: "1", label: "Sí" },
                  ].map(option =>
                    isMobile ? (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ) : (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  SelectProps={{
                    native: isMobile,
                  }}
                  variant="outlined"
                  value={isComplementary}
                  label="Es complementaria"
                  onChange={e => setComplementary(e.target.value)}
                  id="standard-hidrojetState">
                  {[
                    { value: null, label: "Sin especificar" },
                    { value: "0", label: "No" },
                    { value: "1", label: "Sí" },
                  ].map(option =>
                    isMobile ? (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ) : (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  SelectProps={{
                    native: isMobile,
                  }}
                  value={requiresMaterials}
                  onChange={e => setRequiresMaterials(e.target.value)}
                  label="Requiere materiales"
                  variant="outlined"
                  id="standard-hidrojetState">
                  {[
                    { value: null, label: "Sin especificar" },
                    { value: "0", label: "No" },
                    { value: "1", label: "Sí" },
                  ].map(option =>
                    isMobile ? (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ) : (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  SelectProps={{
                    native: isMobile,
                  }}
                  value={requiresCleaning}
                  variant="outlined"
                  label="Requiere limpieza"
                  onChange={e => setRequiresCleaning(e.target.value)}
                  id="standard-hidrojetState">
                  {[
                    { value: null, label: "Sin especificar" },
                    { value: "0", label: "No" },
                    { value: "1", label: "Sí" },
                  ].map(option =>
                    isMobile ? (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ) : (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  SelectProps={{
                    native: isMobile,
                  }}
                  value={hasPipelineInfo}
                  variant="outlined"
                  label="Tiene datos de red"
                  onChange={e => setPipelineInfo(e.target.value)}
                  id="standard-hidrojetState">
                  {[
                    { value: null, label: "Sin especificar" },
                    { value: "0", label: "No" },
                    { value: "1", label: "Sí" },
                  ].map(option =>
                    isMobile ? (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ) : (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  SelectProps={{
                    native: isMobile,
                  }}
                  value={isExpired}
                  onChange={e => setExpired(e.target.value)}
                  label="Está vencida"
                  variant="outlined"
                  id="standard-hidrojetState">
                  {[
                    { value: null, label: "Sin especificar" },
                    { value: "0", label: "No" },
                    { value: "1", label: "Sí" },
                  ].map(option =>
                    isMobile ? (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ) : (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() =>
            save({
              isFailure,
              isExpired,
              hasPipelineInfo,
              isComplementary,
              requiresCleaning,
              requiresMaterials,
            })
          }>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(TaskConditionsDialog);
