import { connect } from "react-redux";

import { useMemo } from "react";
import Grid from "@mui/material/Grid";
import GangCard from "./gangs/GangCard";
import EditDialog from "./gangs/editDialog";
import EmptyGangs from "./gangs/EmptyGangs";
import CardSkeleton from "./gangs/CardSkeleton";
import { List, WindowScroller } from "react-virtualized";

import { getSearchedGangs } from "../../../store/ducks/gangList";
import { setPin, clearPin } from "../../../store/ducks/gangsPin";
import { openModal } from "../../../store/ducks/gangsGroupsHistoryModal";
import { openModal as openEditModal } from "../../../store/ducks/editGang";
import { getGangsWithStatements } from "../../../store/ducks/statementsFilters";

const rowHeight = 320;
const individualrowHeight = 350;
const ROW_HEIGHT_MARGIN = 30;

const GangsList = props => {
  const skeletons = useMemo(
    () =>
      Array.from(Array(12).keys()).map(value => (
        <Grid key={value} item xs={12} md={6} lg={6}>
          <CardSkeleton />
        </Grid>
      )),
    []
  );

  const editModal = useMemo(
    () => (props.roles.includes("editarCuadrilla") ? <EditDialog /> : null),
    [props.roles]
  );

  const gangs = (
    <>
      {editModal}

      <WindowScroller style={{ outline: "none" }}>
        {({ height, isScrolling, registerChild, scrollTop }) => {
          const width = window.innerWidth;
          const itemsPerRow = width >= 900 ? 2 : 1;
          const rowCount = Math.ceil(props.gangs.length / itemsPerRow);
          return (
            <>
              <div ref={registerChild}>
                <List
                  autoHeight
                  width={width - 60}
                  height={height}
                  rowCount={rowCount}
                  scrollTop={scrollTop}
                  style={{ outline: "none" }}
                  rowHeight={
                    itemsPerRow === 1
                      ? individualrowHeight + ROW_HEIGHT_MARGIN
                      : rowHeight + ROW_HEIGHT_MARGIN
                  }
                  isScrolling={isScrolling}
                  rowRenderer={({ index, key, style }) => {
                    const items = [];
                    const fromIndex = index * itemsPerRow;
                    const toIndex = Math.min(fromIndex + itemsPerRow, props.gangs.length);
                    for (let i = fromIndex; i < toIndex; i++) {
                      items.push(
                        <GangCard
                          {...props}
                          gang={props.gangs[i]}
                          key={props.gangs[i].id}
                          itemsPerRow={itemsPerRow}
                          openEditModal={props.openEditModal}
                        />
                      );
                    }
                    const emptySize = itemsPerRow - items.length;
                    for (let i = 0; i < emptySize; i++) {
                      items.push(<div style={{ width: "100%" }}></div>);
                    }
                    return (
                      <div
                        key={key}
                        style={{
                          ...style,
                          gap: 10,
                          display: "flex",
                          marginBottom: 20,
                          justifyContent: "space-around",
                        }}>
                        {items}
                      </div>
                    );
                  }}
                />
              </div>
            </>
          );
        }}
      </WindowScroller>
    </>
  );

  return !props.gangs.length ? (
    <Grid container spacing={2}>
      {props.isFetching ? skeletons : <EmptyGangs />}
    </Grid>
  ) : (
    gangs
  );
};

const mapStateToProps = state => ({
  roles: state.user.roles,
  gangs: getSearchedGangs(state),
  gangsWithStatements: getGangsWithStatements(state),
});

const mapDispatchToProps = dispatch => ({
  setPin: gang => dispatch(setPin(gang)),
  clearPin: gang => dispatch(clearPin(gang)),
  openModal: gang => dispatch(openModal(gang)),
  openEditModal: gang => dispatch(openEditModal(gang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GangsList);
