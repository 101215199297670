import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupIcon from "@mui/icons-material/LocationCity";
import TaskIcon from "@mui/icons-material/Description";
import CommentsIcon from "@mui/icons-material/Assignment";
import DatesIcon from "@mui/icons-material/Today";

import IconButton from "@mui/material/IconButton";
import RoomIcon from "@mui/icons-material/Room";
import { format, addMinutes } from "date-fns";
import { formatAddress } from "../../../../geocode";
import { useState } from "react";

const dateParser = date => {
  if (date && !isNaN(date)) {
    date = new Date(date);
    return format(addMinutes(date, date.getTimezoneOffset()), "dd/MM/yyyy");
  }
  return "-";
};

const styles = theme => ({
  title: {
    fontWeight: 400,
  },
  container: {
    paddingBottom: 0,
  },
  dropdown: {
    minWidth: 80,
  },
  contentContainer: {
    paddingBottom: 0,
    paddingTop: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  listIcon: {
    minWidth: 40,
  },
  actions: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 15,
    paddingLeft: 15,
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
});

const getInitials = type => {
  if (type.trim().indexOf(" ") === -1) {
    return type.substring(0, 2).toUpperCase();
  }
  let initials = type.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

const TaskCard = ({ task: tarea = {}, classes, showAllInfo, ...props }) => {
  const task = { ...tarea.step1, ...tarea.step2, ...tarea.step3 };

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let taskText = "";
  if (task.modulo) taskText += `MOD ${task.modulo}. `;
  taskText += task.tarea || "";

  return (
    <Card variant="outlined" {...props}>
      <CardHeader
        className={classes.container}
        title={
          <Typography variant="h6" className={classes.title}>
            {task.ubicacion}
          </Typography>
        }
        subheader={
          <>
            {task.codigo_ot} -{" "}
            <Tooltip title="Fecha de ingreso">
              <span>{task.ingreso_ot ? dateParser(task.ingreso_ot) : ""}</span>
            </Tooltip>
          </>
        }
        action={
          <>
            {!showAllInfo && (
              <IconButton onClick={handleExpandClick} size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            )}
          </>
        }
      />
      <CardContent className={classes.contentContainer}>
        <List component="nav" disablePadding dense>
          <ListItem dense disableGutters>
            {/* Tarea a realizar */}
            <Tooltip title="Tarea a realizar">
              <ListItemIcon className={classes.listIcon}>
                <TaskIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={taskText || "-"} />
          </ListItem>

          {/* collapse */}
          <Collapse in={expanded || showAllInfo} timeout="auto" unmountOnExit>
            {/* fecha asignacion */}
            <ListItem dense disableGutters>
              <Tooltip title="Fecha de asignación">
                <ListItemIcon className={classes.listIcon}>
                  <DatesIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={task.fecha_asignada ? dateParser(task.fecha_asignada) : "-"}
              />
            </ListItem>

            {/* address */}
            <ListItem dense disableGutters>
              <Tooltip title="Dirección">
                <ListItemIcon className={classes.listIcon}>
                  <RoomIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  task.calle && task.altura && task.localidad
                    ? formatAddress(
                        task.calle,
                        task.altura,
                        task.localidad.name,
                        task.entre,
                        task.y
                      )
                    : "-"
                }
              />
            </ListItem>

            <ListItem dense disableGutters>
              <Tooltip title="Observaciones">
                <ListItemIcon className={classes.listIcon}>
                  <CommentsIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={task.observaciones || "-"} />
            </ListItem>
          </Collapse>

          <ListItem dense disableGutters>
            <Tooltip title="Grupo">
              <ListItemIcon className={classes.listIcon}>
                <GroupIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.grupo ? task.grupo.name : "-"} />
          </ListItem>
        </List>
      </CardContent>
      <CardActions className={classes.actions}>
        <div style={{ marginLeft: "auto", minHeight: 20 }} />
        {task.parent_id ? (
          <Tooltip title="Tarea Complementaria">
            <p className={[classes.stateIcon, "complementary"]}>CO</p>
          </Tooltip>
        ) : null}
        {task.fallida ? (
          <Tooltip title="Fallida">
            <p className={[classes.stateIcon, "failed"]}>F</p>
          </Tooltip>
        ) : null}
        {task.tipo ? (
          <Tooltip title={task.tipo.name}>
            <p className={[classes.stateIcon, "type"]}>{getInitials(task.tipo.name)}</p>
          </Tooltip>
        ) : null}
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(TaskCard);
