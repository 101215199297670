import { Fragment, memo } from "react";
import Typography from "@mui/material/Typography";

const Title = memo(() => {
  return (
    <Fragment>
      <Typography variant="h5" component="h3">
        Validación de Materiales
      </Typography>
      <Typography component="p" style={{ marginTop: 10 }}>
        Seleccione la tarea a la que desea validar materiales.
      </Typography>
    </Fragment>
  );
});

export default Title;
