/* eslint-disable no-case-declarations */
const types = {
  TASK_LIST_STATE_FILTER_OPEN_MODAL: "TASK_LIST_STATE_FILTER_OPEN_MODAL",
  TASK_LIST_STATE_FILTER_CLOSE_MODAL: "TASK_LIST_STATE_FILTER_CLOSE_MODAL",
  TASK_LIST_STATE_FILTER_SAVE: "TASK_LIST_STATE_FILTER_SAVE",
  TASK_LIST_STATE_FILTER_REMOVE: "TASK_LIST_STATE_FILTER_REMOVE",
  TASK_LIST_STATE_SET_ACTIVE: "TASK_LIST_STATE_SET_ACTIVE",
};

export const openModal = () => ({
  type: types.TASK_LIST_STATE_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_STATE_FILTER_CLOSE_MODAL,
});

export const save = states => ({
  type: types.TASK_LIST_STATE_FILTER_SAVE,
  states,
});

export const removeStates = () => ({
  type: types.TASK_LIST_STATE_FILTER_REMOVE,
});

export const setActiveStates = (states = []) => ({
  type: types.TASK_LIST_STATE_SET_ACTIVE,
  states,
});

const defaultStateValues = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
};

const initialState = {
  openModal: false,
  states: defaultStateValues,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_STATE_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_STATE_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_STATE_FILTER_SAVE:
      return { ...state, openModal: false, states: action.states };
    case types.TASK_LIST_STATE_FILTER_REMOVE:
      return { ...state, states: defaultStateValues };

    case types.TASK_LIST_STATE_SET_ACTIVE:
      const newStates = { ...defaultStateValues };
      Object.keys(newStates).forEach(key => {
        if (!action.states.includes(Number(key))) newStates[key] = false;
      });
      return { ...state, states: newStates };

    default:
      return state;
  }
};

export default reducer;
