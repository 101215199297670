import { Component } from "react";
import { connect } from "react-redux";

import { getGangs } from "../store/ducks/gangs";
import ApiError from "../components/UI/ApiError";
import GangsList from "./gangsListContainer/GangsList";
import { getStatements } from "../store/ducks/statements";
import { changeSearchGangs } from "../store/ducks/gangList";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

class GangsListContainer extends Component {
  componentDidMount() {
    this.props.getStatements();
    this.props.getGangs();
    this.props.setSearch("");
  }

  render() {
    const { error } = this.props;
    return error ? (
      <ApiError error="Ocurrió un error al obtener las cuadrillas" />
    ) : (
      <GangsList {...this.props} />
    );
  }
}

const actionNames = ["GANGS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getGangs: () => dispatch(getGangs()),
  getStatements: () => dispatch(getStatements()),
  setSearch: search => dispatch(changeSearchGangs(search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GangsListContainer);
