import { connect } from "react-redux";

import { handleMoveClick } from "../../../../../store/ducks/map";
import {
  toggleMute,
  changeSearch,
  getGangs,
  getSound,
} from "../../../../../store/ducks/mapInfoPanel";
import { toggleGangSound } from "../../../../../store/ducks/gangs";
import GangList from "./gangListContainer/GangList";

const mapStateToProps = state => ({
  gangs: getGangs(state),
  sound: getSound(state),
  search: state.mapInfoPanel.search,
  muted: state.mapInfoPanel.muted,
});

const mapDispatchToProps = dispatch => ({
  handleMoveClick: coordinates => dispatch(handleMoveClick(coordinates)),
  changeSearch: search => dispatch(changeSearch(search)),
  toggleMute: () => dispatch(toggleMute()),
  toggleGangSound: gang => dispatch(toggleGangSound(gang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GangList);
