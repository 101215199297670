import { connect } from "react-redux";

import {
  removeCreationDate,
  removeIncomeDate,
  removeAssignedDate,
  openModal,
} from "../../../../../store/ducks/taskListDateFilters";
import Dates from "./datesContainer/Dates";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  creationFrom: state.taskListDateFilters.creationFrom,
  creationTo: state.taskListDateFilters.creationTo,
  incomeFrom: state.taskListDateFilters.incomeFrom,
  incomeTo: state.taskListDateFilters.incomeTo,
  assignedFrom: state.taskListDateFilters.assignedFrom,
  assignedTo: state.taskListDateFilters.assignedTo,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeCreationDate: () => {
    dispatch(removeCreationDate());
    dispatch(getTasksFromGraphql());
  },
  removeIncomeDate: () => {
    dispatch(removeIncomeDate());
    dispatch(getTasksFromGraphql());
  },
  removeAssignedDate: () => {
    dispatch(removeAssignedDate());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dates);
