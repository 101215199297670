/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-for */
import { useEffect, useMemo, useRef } from "react";
import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";

import { getGroups } from "../../../../../store/ducks/groups";

export const NewTaskStep3 = props => {
  const {
    form: { register, setValue, control, getValues, watch },
  } = props;

  const dispatch = useDispatch();
  const stores = useSelector(state => state.storesList);
  const groups = useSelector(state => state.groups.groups);

  useEffect(() => {
    const localidad = getValues("step2.localidad");
    dispatch(getGroups(localidad.id));
    register("step3.grupo");
    register("step3.stores");
  }, [register, dispatch, getValues]);

  const autoCompleteRef = useRef();
  const type = watch("step1.tipo");
  const group = watch("step3.grupo");

  useEffect(() => {
    autoCompleteRef?.current?.focus();
  }, [autoCompleteRef]);

  const groupsOptions = useMemo(
    () =>
      groups.filter(
        g => !g.taskTypes || !g.taskTypes.length || g.taskTypes.includes(type.id)
      ),
    [groups, type]
  );

  const storesOptions = useMemo(
    () =>
      group
        ? stores.filter(s => group.stores && group.stores.includes(s._id) && s.active)
        : [],
    [group, stores]
  );

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            required
            control={control}
            variant="outlined"
            options={groupsOptions}
            styles={{ width: "100%" }}
            noOptionsText="Sin Resultados"
            value={getValues("step3.grupo")}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              e.stopPropagation();
              setValue("step3.grupo", value);
              if (value) {
                if (value.fills_pipeline_information && type.etapa_id === 1) {
                  setValue("step3.requires_pipeline_information", true);
                } else {
                  setValue("step3.requires_pipeline_information", false);
                }
                setValue("step3.for_contractor", null);
                if (value.stores && value.stores.length) {
                  setValue(
                    "step3.stores",
                    stores.filter(s => value.stores.includes(s._id))
                  );
                }
              } else {
                setValue("step3.stores", []);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                required
                // autoFocus
                label="Grupo"
                variant="outlined"
                inputRef={autoCompleteRef}
                // inputProps={{ autoFocus: true }}
              />
            )}
          />
        </Grid>

        {group && group.has_contractors ? (
          <Grid item xs={12} lg={12}>
            <FormControl style={{ width: "100%" }} margin="dense" variant="outlined">
              <InputLabel id="input-for-contractor">Distribución</InputLabel>
              <Controller
                control={control}
                name="step3.for_contractor"
                render={({ fields }) => (
                  <Select
                    {...fields}
                    defaultValue={true}
                    label="Distribución"
                    labelId="input-for-contractor">
                    <MenuItem value={null}>Sin especificar</MenuItem>
                    <MenuItem value={false}>Fuerza propia</MenuItem>
                    <MenuItem value={true}>Subcontratista</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        ) : null}

        {group && group.fills_pipeline_information && type.etapa_id === 1 ? (
          <Grid item xs={12} lg={12}>
            <FormControl style={{ width: "100%" }} margin="dense" variant="outlined">
              <InputLabel id="input-red">Requiere datos de Red</InputLabel>
              <Controller
                control={control}
                name="step3.requires_pipeline_information"
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={true}
                    labelId="input-red"
                    label="Requiere datos de Red">
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Sí</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Autocomplete
            required
            multiple
            control={control}
            variant="outlined"
            options={storesOptions}
            styles={{ width: "100%" }}
            noOptionsText="Sin Resultados"
            value={getValues("step3.stores")}
            disabled={!storesOptions || !storesOptions.length}
            onChange={(e, value) => {
              e.stopPropagation();
              setValue("step3.stores", value);
            }}
            getOptionLabel={option => `${option.name} - ${option.type.name}`}
            renderInput={params => (
              <TextField
                {...params}
                required
                variant="outlined"
                label="Depósitos Asignados"
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default NewTaskStep3;
