import Control from "react-leaflet-control";
import Fab from "@mui/material/Fab";
import FilterListIcon from "@mui/icons-material/FilterList";
import Hidden from "@mui/material/Hidden";

const muteButton = ({ openPanel }) => {
  return (
    <Control position="bottomright">
      <Hidden mdUp implementation="css">
        <Fab
          onClick={openPanel}
          style={{ backgroundColor: "#fff", color: "#262626", marginBottom: 50 }}>
          <FilterListIcon />
        </Fab>
      </Hidden>
    </Control>
  );
};

export default muteButton;
