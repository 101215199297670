import { connect } from "react-redux";

import { openModal as openScheduleModal } from "../../../../../store/ducks/taskListScheduleTask";
import { openModal as openAssignModal } from "../../../../../store/ducks/taskListAssignTask";
import { openModal as openCancelModal } from "../../../../../store/ducks/taskListCancelTask";
import { openModal as openPendingModal } from "../../../../../store/ducks/taskListPendingTask";
import { openModal as openInspectionModal } from "../../../../../store/ducks/taskListInspectionTask";
import { openModal as openApproveModal } from "../../../../../store/ducks/taskListApproveTask";
import { openModal as openCivilTaskModal } from "../../../../../store/ducks/newCivilTaskDialog";
import { openModal as openRejectModal } from "../../../../../store/ducks/taskListRejectTask";
import { confirmDownload } from "../../../../../store/ducks/downloadTasks";

import Actions from "./actionsContainer/Actions";

const mapDispatchToProps = dispatch => ({
  openScheduleModal: task => dispatch(openScheduleModal(task)),
  openAssignModal: task => dispatch(openAssignModal(task)),
  openCancelModal: task => dispatch(openCancelModal(task)),
  openPendingModal: task => dispatch(openPendingModal(task)),
  openInspectionModal: task => dispatch(openInspectionModal(task)),
  openApproveModal: task => dispatch(openApproveModal(task)),
  openCivilTaskModal: task => dispatch(openCivilTaskModal(task)),
  openRejectModal: task => dispatch(openRejectModal(task)),
  onDownload: taskId => dispatch(confirmDownload([taskId])),
});

export default connect(null, mapDispatchToProps)(Actions);
