import { Component } from "react";
import Table from "@mui/material/Table";

import Head from "./Head";
import Body from "./Body";
import Footer from "./Footer";

class TableInfo extends Component {
  state = {
    page: 0,
    rowsPerPage: 100,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    return (
      <Table
        style={{
          width: "98%",
          margin: "0 auto",
          marginBottom: 20,
          overflowX: "auto",
        }}
        size="small">
        <Head />
        <Body
          {...this.props}
          page={this.state.page}
          data={this.props.gangs}
          rowsPerPage={this.state.rowsPerPage}
        />
        <Footer
          page={this.state.page}
          count={this.props.gangs.length}
          rowsPerPage={this.state.rowsPerPage}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Table>
    );
  }
}

export default TableInfo;
