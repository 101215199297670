import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";
import {
  getAuditsFilters,
  getGangsFilters,
  getHasPipelinesFilters,
  getHidrojetFilters,
  getSidewalkFilters,
  //   getStateChangeFilters,
  getTasksFilters,
} from "../../../../../../graphql/tasks/filters";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const hasFilters = store => {
  return (
    getTasksFilters(store, true) ||
    getGangsFilters(store) ||
    getSidewalkFilters(store) ||
    getHidrojetFilters(store) ||
    // getStateChangeFilters(store) ||
    getAuditsFilters(store) ||
    getHasPipelinesFilters(store)
  );
};

const chips = props => {
  const { classes } = props;
  const show = hasFilters(props.state);

  return show ? (
    <li>
      <Chip
        className={classes.chip}
        label="Quitar todos los filtros"
        variant="outlined"
        onClick={() => {
          props.resetFilters();
        }}
      />
    </li>
  ) : null;
};

export default withStyles(styles)(chips);
