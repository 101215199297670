import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";

import Header from "./articlesTable/Header";
import Body from "./articlesTable/Body";
import Footer from "./articlesTable/Footer";

const styles = theme => ({
  rootTable: {
    width: "100%",
    marginTop: theme.spacing(),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

const articlesTable = props => {
  const { classes, articles, page, rowsPerPage } = props;
  return (
    <div className={classes.rootTable}>
      <Table className={classes.table}>
        <Header />
        <Body
          openValidateModal={props.openValidateModal}
          openRejectModal={props.openRejectModal}
          openEditModal={props.openEditModal}
          openUndoModal={props.openUndoModal}
          articles={articles}
          page={page}
          rowsPerPage={rowsPerPage}
        />
        <Footer
          count={articles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={props.changePage}
          handleChangeRowsPerPage={props.changePageRows}
        />
      </Table>
    </div>
  );
};

export default withStyles(styles)(articlesTable);
