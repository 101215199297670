import { connect } from "react-redux";

import { closeModal, rejectArticle } from "../../../store/ducks/rejectModal";
import RejectModal from "./rejectModalContainer/RejectModal";

const mapStateToProps = state => ({
  open: state.rejectModal.modalOpen,
  article: state.rejectModal.article,
  sending: state.rejectModal.sending,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  confirm: () => dispatch(rejectArticle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectModal);
