import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import { Divider } from "@mui/material";

import StateList from "./infoPanel/StateList";
import TypeFilterContainer from "./infoPanel/TypeFilterContainer";
import GroupFilterContainer from "./infoPanel/GroupFilterContainer";
import TaskListContainer from "./infoPanel/TaskListContainer";
import GangListContainer from "./infoPanel/GangListContainer";
import TaskFiltersContainer from "./infoPanel/TaskFiltersContainer";

const styles = {
  drawerPaper: {
    position: "relative",
    width: 300,
  },
  drawerPaperMobile: {
    width: 300,
  },
};

const infoPanel = props => {
  const content = (
    <>
      <TypeFilterContainer />
      <Divider />
      <GroupFilterContainer />
      <Divider />
      <StateList />
      <Divider />
      <TaskFiltersContainer />
      <Divider />
      <TaskListContainer />
      <Divider />
      <GangListContainer />
    </>
  );
  return (
    <>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          onClose={props.closePanel}
          classes={{ paper: props.classes.drawerPaperMobile }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer variant="permanent" classes={{ paper: props.classes.drawerPaper }}>
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default withStyles(styles)(infoPanel);
