import { parseFromApi } from "../../utils/GangStockParser";
import { parseFromApi as parseStoreStockFromApi } from "../../utils/StoreStockParser";
import { anexo as axios, anexoOld } from "../../axios";
import { enqueueError, enqueueMessage } from "./notifications";
import { updateConsumptionAmount } from "./task";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const types = {
  EDIT_MODAL_OPEN: "EDIT_MODAL_OPEN",
  EDIT_MODAL_OPEN_CONFIRMATION: "EDIT_MODAL_OPEN_CONFIRMATION",
  EDIT_MODAL_CLOSE: "EDIT_MODAL_CLOSE",
  EDIT_MODAL_CHANGE_AMOUNT: "EDIT_MODAL_CHANGE_AMOUNT",
  EDIT_MODAL_CHANGE_AMOUNT_ERROR: "EDIT_MODAL_CHANGE_AMOUNT_ERROR",
  EDIT_MODAL_STORE_STOCK_REQUEST: "EDIT_MODAL_STORE_STOCK_REQUEST",
  EDIT_MODAL_STORE_STOCK_SUCCESS: "EDIT_MODAL_STORE_STOCK_SUCCESS",
  EDIT_MODAL_STORE_STOCK_FAILURE: "EDIT_MODAL_STORE_STOCK_FAILURE",
  EDIT_MODAL_GANG_STOCK_REQUEST: "EDIT_MODAL_GANG_STOCK_REQUEST",
  EDIT_MODAL_GANG_STOCK_SUCCESS: "EDIT_MODAL_GANG_STOCK_SUCCESS",
  EDIT_MODAL_GANG_STOCK_FAILURE: "EDIT_MODAL_GANG_STOCK_FAILURE",
  EDIT_MODAL_REQUEST: "EDIT_MODAL_REQUEST",
  EDIT_MODAL_SUCCESS: "EDIT_MODAL_SUCCESS",
  EDIT_MODAL_FAILURE: "EDIT_MODAL_FAILURE",
};

export const openModal = article => dispatch => {
  dispatch(getGangStock(article));
  dispatch(getStoreStock(article));
  dispatch({
    type: types.EDIT_MODAL_OPEN,
    article,
  });
};

export const closeModal = () => ({
  type: types.EDIT_MODAL_CLOSE,
});

export const openConfirmationModal = () => (dispatch, getState) => {
  const state = getState();
  const {
    editModal: { amount, gangStock, storeStock },
  } = state;
  if (Number(amount) <= gangStock + storeStock) {
    dispatch({
      type: types.EDIT_MODAL_OPEN_CONFIRMATION,
    });
  } else {
    dispatch(changeAmountError(true));
  }
};

export const changeAmount = amount => ({
  type: types.EDIT_MODAL_CHANGE_AMOUNT,
  amount,
});

const getStoreStock = article => {
  return async (dispatch, getState) => {
    const { task } = getState();
    dispatch(requestStoreStock());
    try {
      const { data } = await axios.get(`/stores/${article.store.id}/stock`, {
        params: {
          f: task.stockDate,
        },
      });
      let amount = 0;
      const origins = parseStoreStockFromApi(data);
      const origin = origins.find(o => o.origin.id === article.origin.id);
      if (origin) {
        const material = origin.stock.find(a => a.id === article.id);
        if (material) {
          amount = material.amount;
        }
      }
      dispatch(successStoreStock(amount));
    } catch (error) {
      dispatch(enqueueError("Ocurrió un error al obtener el stock del depósito"));
      dispatch(failureStoreStock());
    }
  };
};

export const getGangStock = article => {
  return async (dispatch, getState) => {
    const { task } = getState();
    dispatch(requestGangStock());
    try {
      const { data } = await axios.get(`/gangs/${article.gang._id}/stock`, {
        params: {
          f: task.stockDate,
        },
      });
      let amount = 0;
      const items = parseFromApi(data);
      const origin = items.find(
        o => o.store.id === article.store.id && o.origin.id === article.origin.id
      );
      if (origin) {
        const material = origin.stock.find(a => a.id === article.id);
        if (material) {
          amount = material.amount;
        }
      }

      amount = amount + article.amount;

      dispatch(successGangStock(amount));
    } catch (error) {
      dispatch(enqueueError("Ocurrió un error al obtener el stock de la cuadrilla"));
      dispatch(failureGangStock());
    }
  };
};

const request = () => ({
  type: types.EDIT_MODAL_REQUEST,
});

const success = () => ({
  type: types.EDIT_MODAL_SUCCESS,
});

const failure = () => ({
  type: types.EDIT_MODAL_FAILURE,
});

const requestStoreStock = () => ({
  type: types.EDIT_MODAL_STORE_STOCK_REQUEST,
});

const successStoreStock = stock => ({
  type: types.EDIT_MODAL_STORE_STOCK_SUCCESS,
  stock,
});

const failureStoreStock = () => ({
  type: types.EDIT_MODAL_STORE_STOCK_FAILURE,
});

const requestGangStock = () => ({
  type: types.EDIT_MODAL_GANG_STOCK_REQUEST,
});

const successGangStock = stock => ({
  type: types.EDIT_MODAL_GANG_STOCK_SUCCESS,
  stock,
});

const failureGangStock = () => ({
  type: types.EDIT_MODAL_GANG_STOCK_FAILURE,
});

const changeAmountError = value => ({
  type: types.EDIT_MODAL_CHANGE_AMOUNT_ERROR,
  value,
});

export const editArticle = () => async (dispatch, getState) => {
  const state = getState();
  const {
    task,
    editModal: { article, amount, gangStock },
  } = state;
  dispatch(request());

  const consumptionDate = dayjs(article.date, "DD/MM/YYYY");
  const stockDate = dayjs(task.stockDate, "YYYY-MM-DD");

  const date = dayjs(
    Math.max(consumptionDate.toDate().getTime(), stockDate.toDate().getTime())
  ).format("YYYY-MM-DD");

  try {
    if (amount > gangStock) {
      await anexoOld.post(`/deposito/${article.store.id}/carga/${article.gang._id}`, {
        cuadrilla: article.gang._id,
        deposito: article.store.id,
        articulo: article.id,
        procedencia: article.origin.id,
        tarea: task.id,
        fecha: date,
        cantidad: amount - gangStock,
      });
    }

    await axios.patch(`/tareas/consumos/${article.consumption}`, {
      cantidad: amount,
      fecha_consumo: date,
    });

    dispatch(enqueueMessage("El consumo se editó correctamente"));
    dispatch(updateConsumptionAmount(article.consumption, Number(amount)));
    dispatch(success());
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al edita el material"));
    dispatch(failure());
  }
};

const initialState = {
  modalOpen: false,
  confirmationOpen: false,
  article: {},
  amount: 0,
  storeStockLoading: false,
  gangStockLoading: false,
  sending: false,
  amountError: false,
  storeStock: 0,
  gangStock: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
        amountError: false,
        article: action.article,
        amount: action.article.amount,
        gangStock: action.article.amount,
      };
    case types.EDIT_MODAL_OPEN_CONFIRMATION:
      return { ...state, modalOpen: false, confirmationOpen: true };
    case types.EDIT_MODAL_CLOSE:
      return { ...state, modalOpen: false, confirmationOpen: false };
    case types.EDIT_MODAL_CHANGE_AMOUNT:
      return { ...state, amount: action.amount, amountError: false };
    case types.EDIT_MODAL_CHANGE_AMOUNT_ERROR:
      return { ...state, amountError: action.value };
    case types.EDIT_MODAL_STORE_STOCK_REQUEST:
      return { ...state, storeStockLoading: true };
    case types.EDIT_MODAL_STORE_STOCK_SUCCESS:
      return { ...state, storeStockLoading: false, storeStock: action.stock };
    case types.EDIT_MODAL_STORE_STOCK_FAILURE:
      return { ...state, storeStockLoading: false };
    case types.EDIT_MODAL_GANG_STOCK_REQUEST:
      return { ...state, gangStockLoading: true };
    case types.EDIT_MODAL_GANG_STOCK_SUCCESS:
      return { ...state, gangStockLoading: false, gangStock: action.stock };
    case types.EDIT_MODAL_GANG_STOCK_FAILURE:
      return { ...state, gangStockLoading: false };
    case types.EDIT_MODAL_REQUEST:
      return { ...state, sending: true };
    case types.EDIT_MODAL_SUCCESS:
      return {
        ...state,
        sending: false,
        modalOpen: false,
        confirmationOpen: false,
      };
    case types.EDIT_MODAL_FAILURE:
      return { ...state, sending: false };
    default:
      return state;
  }
};

export default reducer;
