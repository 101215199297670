import { connect } from "react-redux";

import { closeModal } from "../../store/ducks/gangsGroupsHistoryModal";
import GroupsHistoryDialog from "./groupsHistoryDialogContainer/GroupsHistoryDialog";

const mapStateToProps = state => ({
  gang: state.gangsGroupsHistoryModal.gang,
  open: state.gangsGroupsHistoryModal.modalOpen,
  history: state.gangsGroupsHistoryModal.history,
  isLoading: state.gangsGroupsHistoryModal.isLoading,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsHistoryDialog);
