import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MuteIcon from "@mui/icons-material/VolumeOff";
import SoundIcon from "@mui/icons-material/VolumeUp";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";

const gang = props => {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <ListItem
          style={{ padding: 6, paddingLeft: 20 }}
          key={props.gang.id}
          button
          onClick={() => props.handleMoveClick(props.gang.location.coordinates)}>
          <div
            className={`icon ${props.gang.state} gang-list`}
            style={{ animation: "none", marginRight: 10 }}>
            <p>{props.gang.initials}</p>
          </div>
          <ListItemText primary={props.gang.name} />
        </ListItem>
      </Grid>
      {props.gang.state === "Alerta" ? (
        <Grid item>
          <IconButton
            onClick={() => props.toggleGangSound(props.gang.id)}
            size="small"
            style={{
              marginTop: 0,
              marginRight: 5,
            }}>
            {props.gang.sound ? <SoundIcon /> : <MuteIcon />}
          </IconButton>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default gang;
