import Control from "react-leaflet-control";
import Fab from "@mui/material/Fab";
import MuteIcon from "@mui/icons-material/VolumeOff";
import SoundIcon from "@mui/icons-material/VolumeUp";

const muteButton = ({ muted, toggleMute }) => {
  return (
    <Control position="bottomleft">
      <Fab onClick={toggleMute} style={{ backgroundColor: "#fff", color: "#262626" }}>
        {muted ? <MuteIcon /> : <SoundIcon />}
      </Fab>
    </Control>
  );
};

export default muteButton;
