import { connect } from "react-redux";

import {
  setDefaultsValues,
  openModal as openEditModal,
} from "../../../store/ducks/taskSidewalkEditModal";
import TaskSidewalk from "./taskSidewalkContainer/TaskSidewalk";
import {
  openUndoModal,
  openConfirmationModal,
  openModal as openFinishValidationModal,
} from "../../../store/ducks/finishSidewalkValidationModal";
import { openModal as openAddModal } from "../../../store/ducks/taskSidewalkAddModal";

const mapStateToProps = state => ({
  taskSidewalkEdit: state.taskSidewalkEditModal,
  finishSidewalkValidationModal: state.finishSidewalkValidationModal,
});

const mapDispatchToProps = dispatch => ({
  openAddModal: () => dispatch(openAddModal()),
  openEditModal: sidewalk => dispatch(openEditModal(sidewalk)),
  openUndoModal: sidewalk => dispatch(openUndoModal(sidewalk)),
  openConfirmationModal: sidewalk => dispatch(openConfirmationModal(sidewalk)),
  undoEditChanges: (amount, unit, type, observations, requires_filling) =>
    dispatch(setDefaultsValues(amount, unit, type, observations, requires_filling)),
  openFinishValidationModal: () => dispatch(openFinishValidationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskSidewalk);
