export default ({
  codigo_ot,
  tipo,
  ingreso_ot,
  fecha_asignada,
  tarea,
  operative_zone,
}) => {
  return (
    codigo_ot &&
    tipo &&
    fecha_asignada &&
    !isNaN(fecha_asignada) &&
    ingreso_ot &&
    !isNaN(ingreso_ot) &&
    tarea &&
    (!operative_zone || /^[A-Z0-9]+$/.test(operative_zone))
  );
};
