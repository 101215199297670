import { anexo as axios } from "../../axios";
import { enqueueError, enqueueMessage } from "./notifications";
import { updateConsumptionState } from "./task";

const types = {
  UNDO_MODAL_OPEN: "UNDO_MODAL_OPEN",
  UNDO_MODAL_CLOSE: "UNDO_MODAL_CLOSE",
  UNDO_MODAL_REQUEST: "UNDO_MODAL_REQUEST",
  UNDO_MODAL_SUCCESS: "UNDO_MODAL_SUCCESS",
  UNDO_MODAL_FAILURE: "UNDO_MODAL_FAILURE",
};

export const openModal = article => ({
  type: types.UNDO_MODAL_OPEN,
  article,
});

export const closeModal = () => ({
  type: types.UNDO_MODAL_CLOSE,
});

const request = () => ({
  type: types.UNDO_MODAL_REQUEST,
});

const success = () => ({
  type: types.UNDO_MODAL_SUCCESS,
});

const failure = () => ({
  type: types.UNDO_MODAL_FAILURE,
});

export const undoArticle = () => async (dispatch, getState) => {
  const {
    undoModal: { article },
  } = getState();
  dispatch(request());
  try {
    await axios.patch(
      `/tareas/consumos/${article.consumption}`,
      {
        estado_consumo: "0",
      },
      { headers: { "x-api-cuadrilla-id": article.gang._id } }
    );
    dispatch(
      updateConsumptionState(article.consumption, {
        id: 0,
        name: "Sin validar",
      })
    );
    dispatch(enqueueMessage("El consumo volvió a estar sin validar"));
    dispatch(success());
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      dispatch(enqueueError(error.response.data.message));
    } else {
      dispatch(enqueueError("Ocurrió un error al deshacer el consumo"));
    }
    dispatch(failure());
  }
};

const initialState = {
  modalOpen: false,
  article: {},
  sending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UNDO_MODAL_OPEN:
      return { ...state, modalOpen: true, article: action.article };
    case types.UNDO_MODAL_CLOSE:
      return { ...state, modalOpen: false };
    case types.UNDO_MODAL_REQUEST:
      return { ...state, sending: true };
    case types.UNDO_MODAL_SUCCESS:
      return { ...state, sending: false, modalOpen: false };
    case types.UNDO_MODAL_FAILURE:
      return { ...state, sending: false };
    default:
      return state;
  }
};

export default reducer;
