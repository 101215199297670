import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";

import GangsContainer from "./markerCluster/GangsContainer";
import TasksContainer from "./markerCluster/TasksContainer";

const timeAlertIcon = (item, position) => {
  const px = position * 25 + 2;
  return `<span class="fa-stack update-alert" style="left: ${px}px;">
  <i class="fas fa-circle fa-stack-1x circle-icon"></i>
  <i class="fas ${
    item.oldState && item.oldState.type ? "fa-clock" : "fa-exclamation-circle"
  } fa-stack-1x alert-icon"></i>
</span>`;
};

const createClusterIcon = cluster => {
  const childs = cluster.getAllChildMarkers();
  const gangs = childs.reduce((ac, cv) => {
    let gang = cv.options.icon.options.item;
    if (gang.state.class) {
      gang = { ...gang, oldState: gang.state, state: gang.state.type };
    }
    ac[gang.state] = ac[gang.state] ? [...ac[gang.state], gang] : [gang];
    return ac;
  }, {});
  const markers = Object.keys(gangs).map((key, index) => {
    if (gangs[key].length > 1) {
      const alert = gangs[key].find(g => g.timeAlert || g.expired);
      return `<div class="icon group ${key}"><p>${gangs[key].length}</p>${
        alert ? timeAlertIcon(gangs[key][0], index) : ""
      }</div>`;
    } else {
      const state =
        gangs[key][0].oldState && gangs[key][0].oldState.type
          ? gangs[key][0].oldState.class
          : gangs[key][0].state;
      return `<div class="icon group ${state}"><p>${gangs[key][0].initials}</p>${
        gangs[key][0].timeAlert || gangs[key][0].expired
          ? timeAlertIcon(gangs[key][0], index)
          : ""
      }</div>`;
    }
  });
  markers[0] = markers[0].replace("group ", "");
  const amount = Object.keys(gangs).length;
  const size = amount * 32 - (amount - 1) * 7;
  return L.divIcon({
    className: "cluster-marker",
    html: markers.join(""),
    iconSize: [size, 32],
  });
};

const markerCluster = () => (
  <MarkerClusterGroup
    iconCreateFunction={createClusterIcon}
    maxClusterRadius={60}
    ref={group => group && group.leafletElement.refreshClusters()}>
    <GangsContainer />
    <TasksContainer />
  </MarkerClusterGroup>
);

export default markerCluster;
