import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = theme => ({
  form: {
    // maxWidth: 450,
    // maxHeight: 290,
    marginBottom: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
});

const AddressDialog = props => {
  const { classes, closeModal, openModal, save } = props;
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [between, setBetween] = useState("");
  const [and, setAnd] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const initialize = () => {
    setStreet(props.street);
    setNumber(props.number);
    setBetween(props.between);
    setAnd(props.and);
  };

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({ street, number, between, and });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openModal, street, number, between, and, save]);

  return (
    <Dialog
      open={openModal}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      onClose={closeModal}
      TransitionProps={{
        onEnter: initialize,
        onExited: initialize,
      }}>
      <DialogTitle>Dirección</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Ingrese los datos de la dirección por los que desea filtrar.
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.input}
                  label="Calle"
                  value={street}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setStreet(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={between}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setBetween(e.target.value)}
                  className={classes.input}
                  label="Entre"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={number}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setNumber(e.target.value)}
                  className={classes.input}
                  label="Altura"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={and}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setAnd(e.target.value)}
                  className={classes.input}
                  label="Y"
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button color="primary" onClick={() => save({ street, number, between, and })}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AddressDialog);
