import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

const userName = ({ name }) => (
  <Typography variant="subtitle1" color="inherit">
    {name}
  </Typography>
);

const mapStateToProps = state => ({
  name: state.user.name,
});

export default connect(mapStateToProps)(userName);
