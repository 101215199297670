import { Component } from "react";
import { connect } from "react-redux";

import ApiError from "../components/UI/ApiError";
import NewGroup from "./newGroupContainer/newGroup";

import { getUsers } from "../store/ducks/users";
import { getAllCities } from "../store/ducks/cities";
import { getStores } from "../store/ducks/storesList";
import { getTaskTypes } from "../store/ducks/taskTypes";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

class NewGroupContainer extends Component {
  componentDidMount() {
    this.props.getUsers();
    this.props.getCities();
    this.props.getStores();
    this.props.getTaskTypes();
  }

  render() {
    const { error } = this.props;
    return error ? (
      <ApiError error="Ocurrió un error la información requerida" />
    ) : (
      <NewGroup {...this.props} />
    );
  }
}

const actionNames = ["CITIES", "STORES_LIST", "TASK_TYPES", "USERS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  user: state.user,
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(getUsers()),
  getStores: () => dispatch(getStores()),
  getCities: () => dispatch(getAllCities()),
  getTaskTypes: () => dispatch(getTaskTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupContainer);
