import withStyles from "@mui/styles/withStyles";
import { Map, TileLayer } from "react-leaflet";
import "react-leaflet-fullscreen-control";

import { MAP } from "../../store/ducks/map";
import Preloader from "../../components/UI/Preloader";
import MarkerCluster from "./map/MarkerCluster";
import CenterButtonContainer from "./map/CenterButtonContainer";
import MuteButtonContainer from "./map/MuteButtonContainer";
import InfoPanelContainer from "./map/InfoPanelContainer";
import MobilePanelButtonContainer from "./map/MobilePanelButtonContainer";
import "./styles.css";
import "../../components/Tasks/states.css";

const styles = {
  root: {
    flexGrow: 1,
    height: "calc(100vh - 64px)",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  map: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
};

const map = ({ classes, center, zoom, isFetching }) => {
  return isFetching ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Map
        center={center}
        zoom={zoom}
        maxZoom={MAP.maxZoom}
        fullscreenControl
        className={classes.map}>
        <TileLayer attribution={MAP.attribution} url={MAP.url} />
        <CenterButtonContainer />
        <MuteButtonContainer />
        <MobilePanelButtonContainer />
        <MarkerCluster />
      </Map>
      <InfoPanelContainer />
    </div>
  );
};

export default withStyles(styles)(map);
