const types = {
  TASK_LIST_ADDRESS_FILTER_OPEN_MODAL: "TASK_LIST_ADDRESS_FILTER_OPEN_MODAL",
  TASK_LIST_ADDRESS_FILTER_CLOSE_MODAL: "TASK_LIST_ADDRESS_FILTER_CLOSE_MODAL",
  TASK_LIST_ADDRESS_FILTER_SAVE: "TASK_LIST_ADDRESS_FILTER_SAVE",
  TASK_LIST_ADDRESS_FILTER_REMOVE: "TASK_LIST_ADDRESS_FILTER_REMOVE",
};

export const openModal = () => ({
  type: types.TASK_LIST_ADDRESS_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_ADDRESS_FILTER_CLOSE_MODAL,
});

export const save = state => ({
  type: types.TASK_LIST_ADDRESS_FILTER_SAVE,
  state,
});

export const removeAddress = () => ({
  type: types.TASK_LIST_ADDRESS_FILTER_REMOVE,
});

const initialState = {
  openModal: false,
  street: "",
  number: "",
  between: "",
  and: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_ADDRESS_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_ADDRESS_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_ADDRESS_FILTER_SAVE:
      return { ...state, openModal: false, ...action.state };
    case types.TASK_LIST_ADDRESS_FILTER_REMOVE:
      return { ...state, street: "", number: "", between: "", and: "" };
    default:
      return state;
  }
};

export default reducer;
