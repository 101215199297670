import clsx from "clsx";
import { format } from "date-fns";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";

import {
  Card,
  Grid,
  List,
  Divider,
  Tooltip,
  IconButton,
  Typography,
  CardContent,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { CardHeader } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

import { openModal } from "../../../../store/ducks/taskPipelineEditModal";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const PipelinesCard = ({ task: { pipelines = [] }, ...props }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return pipelines.length ? (
    <Card variant="outlined" style={{ marginTop: 10 }}>
      <CardHeader
        titleTypographyProps={{ variant: "h6", style: { fontWeight: 400 } }}
        title="Información de la Red"
        action={
          <>
            {
              <IconButton onClick={handleExpandClick} size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            }
          </>
        }
      />
      <CardContent>
        {!expanded ? (
          <Typography
            style={{
              fontWeight: 100,
              marginTop: -10,
            }}>{`La tarea posee información de la red de agua`}</Typography>
        ) : null}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List>
            {(pipelines || []).map((pipeline, index) => (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 20 }}>
                  <Grid
                    container
                    spacing={2}
                    style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 20 }}>
                    <Grid item xs={12} md={6}>
                      <div style={{ textAlign: "left" }}>
                        <Typography variant="body2">Tipo</Typography>
                        <Typography variant="body2" style={{ color: "#0000008a" }}>
                          {pipeline.has_sidewalk ? "Vereda" : "Calzada"}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div style={{ textAlign: "left" }}>
                        <Typography variant="body2">Profundidad</Typography>
                        <Typography variant="body2" style={{ color: "#0000008a" }}>
                          {pipeline?.depth || "Sin especificar"}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div style={{ textAlign: "left" }}>
                        <Typography variant="body2">Diametro</Typography>
                        <Typography variant="body2" style={{ color: "#0000008a" }}>
                          {pipeline?.diameter || "Sin especificar"}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div style={{ textAlign: "left" }}>
                        <Typography variant="body2">Material</Typography>
                        <Typography variant="body2" style={{ color: "#0000008a" }}>
                          {pipeline?.material || "Sin especificar"}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div style={{ textAlign: "left" }}>
                        <Typography variant="body2">Alejamiento LM</Typography>
                        <Typography variant="body2" style={{ color: "#0000008a" }}>
                          {pipeline?.municipal_line_distance || "Sin especificar"}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div style={{ textAlign: "left" }}>
                        <Typography variant="body2">Fondo a Calle</Typography>
                        <Typography variant="body2" style={{ color: "#0000008a" }}>
                          {pipeline?.street_orientation || "Sin especificar"}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {pipeline.validated_by ? (
                  <ListItemSecondaryAction
                    style={{
                      textAlign: "right",
                      marginRight: !props.roles.includes("validarRed") ? 0 : 50,
                    }}>
                    <ListItemText
                      primary={<div>{pipeline.validated_by?.name || "-"} </div>}
                      secondary={
                        <>
                          <div>
                            {format(
                              new Date(pipeline.validated_at?.replace(/ /g, "T")),
                              "dd/MM/yyyy HH:mm:ss"
                            )}
                          </div>
                        </>
                      }
                    />
                  </ListItemSecondaryAction>
                ) : null}

                {props.roles.includes("validarRed") ? (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => props.open(pipeline)}
                      size="large">
                      <Tooltip
                        title={pipeline.validated_by ? "Editar validación" : "Validar"}>
                        {pipeline.state === 1 ? <CheckIcon /> : <EditIcon />}
                      </Tooltip>
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : null}

                {index !== (pipelines || []).length - 1 ? <Divider key={index} /> : null}
              </>
            ))}
          </List>
        </Collapse>
      </CardContent>
    </Card>
  ) : null;
};

const mapStateToProps = state => ({
  roles: state.user.roles,
});

const mapDispatchToProps = dispatch => ({
  open: pipeline => dispatch(openModal(pipeline)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PipelinesCard);
