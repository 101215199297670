import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import LayersIcon from "@mui/icons-material/Layers";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import withStyles from "@mui/styles/withStyles";

const styles = theme => ({
  spinner: {
    width: 48,
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(0.2),
  },
  icon: { marginRight: 10 },
  iconContainer: { display: "flex", justifyContent: "flex-end" },
});

const body = props => {
  const { tasks, page, rowsPerPage } = props;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tasks.length - page * rowsPerPage);
  return (
    <TableBody>
      {tasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(a => {
        return (
          <TableRow
            hover
            aria-checked={a.selected}
            tabIndex={-1}
            key={a.id}
            selected={a.selected}>
            <TableCell>{a.otCode}</TableCell>
            <TableCell>{a.group.name || "-"}</TableCell>
            <TableCell>{a.address}</TableCell>
            <TableCell>{a.task || "-"}</TableCell>
            <TableCell>{a.module || "-"}</TableCell>
            <TableCell padding="none" align="right">
              <Tooltip title="Validar vereda">
                <IconButton
                  component={Link}
                  to={`/validacion/vereda/${a.id}`}
                  className={props.classes.button}
                  style={!a.hasPendingSidewalk ? { color: "#b1b1b1" } : null}
                  size="large">
                  <LayersIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default withStyles(styles)(body);
