import axios from "axios";

export const gangs = axios.create({
  baseURL: process.env.REACT_APP_CUADRILLAS_URI,
});

export const stores = axios.create({
  baseURL: process.env.REACT_APP_DEPOSITOS_URI,
});

export const nomenclador = axios.create({
  baseURL: process.env.REACT_APP_NOMENCLADOR_URI,
});

export const anexoOld = axios.create({
  baseURL: process.env.REACT_APP_ANEXO_URI,
  headers: { "x-api-key": process.env.REACT_APP_ANEXO_API_KEY },
});

export const anexo = axios.create({
  baseURL: process.env.REACT_APP_ANEXO_V2_URI,
});

export default axios.create({
  baseURL: process.env.REACT_APP_TAREAS_URI,
});
