import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const header = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Tipo</TableCell>
        <TableCell>Unidad de medida</TableCell>
        <TableCell>Cantidad</TableCell>
        <TableCell>Fecha</TableCell>
        <TableCell>Observación</TableCell>
        <TableCell>Estado</TableCell>
        <TableCell align="right">Acciones</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default header;
