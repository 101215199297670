import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";

import Button from "@mui/material/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useMediaQuery, useTheme } from "@mui/material";

const styles = () => ({
  text: {
    textAlign: "center",
  },
  icon: {
    fontSize: 100,
    marginBottom: 15,
  },
});

const GroupsDialog = props => {
  const { classes, closeModal, openModal, gang } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog fullScreen={isMobile} open={openModal} onClose={closeModal}>
      <DialogTitle>Transferencia de cuadrilla</DialogTitle>
      <DialogContent dividers className={classes.text}>
        <ErrorOutlineIcon fontSize="large" className={classes.icon} />
        <DialogContentText>
          La cuadrilla <i>{gang.cuadrilla}</i> no puede ser transferida de grupo porque
          cuenta con {gang.tareasEnEjecucionCount}{" "}
          {gang.tareasEnEjecucionCount === 1 ? "tarea" : "tareas"} en ejecución.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(GroupsDialog);
