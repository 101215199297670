import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { memo } from "react";

const header = memo(() => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>ODT</TableCell>
        <TableCell>Grupo</TableCell>
        <TableCell>Cuadrilla actual</TableCell>
        <TableCell>Domicilio</TableCell>
        <TableCell>Descripción</TableCell>
        <TableCell>Módulo</TableCell>
        <TableCell padding="none" />
      </TableRow>
    </TableHead>
  );
});

export default header;
