/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */

import { Delete, Image } from "@mui/icons-material";
import { IconButton, Popover, Tooltip, Paper } from "@mui/material";
import { useState } from "react";

export default props => {
  const {
    item,
    form: { setValue, getValues },
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Paper
      elevation={0}
      variant="outlined"
      style={{
        padding: 10,
        marginTop: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}>
      {item.name}
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}>
          <img
            height={100}
            alt="croquis"
            style={{ margin: 20 }}
            src={URL.createObjectURL(item)}
          />
        </Popover>

        {item.type !== "application/pdf" ? (
          <Tooltip title="Ver">
            <IconButton
              color="error"
              onClick={handleClick}
              aria-label="upload picture"
              component="span"
              size="large">
              <Image />
            </IconButton>
          </Tooltip>
        ) : null}

        <IconButton
          color="secondary"
          aria-label="upload picture"
          component="span"
          onClick={() => {
            setValue(
              "step1.croquis",
              getValues().step1.croquis.filter(a => a !== item)
            );
          }}
          size="large">
          <Delete />
        </IconButton>
      </div>
    </Paper>
  );
};
