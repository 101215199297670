export const getPathName = () => {
  const parser = document.createElement("a");
  parser.href = process.env.REACT_APP_CUADRILLAS_URI;
  return parser.pathname;
};

export const getHost = () => {
  const parser = document.createElement("a");
  parser.href = process.env.REACT_APP_CUADRILLAS_URI;
  return parser.host;
};
