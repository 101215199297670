/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import List from "@mui/material/List";
import DownloadIcon from "@mui/icons-material/GetApp";
import ValidationIcon from "@mui/icons-material/TouchApp";
import MapIcon from "@mui/icons-material/Map";
import TasksIcon from "@mui/icons-material/Build";
import GroupIcon from "@mui/icons-material/LocationCity";
import GangIcon from "@mui/icons-material/Person";
import DrawerButton from "./DrawerButton";
import { Divider, ListSubheader, Tooltip } from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";
import InvertColorsIcon from "@mui/icons-material/InvertColors";

import packageJson from "../../../../../package.json";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";

const DrawerButtons = ({ onClose, roles }) => {
  const navigate = useNavigate();
  const [buildDate, setBuildData] = useState();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/version.txt`)
      .then(r => r.text())
      .then(text => {
        if (text) {
          const date = format(new Date(parseInt(text, 10) * 1000), "dd/MM/yyyy HH:mm");
          setBuildData(`Publicación: ${date}`);
        }
      });
  }, []);

  return (
    <>
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Tareas {"   "}
            <Tooltip title={buildDate || ""} placement="right">
              <small
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/actualizaciones");
                  onClose();
                }}>
                v{packageJson.version}
              </small>
            </Tooltip>
          </ListSubheader>
        }>
        <div>
          <DrawerButton name="Listado" path="/" icon={<TasksIcon />} onClose={onClose} />

          <DrawerButton name="Mapa" path="/mapa" icon={<MapIcon />} onClose={onClose} />

          {roles && roles.find(role => role === "planilla") ? (
            <DrawerButton
              name="Planilla"
              path="/planilla"
              icon={<DownloadIcon />}
              onClose={onClose}
            />
          ) : null}
        </div>
      </List>

      <Divider />
      {roles &&
      roles.find(role =>
        ["validarVereda", "validarMateriales", "validarHidrojet"].includes(role)
      ) ? (
        <>
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Validación
              </ListSubheader>
            }>
            <div>
              {roles && roles.find(role => role === "validarMateriales") ? (
                <DrawerButton
                  name="Materiales"
                  path="/validacion"
                  icon={<ValidationIcon />}
                  onClose={onClose}
                />
              ) : null}

              {roles && roles.find(role => role === "validarVereda") ? (
                <DrawerButton
                  name="Datos civiles"
                  path="/validacion/vereda"
                  icon={<LayersIcon />}
                  onClose={onClose}
                />
              ) : null}

              {roles && roles.find(role => role === "validarHidrojet") ? (
                <DrawerButton
                  name="Módulos de hidrojet"
                  path="/validacion/hidrojet"
                  icon={<InvertColorsIcon />}
                  onClose={onClose}
                />
              ) : null}
            </div>
          </List>

          <Divider />
        </>
      ) : null}

      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Cuadrillas
          </ListSubheader>
        }>
        <div>
          <DrawerButton
            name="Listado"
            path="/cuadrillas"
            icon={<GangIcon />}
            onClose={onClose}
          />
        </div>
      </List>

      {roles &&
      roles.find(role =>
        ["cambiarGrupo", "editarGrupo, asignarGrupo, desasignarGrupo"].includes(role)
      ) ? (
        <>
          <Divider />
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Grupos
              </ListSubheader>
            }>
            <div>
              <DrawerButton
                name="Listado"
                path="/grupos"
                icon={<GroupIcon />}
                onClose={onClose}
              />
            </div>
          </List>
          <Divider />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  roles: state.user.roles,
});

export default connect(mapStateToProps)(DrawerButtons);
