const types = {
  ot: "CODIGO_OT",
  creation: "CREATED_AT",
  expiration: "VENCIMIENTO",
  income_ot: "INGRESO_OT",
  state_change: "UPDATED_AT",
};

export const getTaskOrderCriteria = (option = "creation", descOrder) => [
  {
    field: types[option],
    order: descOrder ? "DESC" : "ASC",
  },
];
