import { connect } from "react-redux";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";
import { getSidewalkUnits } from "../../../../store/ducks/sidewalkUnits";
import { getSidewalkTypes } from "../../../../store/ducks/sidewalkTypes";
import { save, closeModal } from "../../../../store/ducks/taskListSidewalkFilter";

import SidewalkContainer from "./sidewalkDialogContainer/SidewalkDialog";

const mapStateToProps = state => ({
  units: state.sidewalkUnits,
  types: state.sidewalkTypes,

  openModal: state.taskListSidewalkFilter.openModal,

  sidewalkType: state.taskListSidewalkFilter.sidewalkType,
  sidewalkUnit: state.taskListSidewalkFilter.sidewalkUnit,
  withSidewalk: state.taskListSidewalkFilter.withSidewalk,
  sidewalkState: state.taskListSidewalkFilter.sidewalkState,
  sidewalkMinAmount: state.taskListSidewalkFilter.sidewalkMinAmount,
  sidewalkMaxAmount: state.taskListSidewalkFilter.sidewalkMaxAmount,
});

const mapDispatchToProps = dispatch => ({
  save: state => {
    dispatch(save(state));
    dispatch(getTasksFromGraphql());
  },
  closeModal: () => dispatch(closeModal()),
  getSidewalkUnits: () => dispatch(getSidewalkUnits()),
  getSidewalkTypes: () => dispatch(getSidewalkTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidewalkContainer);
