/* eslint-disable no-empty */
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useMediaQuery, useTheme } from "@mui/material";

const DownloadDialog = props => {
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Descargar Módulos</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Se descargará la planilla de los módulos seleccionadas.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={props.onClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            setLoading(true);

            try {
              //Create a new Work Sheet using the data stored in an Array of Arrays.
              let data = props.hidrojets.filter(h => props.selected.includes(h.id));

              data = data.map(h => ({
                "Fecha de Tarea": h.task_date,
                "Fecha de Carga": h.created_at,
                "Código OT": h.task.codigo_ot,
                Tarea: h.task.tarea,
                Ubicación: h.task.ubicación,
                Cuadrilla: h.cuadrilla.cuadrilla,
                Grupo: h.task.grupo.nombre,
                "Módulos Utilizados": h.module,
                Estado: h.state === 1 ? "Validado" : "Sin validar",
                Observaciones: h.observations,
              }));

              const workSheet = XLSX.utils.json_to_sheet(data);

              // Generate a Work Book containing the above sheet.
              const workBook = {
                Sheets: { data: workSheet, cols: [] },
                SheetNames: ["data"],
              };

              const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
              // Desired file extesion
              const fileExtension = ".xlsx";

              // Exporting the file with the desired name and extension.
              const excelBuffer = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "array",
              });
              const fileData = new Blob([excelBuffer], { type: fileType });
              FileSaver.saveAs(fileData, `modulos${fileExtension}`);
              props.onClose();
            } catch (error) {}
            setLoading(false);
          }}
          color="primary">
          Descargar
        </Button>
      </DialogActions>
      {loading && <LinearProgress />}
    </Dialog>
  );
};

export default DownloadDialog;
