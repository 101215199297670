import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import FinishIcon from "@mui/icons-material/DoneAll";
import Checkbox from "@mui/material/Checkbox";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";

import ArticlesTableContainer from "./taskArticles/ArticlesTableContainer";

const styles = theme => ({
  tableContainer: {
    paddingTop: theme.spacing(),
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    marginLeft: "auto",
  },
  searchIcon: {
    color: "rgba(0,0,0,0.54)",
    marginRight: 7,
  },
  toolbar: { paddingRight: 0 },
});

const taskArticles = props => {
  return (
    <div className={props.classes.tableContainer}>
      <Toolbar className={props.classes.toolbar}>
        <div>
          <Typography variant="h5">Materiales</Typography>
        </div>
        <div className={props.classes.actions}>
          <Tooltip title={props.showAll ? `Ver solo sin validar` : "Ver todos"}>
            <Checkbox
              icon={<RemoveCircleOutline />}
              checkedIcon={<RemoveCircle />}
              value="checkedH"
              color="default"
              checked={props.showAll}
              onChange={props.toggleShowAll}
            />
          </Tooltip>
          <Tooltip title="Agregar consumo">
            <IconButton onClick={props.openAddModal} size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Finalizar validación">
            <IconButton
              onClick={() => {
                if (!props.canConfirm) {
                  return props.openErrorModal();
                }

                props.openFinishValidationModal();
              }}
              size="large">
              <FinishIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
      <Toolbar>
        <SearchIcon className={props.classes.searchIcon} />
        <Input
          value={props.articleName}
          onChange={e => props.changeArticleFilter(e.target.value)}
          disableUnderline
          fullWidth
          placeholder="Buscar material"
        />
      </Toolbar>
      <ArticlesTableContainer />
    </div>
  );
};

export default withStyles(styles)(taskArticles);
