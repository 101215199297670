import { v4 as uuidv4 } from "uuid";

export default fields => {
  const {
    name: nombre,
    users,
    cities,
    taskTypes,
    inspectors,
    stores,
    has_contractors,
    fills_sidewalks,
    fills_pipeline_information,
  } = fields;

  return {
    nombre,
    users: users.map(u => u.id),
    locations: cities.map(c => c.id),
    taskTypes: taskTypes.map(tt => tt.id),
    fills_sidewalks: fills_sidewalks ? 1 : 0,
    has_contractors: has_contractors ? 1 : 0,
    fills_pipeline_information: fills_pipeline_information ? 1 : 0,
    stores: stores && stores.length ? JSON.stringify(stores.map(s => s._id)) : null,
    inspectors:
      inspectors && inspectors.length
        ? JSON.stringify(
            inspectors.map(inspector => ({
              id: uuidv4(),
              inspector,
            }))
          )
        : null,
  };
};
