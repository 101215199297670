import { connect } from "react-redux";

import DeleteAll from "./deleteAllContainer/deleteAll";
import { resetFilters, getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  resetFilters: async () => {
    await dispatch(resetFilters());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAll);
