import { connect } from "react-redux";

import {
  closeModal,
  changeComment,
  confirm,
  toggleActionRequired,
} from "../../../../store/ducks/taskListInspectionTask";
import InspectionDialog from "./inspectionDialogContainer/InspectionDialog";

const mapStateToProps = state => ({
  openModal: state.taskListInspectionTask.openModal,
  loading: state.taskListInspectionTask.loading,
  task: state.taskListInspectionTask.task,
  actionRequired: state.taskListInspectionTask.actionRequired,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  confirm: () => dispatch(confirm()),
  changeComment: comment => dispatch(changeComment(comment)),
  toggleActionRequired: () => dispatch(toggleActionRequired()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionDialog);
