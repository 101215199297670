import { connect } from "react-redux";
import Grid from "@mui/material/Grid";

import GroupCard from "./groupList/GroupCard";
import EmptyGroups from "./groupList/EmptyGroups";
import TaskCardSkeleton from "./groupList/GroupSkeleton";
import { getFilteredGroups } from "../../../store/ducks/groups";
import { openModal } from "../../../store/ducks/editGroupModal";

const groups = props => {
  const skeletons = Array.from(Array(12).keys()).map(value => (
    <Grid item key={value} xs={12} sm={6} md={6} lg={4}>
      <TaskCardSkeleton />
    </Grid>
  ));

  const groups = props.groups.map(group => (
    <Grid key={group.id} item xs={12} sm={6} md={6} lg={4}>
      <GroupCard {...props} group={group} open={props.openModal} roles={props.roles} />
    </Grid>
  ));

  return (
    <Grid container spacing={2}>
      {props.isFetching ? (
        skeletons
      ) : props.groups.length ? (
        <>{groups}</>
      ) : (
        <EmptyGroups />
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  groups: getFilteredGroups(state),
  roles: state.user.roles,
});

const mapDispatchToProps = dispatch => ({
  openModal: group => dispatch(openModal(group)),
});

export default connect(mapStateToProps, mapDispatchToProps)(groups);
