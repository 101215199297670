import { connect } from "react-redux";

import { closeModal, confirmComposedDownload } from "../../../store/ducks/downloadTasks";
import DownloadDialog from "./downloadDialogContainer/DownloadDialog";

const mapStateToProps = state => ({
  open: state.downloadTasks.open,
  loading: state.downloadTasks.loading,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  confirmDownload: () => dispatch(confirmComposedDownload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDialog);
