/* eslint-disable jsx-a11y/alt-text */

import { MenuItem } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { AbsaReportDocument } from "../../../../../../components/AbsaReport/Document";

export default ({ task }) => {
  return (
    <div>
      <PDFDownloadLink
        fileName={`Planilla ${task.codigo_ot}.pdf`}
        document={<AbsaReportDocument tasks={[task]} />}
        style={{ textDecoration: "none", color: "black" }}>
        {() => {
          return <MenuItem>Descargar planilla ABSA</MenuItem>;
        }}
      </PDFDownloadLink>
    </div>
  );
};
