import { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Alert, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const styles = () => ({
  text: {
    textAlign: "center",
  },
  alert: {
    padding: 15,
    display: "flex",
    border: "1px solid #c4c4c4",
    borderRadius: 5,
    fontSize: 14,
  },
  alertIcon: {
    color: "#747474",
  },
  alertText: {
    marginBottom: 0,
    marginLeft: 10,
  },
  confirmBtn: {
    width: 110,
  },
  input: {
    marginTop: 10,
    marginBottom: 20,
  },
});

const GroupsDialog = props => {
  const { classes, closeModal, openModal, gang, group } = props;

  const [selected, setSelected] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setSelected(false);
  }, [openModal]);

  const hasTasks =
    gang &&
    (gang.tareasProgramadasCount ||
      gang.tareasAsignadasCount ||
      gang.tareasPausadasCount);

  return (
    <Dialog fullScreen={isMobile} open={openModal} onClose={closeModal}>
      <DialogTitle>Transferencia de cuadrilla</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Estás a punto de transferir la cuadrilla <i>{gang ? gang.cuadrilla : ""}</i> del
          grupo <i>{group ? group.nombre : ""}</i> a un nuevo grupo.
        </DialogContentText>
        <Autocomplete
          options={props.groups}
          onChange={(_, value) => {
            setSelected(value);
          }}
          getOptionLabel={option => option.name}
          getOptionDisabled={option => option.id === group.id}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Nuevo Grupo"
              className={classes.input}
            />
          )}
        />

        {hasTasks ? (
          <Alert severity="warning">
            <div>
              Al transferir la cuadrilla <i>{gang && gang.cuadrilla}</i> de grupo todas
              sus tareas en estado <i>Programadas</i>{" "}
              {gang ? `(${gang.tareasProgramadasCount})` : ""}, <i>Asignadas</i>{" "}
              {gang ? `(${gang.tareasAsignadasCount})` : ""} y <i>Pausadas</i>{" "}
              {gang ? `(${gang.tareasPausadasCount})` : ""} pasarán automáticamente a
              estado <i>Pendiente</i> y serán desasignadas de la cuadrilla.
            </div>
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} style={{ color: "black" }}>
          Cancelar
        </Button>

        <LoadingButton
          loading={props.sending}
          color="primary"
          disabled={!selected}
          className={classes.confirmBtn}
          onClick={() => props.transfer(selected)}>
          Transferir
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(GroupsDialog);
