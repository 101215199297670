import { Component } from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Gang from "./gangList/Gang";
import Search from "./gangList/Search";
import Sound from "react-sound";

class TaskList extends Component {
  state = {
    open: true,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    return (
      <List
        component="nav"
        disablePadding
        subheader={
          <>
            <Grid container>
              <Grid item xs>
                <ListSubheader disableSticky>Cuadrillas</ListSubheader>
              </Grid>
              <Grid item>
                <IconButton
                  disableRipple
                  onClick={this.handleClick}
                  size="small"
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                  }}>
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </Grid>
          </>
        }>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List dense>
            <Search value={this.props.search} changeSearch={this.props.changeSearch} />
            {this.props.gangs.map(gang => (
              <Gang
                gang={gang}
                key={gang.id}
                handleMoveClick={this.props.handleMoveClick}
                toggleGangSound={this.props.toggleGangSound}
              />
            ))}
          </List>
          {this.props.sound ? (
            <Sound url="pulse.mp3" loop playStatus={Sound.status.PLAYING} />
          ) : null}
        </Collapse>
      </List>
    );
  }
}

export default TaskList;
