import { createSelector } from "reselect";
import escapeStringRegexp from "escape-string-regexp";

import axios from "../../axios";

import { parseFromApiForValidation } from "../../utils/TaskParser";

const types = {
  TASKS_VALIDATION_REQUEST: "TASKS_VALIDATION_REQUEST",
  TASKS_VALIDATION_SUCCESS: "TASKS_VALIDATION_SUCCESS",
  TASKS_VALIDATION_FAILURE: "TASKS_VALIDATION_FAILURE",
};

const requestTasksValidation = () => ({
  type: types.TASKS_VALIDATION_REQUEST,
});

const successTasksValidation = tasks => ({
  type: types.TASKS_VALIDATION_SUCCESS,
  tasks,
});

const failedTasksValidation = () => ({
  type: types.TASKS_VALIDATION_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las tareas",
});

export const getTasksValidation = () => {
  return async dispatch => {
    dispatch(requestTasksValidation());
    try {
      const { data } = await axios.get(`/tasks/materials/validation`);
      let tasks = data.data.map(task => parseFromApiForValidation(task));
      dispatch(successTasksValidation(tasks));
    } catch (error) {
      dispatch(failedTasksValidation());
    }
  };
};

export const getTasksSidewalkValidation = () => {
  return async dispatch => {
    dispatch(requestTasksValidation());
    try {
      const { data } = await axios.get(`/tasks/sidewalk/validation`);
      let tasks = data.data.map(task => parseFromApiForValidation(task));
      dispatch(successTasksValidation(tasks));
    } catch (error) {
      dispatch(failedTasksValidation());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.TASKS_VALIDATION_SUCCESS:
      return action.tasks;
    default:
      return state;
  }
};

// requiresFilling: null,
// requiresSidewalk: null,
// requiresCleaning: null,

const getAllTasks = state => state.tasksValidation;
const getSelectedTasks = state => state.selectedTasks;
const getOdtFilter = state => state.downloadTasksFilter.odt;
const getGroupFilter = state => state.downloadTasksFilter.group;
const getTaskTypeFilter = state => state.downloadTasksFilter.taskType;

const getrequiresFillingFilter = state => state.downloadTasksFilter.requiresFilling;
const getrequiresSidewalkFilter = state => state.downloadTasksFilter.requiresSidewalk;
const getrequiresCleaningFilter = state => state.downloadTasksFilter.requiresCleaning;

const getShowSelectedTasks = state => state.downloadTasksFilter.showSelectedTasks;

export const mapSelectedTasks = createSelector(
  [
    getAllTasks,
    getSelectedTasks,
    getOdtFilter,
    getGroupFilter,
    getTaskTypeFilter,
    getShowSelectedTasks,
    getrequiresFillingFilter,
    getrequiresSidewalkFilter,
    getrequiresCleaningFilter,
  ],
  (
    allTasks,
    selectedTasks,
    odt,
    group,
    taskType,
    showSelectedTasks,
    requiresFilling,
    requiresSidewalk,
    requiresCleaning
  ) => {
    const regex = new RegExp(`.*${escapeStringRegexp(odt)}.*`, "i");

    return allTasks
      .filter(
        t =>
          t.otCode.match(regex) &&
          (taskType ? t.typeId === taskType : true) &&
          (group ? t.group.id === group : true) &&
          (requiresFilling
            ? requiresFilling === 2
              ? t.sidewalks.filter(s => s.type_id !== 5).some(s => s.requires_filling)
              : !t.sidewalks.filter(s => s.type_id !== 5).some(s => s.requires_filling)
            : true) &&
          (requiresSidewalk
            ? requiresSidewalk === 2
              ? t.sidewalks.filter(s => s.type_id !== 5).length
              : !t.sidewalks.filter(s => s.type_id !== 5).length
            : true) &&
          (requiresCleaning
            ? requiresCleaning === 2
              ? t.requires_cleaning
              : !t.requires_cleaning
            : true) &&
          (showSelectedTasks ? selectedTasks.includes(t.id) : true)
      )
      .map(task => ({
        ...task,
        selected: !!selectedTasks.find(id => id === task.id),
      }));
  }
);

export default reducer;
