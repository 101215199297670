/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { memo, useCallback } from "react";

const styles = () => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
});

const TaskListAddButton = memo(props => {
  const { classes } = props;

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate("/nueva-tarea");
  }, [navigate]);

  return (
    <Tooltip title="Nueva Tarea">
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={onClick}>
        <AddIcon />
      </Fab>
    </Tooltip>
  );
});

export default withStyles(styles)(TaskListAddButton);
