class KeycloakAuth {
  static getUser = kc => {
    const client = kc.tokenParsed.resource_access["tareas-web"];
    const roles = client ? client.roles : [];
    return {
      id: kc.tokenParsed.sub,
      username: kc.tokenParsed.username,
      name: kc.tokenParsed.name,
      roles,
    };
  };
  static updateSavedToken = kc => {
    localStorage.setItem("tareas-token", kc.token);
    localStorage.setItem("tareas-refreshToken", kc.refreshToken);
  };
  static clearSavedToken = () => {
    localStorage.removeItem("tareas-token");
    localStorage.removeItem("tareas-refreshToken");
  };
}

export default KeycloakAuth;
