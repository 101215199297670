import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

const footer = props => {
  const { count, page, rowsPerPage } = props;
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
          labelRowsPerPage="Filas"
          labelDisplayedRows={label => `${label.from}-${label.to} de ${label.count}`}
        />
      </TableRow>
    </TableFooter>
  );
};

export default footer;
