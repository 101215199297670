import addHours from "date-fns/addHours";

export const getGangState = gang => {
  return gang.state === "Ejecutando" &&
    gang.alertLocation &&
    addHours(new Date(gang.alertLocation.date), 4) < new Date()
    ? "Alerta"
    : gang.state || "Libre";
};

export const getTimeAlert = gang => {
  return gang.location ? addHours(new Date(gang.location.date), 1) < new Date() : false;
};

export const parseFromApi = (gang, groupGangs = []) => {
  let initials = gang.name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  const state = getGangState(gang);
  const groupGang = groupGangs.find(g => g.id === gang._id);
  return {
    id: gang._id,
    name: gang.name,
    state,
    sound: state === "Alerta",
    active: gang.active,
    groupName: groupGang && groupGang.grupo ? groupGang.grupo.nombre : null,
    group: groupGang ? groupGang.grupo_id : null,
    timeAlert: getTimeAlert(gang),
    alertLocation: gang.alertLocation,
    appVersion: gang.app_version,
    location: gang.location
      ? {
          date: gang.location.date,
          coordinates: [gang.location.lat, gang.location.lng],
        }
      : null,
    initials,
  };
};
