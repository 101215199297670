/* eslint-disable no-case-declarations */
import { enqueueError } from "./notifications";
import { getTasksFromGraphql } from "./tasksList";

const types = {
  TASK_LIST_TYPES_INITIALIZE: "TASK_LIST_TYPES_INITIALIZE",
  TASK_LIST_TYPES_FILTER_SAVE: "TASK_LIST_TYPES_FILTER_SAVE",
  TASK_LIST_TYPES_FILTER_REMOVE: "TASK_LIST_TYPES_FILTER_REMOVE",
  TASK_LIST_TYPES_FILTER_TOGGLE: "TASK_LIST_TYPES_FILTER_TOGGLE",
  TASK_LIST_TYPES_FILTER_TOGGLE_ALL: "TASK_LIST_TYPES_FILTER_TOGGLE_ALL",
  TASK_LIST_TYPES_FILTER_OPEN_MODAL: "TASK_LIST_TYPES_FILTER_OPEN_MODAL",
  TASK_LIST_TYPES_FILTER_CLOSE_MODAL: "TASK_LIST_TYPES_FILTER_CLOSE_MODAL",
};

export const openModal = () => ({
  type: types.TASK_LIST_TYPES_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_TYPES_FILTER_CLOSE_MODAL,
});

export const save = () => (dispatch, getState) => {
  const {
    taskListTypesFilter: { dialogTypes },
    taskTypes,
  } = getState();
  if (dialogTypes.filter(g => g.selected).length) {
    dispatch({
      type: types.TASK_LIST_TYPES_FILTER_SAVE,
      typesLength: taskTypes.length,
    });
    dispatch(getTasksFromGraphql());
  } else {
    dispatch(enqueueError("Debe seleccionar algún tipo de tarea"));
  }
};

export const removeTypes = () => ({
  type: types.TASK_LIST_TYPES_FILTER_REMOVE,
});

export const initialize = () => (dispatch, getState) => {
  const { taskTypes } = getState();
  dispatch({
    type: types.TASK_LIST_TYPES_INITIALIZE,
    taskTypes,
  });
};

export const toggle = event => ({
  type: types.TASK_LIST_TYPES_FILTER_TOGGLE,
  types: event.target.name,
  state: event.target.checked,
});

export const toggleAll = event => ({
  type: types.TASK_LIST_TYPES_FILTER_TOGGLE_ALL,
  state: event.target.checked,
});

const initialState = {
  openModal: false,

  dialogTypes: [],
  selectedTypes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_TYPES_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_TYPES_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_TYPES_FILTER_SAVE:
      const selected = state.dialogTypes.filter(g => g.selected).map(g => g.id);
      return {
        ...state,
        openModal: false,
        selectedTypes: selected.length === action.typesLength ? [] : selected,
      };
    case types.TASK_LIST_TYPES_FILTER_TOGGLE:
      return {
        ...state,
        dialogTypes: state.dialogTypes.map(g =>
          g.id === Number(action.types) ? { ...g, selected: action.state } : g
        ),
      };
    case types.TASK_LIST_TYPES_FILTER_TOGGLE_ALL:
      return {
        ...state,
        dialogTypes: state.dialogTypes.map(c => ({
          ...c,
          selected: action.state,
        })),
      };
    case types.TASK_LIST_TYPES_FILTER_REMOVE:
      return {
        ...state,
        selectedTypes: [],
      };
    case types.TASK_LIST_TYPES_INITIALIZE:
      return {
        ...state,
        dialogTypes: action.taskTypes.map(c =>
          state.selectedTypes.length
            ? { ...c, selected: state.selectedTypes.includes(c.id) }
            : { ...c, selected: true }
        ),
      };
    default:
      return state;
  }
};

export default reducer;
