/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";

import {
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";

import Card from "../../card";

const capitalize = (string = "") => {
  return string
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
    .join(" ");
};

export const GroupFormFields = props => {
  const {
    form: {
      watch,
      control,
      register,
      setValue,
      setError,
      getValues,
      clearErrors,
      formState: { errors },
    },
  } = props;

  const users = useSelector(state => state.users);
  const cities = useSelector(state => state.cities);
  const stores = useSelector(state => state.storesList);
  const taskTypes = useSelector(state => state.taskTypes);

  const inspectors = watch("inspectors");

  useEffect(() => {
    // For Autocomplete - According to https://react-hook-form.com/get-started#IntegratingwithUIlibraries
    register("users", { required: true });
    register("cities", { required: true });
    register("stores", { required: true });
    register("taskTypes", { required: true });
    register("inspectors", { required: true });
  }, [register]);

  return (
    <Fragment>
      <Card title="Datos Generales" style={{ padding: 15 }} variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Debes ingresar un nombre para el grupo",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  autoFocus
                  margin="dense"
                  label="Nombre"
                  variant="outlined"
                  error={!!errors.name}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              style={{ width: "100%" }}>
              <InputLabel id="input-requires-materials">
                Trabaja con subcontratistas
              </InputLabel>

              <Controller
                control={control}
                name="has_contractors"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    defaultValue={true}
                    labelId="input-requires-materials"
                    label="Trabaja con contratistas externos">
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Sí</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              style={{ width: "100%" }}>
              <InputLabel id="input-requires-materials">Carga datos de Vereda</InputLabel>

              <Controller
                control={control}
                name="fills_sidewalks"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    defaultValue={true}
                    label="Carga datos de Vereda"
                    labelId="input-requires-materials">
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Sí</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              style={{ width: "100%" }}>
              <InputLabel id="input-requires-materials">Carga datos de Red</InputLabel>

              <Controller
                control={control}
                name="fills_pipeline_information"
                render={({ field }) => (
                  <Select
                    fullWidth
                    {...field}
                    defaultValue={true}
                    label="Carga datos de Red"
                    labelId="input-requires-materials">
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Sí</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              control={control}
              variant="outlined"
              options={taskTypes}
              disableCloseOnSelect
              style={{ width: "100%" }}
              noOptionsText="Sin Resultados"
              value={getValues("taskTypes")}
              getOptionLabel={option => option.name}
              onChange={(_, value) => {
                if (value) {
                  setValue("taskTypes", value);
                  clearErrors("taskTypes");
                } else {
                  setError("taskTypes", {
                    type: "required",
                    message: "Debes indicar el tipo de Tarea.",
                  });
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  margin="dense"
                  variant="outlined"
                  error={!!errors.taskTypes}
                  label="Tipos de Tareas que realiza"
                  helperText={errors.taskTypes?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              clearOnBlur
              options={[]}
              selectOnFocus
              handleHomeEndKeys
              label="Inspectores"
              sx={{ width: "100%" }}
              error={!!errors.inspectors}
              value={inspectors.map(i => ({ title: i }))}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              renderInput={params => <TextField {...params} label="Inspectores" />}
              onChange={(event, newValue) => {
                event.preventDefault();
                event.stopPropagation();
                setValue(
                  "inspectors",
                  newValue.map(v => capitalize(v.inputValue || v.title || v))
                );
              }}
              filterOptions={(options, params) => {
                const filtered = [...options];
                const { inputValue } = params;
                const isExisting = options.some(option => inputValue === option.title);
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Card title="Localidades" style={{ padding: 15, marginTop: 20 }} variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={cities}
              control={control}
              variant="outlined"
              disableCloseOnSelect
              style={{ width: "100%" }}
              value={getValues("cities")}
              noOptionsText="Sin Resultados"
              getOptionLabel={option => option.name}
              onChange={(_, value) => {
                if (value) {
                  setValue("cities", value);
                  clearErrors("cities");
                } else {
                  setError("cities", {
                    type: "required",
                    message: "Debes indicar al menos una localidad.",
                  });
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  margin="dense"
                  variant="outlined"
                  label="Localidades"
                  error={errors.cities}
                  helperText={errors.cities?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Card>

      <Card title="Depósitos" style={{ padding: 15, marginTop: 20 }} variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={stores}
              control={control}
              variant="outlined"
              disableCloseOnSelect
              style={{ width: "100%" }}
              value={getValues("stores")}
              noOptionsText="Sin Resultados"
              getOptionLabel={option => `${option.name} - ${option.type.name}`}
              onChange={(_, value) => {
                if (value) {
                  setValue("stores", value);
                  clearErrors("stores");
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  margin="dense"
                  variant="outlined"
                  label="Depósitos"
                  error={errors.stores}
                  helperText={errors.stores?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Card>

      <Card
        title="Usuarios con acceso"
        style={{ padding: 15, marginTop: 20 }}
        variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={users}
              control={control}
              variant="outlined"
              disableCloseOnSelect
              style={{ width: "100%" }}
              noOptionsText="Sin Resultados"
              value={getValues("users")}
              getOptionLabel={option => option.name}
              onChange={(_, value) => {
                if (value) {
                  setValue("users", value);
                  clearErrors("users");
                } else {
                  setError("users", {
                    type: "required",
                    message: "Debes indicar al menos un usuario.",
                  });
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  margin="dense"
                  label="Usuarios"
                  variant="outlined"
                  error={errors.users}
                  helperText={errors.users?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
};

export default GroupFormFields;
