import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import Preloader from "../../../components/UI/Preloader";

const preloader = () => {
  return (
    <TableRow style={{ height: 48 * 10 }}>
      <TableCell colSpan={6}>
        <Preloader />
      </TableCell>
    </TableRow>
  );
};

export default preloader;
