/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import ApiError from "../components/UI/ApiError";
import NewGroup from "./newGroupContainer/newGroup";

import { getUsers } from "../store/ducks/users";
import { getAllCities } from "../store/ducks/cities";
import { getStores } from "../store/ducks/storesList";
import { getTaskTypes } from "../store/ducks/taskTypes";
import { getEditGroup } from "../store/ducks/editGroupModal";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

const EditGroupContainer = props => {
  const params = useParams();
  useEffect(() => {
    Promise.all([
      props.getUsers(),
      props.getStores(),
      props.getCities(),
      props.getTaskTypes(),
    ]).then(() => props.getGroup(params.id));
  }, [params]);

  const { error } = props;
  return error ? (
    <ApiError error="Ocurrió un error la información requerida" />
  ) : (
    <NewGroup {...props} />
  );
};

const actionNames = ["CITIES", "STORES_LIST", "TASK_TYPES", "EDIT_GROUP", "USERS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  user: state.user,
  users: state.users,
  cities: state.cities,
  stores: state.storesList,
  taskTypes: state.taskTypes,
  error: errorSelector(state),
  group: state.editGroupModal.group,
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(getUsers()),
  getStores: () => dispatch(getStores()),
  getCities: () => dispatch(getAllCities()),
  getGroup: id => dispatch(getEditGroup(id)),
  getTaskTypes: () => dispatch(getTaskTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupContainer);
