import ImageGallery from "react-image-gallery";
import { Card, CardContent } from "@mui/material";
import "react-image-gallery/styles/css/image-gallery.css";
import "./styles.css";

export default ({ task }) =>
  task.fotos && task.fotos.length ? (
    <Card style={{ marginTop: 10 }} variant="outlined">
      <CardContent>
        <ImageGallery
          lazyLoad
          showNav={true}
          showBullets={true}
          showThumbnails={false}
          showPlayButton={false}
          style={{ borderRadius: 5 }}
          items={task.fotos.map(image => ({
            original: `${process.env.REACT_APP_IMAGES_URI}/${image.path}`,
          }))}
        />
      </CardContent>
    </Card>
  ) : null;
