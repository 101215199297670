import axios, { gangs as gangsAxios } from "../../axios";

import { parseFromApi } from "../../utils/TaskParser";

const types = {
  TASK_REQUEST: "TASK_REQUEST",
  TASK_SUCCESS: "TASK_SUCCESS",
  TASK_FAILURE: "TASK_FAILURE",
  TASK_UPDATE_CONSUMPTION_AMOUNT: "TASK_UPDATE_CONSUMPTION_AMOUNT",
  TASK_UPDATE_CONSUMPTION_STATE: "TASK_UPDATE_CONSUMPTION_STATE",
  TASK_ADD_CONSUMPTION: "TASK_ADD_CONSUMPTION",
};

const requestTask = () => ({
  type: types.TASK_REQUEST,
});

const successTask = task => ({
  type: types.TASK_SUCCESS,
  task,
});

const failedTask = () => ({
  type: types.TASK_FAILURE,
  message: "Ha ocurrido un error al intentar obtener la tarea",
});

export const updateConsumptionAmount = (consumption, amount) => ({
  type: types.TASK_UPDATE_CONSUMPTION_AMOUNT,
  amount,
  consumption,
});

export const updateConsumptionState = (consumption, state) => ({
  type: types.TASK_UPDATE_CONSUMPTION_STATE,
  consumption,
  state,
});

export const addArticle = article => ({
  type: types.TASK_ADD_CONSUMPTION,
  article,
});

const parseGroupGangs = gangs =>
  gangs.map(gang => ({ id: gang.id, name: gang.cuadrilla }));

export const getTask = id => {
  return async dispatch => {
    dispatch(requestTask());
    try {
      const [taskResponse, gangsResponse] = await Promise.all([
        // request para datos de la tarea
        axios.get(`/tasks/${id}`),
        // request para datos del grupo
        gangsAxios.get("/"),
      ]);

      const { data: gangs } = gangsResponse;
      const task = parseFromApi(taskResponse.data.data, gangs);

      // request para datos del grupo de la cuadrilla
      const promises = [axios.get(`/groups/${task.group.id}/gangs`)];

      let parentTask = null;
      if (task.parent_id) promises.push(axios.get(`/tasks/${task.parent_id}`));

      const responses = await Promise.all(promises);

      const { data: groupGangs } = responses[0];
      if (responses[1]) {
        parentTask = responses[1].data.data;
      }

      if (task.state !== 5 && !(task.sidewalks && task.sidewalks.length)) {
        dispatch(failedTask());
      } else {
        dispatch(
          successTask({
            ...task,
            parentTask,
            groupGangs: parseGroupGangs(groupGangs.data),
            gangs,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(failedTask());
    }
  };
};

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_SUCCESS:
      return action.task;
    case types.TASK_UPDATE_CONSUMPTION_AMOUNT:
      return {
        ...state,
        articles: state.articles.map(a =>
          a.consumption === action.consumption ? { ...a, amount: action.amount } : a
        ),
      };
    case types.TASK_UPDATE_CONSUMPTION_STATE:
      return {
        ...state,
        articles: state.articles.map(a =>
          a.consumption === action.consumption ? { ...a, state: action.state } : a
        ),
      };
    case types.TASK_ADD_CONSUMPTION:
      return {
        ...state,
        articles: [...state.articles, action.article],
      };
    default:
      return state;
  }
};

export const canConfirm = state => {
  const hasPendingArticles =
    state.task.articles.filter(a => a.state.id === 0).length !== 0;
  const hasArticles = state.task.validatesArticles ? !!state.task.articles.length : true;
  return !hasPendingArticles && hasArticles;
};

export default reducer;
