/* eslint-disable no-case-declarations */
import { enqueueError } from "./notifications";
import { getTasksFromGraphql } from "./tasksList";

const options = [
  { id: "null", name: "Sin especificar", selected: true },
  { id: "false", name: "Fuerza propia", selected: true },
  { id: "true", name: "Subcontratista", selected: true },
];

const types = {
  TASK_LIST_CONTRACTOR_FILTER_OPEN_MODAL: "TASK_LIST_CONTRACTOR_FILTER_OPEN_MODAL",
  TASK_LIST_CONTRACTOR_FILTER_CLOSE_MODAL: "TASK_LIST_CONTRACTOR_FILTER_CLOSE_MODAL",
  TASK_LIST_CONTRACTOR_FILTER_SAVE: "TASK_LIST_CONTRACTOR_FILTER_SAVE",
  TASK_LIST_CONTRACTOR_FILTER_TOGGLE: "TASK_LIST_CONTRACTOR_FILTER_TOGGLE",
  TASK_LIST_CONTRACTOR_FILTER_TOGGLE_ALL: "TASK_LIST_CONTRACTOR_FILTER_TOGGLE_ALL",
  TASK_LIST_CONTRACTOR_FILTER_REMOVE: "TASK_LIST_CONTRACTOR_FILTER_REMOVE",
  TASK_LIST_CONTRACTOR_INITIALIZE: "TASK_LIST_CONTRACTOR_INITIALIZE",
};

export const openModal = () => ({
  type: types.TASK_LIST_CONTRACTOR_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_CONTRACTOR_FILTER_CLOSE_MODAL,
});

export const save = () => (dispatch, getState) => {
  const {
    taskListContractorFilter: { options },
  } = getState();
  if (options.filter(g => g.selected).length) {
    dispatch({
      type: types.TASK_LIST_CONTRACTOR_FILTER_SAVE,
    });
    dispatch(getTasksFromGraphql());
  } else {
    dispatch(enqueueError("Debe seleccionar alguna opción"));
  }
};

export const removeOptions = () => ({
  type: types.TASK_LIST_CONTRACTOR_FILTER_REMOVE,
});

export const initialize = () => dispatch => {
  dispatch({
    type: types.TASK_LIST_CONTRACTOR_INITIALIZE,
    options,
  });
};

export const toggle = event => ({
  type: types.TASK_LIST_CONTRACTOR_FILTER_TOGGLE,
  option: event.target.name,
  state: event.target.checked,
});

export const toggleAll = event => ({
  type: types.TASK_LIST_CONTRACTOR_FILTER_TOGGLE_ALL,
  state: event.target.checked,
});

const initialState = {
  selectedContractors: [],
  openModal: false,
  options: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_CONTRACTOR_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };

    case types.TASK_LIST_CONTRACTOR_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };

    case types.TASK_LIST_CONTRACTOR_FILTER_SAVE:
      const selected = state.options.filter(g => g.selected).map(g => g.id);
      return {
        ...state,
        openModal: false,
        selectedContractors: selected.length === options.length ? [] : selected,
      };

    case types.TASK_LIST_CONTRACTOR_FILTER_TOGGLE:
      return {
        ...state,
        options: state.options.map(g =>
          g.id === action.option ? { ...g, selected: action.state } : g
        ),
      };

    case types.TASK_LIST_CONTRACTOR_FILTER_TOGGLE_ALL:
      return {
        ...state,
        options: state.options.map(g => ({
          ...g,
          selected: action.state,
        })),
      };

    case types.TASK_LIST_CONTRACTOR_FILTER_REMOVE:
      return {
        ...state,
        selectedContractors: [],
      };

    case types.TASK_LIST_CONTRACTOR_INITIALIZE:
      return {
        ...state,
        options: options.map(g =>
          state.selectedContractors.length
            ? { ...g, selected: state.selectedContractors.includes(g.id) }
            : { ...g, selected: true }
        ),
      };

    default:
      return state;
  }
};

export default reducer;
