const types = {
  task_date: "TASK_DATE",
  creation: "CREATED_AT",
  state_change: "UPDATED_AT",
};

export const getTaskOrderCriteria = (option = "creation", descOrder) => [
  {
    field: types[option],
    order: descOrder ? "DESC" : "ASC",
  },
];
