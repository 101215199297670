import axios from "../../axios";

import { parseFromApi } from "../../utils/StatementsParser";

const types = {
  STATEMENTS_REQUEST: "STATEMENTS_REQUEST",
  STATEMENTS_SUCCESS: "STATEMENTS_SUCCESS",
  STATEMENTS_FAILURE: "STATEMENTS_FAILURE",
};

const request = () => ({
  type: types.STATEMENTS_REQUEST,
});

const success = statements => ({
  type: types.STATEMENTS_SUCCESS,
  statements,
});

const failure = () => ({
  type: types.STATEMENTS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las declaraciones juradas",
});

export const getStatements = () => {
  return async dispatch => {
    dispatch(request());
    try {
      const { data } = await axios.get("/gangs/statements");
      dispatch(success(parseFromApi(data.data)));
    } catch (error) {
      dispatch(failure());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.STATEMENTS_SUCCESS:
      return action.statements;
    default:
      return state;
  }
};

export default reducer;
