import { Component } from "react";
import DownloadAbsaDialog from "./downloadAbsaReportDialogContainer";

import { connect } from "react-redux";
import { closeModal, handleDownload } from "../../../../store/ducks/taskListDownloadAbsa";

class DownloadAbsaContainer extends Component {
  handleSubmit = () => {
    this.props.handleDownload();
  };

  handleCancel = () => {
    this.props.closeModal();
  };

  render() {
    return <DownloadAbsaDialog {...this.props} handleCancel={this.handleCancel} />;
  }
}

const mapStateToProps = state => ({
  tasks: state.tasksList.tasks,
  paginator: state.tasksList.paginator,
  openModal: state.taskListDownloadAbsa.openModal,
  isLoading: state.taskListDownloadAbsa.isLoading,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  handleDownload: () => dispatch(handleDownload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAbsaContainer);
