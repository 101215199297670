import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const Page = ({ title, children }) => {
  const params = useParams();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);

      if (params.id) {
        document.title = `Tareas | ${(title || "").replace(
          ":id",
          params.id
        )} | Gral Saneamiento`;
      } else {
        document.title = `Tareas | ${title || ""} | Gral Saneamiento`;
      }
    }, 100);
  }, [title, params.id]);

  return children;
};
