import { connect } from "react-redux";

import { removeHistoric } from "../../../../../store/ducks/taskListHistoricFilter";
import Historic from "./historicContainer/Historic";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  showHistoric: state.taskListHistoricFilter.showHistoric,
});

const mapDispatchToProps = dispatch => ({
  removeHistoric: () => {
    dispatch(removeHistoric());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Historic);
