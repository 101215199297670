import apollo from "../../apollo";
import {
  getStateDateFilters,
  getTasksFilters,
} from "../../graphql/tasksDownload/filters";
import { GET_TASKS_IDS } from "../../graphql/tasks/queries";
import { checkAll } from "./selectedTasks";

const parseVariables = object => {
  Object.keys(object).forEach(k => (object[`$${k}`] = object[k]));
  return object;
};

const types = {
  DOWNLOAD_TASKS_PAGINATION_SET_TOTAL: "DOWNLOAD_TASKS_PAGINATION_SET_TOTAL",
  DOWNLOAD_TASKS_PAGINATION_LOADING: "DOWNLOAD_TASKS_PAGINATION_LOADING",
  DOWNLOAD_TASKS_PENDING_PAGES: "DOWNLOAD_TASKS_PENDING_PAGES",
  DOWNLOAD_TASKS_SELECTED_ALL: "DOWNLOAD_TASKS_SELECTED_ALL",
};

export const setTotal = total => ({
  type: types.DOWNLOAD_TASKS_PAGINATION_SET_TOTAL,
  total,
});

export const setLoading = loading => ({
  type: types.DOWNLOAD_TASKS_PAGINATION_LOADING,
  loading,
});

export const setPendingPages = pendingPages => ({
  type: types.DOWNLOAD_TASKS_PENDING_PAGES,
  pendingPages,
});

export const checkAllTasks = tasksIds => {
  return async (dispatch, getState) => {
    const state = getState();

    if (!state.downloadTasksPagination.pendingPages) return dispatch(checkAll(tasksIds));

    try {
      const where = getTasksFilters(state);
      const hasTareaAccion = getStateDateFilters(state);
      const { data } = await apollo.query({
        query: GET_TASKS_IDS,
        variables: parseVariables({ where, hasTareaAccion }),
      });
      const { tasksFull } = data;
      dispatch(checkAll(tasksFull.map(t => t.id)));
    } catch (error) {
      if (
        error.message &&
        error.message ===
          "Store reset while query was in flight (not completed in link chain)"
      ) {
        return false;
      }
    }
  };
};

const initialState = {
  total: 0,
  loading: false,
  selectedAll: false,
  pendingPages: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DOWNLOAD_TASKS_PAGINATION_SET_TOTAL:
      return { ...state, total: action.total };

    case types.DOWNLOAD_TASKS_PAGINATION_LOADING:
      return { ...state, loading: action.loading };

    case types.DOWNLOAD_TASKS_PENDING_PAGES:
      return { ...state, pendingPages: action.pendingPages };

    case types.DOWNLOAD_TASKS_SELECTED_ALL:
      return { ...state, selectedAll: action.selectedAll };
    default:
      return state;
  }
};

export default reducer;
