import Geocode from "react-geocode";
const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

// https://www.npmjs.com/package/react-geocode
Geocode.setApiKey(REACT_APP_GOOGLE_MAPS_API_KEY);

Geocode.setLanguage("es-419");
// es-419 = Spanish Latin america #https://developers.google.com/maps/faq#languagesupport

Geocode.setRegion("ar");

const getBetween = (between, and) => {
  if (!between && !and) return "";
  if (!and) return `esquina ${between}`;
  if (!between) return `esquina ${and}`;
  return "";

  // return `entre ${between} y ${and}`;
};

const parseStreet = street => (street.match(/^\d/) ? `Calle ${street}` : street);

export const formatAddress = (street, number, locality, between, and) => {
  street = parseStreet(street.trim());

  if (!between) return `${street} ${number}, ${locality.trim()}, Buenos Aires, Argentina`;

  const betweenStreets = getBetween(between, and);

  return `${street} ${number} ${betweenStreets}, ${locality.trim()}, Buenos Aires, Argentina`;
};

export const calculateGeoPoint = async fields => {
  const { calle, altura, localidad, entre, y } = fields;
  if (!calle || !localidad || (!entre && !altura)) return;
  const address = formatAddress(calle, altura, localidad.name.split("(")[0], entre, y);
  const { results } = await Geocode.fromAddress(address);

  const street = results.find(r => r.types.includes("street_address"));

  if (street) return street.geometry.location;
  if (results.length === 1) return results[0].geometry.location;

  const { results: localityResults } = await Geocode.fromAddress(
    `${localidad.name.split("(")[0]}, Buenos Aires, Argentina`
  );

  return localityResults.length ? localityResults[0].geometry.location : null;
};
