import { connect } from "react-redux";

import ConfirmValidationModal from "./ConfirmValidation/ConfirmValidationModal";

import {
  confirmSidewalk,
  closeConfirmationModal,
} from "../../../store/ducks/finishSidewalkValidationModal";

const mapStateToProps = state => ({
  task: state.task,
  sending: state.finishSidewalkValidationModal.sending,
  sidewalk: state.finishSidewalkValidationModal.sidewalk,
  open: state.finishSidewalkValidationModal.confirmationModalOpen,
});

const mapDispatchToProps = dispatch => ({
  confirm: () => dispatch(confirmSidewalk()),
  onClose: () => dispatch(closeConfirmationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmValidationModal);
