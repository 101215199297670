const types = {
  TASK_LIST_SIDEWALK_FILTER_OPEN_MODAL: "TASK_LIST_SIDEWALK_FILTER_OPEN_MODAL",
  TASK_LIST_SIDEWALK_FILTER_CLOSE_MODAL: "TASK_LIST_SIDEWALK_FILTER_CLOSE_MODAL",
  TASK_LIST_SIDEWALK_FILTER_SAVE: "TASK_LIST_SIDEWALK_FILTER_SAVE",
  TASK_LIST_SIDEWALK_FILTER_REMOVE: "TASK_LIST_SIDEWALK_FILTER_REMOVE",
};

export const openModal = () => ({
  type: types.TASK_LIST_SIDEWALK_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_SIDEWALK_FILTER_CLOSE_MODAL,
});

export const save = state => ({
  type: types.TASK_LIST_SIDEWALK_FILTER_SAVE,
  state,
});

export const removeSidewalk = () => ({
  type: types.TASK_LIST_SIDEWALK_FILTER_REMOVE,
});

const initialState = {
  openModal: false,
  sidewalkType: null,
  sidewalkUnit: null,
  withSidewalk: null,
  sidewalkState: null,
  sidewalkMinAmount: null,
  sidewalkMaxAmount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_SIDEWALK_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_SIDEWALK_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_SIDEWALK_FILTER_SAVE:
      return { ...state, openModal: false, ...action.state };
    case types.TASK_LIST_SIDEWALK_FILTER_REMOVE:
      return {
        ...state,
        sidewalkType: null,
        sidewalkUnit: null,
        withSidewalk: null,
        sidewalkState: null,
        sidewalkMinAmount: null,
        sidewalkMaxAmount: null,
      };
    default:
      return state;
  }
};

export default reducer;
