/* eslint-disable no-case-declarations */
import { enqueueError } from "./notifications";
import { getTasksFromGraphql } from "./tasksList";

const types = {
  TASK_LIST_GROUP_FILTER_OPEN_MODAL: "TASK_LIST_GROUP_FILTER_OPEN_MODAL",
  TASK_LIST_GROUP_FILTER_CLOSE_MODAL: "TASK_LIST_GROUP_FILTER_CLOSE_MODAL",
  TASK_LIST_GROUP_FILTER_SAVE: "TASK_LIST_GROUP_FILTER_SAVE",
  TASK_LIST_GROUP_FILTER_TOGGLE: "TASK_LIST_GROUP_FILTER_TOGGLE",
  TASK_LIST_GROUP_FILTER_TOGGLE_ALL: "TASK_LIST_GROUP_FILTER_TOGGLE_ALL",
  TASK_LIST_GROUP_FILTER_REMOVE: "TASK_LIST_GROUP_FILTER_REMOVE",
  TASK_LIST_GROUP_INITIALIZE: "TASK_LIST_GROUP_INITIALIZE",
};

export const openModal = () => ({
  type: types.TASK_LIST_GROUP_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_GROUP_FILTER_CLOSE_MODAL,
});

export const save = () => (dispatch, getState) => {
  const {
    taskListGroupFilter: { dialogGroups },
    groups: { groups },
  } = getState();
  if (dialogGroups.filter(g => g.selected).length) {
    dispatch({
      type: types.TASK_LIST_GROUP_FILTER_SAVE,
      groupsLength: groups.length,
    });
    dispatch(getTasksFromGraphql());
  } else {
    dispatch(enqueueError("Debe seleccionar algún grupo"));
  }
};

export const removeGroups = () => ({
  type: types.TASK_LIST_GROUP_FILTER_REMOVE,
});

export const initialize = () => (dispatch, getState) => {
  const groups = getState().groups.groups;
  dispatch({
    type: types.TASK_LIST_GROUP_INITIALIZE,
    groups,
  });
};

export const toggle = event => ({
  type: types.TASK_LIST_GROUP_FILTER_TOGGLE,
  group: event.target.name,
  state: event.target.checked,
});

export const toggleAll = event => ({
  type: types.TASK_LIST_GROUP_FILTER_TOGGLE_ALL,
  state: event.target.checked,
});

const initialState = {
  openModal: false,
  selectedGroups: [],
  dialogGroups: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_GROUP_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_GROUP_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_GROUP_FILTER_SAVE:
      const selected = state.dialogGroups.filter(g => g.selected).map(g => g.id);
      return {
        ...state,
        openModal: false,
        selectedGroups: selected.length === action.groupsLength ? [] : selected,
      };
    case types.TASK_LIST_GROUP_FILTER_TOGGLE:
      return {
        ...state,
        dialogGroups: state.dialogGroups.map(g =>
          g.id === Number(action.group) ? { ...g, selected: action.state } : g
        ),
      };
    case types.TASK_LIST_GROUP_FILTER_TOGGLE_ALL:
      return {
        ...state,
        dialogGroups: state.dialogGroups.map(g => ({
          ...g,
          selected: action.state,
        })),
      };
    case types.TASK_LIST_GROUP_FILTER_REMOVE:
      return {
        ...state,
        selectedGroups: [],
      };
    case types.TASK_LIST_GROUP_INITIALIZE:
      return {
        ...state,
        dialogGroups: action.groups
          .map(g =>
            state.selectedGroups.length
              ? { ...g, selected: state.selectedGroups.includes(g.id) }
              : { ...g, selected: true }
          )
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    default:
      return state;
  }
};

export default reducer;
