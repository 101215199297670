import format from "date-fns/format";

export default ({ step1 = {}, step2 = {}, step3 = {} }, task) => {
  const { tipo, croquis, valida_materiales, requires_cleaning, ...other1 } = step1;
  const { localidad, position, custom_geo, contact_phone, aclaracion, ...other2 } = step2;
  const { grupo, stores, requires_pipeline_information, for_contractor, ...other3 } =
    step3;

  const data = {
    ...other1,
    ...other2,
    ...other3,

    latitud: position.lat,
    longitud: position.lng,
    localidad_id: localidad.id,
    aclaracion: aclaracion || "",
    custom_geo: custom_geo ? 1 : 0,
    contact_phone: contact_phone || "",
    requires_cleaning: requires_cleaning ? 1 : 0,
    valida_materiales: valida_materiales ? "on" : 0,
    ingreso_ot: format(new Date(step1.ingreso_ot), "yyyy-MM-dd"),
    fecha_asignada: format(new Date(step1.fecha_asignada), "yyyy-MM-dd"),
    requires_pipeline_information: requires_pipeline_information ? "1" : 0,
  };

  if (tipo) data.tipo_id = tipo.id;
  if (grupo) data.grupo_id = grupo.id;

  if (stores && stores.length) {
    data.stores = JSON.stringify(stores.map(s => s._id));
  } else {
    data.stores = null;
  }

  if (typeof for_contractor === "boolean") {
    data.for_contractor = for_contractor ? 1 : 0;
  } else {
    data.for_contractor = null;
  }

  const formData = new FormData();

  Object.keys(data).forEach(k => {
    if (data[k] !== undefined && data[k] !== null) formData.append(k, data[k]);
  });

  const removed_croquis = (task.croquis || [])
    .filter(c => !croquis.includes(c.path))
    .map(c => c.id);

  if (removed_croquis.length) {
    removed_croquis.forEach(id => {
      formData.append("removed_croquis[]", id);
    });
  }

  if (croquis && croquis.filter(c => c instanceof File).length) {
    croquis
      .filter(c => c instanceof File)
      .forEach(c => {
        formData.append("croquis[]", c);
      });
  }

  return formData;
};
