/* eslint-disable no-case-declarations */
import axios from "../../axios";
import { orderBy } from "lodash";
import escapeStringRegexp from "escape-string-regexp";
import { removeAccents } from "../../utils/removeAccents";

const types = {
  GROUPS_REQUEST: "GROUPS_REQUEST",
  GROUPS_SUCCESS: "GROUPS_SUCCESS",
  GROUPS_FAILURE: "GROUPS_FAILURE",
  GROUPS_UPDATE_GROUP: "GROUPS_UPDATE_GROUP",
  GROUPS_CHANGE_SEARCH: "GROUPS_CHANGE_SEARCH",
  GROUPS_TOGGLE_SELECTED: "GROUPS_FAILURE_TOGGLE_SELECTED",
};

const requestGroups = () => ({
  type: types.GROUPS_REQUEST,
});

const successGroups = groups => ({
  type: types.GROUPS_SUCCESS,
  groups,
});

const failedGroups = () => ({
  type: types.GROUPS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener los grupos",
});

export const toggleGroup = id => ({
  type: types.GROUPS_TOGGLE_SELECTED,
  id,
});

export const changeSearchGroups = search => ({
  type: types.GROUPS_CHANGE_SEARCH,
  search,
});

export const updateGroup = group => ({
  type: types.GROUPS_UPDATE_GROUP,
  group,
});

export const getGroups = (localidad, all = false) => {
  return async dispatch => {
    dispatch(requestGroups());
    try {
      const params = { localidad };
      if (all) params.all = true;

      const { data } = await axios.get("/groups", { params });
      const groups = data.data
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .map(group => ({
          ...group,
          id: group.id,
          selected: true,
          mode: group.modo,
          name: group.nombre,
          stores: group.stores,
          hasGangs: group.hasGangs,
          taskTypes: group.taskTypes,
          inspectors: JSON.parse(group.inspectors),
          has_contractors: !!group.has_contractors,
          fills_pipeline_information: !!group.fills_pipeline_information,
        }));
      dispatch(successGroups(orderBy(groups, "name")));
    } catch (error) {
      dispatch(failedGroups());
    }
  };
};

const initialState = { search: "", groups: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROUPS_REQUEST:
      return { ...state, isLoading: true };

    case types.GROUPS_FAILURE:
      return { ...state, isLoading: false };

    case types.GROUPS_SUCCESS:
      return { ...state, groups: action.groups, isLoading: false };

    case types.GROUPS_TOGGLE_SELECTED:
      return {
        ...state,
        groups: state.groups.map(g =>
          g.id === action.id ? { ...g, selected: !g.selected } : g
        ),
      };

    case types.GROUPS_CHANGE_SEARCH:
      return { ...state, search: action.search };

    case types.GROUPS_UPDATE_GROUP:
      const groups = [...state.groups];
      const index = groups.findIndex(({ id }) => Number(id) === action.group.id);
      groups[index] = {
        selected: true,
        id: action.group.id,
        mode: action.group.modo,
        name: action.group.nombre,
        inspectors: action.group.inspectors,
      };
      return { ...state, groups };

    default:
      return state;
  }
};

export default reducer;

export const getFilteredGroups = state => {
  const regex = new RegExp(
    `.*${escapeStringRegexp(removeAccents(state.groups.search))}.*`,
    "i"
  );
  return state.groups.groups.filter(
    ({ inspectors, name }) =>
      removeAccents(name).match(regex) ||
      (inspectors &&
        inspectors.some(({ inspector }) => removeAccents(inspector).match(regex)))
  );
};
