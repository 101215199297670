/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

const styles = () => ({
  filter: {
    fontSize: 14,
    color: "#666",
    marginBottom: 7,
  },
  filterText: {
    "&:hover": {
      color: "#262626",
    },
    cursor: "pointer",
  },
});

const filterList = props => {
  const { classes } = props;
  return (
    <>
      <Typography
        variant="h6"
        style={{ fontWeight: 350, marginTop: 0, color: "#333" }}
        gutterBottom>
        Filtros
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openTaskModal}>
          Tarea
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openTypeModal}>
          Tipo
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openAddressModal}>
          Dirección
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openStatesModal}>
          Estados
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openTaskConditionsModal}>
          Condiciones
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openGroupsModal}>
          Grupos
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openContractorModal}>
          Distribución
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openGangsModal}>
          Cuadrillas
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openCitiesModal}>
          Localidades
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openDatesModal}>
          Fechas
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openSidewalkModal}>
          Vereda
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openHidrojetModal}>
          Módulos de hidrojet
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openStateChangesModal}>
          Cambios de estado
        </span>
      </Typography>
      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.openHistoryChangesModal}>
          Datos históricos
        </span>
      </Typography>

      <Typography className={classes.filter}>
        <span className={classes.filterText} onClick={props.showHistoric}>
          Ver archivadas
        </span>
      </Typography>
    </>
  );
};

export default withStyles(styles)(filterList);
