import Step1 from "../components/step1";
import Step2 from "../components/step2";
import Step3 from "../components/step3";

import Card from "./card";

export default props => (
  <>
    <Card title="Datos de orden de trabajo">
      <Step1 {...props} />
    </Card>

    <Card title="Datos de domicilio">
      <Step2 {...props} />
    </Card>

    <Card
      title={
        props.task.estado_id === 1 ? "Datos de grupo y depósitos" : "Datos de depósitos"
      }>
      <Step3 showGroups={props.task.estado_id === 1} {...props} />
    </Card>
  </>
);
