import { memo, useCallback, useMemo } from "react";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import { useNavigate } from "react-router-dom";

const styles = theme => ({
  clickable: {
    margin: 0,
  },
  button: {
    margin: theme.spacing(0.5),
  },
  centered: {
    textAlign: "center",
  },
  container: {
    margin: theme.spacing(1),
  },
});

const Content = memo(props => {
  const navigate = useNavigate();
  const { classes, rowsPerPage, page, setTaskListFilters } = props;

  const onChange = useCallback(
    (id, state) => {
      setTaskListFilters(id, state);
      navigate("/");
    },
    [setTaskListFilters, navigate]
  );

  const data = useMemo(
    () => (props.data || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [props.data, page, rowsPerPage]
  );

  if (!props.data.length) {
    return (
      <TableRow>
        <TableCell colSpan={6} className={classes.centered}>
          <div className={classes.container}>El grupo no tiene cuadrillas asignadas.</div>
        </TableCell>
      </TableRow>
    );
  }

  return data.map(n => {
    return (
      <TableRow key={n.id}>
        <TableCell>
          {n.observations ? (
            <Tooltip title={n.observations || ""}>
              <IconButton
                size="large"
                aria-label="Observation"
                className={props.classes.button}>
                <AnnouncementIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : null}
          {n.cuadrilla}
        </TableCell>

        <TableCell>
          <p className={classes.clickable}>
            <Tooltip title="Ver tareas en ejecución" aria-label="tareas-en-ejecucion">
              <Button style={{ color: "gray" }} onClick={() => onChange(n.id, 4)}>
                {n.tareasEnEjecucionCount}
              </Button>
            </Tooltip>
          </p>
        </TableCell>

        <TableCell>
          <p className={classes.clickable}>
            <Tooltip title="Ver tareas programadas" aria-label="tareas-programadas">
              <Button
                size="small"
                style={{ color: "gray" }}
                onClick={() => onChange(n.id, 2)}>
                {n.tareasProgramadasCount}
              </Button>
            </Tooltip>
          </p>
        </TableCell>

        <TableCell>
          <p className={classes.clickable}>
            <Tooltip title="Ver tareas asignadas" aria-label="tareas-asignadas">
              <Button
                size="small"
                style={{ color: "gray" }}
                onClick={() => onChange(n.id, 11)}>
                {n.tareasAsignadasCount}
              </Button>
            </Tooltip>
          </p>
        </TableCell>

        <TableCell>
          <p className={classes.clickable}>
            <Tooltip title="Ver tareas pausadas" aria-label="tareas-pausadas">
              <Button
                size="small"
                style={{ color: "gray" }}
                onClick={() => onChange(n.id, 3)}>
                {n.tareasPausadasCount}
              </Button>
            </Tooltip>
          </p>
        </TableCell>

        <TableCell padding="none" align="right">
          {props.canTransfer ? (
            <Tooltip title="Transferir cuadrilla">
              <IconButton
                size="large"
                aria-label="Transfer"
                className={props.classes.button}
                onClick={() => props.openTransferGangModal(n)}>
                <TransferWithinAStationIcon />
              </IconButton>
            </Tooltip>
          ) : null}

          {props.canUnassign ? (
            <Tooltip title="Sacar del grupo">
              <IconButton
                onClick={() => props.openUnassignGroupModal(n)}
                className={props.classes.button}
                aria-label="Delete"
                size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
      </TableRow>
    );
  });
});

export default withStyles(styles)(Content);
