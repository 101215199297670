import { connect } from "react-redux";

import { resetCenter } from "../../../store/ducks/map";
import CenterButton from "./centerButtonContainer/CenterButton";

const mapDispatchToProps = dispatch => ({
  resetCenter: () => dispatch(resetCenter()),
});

export default connect(null, mapDispatchToProps)(CenterButton);
