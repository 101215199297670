/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch } from "react-redux";
import {
  confirmEdit,
  setDefaultsValues,
} from "../../../../store/ducks/taskSidewalkEditModal";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { orderBy } from "lodash";

const styles = theme => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

const requiresFillingOptions = [
  { value: 0, name: "No" },
  { value: 1, name: "Si" },
];

const allowedUnits = {
  1: [1, 2, 3],
  2: [1, 2, 3],
  3: [1, 2, 3],
  4: [1, 2, 3],
  5: [1, 2, 3],
};

const EditModal = props => {
  const dispatch = useDispatch();

  const [unit, setUnit] = useState(props.unit);
  const [type, setType] = useState(props.type);
  const [article, setArticle] = useState();
  const [amount, setAmount] = useState(props.amount);
  const [observations, setObservations] = useState(props.observations);
  const [requiresFilling, setRequiresFilling] = useState(props.requires_filling);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isValid = amount && unit && type;

  const onSubmit = () => {
    dispatch(
      confirmEdit(
        amount,
        unit,
        type,
        observations,
        requiresFilling,
        article.id,
        article.name
      )
    );
    props.onClose();
  };

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isValid) {
          onSubmit();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isValid, amount, unit, type, requiresFilling, article]);

  useEffect(() => {
    if (props.open) {
      if (props.edited) {
        const article = props.sidewalkTiles.find(a => a.id_articulo === props.article_id);
        setType(props.type);
        setUnit(props.unit);

        setAmount(props.amount);
        setObservations(props.observations);
        setRequiresFilling(props.requires_filling);
        setArticle(
          article ? { id: article.id_articulo, name: article.descripcion } : null
        );
      } else {
        const sidewalk = props.sidewalk;
        const article = props.sidewalkTiles.find(
          a => a.id_articulo === sidewalk.article_id
        );
        setType(sidewalk.type);
        setUnit(sidewalk.unit);
        setAmount(sidewalk.amount);
        setObservations(sidewalk.observations);
        setRequiresFilling(sidewalk.requires_filling);
        setArticle(
          article ? { id: article.id_articulo, name: article.descripcion } : null
        );

        dispatch(
          setDefaultsValues(
            sidewalk.amount,
            sidewalk.unit,
            sidewalk.type,
            sidewalk.observations,
            sidewalk.requires_filling,
            sidewalk.article_id,
            sidewalk.article_description
          )
        );
      }
    }
  }, [props.task, props.open, props.edited, dispatch]);

  const tilesOptions = useMemo(() => {
    let data = [];
    if (type && type.id === 3) {
      data = props.sidewalkTiles.filter(
        a => !!a.categorias.find(c => c.id_categoria === 6)
      );
    }
    if (type && type.id === 4) {
      data = props.sidewalkTiles.filter(
        a => !!a.categorias.find(c => c.id_categoria === 7)
      );
    }
    return orderBy(
      data.map(a => ({ id: a.id_articulo, name: a.descripcion })),
      "name"
    );
  }, [type, props.sidewalkTiles]);

  const requiresFillingValue = requiresFillingOptions.find(
    f => f.value === requiresFilling
  );

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      open={props.open}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Editar datos de vereda</DialogTitle>
      <DialogContent>
        {props.loading ? (
          <div className={props.classes.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Autocomplete
              options={requiresFillingOptions}
              getOptionLabel={option => option.name}
              onChange={(_, value) => {
                setRequiresFilling(value.value);
              }}
              value={requiresFillingValue}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Requiere Aporte"
                  style={{ marginTop: 15, marginBottom: 20 }}
                />
              )}
            />

            <Autocomplete
              value={type}
              options={props.sidewalkTypes}
              getOptionLabel={option => option.name}
              noOptionsText="No se encontraron tipos de vereda"
              onChange={(_, value) => {
                setType(value);
                setUnit(null);
                setArticle(null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Tipo de Vereda"
                  style={{ marginTop: 15, marginBottom: 20 }}
                />
              )}
            />

            {type && [3, 4].includes(type.id) ? (
              <Autocomplete
                value={article}
                options={tilesOptions}
                getOptionLabel={option => option.name}
                noOptionsText="No se encontró la baldosa buscada"
                onChange={(_, value) => {
                  setArticle(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Baldosa"
                    style={{ marginTop: 15, marginBottom: 10 }}
                  />
                )}
              />
            ) : null}

            <Autocomplete
              value={unit}
              options={props.sidewalkUnits.filter(s => s.id !== 3)}
              getOptionLabel={option => option.name}
              getOptionDisabled={o =>
                type ? !allowedUnits[type.id].includes(o.id) : true
              }
              noOptionsText="No se encontraron unidades de medidas"
              onChange={(_, value) => {
                setUnit(value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Unidad de Medida"
                  style={{ marginTop: 15, marginBottom: 20 }}
                />
              )}
            />

            <TextField
              fullWidth
              type="number"
              margin="dense"
              value={amount}
              label={"Cantidad"}
              style={{ marginTop: 15, marginBottom: 20 }}
              onChange={e => setAmount(e.target.value)}
            />

            <TextField
              fullWidth
              margin="dense"
              value={observations}
              label={"Observaciones"}
              style={{ marginTop: 15, marginBottom: 20 }}
              onChange={e => setObservations(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (props.edited) {
              const article = props.sidewalkTiles.find(
                a => a.id_articulo === props.article_id
              );
              setType(props.type);
              setUnit(props.unit);
              setAmount(props.amount);
              setObservations(props.observations);
              setArticle(
                article ? { id: article.id_articulo, name: article.descripcion } : null
              );
            } else {
              const sidewalk = props.task.sidewalk;
              const article = props.sidewalkTiles.find(
                a => a.id_articulo === sidewalk.article_id
              );
              setType(sidewalk.type);
              setUnit(sidewalk.unit);
              setAmount(sidewalk.amount);
              setObservations(sidewalk.observations);
              setArticle(
                article ? { id: article.id_articulo, name: article.descripcion } : null
              );
              dispatch(
                setDefaultsValues(
                  sidewalk.amount,
                  sidewalk.unit,
                  sidewalk.type,
                  sidewalk.observations,
                  sidewalk.requires_filling,
                  sidewalk.article_id,
                  sidewalk.article_description
                )
              );
            }
            props.onClose();
          }}
          style={{ color: "black" }}>
          Cancelar
        </Button>
        <Button onClick={onSubmit} disabled={!isValid} color="primary">
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EditModal);
