import { connect } from "react-redux";

import {
  changePage,
  changePageRows,
  getFilteredArticles,
} from "../../../../../store/ducks/articlesFilters";
import { openModal as openValidateModal } from "../../../../../store/ducks/validateModal";
import { openModal as openRejectModal } from "../../../../../store/ducks/rejectModal";
import { openModal as openUndoModal } from "../../../../../store/ducks/undoModal";
import { openModal as openEditModal } from "../../../../../store/ducks/editModal";
import ArticlesTable from "./articlesTableContainer/ArticlesTable";

const mapStateToProps = state => ({
  articles: getFilteredArticles(state),
  page: state.articlesFilters.page,
  rowsPerPage: state.articlesFilters.rowsPerPage,
});

const mapDispatchToProps = dispatch => ({
  openValidateModal: article => dispatch(openValidateModal(article)),
  openRejectModal: article => dispatch(openRejectModal(article)),
  openUndoModal: article => dispatch(openUndoModal(article)),
  openEditModal: article => dispatch(openEditModal(article)),
  changePage: (event, page) => dispatch(changePage(event, page)),
  changePageRows: event => dispatch(changePageRows(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesTable);
