const operators = {
  LIKE: "LIKE",
  NOT_LIKE: "NOT_LIKE",

  EQUAL: "EQ",
  NOT_EQUAL: "NEQ",

  GT: "GT",
  GTE: "GTE",

  LT: "LT",
  LTE: "LTE",

  IS_NULL: "IS_NULL",
  IS_NOT_NULL: "IS_NOT_NULL",

  IN: "IN",
  NOT_IN: "NOT_IN",

  HAS: "HAS",

  BETWEEN: "BETWEEN",
  NOT_BETWEEN: "NOT_BETWEEN",
};

const buildObject = (column, operator, value) => ({ column, value, operator });

export const equalMapper = (key, value) =>
  buildObject(key, operators.EQUAL, typeof value === "string" ? value?.trim() : value);
export const notEqualMapper = (key, value) =>
  buildObject(
    key,
    operators.NOT_EQUAL,
    typeof value === "string" ? value?.trim() : value
  );

export const gtMapper = (key, value) => buildObject(key, operators.GT, value?.trim());
export const gteMapper = (key, value) => buildObject(key, operators.GTE, value?.trim());

export const ltMapper = (key, value) => buildObject(key, operators.LT, value?.trim());
export const lteMapper = (key, value) => buildObject(key, operators.LTE, value?.trim());

export const hasMapper = (key, value) => buildObject(key, operators.HAS, value);

export const likeMapper = (key, value) =>
  buildObject(key, operators.LIKE, `%${value?.trim()}%`);

export const notLikeMapper = (key, value) =>
  buildObject(key, operators.NOT_LIKE, `%${value?.trim()}%`);

export const inMapper = (key, values) => buildObject(key, operators.IN, values);
export const notInMapper = (key, values) => buildObject(key, operators.NOT_IN, values);

export const isNullMapper = column => ({ column, operator: operators.IS_NULL });
export const isNotNullMapper = column => ({ column, operator: operators.IS_NOT_NULL });

export const andMapper = values => ({ AND: values });
export const orMapper = values => ({ OR: values });

const convertUTCDateToLocalDate = dateString => {
  const date = new Date(dateString);
  const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const gteDateMapper = (key, dateString) => {
  const value = new Date(dateString);
  value.setHours(0, 0, 0, 0);
  return buildObject(key, operators.GTE, convertUTCDateToLocalDate(value));
};
export const lteDateMapper = (key, dateString) => {
  const value = new Date(dateString);
  value.setHours(23, 59, 59, 999);
  return buildObject(key, operators.LTE, convertUTCDateToLocalDate(value));
};
