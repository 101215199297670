/* eslint-disable jsx-a11y/alt-text */

import { format } from "date-fns";
import { Page, Text, View, Image, Document, StyleSheet } from "@react-pdf/renderer";

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

const styles = StyleSheet.create({
  page: { flex: 1, margin: 20 },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ot: {
    height: 25,
    padding: 3,
    fontSize: 12,
    width: "100%",
    color: "black",
    borderRadius: 5,
    border: "2px solid #00bedb",
    // backgroundColor: "#00bedb",
  },
  text: { fontSize: 11 },
  map: { borderRadius: 10, maxHeight: 300 },
  logo: {
    height: 100,
  },
});

const AbsaReportPage = ({ task }) => {
  const action = task?.reception || task?.tarea_accion.find(t => t.estado_id === 5);
  const pipeline = task?.pipelines[0];

  return (
    <Page size="A4">
      <View style={styles.page}>
        <View style={styles.flex}>
          <View style={styles.ot}>
            <Text>OT: {task?.codigo_ot}</Text>
          </View>
          <View>
            <Image
              style={styles.logo}
              source={`${process.env.PUBLIC_URL}/absa_logo.jpeg`}
            />
          </View>
        </View>

        <View style={{ marginTop: -5 }}>
          <Text style={styles.text}>
            FECHA:{" "}
            {action?.created_at
              ? format(new Date(action?.created_at?.replace(/ /g, "T")), "dd/MM/yyyy")
              : null}
          </Text>
        </View>

        <View style={[styles.flex, { marginTop: 25 }]}>
          <Text style={styles.text}>DOMICILIO: {task?.ubicacion}</Text>
        </View>

        <View style={[styles.flex, { marginTop: 25 }]}>
          <Text style={styles.text}>DIAMETRO: {pipeline?.diameter}</Text>
          <Text style={styles.text}>MATERIAL: {pipeline?.material}</Text>
        </View>

        <View style={[styles.flex, { marginTop: 25 }]}>
          <Text style={styles.text}>PROFUNDIDAD: {pipeline?.depth}</Text>
          <Text style={styles.text}>
            VEREDA/CALZADA: {pipeline?.has_sidewalk ? "Vereda" : "Calzada"}
          </Text>
          <Text style={styles.text}>
            ALEJAMIENTO LM: {pipeline?.municipal_line_distance}
          </Text>
        </View>

        <View style={[styles.ot, { marginTop: 25 }]}>
          <Text style={styles.text}> CROQUIS DE UBICACIÓN</Text>
        </View>

        <View style={{ marginTop: 15 }}>
          <Image
            id={task.id}
            style={styles.map}
            source={`https://maps.googleapis.com/maps/api/staticmap?center=${task.latitud},${task.longitud}&scale=2&zoom=17&size=800x300&language=es&markers=${task.latitud},${task.longitud}&key=${REACT_APP_GOOGLE_MAPS_API_KEY}&style=feature:poi|visibility:off`}
          />
        </View>
        <View style={[styles.flex, { marginTop: 25 }]}>
          <Text style={styles.text}>FONDO A CALLE: {pipeline?.street_orientation}</Text>
        </View>

        <View style={[styles.flex, { marginTop: 25 }]}>
          <Text style={styles.text}>
            OBSERVACIONES: {task.observaciones ? task.observaciones : "-"}
          </Text>
        </View>

        <View style={[{ marginTop: 60 }]}>
          <Text style={styles.text}>
            ENCARGADO: {task.cuadrilla?.cuadrilla || "Sin espeficiar"}
          </Text>

          <Text style={[styles.text, { marginTop: 20 }]}>
            INSPECTOR: {action?.inspector || "Sin espeficiar"}
          </Text>
        </View>
      </View>
    </Page>
  );
};

export const AbsaReportDocument = ({ tasks = [] }) => {
  return (
    <Document>
      {tasks.map((t, index) => (
        <AbsaReportPage task={t} key={index} />
      ))}
    </Document>
  );
};
