/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const styles = theme => ({
  filter: {
    fontSize: 14,
    color: "#666",
    marginBottom: 7,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 16,
  },
  iconContainer: {
    marginLeft: theme.spacing(0.5),
  },
  filterText: {
    "&:hover": {
      color: "#262626",
    },
    cursor: "pointer",
  },
});

const getOrderName = value => {
  if (value === "creation") return "Fecha de creación";
  if (value === "expiration") return "Fecha de vencimiento";
  if (value === "income_ot") return "Fecha de ingreso de OT";
  if (value === "state_change") return "Fecha de último cambio de estado";
  if (value === "ot") return "Código OT";
};

const viewOptions = props => {
  const { classes } = props;
  const orderName = getOrderName(props.selected);
  return (
    <>
      <Typography
        variant="h6"
        style={{ fontWeight: 350, marginTop: 20, color: "#333" }}
        gutterBottom>
        Ordenar tareas
      </Typography>
      <p className={classes.filter}>
        <span className={classes.filterText} onClick={props.openOrderModal}>
          {orderName}
        </span>
        {props.descOrder ? (
          <Tooltip title="Más reciente a más antigua">
            <IconButton
              size="small"
              className={classes.iconContainer}
              onClick={props.toggleOrder}>
              <ArrowDownIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Más antigua a más reciente">
            <IconButton
              size="small"
              className={classes.iconContainer}
              onClick={props.toggleOrder}>
              <ArrowUpIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        )}
      </p>
      <Typography
        variant="h6"
        style={{ fontWeight: 350, marginTop: 20, color: "#333" }}
        gutterBottom>
        Vista
      </Typography>
      <p className={classes.filter}>
        <span className={classes.filterText} onClick={props.toggleShowAllInfo}>
          {props.showAllInfo ? "Ocultar info detallada" : "Ver info detallada"}
        </span>
      </p>
    </>
  );
};

export default withStyles(styles)(viewOptions);
