const types = {
  TASK_LIST_SHOW_ALL_INFO_TOGGLE: "TASK_LIST_SHOW_ALL_INFO_TOGGLE",
};

export const toggleShowAllInfo = () => ({
  type: types.TASK_LIST_SHOW_ALL_INFO_TOGGLE,
});

const initialState = {
  showAllInfo: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_SHOW_ALL_INFO_TOGGLE:
      return { ...state, showAllInfo: !state.showAllInfo };
    default:
      return state;
  }
};

export default reducer;
