import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import withStyles from "@mui/styles/withStyles";
import { CardActions, Button } from "@mui/material";

import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardContent from "@mui/material/CardContent";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import InfoIcon from "@mui/icons-material/Info";

import axios from "../../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { enqueueError, enqueueSuccess } from "../../../../store/ducks/notifications";

import "../../../../components/Tasks/states.css";
import { useCallback, useState } from "react";

const styles = () => ({
  title: {
    fontWeight: 400,
  },
  container: {
    paddingBottom: 0,
  },
  dropdown: {
    minWidth: 80,
  },
  contentContainer: {
    paddingTop: 10,
    paddingBottom: 0,
  },
  listIcon: {
    minWidth: 40,
  },
  inspectors: {
    overflow: "hidden",
    whiteSpace: "pre-line",
  },
});

const getInspectors = group =>
  group.inspectors && group.inspectors.length
    ? group.inspectors.map(({ inspector }) => inspector).join(", ")
    : "-";

const getStores = (groupStores, stores) =>
  groupStores && groupStores.length && stores && stores.length
    ? groupStores
        .map(id => stores.find(store => store._id === id))
        .filter(i => i)
        .map(({ name, type }) => `${name} - ${type.name}`)
        .join(", ")
    : "-";

const GroupCard = ({ group, classes, ...props }) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const stores = useSelector(state => state.storesList);

  const goToGangs = useCallback(() => {
    navigate(`/grupos/${group.id}`);
  }, [group, navigate]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  return (
    <Card variant="outlined">
      <CardHeader
        className={classes.container}
        title={
          <Typography variant="h6" className={classes.title}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {group.name}
              {!group.i_am_included ? (
                <Tooltip title="No perteneces al grupo">
                  <InfoIcon style={{ marginLeft: 5, color: "gray" }} />
                </Tooltip>
              ) : null}
            </div>
          </Typography>
        }
        action={
          props.roles && props.roles.includes("editarGrupo") ? (
            <>
              <IconButton onClick={handleClick} size="large">
                <MoreVertIcon />
              </IconButton>
              <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem
                  className={classes.dropdown}
                  onClick={() => {
                    handleClose();

                    if (props.roles.includes("editarGrupo")) {
                      navigate(`/grupos/${group.id}/editar`);
                    } else {
                      props.open(group);
                    }
                  }}>
                  Editar
                </MenuItem>
                {props.roles.includes("crearGrupos") ? (
                  <MenuItem
                    className={classes.dropdown}
                    onClick={() => {
                      handleClose();

                      if (group.hasGangs) {
                        dispatch(
                          enqueueError(
                            "El grupo no puede deshabilitarse porque posee cuadrillas asignadas."
                          )
                        );
                      } else {
                        confirm({
                          title: "Deshabilitar Grupo",
                          description:
                            "Si continuas con esta acción el grupo dejará de estar habilitado y ya no se podrán vincular tareas al mismo",
                          confirmationText: "Confirmar",
                          cancellationText: "Cancelar",
                          confirmationButtonProps: { color: "secondary" },
                        })
                          .then(() => {
                            axios
                              .delete(`/groups/${group.id}`)
                              .then(() => {
                                props.getGroups();
                                dispatch(
                                  enqueueSuccess("Grupo desactivado correctamente")
                                );
                              })
                              .catch(e => {
                                if (e.response && e.response.data) {
                                  dispatch(enqueueError(e.response.data));
                                }
                              });
                          })
                          .catch(() => {});
                      }
                    }}>
                    Deshabilitar
                  </MenuItem>
                ) : null}
              </Menu>
            </>
          ) : null
        }
      />
      <CardContent className={classes.contentContainer}>
        <List component="nav" disablePadding dense>
          <ListItem dense disableGutters style={{ height: 60 }}>
            <Tooltip title="Inspectores">
              <ListItemIcon className={classes.listIcon}>
                <AssignmentIndIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primaryTypographyProps={{ className: classes.inspectors }}
              primary={getInspectors(group)}
            />
          </ListItem>

          <ListItem dense disableGutters style={{ height: 60 }}>
            <Tooltip title="Depósitos">
              <ListItemIcon className={classes.listIcon}>
                <HomeIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primaryTypographyProps={{ className: classes.inspectors }}
              primary={getStores(group.stores, stores)}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions style={{ float: "right" }}>
        <Button size="small" style={{ color: "black" }} onClick={goToGangs}>
          Cuadrillas
        </Button>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(GroupCard);
