/* eslint-disable react/no-unescaped-entities */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const FinishValidationErrorModal = props => {
  const hasArticles = props.task.articles.length;
  const pendingMaterials = props.task.articles.filter(a => a.state.id === 0).length;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">La validación no puede completarse</DialogTitle>
      <DialogContent>
        {hasArticles ? (
          <DialogContentText style={{ fontSize: 18 }}>
            Para finalizar la validación es necesario validar todos los materiales. La
            tarea cuenta con {pendingMaterials} material(es) sin validar.
          </DialogContentText>
        ) : (
          <DialogContentText style={{ fontSize: 18 }}>
            Esta tarea requiere materiales. Para poder finalizar esta validación la tarea
            deberá tener al menos un material asociado.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.sending}
          style={{ color: "black" }}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishValidationErrorModal;
