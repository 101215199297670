/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import ApiError from "../components/UI/ApiError";
import { getReorerTasks } from "../store/ducks/gangTasksReorder";
import GangsReorderTasks from "./gangsReorderTasksContainer/gangsReorderTasks";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

const GangsReorderTasksContainer = props => {
  const match = useParams();
  useEffect(() => {
    props.getReorerTasks(match.id);
  }, [match]);

  const { error, tasksError } = props;

  return error || tasksError ? (
    <ApiError error="Ocurrió un error al obtener las tareas de la cuadrilla" />
  ) : (
    <GangsReorderTasks {...props} />
  );
};

const actionNames = ["GANGS_TASK_REORDER"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
  gang: state.gangTasksReorder.gang,
});

const mapDispatchToProps = dispatch => ({
  getReorerTasks: gang => dispatch(getReorerTasks(gang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GangsReorderTasksContainer);
