import { connect } from "react-redux";

import {
  closeModal,
  changeGang,
  changeStore,
  changeOrigin,
  changeArticle,
  changeAmount,
  getStores,
  getOrigins,
  getArticles,
  changeShowStoreStock,
  openConfirmationModal,
} from "../../../store/ducks/addModal";
import AddModal from "./addModalContainer/AddModal";

const mapStateToProps = state => ({
  open: state.addModal.modalOpen,
  gang: state.addModal.gang,
  store: state.addModal.store,
  origin: state.addModal.origin,
  article: state.addModal.article,
  amount: state.addModal.amount,
  showStoreStock: state.addModal.showStoreStock,
  amountError: state.addModal.amountError,
  stores: getStores(state),
  origins: getOrigins(state),
  articles: getArticles(state),
  loading: state.addModal.loading || state.addModal.loadingStoreStock,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  changeGang: gang => dispatch(changeGang(gang)),
  changeStore: store => dispatch(changeStore(store)),
  changeOrigin: store => dispatch(changeOrigin(store)),
  changeArticle: store => dispatch(changeArticle(store)),
  changeAmount: store => dispatch(changeAmount(store)),
  changeShowStoreStock: value => dispatch(changeShowStoreStock(value)),
  addConsumption: () => dispatch(openConfirmationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddModal);
