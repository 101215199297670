import axios from "../../axios";
import { enqueueError, enqueueMessage } from "./notifications";
import { canConfirm } from "./task";

import { openModal as openComplementaryModal } from "./addComplementaryModal";

const types = {
  FINISH_VALIDATION_MODAL_OPEN: "FINISH_VALIDATION_MODAL_OPEN",
  FINISH_VALIDATION_MODAL_CLOSE: "FINISH_VALIDATION_MODAL_CLOSE",

  FINISH_VALIDATION_SIDEWALK_MODAL_OPEN: "FINISH_VALIDATION_SIDEWALK_MODAL_OPEN",
  FINISH_VALIDATION_SIDEWALK_MODAL_CLOSE: "FINISH_VALIDATION_SIDEWALK_MODAL_CLOSE",

  FINISH_VALIDATION_MODAL_REQUEST: "FINISH_VALIDATION_MODAL_REQUEST",
  FINISH_VALIDATION_MODAL_SUCCESS: "FINISH_VALIDATION_MODAL_SUCCESS",
  FINISH_VALIDATION_MODAL_FAILURE: "FINISH_VALIDATION_MODAL_FAILURE",
  FINISH_VALIDATION_MODAL_ERROR_OPEN: "FINISH_VALIDATION_MODAL_ERROR_OPEN",
};

export const openModal = () => async (dispatch, getState) => {
  const state = getState();
  if (!canConfirm(state)) {
    dispatch(openErrorModal());
  } else {
    dispatch({
      type: types.FINISH_VALIDATION_MODAL_OPEN,
    });
  }
};

export const openSidewalkValidationModal = () => ({
  type: types.FINISH_VALIDATION_SIDEWALK_MODAL_OPEN,
});

export const closeModal = () => ({
  type: types.FINISH_VALIDATION_MODAL_CLOSE,
});

export const closeSidewalkValidationModal = () => ({
  type: types.FINISH_VALIDATION_SIDEWALK_MODAL_CLOSE,
});

export const openErrorModal = () => ({
  type: types.FINISH_VALIDATION_MODAL_ERROR_OPEN,
});

const request = () => ({
  type: types.FINISH_VALIDATION_MODAL_REQUEST,
});

const success = () => ({
  type: types.FINISH_VALIDATION_MODAL_SUCCESS,
});

const failure = () => ({
  type: types.FINISH_VALIDATION_MODAL_FAILURE,
});

export const finishValidation =
  (state = 6, navigate) =>
  async (dispatch, getState) => {
    const { task } = getState();
    dispatch(request());
    try {
      await axios.post(`/tasks/${task.id}/actions`, { estado_id: 6 });

      if (Number(state) === 8) {
        await axios.post(`/tasks/${task.id}/actions`, { estado_id: 8 });
      }

      const type = Number(task.typeId);
      let sidewalks = task.sidewalks ? task.sidewalks.filter(s => s.type_id !== 5) : [];
      if (sidewalks.length && [1, 2, 3, 4, 6, 8, 5].includes(type)) {
        dispatch(openComplementaryModal());
      } else {
        dispatch(enqueueMessage("La tarea se finalizó correctamente"));
        if (navigate) navigate("/validacion");
      }
      dispatch(success());
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        dispatch(enqueueError("Ocurrió un error al finalizar la validación"));
      } else {
        dispatch(enqueueError("Ocurrió un error al finalizar la validación"));
      }
      dispatch(failure());
    }
  };

const initialState = {
  sending: false,
  modalOpen: false,
  modalErrorOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FINISH_VALIDATION_MODAL_OPEN:
      return { ...state, modalOpen: true };
    case types.FINISH_VALIDATION_MODAL_CLOSE:
      return { ...state, modalOpen: false, modalErrorOpen: false };

    case types.FINISH_VALIDATION_SIDEWALK_MODAL_OPEN:
      return { ...state, modalSidewalkOpen: true };

    case types.FINISH_VALIDATION_SIDEWALK_MODAL_CLOSE:
      return { ...state, modalSidewalkOpen: false };

    case types.FINISH_VALIDATION_MODAL_ERROR_OPEN:
      return { ...state, modalErrorOpen: true };
    case types.FINISH_VALIDATION_MODAL_REQUEST:
      return { ...state, sending: true };
    case types.FINISH_VALIDATION_MODAL_SUCCESS:
      return { ...state, sending: false, modalOpen: false };
    case types.FINISH_VALIDATION_MODAL_FAILURE:
      return { ...state, sending: false };
    default:
      return state;
  }
};

export default reducer;
