/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { useCallback, useEffect } from "react";

import { Controller } from "react-hook-form";
import { Autocomplete } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { CloudUpload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Link,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  checkTaskExistance,
  clearTaskExistance,
} from "../../../../../store/ducks/newTask";
import { useDebounce } from "../../../../../utils/useDebounce";

import { resetFilters } from "../../../../../store/ducks/tasksList";
import { save as setODT } from "../../../../../store/ducks/taskListTaskFilter";
import { showHistoric } from "../../../../../store/ducks/taskListHistoricFilter";

import CroquisItem from "./croquisItem";
import { useRef } from "react";

const useStyles = value =>
  makeStyles(() => ({
    root: {
      "& .Mui-error": {
        color: acquireValidationColor(value),
      },
      "& .MuiFormHelperText-root": {
        color: acquireValidationColor(value),
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": { borderColor: acquireValidationColor(value) },
      },
    },
  }));

const acquireValidationColor = message => {
  if (!message) return "#c4c4c4";
  switch (message) {
    case "Tené en cuenta que este código tambien fue usado en otras tareas.":
      return "orange";
    default:
      return "red";
  }
};

export const NewTaskStep1 = props => {
  const {
    form: {
      watch,
      control,
      register,
      setValue,
      setFocus,
      getValues,
      clearErrors,
      formState: { errors },
    },
  } = props;

  const dispatch = useDispatch();
  const taskTypes = useSelector(state => state.taskTypes);
  const existTask = useSelector(({ newTask }) => newTask.existTask);

  const type = watch("step1.tipo");
  const odt = watch("step1.codigo_ot");
  const operativeZone = watch("step1.operative_zone");

  const debouncedOdt = useDebounce(odt, 1000);

  const inputFile = useRef(null);

  useEffect(() => {
    // For Autocomplete - According to https://react-hook-form.com/get-started#IntegratingwithUIlibraries
    register("step1.tipo", { required: true });
    register("step1.croquis");
    setFocus("step1.codigo_ot");
    setFocus("step1.operative_zone");
  }, [register, setFocus]);

  useEffect(() => {
    if (debouncedOdt && debouncedOdt.length >= 7) {
      dispatch(checkTaskExistance(debouncedOdt));
    } else {
      dispatch(clearTaskExistance());
    }
  }, [debouncedOdt]);

  const errorMessage = useCallback(() => {
    if (errors.step1?.codigo_ot?.message) return errors.step1?.codigo_ot?.message;
    if (existTask)
      return "Tené en cuenta que este código tambien fue usado en otras tareas.";
    return "";
  }, [errors, existTask]);

  const getAction = useCallback(
    message => {
      switch (message) {
        case "Tené en cuenta que este código tambien fue usado en otras tareas.":
          return (
            <Link
              to="route"
              target="_blank"
              style={{
                marginLeft: 4,
                color: "orange",
                cursor: "pointer",
              }}
              onClick={async e => {
                e.preventDefault();
                await dispatch(resetFilters());
                await dispatch(showHistoric());
                await dispatch(setODT({ odt }));
                setTimeout(() => {
                  window.open(`${process.env.PUBLIC_URL}`);
                }, 1001);
              }}>
              Ir a verlas.
            </Link>
          );
        default:
          return null;
      }
    },
    [odt, dispatch]
  );

  const classes = useStyles(errorMessage())();

  const handleFileSelected = useCallback(
    e => {
      let files = Array.from(e.target.files);
      if (files[0] && files[0].type === "application/pdf") {
        files = [files[0]];
      }
      setValue("step1.croquis", files);
    },
    [setValue]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step1.codigo_ot"
          rules={{
            required: "Debes ingresar un código OT",
            minLength: { value: 7, message: "Debe tener al menos 7 caracteres." },
            maxLength: { value: 50, message: "Debe tener menos de 50 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              required
              fullWidth
              autoFocus
              margin="dense"
              label="Código OT"
              variant="outlined"
              error={errors.step1?.codigo_ot}
              helperText={
                <div>
                  <span>{errorMessage()}</span>
                  {getAction(errorMessage())}
                </div>
              }
              className={classes.root}
              inputProps={{ maxLength: 50, minLength: 7, autoFocus: true }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Autocomplete
          variant="outlined"
          control={control}
          options={taskTypes}
          disableClearable
          style={{ width: "100%" }}
          noOptionsText="Sin Resultados"
          value={getValues("step1.tipo")}
          getOptionLabel={option => option.name}
          onChange={(e, value) => {
            e.stopPropagation();
            if (value) {
              setValue("step1.tipo", value);
              clearErrors("step1.tipo");
              if (value.id === 9) {
                setValue("step1.valida_materiales", false);
              }
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              required
              margin="dense"
              variant="outlined"
              label="Tipo de Tarea"
              error={errors.step1?.tipo}
              helperText={errors.step1?.tipo?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step1.ingreso_ot"
          rules={{ required: "Debe completar este campo." }}
          render={({ field }) => (
            <DatePicker
              {...field}
              required
              fullWidth
              margin="dense"
              todayText="Hoy"
              showTodayButton
              clearText="Borrar"
              defaultValue={null}
              cancelText="Cancelar"
              inputVariant="outlined"
              inputFormat="dd/MM/yyyy"
              label="Fecha de Ingreso"
              renderInput={params => <TextField {...params} style={{ width: "100%" }} />}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step1.fecha_asignada"
          rules={{ required: "Debe completar este campo." }}
          render={({ field }) => (
            <DatePicker
              {...field}
              fullWidth
              required
              margin="dense"
              todayText="Hoy"
              showTodayButton
              clearText="Borrar"
              cancelText="Cancelar"
              allowSameDateSelection
              inputVariant="outlined"
              inputFormat="dd/MM/yyyy"
              defaultValue={new Date()}
              label="Fecha de Asignación"
              renderInput={params => <TextField {...params} style={{ width: "100%" }} />}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step1.modulo"
          render={({ field }) => (
            <TextField
              fullWidth
              label="Módulo"
              margin="dense"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">MOD.</InputAdornment>,
              }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <FormControl
          margin="dense"
          variant="outlined"
          style={{ width: "100%" }}
          disabled={type ? type.id === 9 : false}>
          <InputLabel id="input-requires-materials">Requiere materiales</InputLabel>
          <Controller
            control={control}
            name="step1.valida_materiales"
            render={({ field }) => (
              <Select
                {...field}
                defaultValue={true}
                label="Requiere materiales"
                labelId="input-requires-materials"
                disabled={type ? type.id === 9 : false}>
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Sí</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          defaultValue=""
          control={control}
          name="step1.operative_zone"
          render={({ field }) => (
            <TextField
              fullWidth
              variant="outlined"
              label="Zona operativa"
              error={operativeZone ? !/^[A-Z0-9]+$/.test(operativeZone) : false}
              helperText={
                operativeZone && !/^[A-Z0-9]+$/.test(operativeZone)
                  ? "Solo puede contener caracteres alfanuméricos y en mayúscula."
                  : undefined
              }
              inputProps={{ maxLength: 10, minLength: 6 }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step1.approved_by"
          render={({ field }) => (
            <TextField
              fullWidth
              variant="outlined"
              label="Autorizado por"
              inputProps={{ maxLength: 200, minLength: 5 }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Controller
          control={control}
          name="step1.tarea"
          rules={{
            required: "Debes ingresar una descripción.",
            minLength: { value: 5, message: "Debe tener al menos 5 caracteres." },
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              required
              multiline
              fullWidth
              variant="outlined"
              label="Descripción de la tarea"
              error={errors.step1?.tarea}
              helperText={errors.step1?.tarea?.message}
              inputProps={{ maxLength: 200, minLength: 5 }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name="step1.croquisLabel"
          render={({ field }) => (
            <TextField
              disabled
              fullWidth
              label="Croquis"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <input
                      multiple
                      type="file"
                      ref={inputFile}
                      name="step1.croquis"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      onChange={handleFileSelected}
                      accept="image/jpeg,image/gif,image/png,application/pdf"
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          inputFile.current.click();
                        }}
                        aria-label="upload picture"
                        component="span"
                        size="large">
                        <CloudUpload />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
              {...field}
            />
          )}
        />

        {getValues().step1.croquis && getValues().step1.croquis.length
          ? getValues().step1.croquis.map((item, index) => {
              return <CroquisItem {...props} item={item} key={index} />;
            })
          : null}
      </Grid>
    </Grid>
  );
};

export default NewTaskStep1;
