import format from "date-fns/format";
import parse from "date-fns/parse";

const getArticleState = id => {
  switch (id) {
    case "0":
      return { id: 0, name: "Sin validar" };
    case "1":
      return { id: 1, name: "Validado" };
    case "2":
      return { id: 2, name: "Objetado" };
    case "3":
      return { id: 3, name: "Consolidado" };
    case "4":
      return { id: 4, name: "Objetado (pañol)" };
    default:
      break;
  }
};

export const parseFromApi = (task, gangs = []) => {
  const tarea_accion = task.tarea_accion
    .filter(c => c.estado_id === 5)
    .sort(
      (a, b) =>
        new Date(b.created_at?.replace(/ /g, "T")) -
        new Date(a.created_at?.replace(/ /g, "T"))
    )[0];

  const validationDate = task.tarea_accion
    .filter(c => c.estado_id === 6)
    .sort(
      (a, b) =>
        new Date(b.created_at?.replace(/ /g, "T")) -
        new Date(a.created_at?.replace(/ /g, "T"))
    )[0];

  const stockDate = task.tarea_accion.filter(c => c.estado_id === 4).length
    ? format(
        new Date(
          task.tarea_accion
            .filter(c => c.estado_id === 4)
            .sort(
              (a, b) =>
                new Date(b.created_at?.replace(/ /g, "T")) -
                new Date(a.created_at?.replace(/ /g, "T"))
            )[0]
            ?.created_at?.replace(/ /g, "T")
        ),
        "yyyy-MM-dd"
      )
    : null;

  return {
    id: task.id,
    otCode: task.codigo_ot,
    state: task.estado_id,
    address: task.ubicacion,
    task: task.tarea,
    comment: task.observaciones,
    module: task.modulo,
    parent_id: task.parent_id,
    validatesArticles: !!task.valida_materiales,
    error: !!tarea_accion?.fallida,
    inspector: tarea_accion?.inspector,
    validationDate: validationDate
      ? new Date(validationDate?.created_at?.replace(/ /g, "T"))
      : null,
    date: task
      ? format(new Date(task?.created_at?.replace(/ /g, "T")), "dd/MM/yyyy HH:mm")
      : "-",
    stockDate,
    actions: task.tarea_accion,
    sidewalks: task.sidewalks,
    user: task.created_by.name,
    sidewalk_photos: task.sidewalk_photos,
    hidrojets: task.hidrojets,
    gang: task.cuadrilla_id
      ? { id: task.cuadrilla_id, name: task.cuadrilla.cuadrilla }
      : null,
    type: task.tipo.tipo,
    typeId: task.tipo.id,
    group: { id: task.grupo.id, name: task.grupo.nombre },
    images: task.fotos,
    articles:
      task.materiales && task.materiales instanceof Array
        ? task.materiales.map(article => ({
            id: Number(article.id_articulo),
            consumption: Number(article.id_consumo),
            name: article.descripcion,
            unit: article.unidad_medida,
            amount: Number(article.cantidad),
            date: format(
              parse(article.fecha_consumo, "yyyy-MM-dd", new Date()),
              "dd/MM/yyyy"
            ),
            store: { id: article.id_deposito, name: article.deposito },
            code: article.cod_equivalente,
            origin: { id: article.id_procedencia, name: article.procedencia },
            gang: gangs.find(gang => gang._id === article.id_cuadrilla),
            state: getArticleState(article.estado_consumo),
          }))
        : [],
    sheetGenerated:
      task.sheet_generated_by && task.sheet_generated_at
        ? `${task.sheet_generated_by.name} [${format(
            new Date(task.sheet_generated_at?.replace(/ /g, "T")),
            "dd/MM/yyyy HH:mm"
          )}]`
        : null,
    summaryGenerated:
      task.summary_generated_by && task.summary_generated_at
        ? `${task.summary_generated_by.name} [${format(
            new Date(task.summary_generated_at?.replace(/ /g, "T")),
            "dd/MM/yyyy HH:mm"
          )}]`
        : null,
  };
};

export const parseFromApiForValidation = (task, gangs = []) => {
  const articles = task.materiales
    ? JSON.parse(task.materiales).map(article => ({
        id: Number(article.id_articulo),
        consumption: Number(article.id_consumo),
        name: article.descripcion,
        unit: article.unidad_medida,
        amount: Number(article.cantidad),
        date: format(
          parse(article.fecha_consumo, "yyyy-MM-dd", new Date()),
          "dd/MM/yyyy"
        ),
        store: { id: article.id_deposito, name: article.deposito },
        code: article.cod_equivalente,
        origin: { id: article.id_procedencia, name: article.procedencia },
        gang: gangs.find(gang => gang._id === article.id_cuadrilla),
        state: getArticleState(article.estado_consumo),
      }))
    : [];

  const hasPendingArticles = !!articles.find(a => a.state && a.state.id === 0);
  const hasPendingSidewalk = task.sidewalk && task.sidewalk.state === 0 && task.parent_id;

  return {
    articles,
    id: task.id,
    task: task.tarea,
    images: task.fotos,
    hasPendingArticles,
    hasPendingSidewalk,
    module: task.modulo,
    type: task.tipo.tipo,
    typeId: task.tipo.id,
    state: task.estado_id,
    otCode: task.codigo_ot,
    address: task.ubicacion,
    sidewalks: task.sidewalks,
    comment: task.observaciones,
    actions: task.tarea_accion,
    validatesArticles: !!task.valida_materiales,
    group: { id: task.grupo.id, name: task.grupo.nombre },
    gang: task.cuadrilla_id
      ? { id: task.cuadrilla_id, name: task.cuadrilla.cuadrilla }
      : null,
  };
};

export const parseGraphqlFromApi = (task, gangs = []) => {
  const tarea_accion = task.tareaAccion
    .filter(c => Number(c.estado_id) === 5)
    .sort(
      (a, b) =>
        new Date(b.created_at.replace(/ /g, "T")) -
        new Date(a.created_at.replace(/ /g, "T"))
    )[0];
  const validationDate = task.tareaAccion
    .filter(c => Number(c.estado_id) === 6)
    .sort(
      (a, b) =>
        new Date(b.created_at.replace(/ /g, "T")) -
        new Date(a.created_at.replace(/ /g, "T"))
    )[0];

  const stockDate = format(
    new Date(
      task.tareaAccion
        .filter(c => Number(c.estado_id) === 4)
        .sort(
          (a, b) =>
            new Date(b.created_at.replace(/ /g, "T")) -
            new Date(a.created_at.replace(/ /g, "T"))
        )[0]
        .created_at.replace(/ /g, "T")
    ),
    "yyyy-MM-dd"
  );
  return {
    id: task.id,
    otCode: task.codigo_ot,
    state: task.estado.id,
    address: task.ubicacion,
    task: task.tarea,
    comment: task.observaciones,
    module: task.modulo,
    error: !!tarea_accion.fallida,
    inspector: tarea_accion.inspector,
    validationDate: validationDate
      ? new Date(validationDate.created_at.replace(/ /g, "T"))
      : null,
    date: task.tareaAccion
      ? format(new Date(tarea_accion.created_at.replace(/ /g, "T")), "dd/MM/yyyy HH:mm")
      : "-",
    stockDate,
    actions: task.tareaAccion,
    user: task.createdBy.name,
    gang: {
      id: task.cuadrilla ? task.cuadrilla.id : undefined,
      name: task.cuadrilla ? task.cuadrilla.cuadrilla : undefined,
    },
    type: task.tipo.tipo,
    typeId: task.tipo.id,
    group: { id: task.grupo.id, name: task.grupo.nombre },
    images: task.fotos,
    articles:
      task.materiales && task.materiales instanceof Array
        ? task.materiales.map(article => ({
            id: Number(article.id_articulo),
            consumption: Number(article.id_consumo),
            name: article.descripcion,
            unit: article.unidad_medida,
            amount: Number(article.cantidad),
            date: format(
              parse(article.fecha_consumo, "yyyy-MM-dd", new Date()),
              "dd/MM/yyyy"
            ),
            store: { id: article.id_deposito, name: article.deposito },
            code: article.cod_equivalente,
            origin: { id: article.id_procedencia, name: article.procedencia },
            gang: gangs.find(gang => gang._id === article.id_cuadrilla),
            state: getArticleState(article.estado_consumo),
          }))
        : [],
    sheetGenerated: task.sheetGeneratedBy
      ? `${task.sheetGeneratedBy.name} [${format(
          new Date(task.sheet_generated_at.replace(/ /g, "T")),
          "dd/MM/yyyy HH:mm"
        )}]`
      : null,
    summaryGenerated: task.summaryGeneratedBy
      ? `${task.summaryGeneratedBy.name} [${format(
          new Date(task.summary_generated_at.replace(/ /g, "T")),
          "dd/MM/yyyy HH:mm"
        )}]`
      : null,
  };
};
