import { connect } from "react-redux";
import CivilTaskDialog from "./civilTaskDialogContainer/CivilTaskDialog";

import {
  confirm,
  changeType,
  closeModal,
  changeGroup,
} from "../../../../store/ducks/newCivilTaskDialog";

const mapStateToProps = state => ({
  groups: state.groups.groups,
  type: state.newCivilTaskDialog.type,
  task: state.newCivilTaskDialog.task,
  group: state.newCivilTaskDialog.group,
  loading: state.newCivilTaskDialog.loading,
  openModal: state.newCivilTaskDialog.openModal,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  changeType: value => dispatch(changeType(value)),
  changeGroup: group => dispatch(changeGroup(group)),
  confirm: shouldRedirect => {
    dispatch(confirm(shouldRedirect));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CivilTaskDialog);
