import { Component } from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DatePicker from "@mui/lab/MobileDatePicker";
import Autocomplete from "@mui/material/Autocomplete";

class TaskFilters extends Component {
  state = {
    open: false,
    odt: "",
    task: "",
    street: "",
    number: "",
    between: "",
    and: "",
    from: null,
    to: null,
    type: null,
    taskGang: null,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  changeField = target => {
    this.setState({ [target.name]: target.value });
  };

  changeFromDate = date => {
    this.setState({ from: date });
  };

  changeToDate = date => {
    this.setState({ to: date });
  };

  changeType = type => {
    this.setState({ type });
  };

  changeTaskGang = taskGang => {
    this.setState({ taskGang });
  };

  submit = () => {
    this.props.search(this.state);
  };

  clear = () => {
    this.setState({
      odt: "",
      task: "",
      street: "",
      number: "",
      between: "",
      and: "",
      from: null,
      to: null,
      type: null,
      taskGang: null,
    });
    this.props.clear();
  };

  render() {
    return (
      <List
        component="nav"
        disablePadding
        subheader={
          <>
            <Grid container>
              <Grid item xs>
                <ListSubheader disableSticky>Filtros tareas</ListSubheader>
              </Grid>
              <Grid item>
                <IconButton
                  disableRipple
                  onClick={this.handleClick}
                  size="small"
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                  }}>
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </Grid>
          </>
        }>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <div style={{ margin: 20, marginTop: 0 }}>
            <TextField
              fullWidth
              style={{ marginTop: 0 }}
              margin="dense"
              label="ODT"
              value={this.state.odt}
              name="odt"
              onChange={e => this.changeField(e.target)}
            />
            <Autocomplete
              options={this.props.types}
              getOptionLabel={option => option.name}
              value={this.state.type}
              onChange={(e, newValue) => this.changeType(newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tipo"
                  margin="dense"
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Descripción tarea"
              value={this.state.task}
              name="task"
              onChange={e => this.changeField(e.target)}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Calle"
              value={this.state.street}
              name="street"
              onChange={e => this.changeField(e.target)}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Altura"
              value={this.state.number}
              name="number"
              onChange={e => this.changeField(e.target)}
            />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Entre"
                  value={this.state.between}
                  name="between"
                  onChange={e => this.changeField(e.target)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Y"
                  value={this.state.and}
                  name="and"
                  onChange={e => this.changeField(e.target)}
                />
              </Grid>
            </Grid>
            <Autocomplete
              options={this.props.gangs}
              getOptionLabel={option => option.name}
              value={this.state.taskGang}
              onChange={(e, newValue) => this.changeTaskGang(newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Cuadrilla"
                  margin="dense"
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
            <DatePicker
              clearable
              todayText="Hoy"
              clearText="Borrar"
              cancelText="Cancelar"
              margin="dense"
              fullWidth
              showTodayButton
              inputFormat="dd/MM/yyyy"
              label="Fecha asignada desde"
              value={this.state.from}
              onChange={date => this.changeFromDate(date)}
              DialogProps={{ disableRestoreFocus: true }}
              renderInput={params => (
                <TextField {...params} style={{ width: "100%", marginTop: 5 }} />
              )}
            />
            <DatePicker
              clearable
              todayText="Hoy"
              clearText="Borrar"
              cancelText="Cancelar"
              margin="dense"
              fullWidth
              showTodayButton
              inputFormat="dd/MM/yyyy"
              label="Fecha asignada hasta"
              value={this.state.to}
              onChange={date => this.changeToDate(date)}
              DialogProps={{ disableRestoreFocus: true }}
              renderInput={params => (
                <TextField {...params} style={{ width: "100%", marginTop: 10 }} />
              )}
            />
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={6}>
                <Button variant="outlined" size="small" fullWidth onClick={this.submit}>
                  Buscar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" size="small" fullWidth onClick={this.clear}>
                  Limpiar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </List>
    );
  }
}

export default TaskFilters;
