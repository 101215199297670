/* eslint-disable jsx-a11y/iframe-has-title */

import ImageGallery from "react-image-gallery";
import { Card, CardContent, Typography } from "@mui/material";
import "react-image-gallery/styles/css/image-gallery.css";
import "./styles.css";

export default ({ task }) => {
  return task.croquis && task.croquis.length ? (
    <Card style={{ marginTop: 10 }} variant="outlined">
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Croquis
        </Typography>
        {!task.croquis.find(c => c.path.endsWith(".pdf")) ? (
          <ImageGallery
            lazyLoad
            showNav={true}
            showBullets={true}
            showThumbnails={false}
            showPlayButton={false}
            style={{ borderRadius: 5 }}
            items={task.croquis.map(croquis => ({
              original: `${process.env.REACT_APP_IMAGES_URI}/${croquis.path}`,
            }))}
          />
        ) : (
          <iframe
            src={`${process.env.REACT_APP_IMAGES_URI}/${
              task.croquis.find(c => c.path.endsWith(".pdf")).path
            }`}
            style={{ minHeight: 800, width: "100%", border: "none", borderRadius: 5 }}
          />
        )}
      </CardContent>
    </Card>
  ) : null;
};
