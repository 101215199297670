import { Component } from "react";
import { connect } from "react-redux";

import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";
import ApiError from "../components/UI/ApiError";
import { getGroups } from "../store/ducks/groups";
import { getTasks } from "../store/ducks/downloadTaskList";

import TaskList from "./downloadTasksContainer/TaskList";

class DownloadTasksContainer extends Component {
  componentDidMount() {
    this.props.getTasks(1, true);
    this.props.getGroups();
  }

  render() {
    const { error } = this.props;
    return !error ? <TaskList {...this.props} /> : <ApiError error={error} />;
  }
}

const actionNames = ["GROUPS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getTasks: (state, reset) => dispatch(getTasks(state, reset)),
  getGroups: () => dispatch(getGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadTasksContainer);
