/* eslint-disable no-unused-vars */
import {
  gtMapper,
  inMapper,
  orMapper,
  gteMapper,
  lteMapper,
  andMapper,
  likeMapper,
  equalMapper,
  notInMapper,
  isNullMapper,
  gteDateMapper,
  lteDateMapper,
  isNotNullMapper,
} from "../mappers";

const now = new Date().toISOString();

const filterGangs = gangs =>
  gangs.filter(v => !["no-gang", "no-group", "other-group"].includes(v));

// $Where filters
const getFilterReducers = key => /taskList[a-z]*filter/i.test(key.toLowerCase());

export const getTasksFilters = (state, hideHistoric = false) => {
  let filters = [];
  const exclude = [
    "taskListHidrojetFilter",
    "taskListStateChangesFilter",
    "taskListHistoryChangesFilter",
  ];

  const keys = Object.keys(state)
    .filter(getFilterReducers)
    .filter(key => !exclude.includes(key));

  keys.forEach(k => {
    Object.keys(state[k]).forEach(att => {
      const value = state[k][att];

      if (att === "showHistoric") {
        if (!value && !hideHistoric) {
          return filters.push(equalMapper("HISTORIC", false));
        } else {
          return false;
        }
      }

      if (Taskskeys.includes(att) && value && (!Array.isArray(value) || value.length)) {
        const name = TasksFilters[att].key;
        const mapped = TasksFilters[att].mapper(name, value);
        mapped && filters.push(mapped);
      }
    });
  });

  return filters.length ? andMapper(filters) : null;
};

export const TasksFilters = {
  isComplementary: {
    key: "PARENT",
    mapper: (name, value) => (value === "1" ? isNotNullMapper(name) : isNullMapper(name)),
  },

  operationZone: {
    key: "OPERATIVE_ZONE",
    mapper: likeMapper,
  },

  isFailure: {
    key: "FALLIDA",
    mapper: (name, value) => equalMapper(name, value === "1"),
  },

  requiresCleaning: {
    key: "REQUIRES_CLEANING",
    mapper: (name, value) => equalMapper(name, value === "1"),
  },

  requiresMaterials: {
    key: "VALIDAMATERIALES",
    mapper: (name, value) => equalMapper(name, value === "1"),
  },

  isExpired: {
    key: "VENCIMIENTO",
    mapper: (name, value) => (value !== "1" ? gtMapper(name, now) : lteMapper(name, now)),
  },

  hasPipelineInfo: {
    key: "REQUIRES_PIPELINE_INFORMATION",
    mapper: (name, value) => equalMapper(name, value === "1"),
  },

  certificate: {
    key: "CERTIFICATE",
    mapper: likeMapper,
  },

  task: {
    key: "TAREA",
    mapper: likeMapper,
  },

  comment: {
    key: "OBSERVACIONES",
    mapper: likeMapper,
  },

  odt: {
    key: "CODIGO_OT",
    mapper: (key, value) => {
      return !Array.isArray(value)
        ? likeMapper(key, value)
        : value.length === 1
        ? likeMapper(key, value[0])
        : orMapper(value.map(v => likeMapper(key, v)));
    },
  },
  street: {
    key: "CALLE",
    mapper: likeMapper,
  },
  number: {
    key: "ALTURA",
    mapper: likeMapper,
  },
  between: {
    key: "ENTRE",
    mapper: likeMapper,
  },
  and: {
    key: "Y",
    mapper: likeMapper,
  },
  creationFrom: {
    key: "CREATED_AT",
    mapper: gteDateMapper,
  },
  creationTo: {
    key: "CREATED_AT",
    mapper: lteDateMapper,
  },

  incomeFrom: {
    key: "INGRESO_OT",
    mapper: gteDateMapper,
  },
  incomeTo: {
    key: "INGRESO_OT",
    mapper: lteDateMapper,
  },
  assignedFrom: {
    key: "FECHA_ASIGNADA",
    mapper: gteDateMapper,
  },
  assignedTo: {
    key: "FECHA_ASIGNADA",
    mapper: lteDateMapper,
  },
  selectedGroups: {
    key: "GRUPO",
    mapper: inMapper,
  },
  selectedContractors: {
    key: "FOR_CONTRACTOR",
    mapper: (name, values) => {
      const inQuery = inMapper(
        name,
        values.map(v => (v === "null" ? null : v === "true"))
      );
      if (values.includes("null")) {
        return orMapper([inQuery, isNullMapper(name)]);
      } else {
        return inQuery;
      }
    },
  },
  selectedCities: {
    key: "LOCALIDAD",
    mapper: inMapper,
  },
  selectedTypes: {
    key: "TIPO",
    mapper: inMapper,
  },

  states: {
    key: "ESTADO",
    mapper: (name, values) => {
      const hasFalse = Object.entries(values).find(([_, value]) => !value);
      if (!hasFalse) return null;

      const v = Object.entries(values)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);

      return inMapper(name, v);
    },
  },
};

// $hasPipeline filters
export const getHasPipelinesFilters = ({ taskListPipelineFilter: state }) => {
  return null;
};

export const Taskskeys = Object.keys(TasksFilters);

// $hasTareaAccion filters
export const getStateChangeFilters = ({ taskListStateChangesFilter: state }) => {
  const filters = [];
  const keys = Object.keys(state);

  keys.forEach(k => {
    const value = state[k];
    if (ChangeStateskeys.includes(k) && value) {
      const name = ChangeStateFilters[k].key;
      const mapped = ChangeStateFilters[k].mapper(name, value);
      mapped && filters.push(mapped);
    }
  });

  return filters.length ? andMapper(filters) : null;
};

export const ChangeStateFilters = {
  dateFrom: {
    key: "created_at",
    mapper: gteDateMapper,
  },
  dateTo: {
    key: "created_at",
    mapper: lteDateMapper,
  },
  statesChanges: {
    key: "estado_id",
    mapper: (name, values) => {
      const v = Object.keys(values);
      const keys = v.filter(key => values[key]);
      return keys && keys.length ? inMapper(name, keys) : null;
    },
  },
};

const ChangeStateskeys = Object.keys(ChangeStateFilters);

// $hasCuadrilla filters
export const getGangsFilters = state => {
  const {
    groups: { groups: myGroups },
    taskListGangFilter: { selectedGangs },
  } = state;
  const array = [];
  const gangs = filterGangs(selectedGangs);
  const showWithoutGang = selectedGangs.includes("no-gang");
  const showInactive = selectedGangs.includes("no-group");
  const showOthers = selectedGangs.includes("other-group");
  const myGroupsIds = myGroups.map(({ id }) => id);

  if (showWithoutGang) array.push(isNotNullMapper("id"));
  if (showInactive) array.push(isNullMapper("grupo_id"));
  if (showOthers) array.push(notInMapper("grupo_id", myGroupsIds));
  if (gangs.length) array.push(inMapper("id", gangs));

  return array.length ? orMapper(array) : null;
};

// $hasAudits filters
export const AuditStateFilters = {
  task: {
    key: "old_values",
    mapper: (key, value) => likeMapper(key, `\\"tarea\\":\\"%${value}%\\"`),
  },
  odt: {
    key: "old_values",
    mapper: (key, value) => likeMapper(key, `\\"codigo_ot\\":\\"%${value}%\\"`),
  },
  street: {
    key: "old_values",
    mapper: (key, value) => likeMapper(key, `\\"calle\\":\\"%${value}%\\"`),
  },
  number: {
    key: "old_values",
    mapper: (key, value) => likeMapper(key, `\\"altura\\":\\"%${value}%\\"`),
  },
  between: {
    key: "old_values",
    mapper: (key, value) => likeMapper(key, `\\"entre\\":\\"%${value}%\\"`),
  },
  and: {
    key: "old_values",
    mapper: (key, value) => likeMapper(key, `\\"y\\":\\"%${value}%\\"`),
  },
};

export const getAuditsFilters = ({ taskListHistoryChangesFilter: state }) => {
  const filters = [];
  const keys = Object.keys(state);

  const AuditsStateskeys = Object.keys(AuditStateFilters);

  keys.forEach(k => {
    const value = state[k];
    if (AuditsStateskeys.includes(k) && value) {
      const name = AuditStateFilters[k].key;
      const mapped = AuditStateFilters[k].mapper(name, value);
      mapped && filters.push(mapped);
    }
  });

  return filters.length ? andMapper(filters) : null;
};

// $hasSidewalks filters
export const getSidewalkFilters = state => {
  const {
    taskListSidewalkFilter: {
      sidewalkType,
      sidewalkUnit,
      sidewalkState,
      sidewalkMinAmount,
      sidewalkMaxAmount,
      withSidewalk,
    },
  } = state;

  const array = [];
  if (withSidewalk === "1") array.push(gteMapper("type_id", "1"));
  if (sidewalkType) array.push(equalMapper("type_id", sidewalkType));
  if (sidewalkUnit) array.push(equalMapper("unit_id", sidewalkUnit));
  if (sidewalkState) array.push(equalMapper("state", sidewalkState));
  if (sidewalkMinAmount) array.push(gteMapper("amount", sidewalkMinAmount));
  if (sidewalkMaxAmount) array.push(lteDateMapper("amount", sidewalkMaxAmount));

  return array.length ? andMapper(array) : null;
};

// $hasHidrojet filters
export const getHidrojetFilters = state => {
  const {
    taskListHidrojetFilter: {
      dateTo,
      dateFrom,
      hidrojetState,
      hidrojetMinModule,
      hidrojetMaxModule,
    },
  } = state;

  const array = [];

  if (dateTo) array.push(equalMapper("created_at", lteDateMapper));
  if (dateFrom) array.push(equalMapper("created_at", gteDateMapper));
  if (hidrojetState) array.push(equalMapper("state", hidrojetState));
  if (hidrojetMinModule) array.push(gteMapper("module", hidrojetMinModule));
  if (hidrojetMaxModule) array.push(lteDateMapper("module", hidrojetMaxModule));

  return array.length ? andMapper(array) : null;
};
