import { memo } from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";

const Title = memo(props => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between",
      }}>
      <Typography variant="h4" style={{ fontWeight: 300, marginBottom: 8 }}>
        Listado de grupos
      </Typography>
      <Typography
        style={{
          fontSize: "0.9rem",
          fontWeight: 350,
          color: "#333",
        }}>
        {!props.isFetching ? `${props.groups} resultados` : ""}
      </Typography>
    </div>
  );
});

const mapStateToProps = state => ({
  groups: state.groups.groups.length,
});

export default connect(mapStateToProps)(Title);
