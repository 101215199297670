import { connect } from "react-redux";

import { closeModal, save } from "../../../../store/ducks/taskListHistoryChangesFilter";
import HistoryChangesDialog from "./historyChangesDialogContainer/historyChangesDialog";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  openModal: state.taskListHistoryChangesFilter.openModal,
  street: state.taskListHistoryChangesFilter.street,
  number: state.taskListHistoryChangesFilter.number,
  between: state.taskListHistoryChangesFilter.between,
  and: state.taskListHistoryChangesFilter.and,
  odt: state.taskListHistoryChangesFilter.odt,
  task: state.taskListHistoryChangesFilter.task,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: states => {
    dispatch(save(states));
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryChangesDialog);
