import { connect } from "react-redux";

import { closeModal, finishValidation } from "../../../store/ducks/finishValidationModal";
import { canConfirm } from "../../../store/ducks/task";
import FinishValidationModal from "./finishValidationModalContainer/FinishValidationModal";

const mapStateToProps = state => ({
  task: state.task,
  canConfirm: canConfirm(state),
  open: state.finishValidationModal.modalOpen,
  sending: state.finishValidationModal.sending,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  confirm: (state, navigation) => dispatch(finishValidation(state, navigation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinishValidationModal);
