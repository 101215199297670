import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";

import Title from "./hidrojetList/Title";
import Preloader from "../../components/UI/Preloader";
import ToolbarContainer from "./hidrojetList/ToolbarContainer";
import HidrojetTableContainer from "./hidrojetList/HidrojetTableContainer";
import DownloadDialogContainer from "./hidrojetList/DownloadDialogContainer";

import EditHidrojetDialogContainer from "../taskDetailsContainer/taskDetails/editHidrojetModulesDialog";

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  tableContainer: {},
});

const taskList = props => {
  const { classes } = props;
  return props.isFetching ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Paper elevation={0} variant="outlined">
            <Grid container className={classes.title}>
              <Grid item xs>
                <Title />
              </Grid>
            </Grid>
            <ToolbarContainer />
            <div className={classes.tableContainer}>
              <HidrojetTableContainer />
            </div>
            <DownloadDialogContainer />
            <EditHidrojetDialogContainer />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(taskList);
