import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";

import ReportDownloadAction from "./ReportDownloadAction";
import { memo } from "react";

const styles = {
  dropdown: {
    minWidth: 80,
  },
};

const onClick = (task, openModal, handleClose) => {
  handleClose();
  openModal(task);
};

const actions = memo(props => {
  const state = Number(props.task.estado.id);
  const type = Number(props.task.tipo.id);
  const hasNotRequiredArticles = props.task.notRequiredArticles?.length;
  const requireArticles = props.task.valida_materiales;
  const failed = props.task.fallida;
  const canCancel = !props.task.has_materials;

  return (
    <>
      {[6, 8, 9, 10, 11].includes(state) && props.task.tarea_accion ? (
        <>
          <ReportDownloadAction task={props.task} />
        </>
      ) : null}

      {[6, 8, 9, 10, 11].includes(state) ? (
        <>
          <MenuItem
            className={props.classes.dropdown}
            onClick={() => props.onDownload(props.task.id)}>
            Descargar planilla AYSA
          </MenuItem>
        </>
      ) : null}

      {[8, 9].includes(state) &&
      [1, 2, 3, 4, 5, 6, 8].includes(type) &&
      props.task.sidewalks.length ? (
        <>
          <MenuItem
            className={props.classes.dropdown}
            onClick={() =>
              onClick(props.task, props.openCivilTaskModal, props.handleClose)
            }>
            Generar tarea complementaria
          </MenuItem>
        </>
      ) : null}

      {state !== 4 && state !== 9 ? <Divider /> : null}

      {[11, 2, 3].includes(state) ? (
        <MenuItem
          className={props.classes.dropdown}
          onClick={() => onClick(props.task, props.openPendingModal, props.handleClose)}>
          Pasar a pendiente
        </MenuItem>
      ) : null}
      {[1, 11, 2, 3, 6, 7, 8, 5].includes(state) ? (
        <MenuItem
          className={props.classes.dropdown}
          onClick={() => onClick(props.task, props.openAssignModal, props.handleClose)}>
          Asignar
        </MenuItem>
      ) : null}
      {[1, 11, 2, 3, 6, 7, 8, 5].includes(state) ? (
        <MenuItem
          className={props.classes.dropdown}
          onClick={() => onClick(props.task, props.openScheduleModal, props.handleClose)}>
          Programar
        </MenuItem>
      ) : null}
      {state === 5 && (requireArticles || hasNotRequiredArticles) && !failed ? (
        <MenuItem
          className={props.classes.dropdown}
          component={Link}
          to={`/validacion/materiales/${props.task.id}`}
          onClick={props.handleClose}>
          Validar materiales
        </MenuItem>
      ) : null}
      {state === 6 ||
      (state === 5 && ((!requireArticles && !hasNotRequiredArticles) || failed)) ? (
        <MenuItem
          className={props.classes.dropdown}
          onClick={() =>
            onClick(props.task, props.openInspectionModal, props.handleClose)
          }>
          Pasar a inspección
        </MenuItem>
      ) : null}
      {[8].includes(state) ? (
        <MenuItem
          className={props.classes.dropdown}
          onClick={() => onClick(props.task, props.openApproveModal, props.handleClose)}>
          Aprobar
        </MenuItem>
      ) : null}
      {[8].includes(state) ? (
        <MenuItem
          className={props.classes.dropdown}
          onClick={() => onClick(props.task, props.openRejectModal, props.handleClose)}>
          Rechazar
        </MenuItem>
      ) : null}

      {canCancel && [1, 11, 2, 3, 6, 8, 5].includes(state) ? (
        <MenuItem
          className={props.classes.dropdown}
          onClick={() => onClick(props.task, props.openCancelModal, props.handleClose)}>
          Cancelar
        </MenuItem>
      ) : null}
    </>
  );
});

export default withStyles(styles)(actions);
