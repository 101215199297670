import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const DownloadDialog = props => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Descargar tareas</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Se descargará la planilla de las tareas seleccionadas.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} style={{ color: "black" }} color="primary">
          Cancelar
        </Button>
        <LoadingButton
          color="primary"
          disableElevation
          loading={props.loading}
          onClick={props.confirmDownload}>
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDialog;
