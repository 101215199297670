import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Alert, InputLabel, Select, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const styles = theme => ({
  form: {
    marginBottom: theme.spacing(1.5),
  },
  input: {
    marginTop: theme.spacing(3),
  },
  confirmBtn: {
    width: 110,
  },
});

const AssignDialog = props => {
  const { classes, closeModal, openModal, stores, task, roles } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const selectedStoresIds = task && task.stores ? JSON.parse(task.stores) : [];
  const selectedStores = stores.filter(s => selectedStoresIds.includes(s._id));

  const hasPendingSidewalk =
    task && task.sidewalks.some(s => s.state === 0) && task.parent_id;
  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      maxWidth={"sm"}
      open={openModal}
      onClose={closeModal}>
      <DialogTitle>Asignar tarea {props.task?.codigo_ot}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Dirección: {props.task?.ubicacion}
        </Typography>

        {hasPendingSidewalk ? (
          <Alert severity="warning" style={{ marginTop: 20 }}>
            Para realizar la asignación de esta tarea es necesario realizar primero la
            validación de información de vereda.{" "}
            {roles.includes("validarVereda") ? (
              <span>
                Puedes hacer esto haciendo click{" "}
                <Link
                  target="_blank"
                  href={`${process.env.PUBLIC_URL}/validacion/vereda/${task.id}`}>
                  aquí
                </Link>
                .
              </span>
            ) : null}
          </Alert>
        ) : null}

        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            {isMobile ? (
              <FormControl fullWidth variant="outlined" style={{ marginTop: 20 }}>
                <InputLabel variant="outlined" htmlFor="uncontrolled-native">
                  Cuadrilla
                </InputLabel>
                <Select
                  native
                  label="Cuadrilla"
                  variant="outlined"
                  onChange={e => {
                    props.changeGang(
                      e.target.value
                        ? props.gangs.find(g => g.id === e.target.value)
                        : null
                    );
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={hasPendingSidewalk}>
                  <option value="" key="0"></option>
                  {props.gangs.map(g => (
                    <option value={g.id} key={g.id}>
                      {g.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Autocomplete
                options={props.gangs}
                noOptionsText="No hay cuadrillas disponibles"
                value={props.gang}
                disabled={hasPendingSidewalk}
                onChange={(_, value) => {
                  props.changeGang(value);
                }}
                getOptionLabel={option => option.name}
                getOptionDisabled={option => props.gang && option.id === props.gang.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.input}
                    label="Cuadrilla"
                    error={props.gangError}
                    disabled={hasPendingSidewalk}
                    helperText={props.gangError ? "Seleccione una cuadrilla" : ""}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            )}

            <TextField
              value={props.comment}
              onChange={e => props.changeComment(e.target.value)}
              className={classes.input}
              label="Observaciones"
              disabled={hasPendingSidewalk}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormGroup>
        </FormControl>

        {!hasPendingSidewalk && selectedStores && selectedStores.length ? (
          <Alert severity="info">
            Esta tarea solo permite la utilización de materiales{" "}
            {selectedStores.length === 1 ? "del depósito" : "de los depósitos"}:{" "}
            <ul>
              {selectedStores.map(s => (
                <li key={s._id}>{`${s.name} - ${s.type.name}`}</li>
              ))}
            </ul>
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal} disabled={props.loading}>
          Cancelar
        </Button>

        <LoadingButton
          loading={props.loading}
          color="primary"
          onClick={props.confirm}
          disabled={hasPendingSidewalk}>
          Asignar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AssignDialog);
