import { connect } from "react-redux";

import { handleMoveClick } from "../../../../../store/ducks/map";
import { getSelectedTasks } from "../../../../../store/ducks/mapTasks";
import TaskList from "./taskListContainer/TaskList";

const mapStateToProps = state => ({
  tasks: getSelectedTasks(state),
});

const mapDispatchToProps = dispatch => ({
  handleMoveClick: coordinates => dispatch(handleMoveClick(coordinates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
