import { connect } from "react-redux";

import {
  removeAddress,
  openModal,
} from "../../../../../store/ducks/taskListAddressFilter";
import Address from "./addressContainer/Address";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  street: state.taskListAddressFilter.street,
  number: state.taskListAddressFilter.number,
  between: state.taskListAddressFilter.between,
  and: state.taskListAddressFilter.and,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeAddress: () => {
    dispatch(removeAddress());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Address);
