import { orderBy } from "lodash";
import { createSelector } from "reselect";
import escapeStringRegexp from "escape-string-regexp";

import apollo from "../../apollo";

import { setTotal, setLoading, setPendingPages } from "./downloadTasksPagination";

import { getTaskOrderCriteria } from "../../graphql/hidrojet/order";
import { GET_HIDROJET_TASKS } from "../../graphql/hidrojet/queries";
import { getHidrojetFilters, getTaskFilters } from "../../graphql/hidrojet/filters";

const types = {
  TASKS_REQUEST: "HIDROJET_LIST_REQUEST",
  TASKS_SUCCESS: "HIDROJET_LIST_SUCCESS",
  TASKS_FAILURE: "HIDROJET_LIST_FAILURE",
  HIDROJET_ADD_MORE: "HIDROJET_LIST_ADD_MORE",
  TASKS_UPDATE_SHEET: "HIDROJET_LIST_UPDATE_SHEET",
  TASKS_UPDATE_SUMMARY: "HIDROJET_LIST_UPDATE_SUMMARY",
};

const parseVariables = object => {
  Object.keys(object).forEach(k => (object[`$${k}`] = object[k]));
  return object;
};

const requestTasks = () => ({
  type: types.TASKS_REQUEST,
});

const successTasks = tasks => ({
  type: types.TASKS_SUCCESS,
  tasks,
});

const addMoreTasks = tasks => ({
  type: types.HIDROJET_ADD_MORE,
  tasks,
});

const failedTasks = () => ({
  type: types.TASKS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las tareas",
});

export const updateTask = task => ({
  type: types.TASKS_UPDATE_SHEET,
  task,
});

export const updateTasks = tasks => ({
  type: types.TASKS_UPDATE_SUMMARY,
  tasks,
});

export const getTasks = (page = 1, reset) => {
  return async (dispatch, getState) => {
    if (reset) dispatch(requestTasks());
    if (page === 1) dispatch(setLoading(true));

    const state = getState();

    try {
      await apollo.clearStore();
      await apollo.resetStore();

      const where = getHidrojetFilters(state);
      const whereTask = getTaskFilters(state);
      const order_by = getTaskOrderCriteria("creation", true);

      const { data } = await apollo.query({
        query: GET_HIDROJET_TASKS,
        variables: parseVariables({
          where,
          page,
          order_by,
          first: 700,
          hasTask: whereTask,
        }),
      });
      const { hidrojets } = data;

      if (page === 1) {
        dispatch(setLoading(false));
        dispatch(successTasks(hidrojets.data));

        dispatch(setTotal(hidrojets.paginatorInfo.total));
      } else {
        dispatch(addMoreTasks(hidrojets.data));
      }

      if (hidrojets.paginatorInfo.hasMorePages) {
        dispatch(setPendingPages(true));
        dispatch(getTasks(page + 1, false));
      } else {
        dispatch(setPendingPages(false));
      }
    } catch (error) {
      if (
        error.message &&
        error.message ===
          "Store reset while query was in flight (not completed in link chain)"
      ) {
        return false;
      }
      dispatch(failedTasks());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.TASKS_SUCCESS:
      return action.tasks;
    case types.HIDROJET_ADD_MORE:
      return [...state, ...action.tasks];
    case types.TASKS_UPDATE_SHEET:
      return state.map(task =>
        task.id === action.task.id ? { ...task, ...action.task } : task
      );
    case types.TASKS_UPDATE_SUMMARY:
      return state.map(task => {
        const selected = action.tasks.find(t => t.id === task.id);
        return selected ? { ...task, ...selected } : task;
      });
    default:
      return state;
  }
};

const getAllHidrojets = state => state.hidrojetList;
const getSelectedTasks = state => state.selectedTasks;
const getOdtFilter = state => state.hidrojetListFilter.odt;
const getGroupFilter = state => state.hidrojetListFilter.group;
const getFromFilter = state => state.hidrojetListFilter.dateFrom;
const getToFilter = state => state.hidrojetListFilter.dateTo;
const getShowSelectedTasks = state => state.hidrojetListFilter.showSelectedTasks;

export const mapSelectedTasks = createSelector(
  [getAllHidrojets, getSelectedTasks, getOdtFilter, getGroupFilter, getShowSelectedTasks],
  (allHidrojets, selectedTasks, odt, group, showSelectedTasks) => {
    const regex = new RegExp(`.*${escapeStringRegexp(odt)}.*`, "i");
    return allHidrojets
      .filter(
        h =>
          h.task.codigo_ot.match(regex) &&
          (group ? Number(h.task.grupo.id) === Number(group) : true) &&
          (showSelectedTasks ? selectedTasks.includes(h.id) : true)
      )
      .filter(function (item, pos) {
        return allHidrojets.findIndex(i => i.id === item.item) === pos;
      })
      .map(hidrojet => ({
        ...hidrojet,
        selected: !!selectedTasks.find(id => id === hidrojet.id),
      }));
  }
);

export const mapSelectedHidrojetsForDownload = createSelector(
  [
    getAllHidrojets,
    getSelectedTasks,
    getOdtFilter,
    getGroupFilter,
    getFromFilter,
    getToFilter,
    getShowSelectedTasks,
  ],
  (allHidrojets, selectedTasks, odt, group, dateFrom, dateTo, showSelectedTasks) => {
    const regex = new RegExp(`.*${escapeStringRegexp(odt)}.*`, "i");
    return orderBy(
      allHidrojets
        .filter(
          h =>
            h.task.codigo_ot.match(regex) &&
            (group ? Number(h.task.grupo.id) === Number(group) : true) &&
            (showSelectedTasks ? selectedTasks.includes(h.id) : true)
        )
        .filter(function (item, pos) {
          return allHidrojets.findIndex(i => i.id === item.id) === pos;
        })
        .map(hidrojet => ({
          ...hidrojet,
          validated: hidrojet.validated_at ? hidrojet.validated_at : undefined,
          selected: !!selectedTasks.find(id => id === hidrojet.id),
        })),
      ["validated", "updated_at"],
      ["desc", "desc"]
    );
  }
);

export default reducer;
