import { Fragment } from "react";
import Typography from "@mui/material/Typography";

const title = () => {
  return (
    <Fragment>
      <Typography variant="h5" component="h3">
        Descarga de tareas con materiales validados
      </Typography>
      <Typography component="p" style={{ marginTop: 10 }}>
        Seleccione las tareas que desea descargar.
      </Typography>
    </Fragment>
  );
};

export default title;
