import { format, addMinutes } from "date-fns";
import withStyles from "@mui/styles/withStyles";

import ExpandMoreIcon from "@mui/icons-material/ArrowDownward";
import ExpandLessIcon from "@mui/icons-material/ArrowUpward";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import TaskIcon from "@mui/icons-material/Description";
import IconButton from "@mui/material/IconButton";

import { arrayMoveImmutable as arrayMove } from "array-move";
import { useDispatch } from "react-redux";
import { setNewOrderTask } from "../../../../store/ducks/gangTasksReorder";
import { Filter1Outlined, DragHandleOutlined } from "@mui/icons-material";
import { useCallback, useMemo } from "react";

const dateParser = date => {
  date = new Date(date.replace(/ /g, "T"));
  return format(addMinutes(date, date.getTimezoneOffset()), "dd/MM/yyyy");
};

const styles = () => ({
  title: {
    fontWeight: 400,
    paddingRight: 35,
  },
  container: {
    position: "relative",
    paddingBottom: 0,
  },
  contentContainer: {
    paddingBottom: "10px !important",
    paddingTop: 10,
    minHeight: 100,
    position: "relative",
  },
  listIcon: {
    minWidth: 40,
  },
  actions: {
    display: "flex",
    marginTop: -20,
    marginBottom: -10,
    justifyContent: "flex-end",
    position: "absolute",
    right: 16,
    bottom: 15,
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
  moveButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: 10,
  },
  titleWithMoveActivatorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "grabbing",
  },
});

const getInitials = type => {
  if (type.trim().indexOf(" ") === -1) {
    return type.substring(0, 2).toUpperCase();
  }
  let initials = type.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

const getStateClass = state => {
  if (state === "Programada") {
    return "Programada-T";
  } else if (state === "Validada") {
    return "Materiales-validados";
  }
  return state;
};

const TaskCard = ({ task, classes, position, items, listeners, activatorNodeRef }) => {
  const dispatch = useDispatch();

  const onMove = useCallback(
    newIndex => {
      dispatch(setNewOrderTask(arrayMove(items, position, newIndex)));
    },
    [items, position, dispatch]
  );
  const taskText = useMemo(() => {
    let taskText = "";
    if (task.modulo) taskText += `MOD ${task.modulo}. `;
    taskText += task.tarea;
    return taskText;
  }, [task]);

  return (
    <Card variant="outlined">
      <CardHeader
        ref={activatorNodeRef}
        className={classes.container}
        title={
          <div
            {...listeners}
            ref={activatorNodeRef}
            className={classes.titleWithMoveActivatorContainer}>
            <Tooltip title="Reordenar">
              <IconButton size="large" style={{ cursor: "grabbing" }}>
                <DragHandleOutlined />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className={classes.title}>
              {task.full_address}
            </Typography>
          </div>
        }
        subheader={
          <>
            <Tooltip title="Código OT">
              <span> {task.codigo_ot}</span>
            </Tooltip>{" "}
            <Tooltip title="Fecha de asignación">
              <span>{dateParser(task.fecha_asignada)}</span>
            </Tooltip>
          </>
        }
        action={
          <div className={classes.moveButtonsContainer}>
            <Tooltip title="Posicionar primera">
              <span>
                <IconButton
                  onClick={() => onMove(0)}
                  disabled={position === 0}
                  size="large">
                  <Filter1Outlined />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        }
      />
      <CardContent className={classes.contentContainer}>
        <Grid container>
          <Grid item xs={9}>
            <List component="nav" disablePadding dense>
              <ListItem dense disableGutters>
                <Tooltip title="Tarea a realizar">
                  <ListItemIcon className={classes.listIcon}>
                    <TaskIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={taskText} />
              </ListItem>
            </List>
          </Grid>
          <Grid item className={classes.actions}>
            {task.parent_id ? (
              <Tooltip title="Tarea Complementaria" style={{ marginRight: 5 }}>
                <p className={[classes.stateIcon, "complementary"]}>CO</p>
              </Tooltip>
            ) : null}

            <Tooltip title={task.tipo.tipo} style={{ marginRight: 5 }}>
              <p className={[classes.stateIcon, "type"]}>{getInitials(task.tipo.tipo)}</p>
            </Tooltip>
            <Tooltip title={task.estado.estado} style={{ marginRight: 60 }}>
              <p className={[classes.stateIcon, getStateClass(task.estado.estado)]}>
                {getInitials(task.estado.estado)}
              </p>
            </Tooltip>

            <div style={{ position: "relative" }}>
              <IconButton
                disabled={!position}
                style={{
                  right: -5,
                  bottom: 60,
                  position: "absolute",
                }}
                onClick={() => onMove(position - 1)}
                size="large">
                <ExpandLessIcon />
              </IconButton>
            </div>

            <div style={{ position: "relative" }}>
              <IconButton
                onClick={() => onMove(position + 1)}
                style={{
                  right: -5,
                  bottom: 7,
                  position: "absolute",
                }}
                disabled={position === items.length - 1}
                size="large">
                <ExpandMoreIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(TaskCard);
