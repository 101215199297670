/* eslint-disable react/no-unescaped-entities */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ConfirmValidationModal = props => {
  const { sidewalk } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      open={props.open}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Confirmar información de vereda</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontSize: 18 }}>
          Al confirmar esta acción, la información de vereda quedará validada.
        </DialogContentText>

        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="1">
              <TableCell component="th" scope="row">
                Tipo
              </TableCell>
              <TableCell align="right">{sidewalk?.type.name}</TableCell>
            </TableRow>

            {sidewalk?.article_description ? (
              <TableRow key="0">
                <TableCell component="th" scope="row">
                  Baldosa
                </TableCell>
                <TableCell align="right">
                  {sidewalk?.article_description || "-"}
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow key="2">
              <TableCell component="th" scope="row">
                Unidad de Medida
              </TableCell>
              <TableCell align="right">{sidewalk?.unit.name}</TableCell>
            </TableRow>

            <TableRow key="3">
              <TableCell component="th" scope="row">
                Cantidad
              </TableCell>
              <TableCell align="right">{sidewalk?.amount}</TableCell>
            </TableRow>

            <TableRow key="4">
              <TableCell component="th" scope="row">
                Requiere Aporte
              </TableCell>
              <TableCell align="right">
                {sidewalk?.requires_filling ? "Sí" : "No"}
              </TableCell>
            </TableRow>

            <TableRow key="5">
              <TableCell component="th" scope="row">
                Observación
              </TableCell>
              <TableCell align="right">{sidewalk?.observations || "-"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "black" }}
          onClick={props.onClose}
          disabled={props.sending}>
          Cancelar
        </Button>
        <Button color="primary" onClick={props.confirm} disabled={props.sending}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmValidationModal;
