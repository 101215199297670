import axios from "../../axios";

import { enqueueError } from "./notifications";

const types = {
  TASK_ARTICLES_REQUEST: "TASK_ARTICLES_REQUEST",
  TASK_ARTICLES_SUCCESS: "TASK_ARTICLES_SUCCESS",
  TASK_ARTICLES_FAILURE: "TASK_ARTICLES_FAILURE",
  TASK_ARTICLES_CLOSE_MODAL: "TASK_ARTICLES_CLOSE_MODAL",
};

const parseArticles = articles =>
  articles.map(a => ({
    id: a.id_articulo,
    name: a.descripcion,
    code: a.cod_equivalente,
    amount: a.cantidad,
  }));

export const openModal = (taskId, odt) => async dispatch => {
  dispatch(request(odt));
  try {
    const { data } = await axios.get(`/tasks/${taskId}`);
    dispatch(success(parseArticles(data.data.materiales)));
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al obtener los materiales"));
    dispatch(failure());
  }
};

export const closeModal = () => ({
  type: types.TASK_ARTICLES_CLOSE_MODAL,
});

const request = odt => ({
  type: types.TASK_ARTICLES_REQUEST,
  odt,
});

const success = articles => ({
  type: types.TASK_ARTICLES_SUCCESS,
  articles,
});

const failure = () => ({
  type: types.TASK_ARTICLES_FAILURE,
});

const initialState = {
  open: false,
  loading: false,
  articles: [],
  odt: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_ARTICLES_REQUEST:
      return { ...state, loading: true, open: true, odt: action.odt };
    case types.TASK_ARTICLES_SUCCESS:
      return { ...state, loading: false, articles: action.articles };
    case types.TASK_ARTICLES_FAILURE:
      return { ...state, loading: false, open: false };
    case types.TASK_ARTICLES_CLOSE_MODAL:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default reducer;
