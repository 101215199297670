import format from "date-fns/format";
import axios from "../../axios";
import {
  updateTasks as updateDownloadTasks,
  updateTask as updateDownloadTask,
} from "./downloadTaskList";

import { enqueueError } from "./notifications";

const types = {
  DOWNLOAD_TASKS_OPEN_MODAL: "DOWNLOAD_TASKS_OPEN_MODAL",
  DOWNLOAD_TASKS_CLOSE_MODAL: "DOWNLOAD_TASKS_CLOSE_MODAL",
  DOWNLOAD_TASKS_REQUEST: "DOWNLOAD_TASKS_REQUEST",
  DOWNLOAD_TASKS_SUCCESS: "DOWNLOAD_TASKS_SUCCESS",
  DOWNLOAD_TASKS_FAILURE: "DOWNLOAD_TASKS_FAILURE",
  DOWNLOAD_TASK_REQUEST: "DOWNLOAD_TASK_REQUEST",
  DOWNLOAD_TASK_SUCCESS: "DOWNLOAD_TASK_SUCCESS",
  DOWNLOAD_TASK_FAILURE: "DOWNLOAD_TASK_FAILURE",
};

export const openModal = () => ({
  type: types.DOWNLOAD_TASKS_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.DOWNLOAD_TASKS_CLOSE_MODAL,
});

const request = () => ({
  type: types.DOWNLOAD_TASKS_REQUEST,
});

const success = () => ({
  type: types.DOWNLOAD_TASKS_SUCCESS,
});

const failure = () => ({
  type: types.DOWNLOAD_TASKS_FAILURE,
});

const requestSimple = taskId => ({
  type: types.DOWNLOAD_TASK_REQUEST,
  taskId,
});

const successSimple = () => ({
  type: types.DOWNLOAD_TASK_SUCCESS,
});

const failureSimple = () => ({
  type: types.DOWNLOAD_TASK_FAILURE,
});

export const confirmDownload = tasks => async dispatch => {
  try {
    const response = await axios({
      method: "POST",
      url: `/tasks/download`,
      responseType: "blob",
      data: { tasks },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Informe.zip");
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al descargar"));
  }
};

export const confirmComposedDownload = () => async (dispatch, getState) => {
  const { selectedTasks, user } = getState();
  dispatch(request());
  try {
    const tasks = selectedTasks;
    const response = await axios({
      method: "POST",
      url: `/tasks/download`,
      responseType: "blob",
      data: { tasks },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Informe.zip");
    document.body.appendChild(link);
    link.click();
    dispatch(success());
    const date = format(new Date(), "dd/MM/yyyy HH:mm");
    const selected = selectedTasks.map(taskId => ({
      id: taskId,
      summaryGenerated: `${user.name} [${date}]`,
    }));
    // dispatch(updateTasks(selected));
    dispatch(updateDownloadTasks(selected));
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al descargar"));
    dispatch(failure());
  }
};

export const confirmSimpleDownload = taskId => async (dispatch, getState) => {
  const { user } = getState();
  dispatch(requestSimple(taskId));
  try {
    const response = await axios({
      method: "GET",
      url: `/tasks/${taskId}/download`,
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ODT.xlsx");
    document.body.appendChild(link);
    link.click();
    dispatch(successSimple());
    const date = format(new Date(), "dd/MM/yyyy HH:mm");
    // dispatch(updateTask({ id: taskId, sheetGenerated: `${user.name} [${date}]` }));
    dispatch(
      updateDownloadTask({ id: taskId, sheetGenerated: `${user.name} [${date}]` })
    );
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al descargar"));
    dispatch(failureSimple());
  }
};

const initialState = {
  open: false,
  loading: false,
  loadingTask: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DOWNLOAD_TASKS_REQUEST:
      return { ...state, loading: true };
    case types.DOWNLOAD_TASKS_SUCCESS:
      return { ...state, loading: false, open: false };
    case types.DOWNLOAD_TASKS_FAILURE:
      return { ...state, loading: false };
    case types.DOWNLOAD_TASK_REQUEST:
      return { ...state, loadingTask: action.taskId };
    case types.DOWNLOAD_TASK_SUCCESS:
      return { ...state, loadingTask: null };
    case types.DOWNLOAD_TASK_FAILURE:
      return { ...state, loadingTask: null };
    case types.DOWNLOAD_TASKS_OPEN_MODAL:
      return { ...state, open: true };
    case types.DOWNLOAD_TASKS_CLOSE_MODAL:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default reducer;
