/* eslint-disable react/no-unescaped-entities */
import DvrIcon from "@mui/icons-material/Dvr";
import RoomIcon from "@mui/icons-material/Room";
import InfoIcon from "@mui/icons-material/Info";
import GangIcon from "@mui/icons-material/Person";
import TasksIcon from "@mui/icons-material/Build";
import LayersIcon from "@mui/icons-material/Layers";
import DownloadIcon from "@mui/icons-material/GetApp";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ArticleIcon from "@mui/icons-material/Article";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ValidationIcon from "@mui/icons-material/TouchApp";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FilterListIcon from "@mui/icons-material/FilterList";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { DragHandleOutlined, ImportExportOutlined } from "@mui/icons-material";

export default [
  {
    version: "3.0.27",
    timestamp: 1692904629,
    changes: [
      {
        avatar: <DvrIcon />,
        title: "Mejoras generales",
        description: (
          <div>
            Se incorpora el campo opcional "Zona de operación" a la carga de tareas y su
            respectivo filtro al listado de tareas.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.26",
    timestamp: 1666186818,
    changes: [
      {
        avatar: <DvrIcon />,
        title: "Mejoras generales",
        description: (
          <div>
            Se actualizan librerías y dependencias necesarias para el funcionamiento del
            sistema.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.25",
    timestamp: 1665277418,
    changes: [
      {
        avatar: <ImportExportOutlined />,
        title: "Reordenamiento de tareas",
        description: (
          <div style={{ marginTop: 5 }}>
            Se actualiza el módulo de reordenamiento de tareas programadas de las
            cuadrillas para solucionar un problema de interfaz general que provocaba la
            duplicación de tarjetas de tareas.
          </div>
        ),
      },
      {
        avatar: <DragHandleOutlined />,
        title: "Nuevo esquema de arrastre para reordenar tareas",
        description: (
          <div>
            Las tareas programadas solo se pueden arrastrar desde su domicilio o el ícono
            de ordenamiento (a la izquierda del domicilio). El cursor cambia de forma para
            indicar que se puede realizar la acción.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.24",
    timestamp: 1663089567,
    changes: [
      {
        avatar: <TasksIcon />,
        title: "Mejoras en formularios",
        description: (
          <div style={{ marginTop: 5 }}>
            Se aplican mejoras sobre los formularios de alta y edición de grupos y tareas.
          </div>
        ),
      },
      {
        avatar: <DvrIcon />,
        title: "Mejoras generales",
        description: (
          <div>
            Se actualizan librerías y dependencias necesarias para el funcionamiento del
            sistema.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.23",
    timestamp: 1662386037,
    changes: [
      {
        avatar: <EditRoadIcon />,
        title: "Cambio en planilla ABSA",
        description: (
          <div style={{ marginTop: 5 }}>
            Se soluciona error para poder mostrar observaciones de la tarea en la
            planilla.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.22",
    timestamp: 1661261639,
    changes: [
      {
        avatar: <PhoneIcon />,
        title: "Carga de teléfono de contacto",
        description: (
          <div style={{ marginTop: 5 }}>
            Ahora al cargar o editar los datos de las tareas se puede cargar un teléfono
            de contacto que estará disponible a las cuadrillas desde la aplicación.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.21",
    timestamp: 1660660243,
    changes: [
      {
        avatar: <ValidationIcon />,
        title: "Mejoras en validación de materiales",
        description: (
          <div style={{ marginTop: 5 }}>
            Se hacen cambios en la forma de calcular el stock de las cuadrillas.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.20",
    timestamp: 1654615892,
    changes: [
      {
        avatar: <RoomIcon />,
        title: "Se agrega un nuevo tipo de tarea",
        description: (
          <div style={{ marginTop: 5 }}>
            Ahora se pueden registrar las tarea de tipo "Excavación".
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.19",
    timestamp: 1654615892,
    changes: [
      {
        avatar: <ValidationIcon />,
        title: "Mejoras en validación de materiales",
        description: (
          <div style={{ marginTop: 5 }}>
            Se agrega la posibilidad de ver y filtrar por el tipo de las tareas.
          </div>
        ),
      },
      {
        avatar: <LayersIcon />,
        title: "Datos civiles",
        description: (
          <div style={{ marginTop: 5 }}>
            Se sección de "Validación de vereda" fue renombrada "Revisión de datos
            civiles".
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.18",
    timestamp: 1652111824,
    changes: [
      {
        avatar: <DownloadIcon />,
        title: "Mejoras en validación de vereda",
        description: (
          <div style={{ marginTop: 5 }}>
            Se agregan filtros adicionales a la pantalla.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.17",
    timestamp: 1649095816,
    changes: [
      {
        avatar: <DownloadIcon />,
        title: "Mejoras en planillas",
        description: (
          <div style={{ marginTop: 5 }}>
            Ahora los materiales que se muestran en las planillas se muestran en orden
            alfabético.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.16",
    timestamp: 1648566761,
    changes: [
      {
        avatar: <TasksIcon />,
        title: "Cambios en formulario de alta y edición de tareas",
        description: (
          <div style={{ marginTop: 5 }}>
            Se actualizan los campos para completar fechas permitiendo ahora escribirlas.
          </div>
        ),
      },
      {
        avatar: <AddTaskIcon />,
        title: "Tareas complementarias",
        description: (
          <div style={{ marginTop: 5 }}>
            Al crear tareas complementarias{" "}
            <b>ahora se informa si las tareas requieren aporte y/o limpieza</b> de acuerdo
            a lo informado por las cuadrillas.
            <div style={{ marginTop: 5 }}>
              Adicionalmente, se <b>mejoran las validaciones y restricciones</b> para
              nuevas complementarias.
            </div>
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.15",
    timestamp: 1648160931,
    changes: [
      {
        avatar: <FilterListIcon />,
        title: "Nuevo filtro de tareas en el mapa",
        description: (
          <div style={{ marginTop: 5 }}>
            Se agrega un nuevo filtro en la sección "Filtros tareas" del mapa, para
            mostrar únicamente las tareas que están asociadas con una cuadrilla
            específica. Este filtro implica que no se muestren las tareas pendientes, a
            pesar de que se solicite mostrarlas, ya que no tienen cuadrilla asociada.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.14",
    timestamp: 1647891570,
    changes: [
      {
        avatar: <FactCheckIcon />,
        title: "Mejoras en aprobación de tareas",
        description: (
          <div style={{ marginTop: 5 }}>
            Al aprobar una tarea ahora se puede cargar el <b>certificado</b>{" "}
            correspondiente. Este certificado <b>puede editarse luego</b> desde la
            pantalla de edición de la tarea.
          </div>
        ),
      },
      {
        avatar: <FilterListIcon />,
        title: "Mejoras en filtros",
        description: (
          <div>
            En el listado general de tareas, al acceder a la sección "tarea" ahora se
            puede filtrar por el <b>número de certificado</b> asociado a una OT.
          </div>
        ),
      },
      {
        avatar: <FileCopyIcon />,
        title: "Cambios planilla de tareas",
        description: (
          <div style={{ marginTop: 5 }}>
            Se agregan <b>nuevas columnas</b> a la planilla. Ahora se incorporan la{" "}
            <b>fecha de validación</b>, la <b>fecha de aprobación</b> y el{" "}
            <b>certificado</b>.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.13",
    timestamp: 1646922523,
    changes: [
      {
        avatar: <EditRoadIcon />,
        title: "Cambios en validación de vereda",
        description: (
          <div>
            <div style={{ marginTop: 5 }}>
              Desde la <b>pantalla de validación</b> de las tareas ahora se permite{" "}
              <b> cargar nuevos datos de vereda</b>.
            </div>
            <div style={{ marginTop: 5 }}>
              Adicionalmente, ahora se pueden <b>editar las baldosas</b> de las veredas.
              Al terminar de editar los datos de la vereda, la misma quedará en estado
              "Validada".
            </div>
          </div>
        ),
      },
      {
        avatar: <AddTaskIcon />,
        title: "Tareas complementarias",
        description: (
          <div>
            <div style={{ marginTop: 5 }}>
              Ahora las tareas de limpieza y de aporte por defecto{" "}
              <b>no requieren materiales</b>.
            </div>
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.12",
    timestamp: 1644867577,
    changes: [
      {
        avatar: <EditRoadIcon />,
        title: "Cambios en registro de datos de vereda",
        description: (
          <div>
            <div>
              Cuando se registran o editan datos de vereda{" "}
              <b>ahora se puede especificar el tipo de baldosa utilizada</b>. Esta opción
              aparece unicamente cuando el tipo de vereda seleccionado es "Vereda común" o
              "Vereda especial".
            </div>
            <div style={{ marginTop: 10 }}>
              Adicionalmente,{" "}
              <b>
                se elimina la restricción que permitía cargar un tipo de tarea una unica
                vez
              </b>
              .
            </div>
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.11",
    timestamp: 1643830194,
    changes: [
      {
        avatar: <TasksIcon />,
        title: "Cambios en la creación de tareas",
        description: (
          <div>Ahora por defecto las tareas de vereda creadas requiren materiales.</div>
        ),
      },
    ],
  },
  {
    version: "3.0.10",
    timestamp: 1643301866,
    changes: [
      {
        avatar: <FilterListIcon />,
        title: "Mejoras generales",
        description: <div>Se soluciona un problema con el filtro de cuadrillas.</div>,
      },
    ],
  },
  {
    version: "3.0.9",
    timestamp: 1642685929,
    changes: [
      {
        avatar: <AssignmentTurnedInIcon />,
        title: "Cambios en formulario de alta y edición de tareas",
        description: <div>Se agrega el campo opcional "Autorizado por".</div>,
      },
    ],
  },
  {
    version: "3.0.8",
    timestamp: 1642517327,
    changes: [
      {
        avatar: <DvrIcon />,
        title: "Mejoras generales",
        description: (
          <div>
            Se soluciona un problema visual en el formulario de creación y edición de
            grupos.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.7",
    timestamp: 1641910617,
    changes: [
      {
        avatar: <FilterListIcon />,
        title: "Mejoras en filtros",
        description: (
          <div>
            Se agrega un{" "}
            <b>buscador para el filtro de cuadrillas, grupos y localidades</b>. En el
            filtro de cuadrillas <b>se separan las cuadrillas activas de las inactivas</b>
            .
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.6",
    timestamp: 1641394752,
    changes: [
      {
        avatar: <GangIcon />,
        title: "Mejoras en listado de cuadrillas",
        description: (
          <div>
            Se mejora la búsqueda dentro de la pantalla del listado permitiendo{" "}
            <b>buscar</b> no solo por el nombre de la cuadrilla, sino{" "}
            <b>
              también por el grupo al que pertenece y la versión de la aplicación
              instalada
            </b>
            .
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.5",
    timestamp: 1638282420,
    changes: [
      {
        avatar: <ArticleIcon />,
        title: "Historial de versiones de la aplicación",
        description: (
          <div>
            Al hacer click sobre el número de versión de la aplicación en el menú tendrás
            acceso a una <b>nueva pantalla para ver todos los cambios</b> que se hicieron.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.4",
    timestamp: 1637678524,
    changes: [
      {
        avatar: <PhoneIphoneIcon />,
        title: "Mejoras de usabilidad para celulares",
        description:
          "Se soluciona un error al tratar de asignar o programar tareas desde el celular.",
      },
    ],
  },
  {
    version: "3.0.3",
    timestamp: 1637084049,
    changes: [
      {
        avatar: <FilterListIcon />,
        title: "Cambios en la ubicación de los filtros",
        description: (
          <div>
            Ahora los filtros para tareas <b>vencidas</b>, <b>complementarias</b>,{" "}
            <b>fallidas</b> y <b>con datos de red</b> se encuentran dentro de las sección{" "}
            <b>Condiciones</b>.
          </div>
        ),
      },
      {
        avatar: <DeleteOutlineIcon />,
        title: "Botón para eliminar todos filtros",
        description: (
          <div>
            Se agrega la posibilidad de eliminar de forma conjunta todos los filtros
            establecidos sobre el listado de tareas.
          </div>
        ),
      },
    ],
  },
  {
    version: "3.0.2",
    timestamp: 1633378569,
    changes: [
      {
        avatar: <InfoIcon />,
        title: "Cartel informativo de actualización",
        description:
          "Se agrega un cartel a mostrar a los usuarios cuando se realiza una actualización del sistema.",
      },
    ],
  },
];
