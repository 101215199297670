import { connect } from "react-redux";

import {
  openModal,
  removeIsExpired,
  removeIsFailure,
  removeIsComplementary,
  removeHasPipelineInfo,
  removeRequiresCleaning,
  removeRequiresMaterials,
} from "../../../../../store/ducks/taskListTaskConditionsFilter";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

import TaskConditions from "./taskConditionsContainer/Task";

const mapStateToProps = state => ({
  isFailure: state.taskListTaskConditionsFilter.isFailure,
  isExpired: state.taskListTaskConditionsFilter.isExpired,
  hasPipelineInfo: state.taskListTaskConditionsFilter.hasPipelineInfo,
  isComplementary: state.taskListTaskConditionsFilter.isComplementary,
  requiresCleaning: state.taskListTaskConditionsFilter.requiresCleaning,
  requiresMaterials: state.taskListTaskConditionsFilter.requiresMaterials,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeIsExpired: () => {
    dispatch(removeIsExpired());
    dispatch(getTasksFromGraphql());
  },
  removeIsFailure: () => {
    dispatch(removeIsFailure());
    dispatch(getTasksFromGraphql());
  },
  removeIsComplementary: () => {
    dispatch(removeIsComplementary());
    dispatch(getTasksFromGraphql());
  },
  removeHasPipelineInfo: () => {
    dispatch(removeHasPipelineInfo());
    dispatch(getTasksFromGraphql());
  },
  removeRequiresCleaning: () => {
    dispatch(removeRequiresCleaning());
    dispatch(getTasksFromGraphql());
  },
  removeRequiresMaterials: () => {
    dispatch(removeRequiresMaterials());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskConditions);
