import Control from "react-leaflet-control";
import Fab from "@mui/material/Fab";
import Icon from "@mui/icons-material/MyLocation";

const centerButton = ({ resetCenter }) => {
  return (
    <Control position="bottomleft">
      <Fab
        onClick={resetCenter}
        style={{ backgroundColor: "#fff", color: "#262626", marginBottom: 50 }}>
        <Icon />
      </Fab>
    </Control>
  );
};

export default centerButton;
