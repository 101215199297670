import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = theme => ({
  form: {
    // minWidth: 380,
    // maxHeight: 290,
  },
  input: {
    marginTop: theme.spacing(3),
  },
  confirmBtn: {
    width: 110,
  },
  action: {
    marginTop: theme.spacing(2),
  },
});

const RejectDialog = props => {
  const { classes, closeModal, openModal } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}>
      <DialogTitle>Rechazar tarea {props.task?.codigo_ot}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Dirección: {props.task?.ubicacion}
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <TextField
              value={props.comment}
              onChange={e => props.changeComment(e.target.value)}
              className={classes.input}
              label="Observaciones"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControlLabel
              className={classes.action}
              control={
                <Checkbox
                  color="default"
                  checked={props.actionRequired}
                  onChange={props.toggleActionRequired}
                />
              }
              label="Requiere acción"
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal} disabled={props.loading}>
          Cancelar
        </Button>

        <LoadingButton loading={props.loading} color="primary" onClick={props.confirm}>
          Rechazar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(RejectDialog);
