const types = {
  TASK_LIST_STATE_FILTER_CHANGES_FILTER_OPEN_MODAL:
    "TASK_LIST_STATE_FILTER_CHANGES_FILTER_OPEN_MODAL",
  TASK_LIST_STATE_FILTER_CHANGES_FILTER_CLOSE_MODAL:
    "TASK_LIST_STATE_FILTER_CHANGES_FILTER_CLOSE_MODAL",
  TASK_LIST_STATE_FILTER_FILTER_SAVE: "TASK_LIST_STATE_FILTER_FILTER_SAVE",
  TASK_LIST_STATE_FILTER_FILTER_REMOVE: "TASK_LIST_STATE_FILTER_FILTER_REMOVE",
};

export const openModal = () => ({
  type: types.TASK_LIST_STATE_FILTER_CHANGES_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_STATE_FILTER_CHANGES_FILTER_CLOSE_MODAL,
});

export const save = filters => ({
  type: types.TASK_LIST_STATE_FILTER_FILTER_SAVE,
  filters,
});

export const removeStateChanges = () => ({
  type: types.TASK_LIST_STATE_FILTER_FILTER_REMOVE,
});

const defaultStateValues = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
};

const initialState = {
  openModal: false,
  statesChanges: defaultStateValues,
  dateFrom: null,
  dateTo: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_STATE_FILTER_CHANGES_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_STATE_FILTER_CHANGES_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_STATE_FILTER_FILTER_SAVE:
      return { ...state, openModal: false, ...action.filters };
    case types.TASK_LIST_STATE_FILTER_FILTER_REMOVE:
      return {
        ...state,
        statesChanges: defaultStateValues,
        dateFrom: null,
        dateTo: null,
      };
    default:
      return state;
  }
};

export default reducer;
