import { connect } from "react-redux";

import { closeModal, save } from "../../../../store/ducks/taskListTaskFilter";
import TaskDialog from "./taskDialogContainer/TaskDialog";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  openModal: state.taskListTaskFilter.openModal,
  odt: state.taskListTaskFilter.odt,
  comment: state.taskListTaskFilter.comment,
  task: state.taskListTaskFilter.task,
  certificate: state.taskListTaskFilter.certificate,
  operationZone: state.taskListTaskFilter.operationZone,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: state => {
    dispatch(save(state));
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDialog);
