const types = {
  TASK_LIST_STATE_FILTER_TASK_CONDITIONS_FILTER_OPEN_MODAL:
    "TASK_LIST_STATE_FILTER_TASK_CONDITIONS_FILTER_OPEN_MODAL",
  TASK_LIST_STATE_FILTER_TASK_CONDITIONS_FILTER_CLOSE_MODAL:
    "TASK_LIST_STATE_FILTER_TASK_CONDITIONS_FILTER_CLOSE_MODAL",
  TASK_LIST_TASK_FILTER_REMOVE_IS_FAILURE: "TASK_LIST_TASK_FILTER_REMOVE_IS_FAILURE",
  TASK_LIST_TASK_FILTER_REMOVE_IS_EXPIRED: "TASK_LIST_TASK_FILTER_REMOVE_IS_EXPIRED",

  TASK_LIST_TASK_FILTER_REMOVE_PIPELINE_INFO:
    "TASK_LIST_TASK_FILTER_REMOVE_PIPELINE_INFO",

  TASK_LIST_TASK_FILTER_REMOVE_IS_COMPLEMENTARY:
    "TASK_LIST_TASK_FILTER_REMOVE_IS_COMPLEMENTARY",

  TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_CLEANING:
    "TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_CLEANING",

  TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_MATERIALS:
    "TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_MATERIALS",

  TASK_LIST_STATE_FILTER_FILTER_SAVE: "TASK_LIST_STATE_FILTER_HISTORY_FILTER_SAVE",
  TASK_LIST_STATE_FILTER_FILTER_REMOVE: "TASK_LIST_STATE_FILTER_HISTORY_FILTER_REMOVE",
};

export const openModal = () => ({
  type: types.TASK_LIST_STATE_FILTER_TASK_CONDITIONS_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_STATE_FILTER_TASK_CONDITIONS_FILTER_CLOSE_MODAL,
});

export const save = filters => ({
  type: types.TASK_LIST_STATE_FILTER_FILTER_SAVE,
  filters,
});

export const removeIsFailure = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_IS_FAILURE,
});

export const removeIsExpired = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_IS_EXPIRED,
});

export const removeIsComplementary = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_IS_COMPLEMENTARY,
});

export const removeHasPipelineInfo = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_PIPELINE_INFO,
});

export const removeRequiresCleaning = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_CLEANING,
});

export const removeRequiresMaterials = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_MATERIALS,
});

export const removeTaskConditionsFilters = () => async dispatch => {
  dispatch(removeIsFailure());
  dispatch(removeIsExpired());
  dispatch(removeIsComplementary());
  dispatch(removeHasPipelineInfo());
  dispatch(removeRequiresCleaning());
  dispatch(removeRequiresMaterials());
};

const initialState = {
  openModal: false,
  isFailure: null,
  isExpired: null,
  hasPipelineInfo: null,
  isComplementary: null,
  requiresCleaning: null,
  requiresMaterials: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_STATE_FILTER_TASK_CONDITIONS_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_STATE_FILTER_TASK_CONDITIONS_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };

    case types.TASK_LIST_TASK_FILTER_REMOVE_IS_FAILURE:
      return { ...state, isFailure: null };

    case types.TASK_LIST_TASK_FILTER_REMOVE_IS_EXPIRED:
      return { ...state, isExpired: null };

    case types.TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_CLEANING:
      return { ...state, requiresCleaning: null };

    case types.TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_MATERIALS:
      return { ...state, requiresMaterials: null };

    case types.TASK_LIST_TASK_FILTER_REMOVE_IS_COMPLEMENTARY:
      return { ...state, isComplementary: null };

    case types.TASK_LIST_TASK_FILTER_REMOVE_PIPELINE_INFO:
      return { ...state, hasPipelineInfo: null };

    case types.TASK_LIST_STATE_FILTER_FILTER_SAVE:
      return { ...state, openModal: false, ...action.filters };
    case types.TASK_LIST_STATE_FILTER_FILTER_REMOVE:
      return {
        ...state,
        isFailure: null,
        isExpired: null,
        hasPipelineInfo: null,
        isComplementary: null,
        requiresCleaning: null,
        requiresMaterials: null,
      };
    default:
      return state;
  }
};

export default reducer;
