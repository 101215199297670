import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import DateIcon from "@mui/icons-material/DateRange";
import ModuleIcon from "@mui/icons-material/Category";
import InspectorIcon from "@mui/icons-material/HowToReg";
import AddressIcon from "@mui/icons-material/LocationOn";
import GroupIcon from "@mui/icons-material/LocationCity";
import CommentsIcon from "@mui/icons-material/Assignment";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DescriptionIcon from "@mui/icons-material/Description";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const TaskInfo = props => {
  return (
    <List component="nav" disablePadding style={{ marginBottom: 30 }}>
      <ListItem dense>
        <Tooltip title="Grupo">
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.group.name} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Dirección">
          <ListItemIcon>
            <AddressIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.address} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Tarea">
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.task} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Fecha finalización">
          <ListItemIcon>
            <DateIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.date} />
      </ListItem>

      <ListItem dense>
        <Tooltip title="Aporte">
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={
            props.task.sidewalks.some(s => s.requires_filling)
              ? "Requiere aporte"
              : "No requiere aporte"
          }
        />
      </ListItem>

      <ListItem dense>
        <Tooltip title="Limpieza">
          <ListItemIcon>
            <HowToVoteIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={
            props.task.requires_cleaning ? "Requiere limpieza" : "No requiere limpieza"
          }
        />
      </ListItem>

      <ListItem dense>
        <Tooltip title="Módulo">
          <ListItemIcon>
            <ModuleIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={`Modulo: ${props.task.module || "-"}`} />
      </ListItem>

      <ListItem dense>
        <Tooltip title="Inspector">
          <ListItemIcon>
            <InspectorIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={`Inspector: ${props.task.inspector || "-"}`} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Observaciones">
          <ListItemIcon>
            <CommentsIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={`Observaciones: ${props.task.comment || "-"}`} />
      </ListItem>
    </List>
  );
};

export default TaskInfo;
