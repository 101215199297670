import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { TextField, useMediaQuery, useTheme } from "@mui/material";

const styles = theme => ({
  form: {
    // minWidth: 450,
    // maxHeight: 380,
    marginBottom: theme.spacing(2),
  },
  dates: {
    marginTop: 20,
  },
  states: {
    width: "100%",
  },
  input: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
});

const StateChangesDialog = props => {
  const { classes, closeModal, openModal, save } = props;
  const [odt, setOdt] = useState(null);
  const [task, setTask] = useState(null);
  const [street, setStreet] = useState(null);
  const [number, setNumber] = useState(null);
  const [between, setBetween] = useState(null);
  const [and, setAnd] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const initialize = () => {
    setOdt(props.odt);
    setTask(props.task);
    setStreet(props.street);
    setNumber(props.number);
    setBetween(props.between);
    setAnd(props.and);
  };

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({ odt, task, street, number, between, and });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openModal, odt, task, street, number, between, and, save]);

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth={"md"}
      fullWidth
      open={openModal}
      onClose={closeModal}
      TransitionProps={{
        onEnter: initialize,
        onExited: initialize,
      }}>
      <DialogTitle>Datos Históricos</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Se filtrarán las tareas que hayan tenido en algún momento alguno de los valores
          seleccionados.
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  value={odt}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setOdt(e.target.value)}
                  className={classes.input}
                  label="Orden de tarea"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  value={task}
                  onChange={e => setTask(e.target.value)}
                  className={classes.input}
                  label="Tarea a realizar"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Calle"
                  value={street}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.input}
                  onChange={e => setStreet(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  value={between}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setBetween(e.target.value)}
                  label="Entre"
                  className={classes.input}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  value={number}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setNumber(e.target.value)}
                  className={classes.input}
                  label="Altura"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  value={and}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.input}
                  onChange={e => setAnd(e.target.value)}
                  label="Y"
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => save({ odt, task, street, number, between, and })}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(StateChangesDialog);
