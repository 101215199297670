import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { memo } from "react";

const labelDisplayedRows = label => `${label.from}-${label.to} de ${label.count}`;

const footer = memo(props => {
  const { count, page, rowsPerPage } = props;

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          page={page}
          count={count}
          labelRowsPerPage="Filas"
          rowsPerPage={rowsPerPage}
          onPageChange={props.handleChangePage}
          labelDisplayedRows={labelDisplayedRows}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  );
});

export default footer;
