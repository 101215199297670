import withStyles from "@mui/styles/withStyles";

import DeleteAllContainer from "./chips/DeleteAllContainer";

import TaskContainer from "./chips/TaskContainer";
import DatesContainer from "./chips/DatesContainer";
import GangsContainer from "./chips/GangsContainer";
import StatesContainer from "./chips/StatesContainer";
import GroupsContainer from "./chips/GroupsContainer";
import CitiesContainer from "./chips/CitiesContainer";
import AddressContainer from "./chips/AddressContainer";
import HistoricContainer from "./chips/HistoricContainer";
import SidewalkContainer from "./chips/SidewalkContainer";
import HidrojetContainer from "./chips/HidrojetContainer";
import TaskTypesContainer from "./chips/TaskTypesContainer";
import ContractorContainer from "./chips/ContractorContainer";
import StateChangesContainer from "./chips/StateChangesContainer";
import HistoryChangesContainer from "./chips/HistoryChangesContainer";
import TaskConditionsContainer from "./chips/TaskConditionsContainer";

const styles = {
  root: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 10,
    listStyle: "none",
    justifyContent: "start",
  },
};

const chips = ({ classes }) => {
  return (
    <>
      <ul className={classes.root}>
        <TaskContainer />
        <GangsContainer />
        <DatesContainer />
        <StatesContainer />
        <GroupsContainer />
        <CitiesContainer />
        <AddressContainer />
        <HidrojetContainer />
        <HistoricContainer />
        <SidewalkContainer />
        <TaskTypesContainer />
        <ContractorContainer />
        <StateChangesContainer />
        <TaskConditionsContainer />
        <HistoryChangesContainer />
      </ul>
      <ul className={classes.root}>
        <DeleteAllContainer />
      </ul>
    </>
  );
};

export default withStyles(styles)(chips);
