import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const styles = theme => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

const AddModal = props => {
  const theme = useTheme();
  const task = useSelector(state => state.task);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const gangs = useMemo(() => {
    const ids = task.actions.filter(a => a.estado_id === 4).map(a => a.cuadrilla_id);
    return task.gangs.filter(g => ids.includes(g._id));
  }, [task]);

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar consumo</DialogTitle>
      <DialogContent>
        {props.loading ? (
          <div className={props.classes.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <DialogContentText>
              El consumo se agregará como validado. Si se selecciona una cantidad mayor a
              la que dispone la cuadrilla, se registrará una carga desde el depósito.
            </DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={props.showStoreStock}
                  onChange={e => props.changeShowStoreStock(e.target.checked)}
                />
              }
              label="Mostrar stock en depósito"
            />
            <Autocomplete
              disableClearable
              options={gangs}
              value={props.gang}
              noOptionsText="Sin opciones"
              getOptionLabel={o => o.name}
              onChange={(e, newValue) => props.changeGang(newValue)}
              renderInput={params => (
                <TextField {...params} label="Cuadrilla" margin="normal" />
              )}
            />
            <Autocomplete
              disableClearable
              noOptionsText="Sin opciones"
              options={props.stores}
              getOptionLabel={o => o.name}
              value={props.store}
              disabled={!props.gang}
              onChange={(_, newValue) => props.changeStore(newValue)}
              renderInput={params => (
                <TextField {...params} label="Depósito" margin="normal" />
              )}
            />
            <Autocomplete
              disableClearable
              noOptionsText="Sin opciones"
              options={props.origins}
              getOptionLabel={o => o.name}
              disabled={!props.store}
              value={props.origin}
              onChange={(e, newValue) => props.changeOrigin(newValue)}
              renderInput={params => (
                <TextField {...params} label="Procedencia" margin="normal" />
              )}
            />
            <Autocomplete
              disableClearable
              noOptionsText="Sin opciones"
              options={props.articles}
              getOptionLabel={o => o.name + (o.code ? ` (${o.code})` : "")}
              value={props.article}
              disabled={!props.origin}
              onChange={(e, newValue) => props.changeArticle(newValue)}
              renderInput={params => (
                <TextField {...params} label="Artículo" margin="normal" />
              )}
            />
            <TextField
              style={{ marginTop: 15, marginBottom: 18 }}
              value={props.amount}
              onChange={e => props.changeAmount(e.target.value)}
              disabled={!props.article}
              margin="dense"
              error={props.amountError}
              helperText={
                props.amountError ? "La cantidad no debe superar el máximo" : null
              }
              label={props.article ? `Cantidad (${props.article.max} max)` : "Cantidad"}
              type="number"
              fullWidth
            />
            {props.article && props.showStoreStock ? (
              <>
                <Typography variant="subtitle1">
                  Stock cuadrilla: {props.article.amount}
                </Typography>
                <Typography variant="subtitle1">
                  Stock depósito: {props.article.storeAmount || 0}
                </Typography>
              </>
            ) : null}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={props.loading}
          onClick={props.onClose}
          style={{ color: "black" }}>
          Cancelar
        </Button>
        <LoadingButton
          disabled={props.loading}
          onClick={props.addConsumption}
          color="primary">
          Agregar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AddModal);
