import { connect } from "react-redux";

import { closeModal, undoArticle } from "../../../store/ducks/undoModal";
import UndoModal from "./undoModalContainer/UndoModal";

const mapStateToProps = state => ({
  open: state.undoModal.modalOpen,
  article: state.undoModal.article,
  sending: state.undoModal.sending,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  confirm: () => dispatch(undoArticle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UndoModal);
