import {
  inMapper,
  andMapper,
  equalMapper,
  likeMapper,
  gteDateMapper,
  lteDateMapper,
  isNullMapper,
  orMapper,
} from "../mappers";

// $hasTareaAccion filters
export const getTasksFilters = ({ downloadTasksFilter: state }) => {
  // const filters = [inMapper("ESTADO", [6]), equalMapper("HISTORIC", false)];
  const filters = [equalMapper("HISTORIC", false), equalMapper("HAS_MATERIALS", true)];

  const keys = Object.keys(state);
  keys.forEach(k => {
    const value = state[k];

    if (TaskFilterskeys.includes(k) && value) {
      const name = TaskFilters[k].key;
      const mapped = TaskFilters[k].mapper(name, value);
      mapped && filters.push(mapped);
    }
  });
  return filters.length ? andMapper(filters) : null;
};

export const TaskFilters = {
  odt: {
    key: "CODIGO_OT",
    mapper: likeMapper,
  },
  hideDonwloaded: {
    key: "SUMMARY_GENERATED_BY",
    mapper: () => {
      return orMapper([
        isNullMapper("SHEET_GENERATED_BY"),
        isNullMapper("SUMMARY_GENERATED_BY"),
      ]);
    },
  },
  group: {
    key: "GRUPO",
    mapper: (key, value) => equalMapper(key, String(value)),
  },
};

const TaskFilterskeys = Object.keys(TaskFilters);

export const getStateDateFilters = ({ downloadTasksFilter: state }) => {
  const filters = [inMapper("estado_id", [6]), equalMapper("HISTORIC", false)];
  const keys = Object.keys(state);
  keys.forEach(k => {
    const value = state[k];
    if (ChangeStateskeys.includes(k) && value) {
      const name = ChangeStateFilters[k].key;
      const mapped = ChangeStateFilters[k].mapper(name, value);
      mapped && filters.push(mapped);
    }
  });
  return filters.length ? andMapper(filters) : null;
};

export const ChangeStateFilters = {
  dateFrom: {
    key: "created_at",
    mapper: gteDateMapper,
  },
  dateTo: {
    key: "created_at",
    mapper: lteDateMapper,
  },
};

const ChangeStateskeys = Object.keys(ChangeStateFilters);
