import setAddresses from "../../utils/AddressParser";

const types = {
  STATE_CHANGED_TASKS_MODAL_OPEN: "STATE_CHANGED_TASKS_MODAL_OPEN",
  STATE_CHANGED_TASKS_MODAL_CLOSE: "STATE_CHANGED_TASKS_MODAL_CLOSE",
  STATE_CHANGED_TASKS_MODAL_REQUEST: "STATE_CHANGED_TASKS_MODAL_REQUEST",
  STATE_CHANGED_TASKS_MODAL_SUCCESS: "STATE_CHANGED_TASKS_MODAL_SUCCESS",
  STATE_CHANGED_TASKS_MODAL_FAILURE: "STATE_CHANGED_TASKS_MODAL_FAILURE",
};

export const openModal = tasks => ({
  type: types.STATE_CHANGED_TASKS_MODAL_OPEN,
  tasks,
});

export const closeModal = () => ({
  type: types.STATE_CHANGED_TASKS_MODAL_CLOSE,
});

const initialState = {
  modalOpen: false,
  tasks: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STATE_CHANGED_TASKS_MODAL_OPEN:
      return { ...state, modalOpen: true, tasks: setAddresses(action.tasks) };
    case types.STATE_CHANGED_TASKS_MODAL_CLOSE:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export default reducer;
