import { connect } from "react-redux";

import UndoValidationModal from "./UndoValidationModal/UndoValidationModal";

import {
  closeUndoModal,
  confirmUndoSidewalk,
} from "../../../store/ducks/finishSidewalkValidationModal";

const mapStateToProps = state => ({
  task: state.task,
  sending: state.finishSidewalkValidationModal.sending,
  open: state.finishSidewalkValidationModal.undoModalOpen,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeUndoModal()),
  confirm: () => dispatch(confirmUndoSidewalk()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UndoValidationModal);
