import { connect } from "react-redux";

import { checkTask } from "../../../store/ducks/selectedTasks";
import { confirmSimpleDownload } from "../../../store/ducks/downloadTasks";
import { openModal } from "../../../store/ducks/taskArticles";
import { mapSelectedTasksForDownload } from "../../../store/ducks/downloadTaskList";
import { checkAllTasks } from "../../../store/ducks/downloadTasksPagination";

import TaskTable from "./taskTableContainer/TaskTable";

const mapStateToProps = state => ({
  tasks: mapSelectedTasksForDownload(state),
  selectedCount: state.selectedTasks.length,
  loading: state.downloadTasks.loadingTask,
  total: state.downloadTasksPagination.total,
  loadingPage: state.downloadTasksPagination.loading,
  pendingPages: state.downloadTasksPagination.pendingPages,
});

const mapDispatchToProps = dispatch => ({
  checkTask: taskId => dispatch(checkTask(taskId)),
  checkAll: taskIds => dispatch(checkAllTasks(taskIds)),
  onDownload: taskId => dispatch(confirmSimpleDownload(taskId)),
  openArticlesDialog: (taskId, odt) => dispatch(openModal(taskId, odt)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskTable);
