import { connect } from "react-redux";

import { openPanel } from "../../../store/ducks/mapInfoPanel";
import PanelButton from "./mobilePanelButtonContainer/PanelButton";

const mapDispatchToProps = dispatch => ({
  openPanel: () => dispatch(openPanel()),
});

export default connect(null, mapDispatchToProps)(PanelButton);
