import { connect } from "react-redux";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";
import { closeModal, save } from "../../../../store/ducks/taskListDateFilters";
import DatesDialog from "./datesDialogContainer/DatesDialog";

const mapStateToProps = state => ({
  openModal: state.taskListDateFilters.openModal,
  creationFrom: state.taskListDateFilters.creationFrom,
  creationTo: state.taskListDateFilters.creationTo,
  incomeFrom: state.taskListDateFilters.incomeFrom,
  incomeTo: state.taskListDateFilters.incomeTo,
  assignedFrom: state.taskListDateFilters.assignedFrom,
  assignedTo: state.taskListDateFilters.assignedTo,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: fields => {
    dispatch(save(fields));
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DatesDialog);
