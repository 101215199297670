import { connect } from "react-redux";

import {
  save,
  toggle,
  toggleAll,
  closeModal,
  initialize,
} from "../../../../store/ducks/taskListContractorFilter";
import ContractDialog from "./contractDialogContainer/ContractDialog";

const mapStateToProps = state => ({
  options: state.taskListContractorFilter.options,
  openModal: state.taskListContractorFilter.openModal,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: () => dispatch(save()),
  initialize: () => dispatch(initialize()),
  toggle: event => dispatch(toggle(event)),
  toggleAll: event => dispatch(toggleAll(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractDialog);
