import { Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { AddOutlined } from "@mui/icons-material";
import FinishIcon from "@mui/icons-material/DoneAll";

import SidewalkTable from "./sidewalkTable";
import { openModal } from "../../../../store/ducks/newCivilTaskDialog";
import { openModal as openScheduleModal } from "../../../../store/ducks/taskListScheduleTask";
import NewCivilTaskDialogContainer from "../../../taskListContainer/taskList/tasks/CivilTaskDialogContainer";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const styles = theme => ({
  tableContainer: {
    paddingTop: theme.spacing(),
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    marginLeft: "auto",
  },
  searchIcon: {
    color: "rgba(0,0,0,0.54)",
    marginRight: 7,
  },
  toolbar: { paddingRight: 0 },
});

const TaskSidewalk = props => {
  const { task } = props;

  const dispatch = useDispatch();
  const disabled = task.sidewalks.some(s => s.state === 0);

  return (
    <Paper variant="outlined">
      <div className={props.classes.tableContainer}>
        <Toolbar className={props.classes.toolbar}>
          <div>
            <Typography variant="h5">Roturas registradas</Typography>
          </div>

          <div className={props.classes.actions}>
            <Tooltip title="Agregar">
              <IconButton size="large" onClick={props.openAddModal}>
                <AddOutlined />
              </IconButton>
            </Tooltip>
            {[1].includes(task.state) ? (
              <Tooltip title="Finalizar validación y pogramar">
                <IconButton
                  onClick={() => {
                    dispatch(openScheduleModal(task));
                  }}
                  disabled={disabled}
                  size="large">
                  <EventAvailableIcon />
                </IconButton>
              </Tooltip>
            ) : null}

            <Tooltip title="Finalizar validación">
              <IconButton
                onClick={() => {
                  if ([6, 8, 9, 11].includes(task.state)) {
                    return dispatch(openModal(task));
                  }
                  window.location = `${process.env.PUBLIC_URL}/tarea/${props.task.id}`;
                }}
                disabled={disabled}
                size="large">
                <FinishIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>

        {!disabled ? <NewCivilTaskDialogContainer /> : null}

        <SidewalkTable
          sidewalks={props.task.sidewalks}
          openEditModal={props.openEditModal}
          openUndoModal={props.openUndoModal}
          openConfirmationModal={props.openConfirmationModal}
        />
      </div>
    </Paper>
  );
};

export default withStyles(styles)(TaskSidewalk);
