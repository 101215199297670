import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";

const logoutButton = props => (
  <MenuItem onClick={() => props.session.logout()}>Cerrar sesión</MenuItem>
);

const mapStateToProps = state => ({
  session: state.keycloak.kc,
});

export default connect(mapStateToProps)(logoutButton);
