import clsx from "clsx";

import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import makeStyles from "@mui/styles/makeStyles";
import { Divider, CardHeader, Tooltip } from "@mui/material";
import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SidewalkPhotos from "./photos";

import AddSidewalkModal from "./addSidewalkModal";
import EditSidewalkModal from "./editSidewalkModal";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  stateIcon: {
    width: 40,
    height: 40,
    fontSize: 13,
    fontWeight: 500,
    display: "flex",
    userSelect: "none",
    border: "1px solid",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default ({ task, roles = [] }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedSidewalk, setSelectedSidewalk] = useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card variant="outlined" style={{ marginTop: 10 }}>
        <CardHeader
          titleTypographyProps={{ variant: "h6", style: { fontWeight: 400 } }}
          title="Información de vereda"
          action={
            <>
              {roles.includes("validarVereda") ? (
                <Tooltip title="Cargar" key="add" onClick={() => setAddModalOpen(true)}>
                  <IconButton size="large">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              <IconButton
                key="expand"
                onClick={handleExpandClick}
                disabled={!task.sidewalks || !task.sidewalks.length}
                size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            </>
          }
        />
        <CardContent>
          {!expanded ? (
            <Typography style={{ fontWeight: 100, marginTop: -10 }}>{`La tarea posee ${
              task.sidewalks.length
            } ${
              task.sidewalks.length === 1
                ? "información de vereda asociada."
                : "informaciones de vereda asociadas."
            }`}</Typography>
          ) : null}

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List>
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 20 }}>
                  {task.sidewalks ? (
                    <Grid item xs={12}>
                      {task.sidewalks.map((sidewalk, index) => (
                        <>
                          {" "}
                          {roles.includes("validarVereda") ? (
                            <Tooltip
                              title="Editar"
                              style={{ float: "right", marginBottom: -50 }}>
                              <IconButton
                                onClick={() => {
                                  setSelectedSidewalk(sidewalk);
                                }}
                                size="large">
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          <Grid
                            container
                            spacing={2}
                            style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 20 }}>
                            <Grid item xs={12} sm={6}>
                              <div style={{ textAlign: "left" }}>
                                <Typography variant="body2">Tipo de vereda</Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#0000008a" }}>
                                  {sidewalk?.type?.name || "Sin especificar"}
                                </Typography>
                              </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <div style={{ textAlign: "left" }}>
                                <Typography variant="body2">Unidad de Medida</Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#0000008a" }}>
                                  {sidewalk?.unit?.name || "Sin especificar"}
                                </Typography>
                              </div>
                            </Grid>

                            {sidewalk.article_description ? (
                              <Grid item xs={12}>
                                <div style={{ textAlign: "left" }}>
                                  <Typography variant="body2">Baldosa</Typography>
                                  <Typography
                                    variant="body2"
                                    style={{ color: "#0000008a" }}>
                                    {sidewalk?.article_description || "Sin especificar"}
                                  </Typography>
                                </div>
                              </Grid>
                            ) : null}

                            <Grid item xs={12} sm={6}>
                              <div style={{ textAlign: "left" }}>
                                <Typography variant="body2">Cantidad</Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#0000008a" }}>
                                  {sidewalk?.amount || "Sin especificar"}
                                </Typography>
                              </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <div style={{ textAlign: "left" }}>
                                <Typography variant="body2">Requiere Aporte</Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#0000008a" }}>
                                  {sidewalk?.requires_filling ? "Si" : "No"}
                                </Typography>
                              </div>
                            </Grid>

                            <Grid item xs={12}>
                              <div style={{ textAlign: "left" }}>
                                <Typography variant="body2">Observaciones</Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#0000008a" }}>
                                  {sidewalk?.observations || "Sin especificar"}
                                </Typography>
                              </div>
                            </Grid>

                            {task.sidewalk_photos.length ||
                            task.sidewalks.length - 1 < index ? (
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            ) : null}
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  ) : null}

                  {task.sidewalk_photos.length ? (
                    <Grid item xs={12}>
                      <div style={{ marginTop: 30 }}>
                        <SidewalkPhotos task={task} />
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </>
            </List>
          </Collapse>
        </CardContent>
      </Card>
      <AddSidewalkModal
        task={task}
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
      />
      <EditSidewalkModal
        task={task}
        open={!!selectedSidewalk}
        sidewalk={selectedSidewalk}
        onClose={() => setSelectedSidewalk(null)}
      />
    </>
  );
};
