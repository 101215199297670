/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import DatePicker from "@mui/lab/MobileDatePicker";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = () => ({
  form: {
    // minWidth: 380,
    // maxHeight: 290,
    marginBottom: 10,
    marginTop: 30,
  },
});

const StatesDialog = props => {
  const { classes, closeModal, openModal, save } = props;
  const [creationFrom, setCreationFrom] = useState(null);
  const [creationTo, setCreationTo] = useState(null);
  const [incomeFrom, setIncomeFrom] = useState(null);
  const [incomeTo, setIncomeTo] = useState(null);
  const [assignedFrom, setAssignedFrom] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const initialize = () => {
    setCreationFrom(props.creationFrom);
    setCreationTo(props.creationTo);
    setIncomeFrom(props.incomeFrom);
    setIncomeTo(props.incomeTo);
    setAssignedFrom(props.assignedFrom);
    setAssignedTo(props.assignedTo);
  };

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({
          creationFrom,
          creationTo,
          incomeFrom,
          incomeTo,
          assignedFrom,
          assignedTo,
        });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [
    openModal,
    save,
    creationFrom,
    creationTo,
    incomeFrom,
    incomeTo,
    assignedFrom,
    assignedTo,
  ]);

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}
      TransitionProps={{
        onEnter: initialize,
        onExited: initialize,
      }}>
      <DialogTitle>Fechas</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Seleccione solo las fechas por las que desea filtrar.
        </Typography>
        <FormControl component="fieldset" className={classes.form}>
          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  todayText="Hoy"
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha creación desde"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={creationFrom}
                  onChange={setCreationFrom}
                  DialogProps={{ disableRestoreFocus: true }}
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  todayText="Hoy"
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha creación hasta"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={creationTo}
                  onChange={setCreationTo}
                  DialogProps={{ disableRestoreFocus: true }}
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  todayText="Hoy"
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha ingreso desde"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={incomeFrom}
                  onChange={setIncomeFrom}
                  DialogProps={{ disableRestoreFocus: true }}
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  todayText="Hoy"
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha ingreso hasta"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={incomeTo}
                  onChange={setIncomeTo}
                  DialogProps={{ disableRestoreFocus: true }}
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  todayText="Hoy"
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha asignada desde"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={assignedFrom}
                  onChange={setAssignedFrom}
                  DialogProps={{ disableRestoreFocus: true }}
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  todayText="Hoy"
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha asignada hasta"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={assignedTo}
                  onChange={setAssignedTo}
                  DialogProps={{ disableRestoreFocus: true }}
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            save({
              creationFrom,
              creationTo,
              incomeFrom,
              incomeTo,
              assignedFrom,
              assignedTo,
            });
          }}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(StatesDialog);
