import { gql } from "apollo-boost";

export const GET_TASKS = gql`
  query GET_TASKS(
    $page: Int = 0
    $first: Int = 25
    $hasAudits: WhereConditions = null
    $hasSidewalks: WhereConditions = null
    $hasPipelines: WhereConditions = null
    $hasHidrojets: WhereConditions = null
    $hasCuadrilla: WhereConditions = null
    $hasTareaAccion: WhereConditions = null
    $where: TasksWhereWhereConditions = null
    $order_by: [TasksOrderByOrderByClause!] = [{ field: CREATED_AT, order: DESC }]
  ) {
    tasks(
      page: $page
      first: $first
      where: $where
      hasAudits: $hasAudits
      order_by: $order_by
      hasSidewalks: $hasSidewalks
      hasPipelines: $hasPipelines
      hasHidrojets: $hasHidrojets
      hasCuadrilla: $hasCuadrilla
      hasTareaAccion: $hasTareaAccion
    ) {
      data {
        id
        tareaAccion {
          created_at
          fallida
          estado_id
          inspector
        }
        localidad {
          id
        }
        codigo_ot
        created_at
        ingreso_ot
        summaryGeneratedBy {
          name
        }
        updated_at
        sheetGeneratedBy {
          name
        }
        sheet_generated_at
        summary_generated_at
        modulo
        vencimiento
        observaciones
        ubicacion
        fallida
        grupo {
          id
          nombre
        }
        cuadrilla {
          id
          cuadrilla
        }
        tipo {
          id
          tipo
        }
        estado {
          id
          estado
        }
        createdBy {
          name
        }
      }
      paginatorInfo {
        hasMorePages
        currentPage
        perPage
        total
      }
    }
  }
`;

export const GET_TASKS_IDS = gql`
  query GET_TASKS_IDS(
    $hasAudits: WhereConditions = null
    $hasSidewalks: WhereConditions = null
    $hasPipelines: WhereConditions = null
    $hasCuadrilla: WhereConditions = null
    $hasHidrojets: WhereConditions = null
    $hasTareaAccion: WhereConditions = null
    $where: TasksFullWhereWhereConditions = null
  ) {
    tasksFull(
      where: $where
      hasAudits: $hasAudits
      hasSidewalks: $hasSidewalks
      hasHidrojets: $hasHidrojets
      hasPipelines: $hasPipelines
      hasCuadrilla: $hasCuadrilla
      hasTareaAccion: $hasTareaAccion
    ) {
      id
    }
  }
`;
