/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getGangs } from "../store/ducks/gangs";
import ApiError from "../components/UI/ApiError";
import { getGroups } from "../store/ducks/groups";
import { getStores } from "../store/ducks/storesList";
import TaskDetails from "./taskDetailsContainer/taskDetails";
import { getTask, clearTask } from "../store/ducks/taskDetails";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

const TaskDetailsContainer = props => {
  const { error } = props;
  const match = useParams();
  useEffect(() => {
    Promise.all([props.getGangs(), props.getStores(), props.getGroups()]).then(() => {
      props.getTask(match.id);
    });
    return () => {
      props.clearTask();
    };
  }, [match]);

  return error ? <ApiError error="Ocurrió un error." /> : <TaskDetails {...props} />;
};

const actionNames = ["STORES_LIST", "TASK_DETAILS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  task: state.taskDetails.task,
  actions: state.taskDetails.actions,
  changes: state.taskDetails.changes,
  parentTask: state.taskDetails.parentTask,

  roles: state.user.roles,
  stores: state.storesList,
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getGangs: () => dispatch(getGangs()),
  getTask: id => dispatch(getTask(id)),
  getStores: () => dispatch(getStores()),
  clearTask: () => dispatch(clearTask()),
  getGroups: () => dispatch(getGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailsContainer);
