/* eslint-disable react-hooks/exhaustive-deps */
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

const styles = theme => ({
  title: {
    flex: "0 0 auto",
  },
  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3.5),
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  actions: {
    color: theme.palette.text.secondary,
    marginLeft: "auto",
    marginRight: theme.spacing(3),
  },
  searchIcon: {
    color: "rgba(0,0,0,0.54)",
    marginRight: 7,
  },
  groupSelect: {
    margin: theme.spacing(1),
    width: "96%",
  },
});

const getOptionLabel = option => option.name;

export const ToolbarContainer = props => {
  const { classes } = props;

  const taskTypes = useSelector(state => state.taskTypes);

  const selectedGroup = useMemo(
    () =>
      props.group ? (props.groups || []).find(g => g.id === props.group) : undefined,
    [props.groups, props.group]
  );

  const selectedTaskType = useMemo(
    () =>
      props.taskType ? (taskTypes || []).find(g => g.id === props.taskType) : undefined,
    [props.taskType, taskTypes]
  );

  const handleSearch = useCallback(e => props.changeOdt(e.target.value), []);
  const handleTaskType = useCallback((e, value) => {
    props.changeTaskType(value ? value.id : null);
  }, []);
  const handleGroup = useCallback((e, value) => {
    props.changeGroup(value ? value.id : null);
  }, []);

  return (
    <Toolbar
      className={classNames(classes.toolbar, {
        [classes.highlight]: false,
      })}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item xs={12} lg={8}>
          <div style={{ display: "flex" }}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              fullWidth
              disableUnderline
              value={props.odt}
              onChange={handleSearch}
              placeholder="Buscar por odt"
            />
          </div>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormControl className={classes.groupSelect}>
            <Autocomplete
              options={taskTypes}
              value={selectedTaskType}
              onChange={handleTaskType}
              getOptionLabel={getOptionLabel}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tipo de tarea"
                  variant="standard"
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormControl className={classes.groupSelect}>
            <Autocomplete
              value={selectedGroup}
              options={props.groups}
              onChange={handleGroup}
              getOptionLabel={getOptionLabel}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Grupo"
                  variant="standard"
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default withStyles(styles)(ToolbarContainer);
