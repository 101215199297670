import { Component } from "react";
import { connect } from "react-redux";

import ApiError from "../components/UI/ApiError";
import NewTask from "./newTaskContainer/newTask";
import { getTaskTypes } from "../store/ducks/taskTypes";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";
import { getCities } from "../store/ducks/cities";
import { clearTaskExistance } from "../store/ducks/newTask";
import { getStores } from "../store/ducks/storesList";

class NewTaskContainer extends Component {
  componentDidMount() {
    this.props.getStores();
    this.props.getCities();
    this.props.getTaskTypes();
  }

  componentWillUnmount() {
    this.props.clearTaskExistance();
  }

  render() {
    const { error } = this.props;
    return error ? <ApiError error="Ocurrió un error." /> : <NewTask {...this.props} />;
  }
}

const actionNames = ["CITIES", "TASK_TYPES"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getCities: () => dispatch(getCities()),
  getStores: () => dispatch(getStores()),
  getTaskTypes: () => dispatch(getTaskTypes()),
  clearTaskExistance: () => dispatch(clearTaskExistance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTaskContainer);
