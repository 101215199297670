import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContentText from "@mui/material/DialogContentText";
import { LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = theme => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

const EditModal = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Editar Consumo</DialogTitle>
      <DialogContent>
        {props.loading ? (
          <div className={props.classes.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <DialogContentText>
              Si se selecciona una cantidad mayor a la que dispone la cuadrilla, se
              registrará una carga desde el depósito.
            </DialogContentText>
            <Typography variant="subtitle1">{`${props.article.name}${
              props.article.code ? ` (${props.article.code})` : ""
            }`}</Typography>
            <Typography variant="subtitle1">
              {props.article.gang ? props.article.gang.name : "-"}
            </Typography>
            <Typography variant="subtitle1">
              {props.article.store ? props.article.store.name : "-"}
            </Typography>
            <Typography variant="subtitle1">
              {props.article.origin ? props.article.origin.name : "-"}
            </Typography>
            <TextField
              style={{ marginTop: 15, marginBottom: 20 }}
              value={props.amount}
              onChange={e => props.changeAmount(e.target.value)}
              type="number"
              margin="dense"
              label={`Cantidad (${props.gangAmount + props.storeAmount} max)`}
              fullWidth
              error={props.amountError}
              helperText={
                props.amountError ? "La cantidad no debe superar el máximo" : null
              }
            />
            <Typography variant="subtitle1">
              Stock cuadrilla: {props.gangAmount}
            </Typography>
            <Typography variant="subtitle1">
              Stock depósito: {props.storeAmount}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.loading || props.sending}
          style={{ color: "black" }}>
          Cancelar
        </Button>
        <LoadingButton
          onClick={props.confirm}
          loading={props.sending}
          disabled={props.loading || props.sending}
          color="primary">
          Editar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EditModal);
