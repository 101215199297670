import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import ValidateIcon from "@mui/icons-material/Done";
import RejectIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import Tooltip from "@mui/material/Tooltip";

const body = props => {
  const { articles, page, rowsPerPage } = props;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, articles.length - page * rowsPerPage);
  return (
    <TableBody>
      {articles
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(article => (
          <TableRow key={article.consumption}>
            <TableCell>{article.name}</TableCell>
            <TableCell>{article.code || "-"}</TableCell>
            <TableCell>{article.gang ? article.gang.name : "-"}</TableCell>
            <TableCell>{article.store ? article.store.name : "-"}</TableCell>
            <TableCell>{article.unit}</TableCell>
            <TableCell>{article.date || "-"}</TableCell>
            <TableCell>{article.state.name}</TableCell>
            <TableCell align="right">{article.amount}</TableCell>
            <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {article.state.id === 0 ? (
                  <>
                    <Tooltip title="Cambiar cantidad">
                      <IconButton
                        onClick={() => props.openEditModal(article)}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Validar">
                      <IconButton
                        onClick={() => props.openValidateModal(article)}
                        size="large">
                        <ValidateIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Objetar">
                      <IconButton
                        onClick={() => props.openRejectModal(article)}
                        size="large">
                        <RejectIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : article.state.id === 1 || article.state.id === 2 ? (
                  <Tooltip title="Deshacer">
                    <IconButton onClick={() => props.openUndoModal(article)} size="large">
                      <UndoIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
            </TableCell>
          </TableRow>
        ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={9} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default body;
