const types = {
  TASK_LIST_TASK_FILTER_OPEN_MODAL: "TASK_LIST_TASK_FILTER_OPEN_MODAL",
  TASK_LIST_TASK_FILTER_CLOSE_MODAL: "TASK_LIST_TASK_FILTER_CLOSE_MODAL",
  TASK_LIST_TASK_FILTER_SAVE: "TASK_LIST_TASK_FILTER_SAVE",
  TASK_LIST_TASK_FILTER_REMOVE_ODT: "TASK_LIST_TASK_FILTER_REMOVE_ODT",
  TASK_LIST_TASK_FILTER_REMOVE_TASK: "TASK_LIST_TASK_FILTER_REMOVE_TASK",
  TASK_LIST_TASK_FILTER_REMOVE_COMMENT: "TASK_LIST_TASK_FILTER_REMOVE_COMMENT",
  TASK_LIST_TASK_FILTER_REMOVE_CERTIFICATE: "TASK_LIST_TASK_FILTER_REMOVE_CERTIFICATE",
  TASK_LIST_TASK_FILTER_REMOVE_OPERATION_ZONE:
    "TASK_LIST_TASK_FILTER_REMOVE_OPERATION_ZONE",
  TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_MATERIALS:
    "TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_MATERIALS",
  TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_CLEANING:
    "TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_CLEANING",
  TASK_LIST_TASK_FILTER_REMOVE_HAS_PIPELINE: "TASK_LIST_TASK_FILTER_REMOVE_HAS_PIPELINE",
};

export const openModal = () => ({
  type: types.TASK_LIST_TASK_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_TASK_FILTER_CLOSE_MODAL,
});

export const removeOdt = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_ODT,
});

export const removeTask = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_TASK,
});

export const removeComment = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_COMMENT,
});

export const removeCertificate = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_CERTIFICATE,
});

export const removeOperationZone = () => ({
  type: types.TASK_LIST_TASK_FILTER_REMOVE_OPERATION_ZONE,
});

export const removeTaskFilters = () => async dispatch => {
  dispatch(removeOdt());
  dispatch(removeTask());
  dispatch(removeComment());
  dispatch(removeCertificate());
  dispatch(removeOperationZone());
};

export const save = state => ({
  type: types.TASK_LIST_TASK_FILTER_SAVE,
  state,
});

const initialState = {
  openModal: false,
  odt: null,
  task: null,
  comment: null,
  certificate: null,
  operationZone: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_TASK_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_TASK_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_TASK_FILTER_REMOVE_ODT:
      return { ...state, odt: "" };
    case types.TASK_LIST_TASK_FILTER_REMOVE_OPERATION_ZONE:
      return { ...state, operationZone: "" };
    case types.TASK_LIST_TASK_FILTER_REMOVE_CERTIFICATE:
      return { ...state, certificate: "" };
    case types.TASK_LIST_TASK_FILTER_REMOVE_TASK:
      return { ...state, task: "" };
    case types.TASK_LIST_TASK_FILTER_REMOVE_COMMENT:
      return { ...state, comment: "" };
    case types.TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_CLEANING:
      return { ...state, requiresCleaning: "" };
    case types.TASK_LIST_TASK_FILTER_REMOVE_REQUIRES_MATERIALS:
      return { ...state, requiresMaterials: "" };
    case types.TASK_LIST_TASK_FILTER_REMOVE_HAS_PIPELINE:
      return { ...state, hasPipeline: "" };
    case types.TASK_LIST_TASK_FILTER_SAVE:
      return { ...state, openModal: false, ...action.state };
    default:
      return state;
  }
};

export default reducer;
