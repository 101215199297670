/* eslint-disable no-empty */
import axios from "../../axios";

const types = {
  SIDEWALK_ADD_MODAL_OPEN: "SIDEWALK_ADD_MODAL_OPEN",
  SIDEWALK_ADD_MODAL_OPEN_CONFIRMATION: "SIDEWALK_ADD_MODAL_OPEN_CONFIRMATION",
  SIDEWALK_ADD_MODAL_CLOSE: "SIDEWALK_ADD_MODAL_CLOSE",
  SIDEWALK_ADD_MODAL_CHANGE_AMOUNT: "SIDEWALK_ADD_MODAL_CHANGE_AMOUNT",
  SIDEWALK_ADD_REQUIRES_FILLING: "SIDEWALK_ADD_REQUIRES_FILLING",
  SIDEWALK_ADD_MODAL_CHANGE_UNIT: "SIDEWALK_ADD_MODAL_CHANGE_UNIT",
  SIDEWALK_ADD_MODAL_CHANGE_TYPE: "SIDEWALK_ADD_MODAL_CHANGE_TYPE",
  SIDEWALK_ADD_MODAL_SET_DEFAULTS: "SIDEWALK_ADD_MODAL_SET_DEFAULTS",
  SIDEWALK_ADD_MODAL_CHANGE_OBSERVATIONS: "SIDEWALK_ADD_MODAL_CHANGE_OBSERVATIONS",
  SIDEWALK_ADD_MODAL_CONFIRM_ADD: "SIDEWALK_ADD_MODAL_CONFIRM_ADD",
};

export const openModal = () => dispatch => {
  dispatch({
    type: types.SIDEWALK_ADD_MODAL_OPEN,
  });
};

export const closeModal = () => ({
  type: types.SIDEWALK_ADD_MODAL_CLOSE,
});

export const confirm =
  (amount, unit, type, observations, requires_filling, article_id, article_description) =>
  async (dispatch, getState) => {
    const { taskSidewalkAddModal } = getState();
    const { sidewalk } = taskSidewalkAddModal;

    const data = {
      amount,
      unit,
      type,
      observations,
      requires_filling,
      article_id,
      article_description,
    };

    try {
      await axios.patch(`/sidewalk/${sidewalk.id}/`, data);
    } catch (error) {}
    dispatch({
      type: types.SIDEWALK_ADD_MODAL_CONFIRM_ADD,
    });
  };

const initialState = {
  sending: false,
  modalOpen: false,
  confirmationOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIDEWALK_ADD_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
      };

    case types.SIDEWALK_ADD_MODAL_CLOSE:
      return { ...state, modalOpen: false, confirmationOpen: false };

    case types.SIDEWALK_ADD_MODAL_CONFIRM_ADD:
      Object.keys(action.data).forEach(k => {
        state.sidewalk[k] = action.data[k];
      });
      return { ...initialState };

    default:
      return state;
  }
};

export default reducer;
