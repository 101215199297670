import { connect } from "react-redux";

import {
  toggleGangs,
  togglePendingTasks,
  toggleAssignedTasks,
  toggleProgrammedTasks,
  toggleExecutingTasks,
  togglePausedTasks,
} from "../../../../../store/ducks/mapInfoPanel";
import TypeFilter from "./typeFilterContainer/TypeFilter";

const mapStateToProps = state => ({
  gangs: state.mapInfoPanel.gangs,
  pendingTasks: state.mapInfoPanel.pendingTasks,
  assignedTasks: state.mapInfoPanel.assignedTasks,
  programmedTasks: state.mapInfoPanel.programmedTasks,
  executingTasks: state.mapInfoPanel.executingTasks,
  pausedTasks: state.mapInfoPanel.pausedTasks,
});

const mapDispatchToProps = dispatch => ({
  toggleGangs: () => dispatch(toggleGangs()),
  togglePendingTasks: () => dispatch(togglePendingTasks()),
  toggleAssignedTasks: () => dispatch(toggleAssignedTasks()),
  toggleProgrammedTasks: () => dispatch(toggleProgrammedTasks()),
  toggleExecutingTasks: () => dispatch(toggleExecutingTasks()),
  togglePausedTasks: () => dispatch(togglePausedTasks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeFilter);
