import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const RejectModal = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Objetar Consumo</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          Material: {props.article.name}
          {props.article.code ? ` (${props.article.code})` : ""}
        </Typography>
        <Typography variant="subtitle1">Cantidad: {props.article.amount}</Typography>
        <Typography variant="subtitle1">
          Cuadrilla: {props.article.gang ? props.article.gang.name : "-"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.sending}
          style={{ color: "black" }}>
          Cancelar
        </Button>
        <Button onClick={props.confirm} disabled={props.sending} color="primary">
          Objetar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectModal;
