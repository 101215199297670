import { combineReducers } from "redux";

import keycloak from "./ducks/keycloak";
import user from "./ducks/user";
import users from "./ducks/users";
import loading from "./ducks/loading";
import error from "./ducks/error";
import notifications from "./ducks/notifications";
import tasks from "./ducks/tasks";
import selectedTasks from "./ducks/selectedTasks";
import downloadTasks from "./ducks/downloadTasks";
import downloadTasksFilter from "./ducks/downloadTasksFilter";
import taskArticles from "./ducks/taskArticles";
import gangs from "./ducks/gangs";
import groups from "./ducks/groups";
import cities from "./ducks/cities";
import task from "./ducks/task";
import articlesFilters from "./ducks/articlesFilters";
import validateModal from "./ducks/validateModal";
import rejectModal from "./ducks/rejectModal";
import editModal from "./ducks/editModal";
import addModal from "./ducks/addModal";
import undoModal from "./ducks/undoModal";
import finishValidationModal from "./ducks/finishValidationModal";
import stores from "./ducks/stores";
import statements from "./ducks/statements";
import statementModal from "./ducks/statementModal";
import statementsFilters from "./ducks/statementsFilters";
import mapTasks from "./ducks/mapTasks";
import map from "./ducks/map";
import mapInfoPanel from "./ducks/mapInfoPanel";
import tasksList from "./ducks/tasksList";
import taskFilters from "./ducks/taskFilters";
import taskTypes from "./ducks/taskTypes";
import taskListStateFilter from "./ducks/taskListStateFilter";
import taskListGangFilter from "./ducks/taskListGangFilter";
import taskListGroupFilter from "./ducks/taskListGroupFilter";
import taskListCityFilter from "./ducks/taskListCityFilter";
import taskListStateChangesFilter from "./ducks/taskListStateChangesFilter";
import taskListTaskFilter from "./ducks/taskListTaskFilter";
import taskListAddressFilter from "./ducks/taskListAddressFilter";
import taskListDateFilters from "./ducks/taskListDateFilters";
import taskListOrder from "./ducks/taskListOrder";
import taskListDownloadMaterials from "./ducks/taskListDownloadMaterials";
import taskListShowAllInfo from "./ducks/taskListShowAllInfo";
import taskListGangs from "./ducks/taskListGangs";
import taskListScheduleTask from "./ducks/taskListScheduleTask";
import taskListAssignTask from "./ducks/taskListAssignTask";
import taskListCancelTask from "./ducks/taskListCancelTask";
import taskListPendingTask from "./ducks/taskListPendingTask";
import taskListInspectionTask from "./ducks/taskListInspectionTask";
import taskListApproveTask from "./ducks/taskListApproveTask";
import taskListRejectTask from "./ducks/taskListRejectTask";
import groupGangs from "./ducks/groupGangs";
import transferGangModal from "./ducks/transferGangModal";
import statedChangedTasksModal from "./ducks/statedChangedTasksModal";
import unnasignGangModal from "./ducks/unnasignGangModal";
import addGangToGroupModal from "./ducks/addGangToGroupModal";
import editGroupModal from "./ducks/editGroupModal";
import gangList from "./ducks/gangList";
import gangsGroupsHistoryModal from "./ducks/gangsGroupsHistoryModal";
import gangsPin from "./ducks/gangsPin";
import gangTasksReorder from "./ducks/gangTasksReorder";
import editTask from "./ducks/editTask";
import newTask from "./ducks/newTask";
import taskDetails from "./ducks/taskDetails";
import downloadTaskList from "./ducks/downloadTaskList";
import downloadTasksPagination from "./ducks/downloadTasksPagination";
import taskListDownloadTasks from "./ducks/taskListDownloadTasks";
import taskListHistoricFilter from "./ducks/taskListHistoricFilter";
import taskListHistoryChangesFilter from "./ducks/taskListHistoryChangesFilter";
import storesList from "./ducks/storesList";
import tasksValidation from "./ducks/tasksValidation";
import sidewalkUnits from "./ducks/sidewalkUnits";
import sidewalkTypes from "./ducks/sidewalkTypes";
import finishSidewalkValidationModal from "./ducks/finishSidewalkValidationModal";
import newCivilTaskDialog from "./ducks/newCivilTaskDialog";
import taskSidewalkEditModal from "./ducks/taskSidewalkEditModal";
import taskListSidewalkFilter from "./ducks/taskListSidewalkFilter";
import taskListTypesFilter from "./ducks/taskListTypesFilter";
import editGang from "./ducks/editGang";
import taskHidrojetEditModal from "./ducks/taskHidrojetEditModal";
import taskListHidrojetFilter from "./ducks/taskListHidrojetFilter";
import hidrojetList from "./ducks/hidrojetList";
import hidrojetListFilter from "./ducks/hidrojetListFilter";
import addComplementaryModal from "./ducks/addComplementaryModal";
import taskListDownloadAbsa from "./ducks/taskListDownloadAbsa";
import taskPipelineEditModal from "./ducks/taskPipelineEditModal";
import taskListContractorFilter from "./ducks/taskListContractorFilter";
import taskListTaskConditionsFilter from "./ducks/taskListTaskConditionsFilter";
import sidewalkTiles from "./ducks/sidewalkTiles";
import taskSidewalkAddModal from "./ducks/taskSidewalkAddModal";

const createRootReducer = () =>
  combineReducers({
    keycloak,
    user,
    users,
    loading,
    error,
    notifications,
    tasks,
    taskDetails,
    selectedTasks,
    downloadTasks,
    downloadTasksFilter,
    downloadTasksPagination,
    taskArticles,
    gangs,
    groups,
    groupGangs,
    cities,
    task,
    articlesFilters,
    validateModal,
    statedChangedTasksModal,
    unnasignGangModal,
    rejectModal,
    editModal,
    addModal,
    undoModal,
    finishValidationModal,
    stores,
    statements,
    statementModal,
    statementsFilters,
    mapTasks,
    map,
    tasksList,
    mapInfoPanel,
    taskFilters,
    taskTypes,
    taskListStateFilter,
    taskListGangFilter,
    taskListGroupFilter,
    taskListCityFilter,
    taskListHistoryChangesFilter,
    taskListDateFilters,
    taskListStateChangesFilter,
    taskListTaskFilter,
    taskListAddressFilter,
    taskListOrder,
    taskListShowAllInfo,
    taskListScheduleTask,
    taskListGangs,
    taskListAssignTask,
    taskListCancelTask,
    taskListPendingTask,
    taskListInspectionTask,
    taskListApproveTask,
    taskListRejectTask,
    transferGangModal,
    addGangToGroupModal,
    editGroupModal,
    gangList,
    gangsGroupsHistoryModal,
    gangsPin,
    gangTasksReorder,
    editTask,
    newTask,
    downloadTaskList,
    taskListDownloadMaterials,
    taskListDownloadTasks,
    taskListHistoricFilter,
    storesList,
    tasksValidation,
    taskSidewalkEditModal,
    sidewalkUnits,
    sidewalkTypes,
    sidewalkTiles,
    finishSidewalkValidationModal,
    newCivilTaskDialog,
    taskListSidewalkFilter,
    taskListTypesFilter,
    editGang,
    taskHidrojetEditModal,
    taskListHidrojetFilter,
    hidrojetList,
    hidrojetListFilter,
    addComplementaryModal,
    taskListDownloadAbsa,
    taskPipelineEditModal,
    taskListContractorFilter,
    taskSidewalkAddModal,
    taskListTaskConditionsFilter,
  });
export default createRootReducer;
