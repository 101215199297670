import { connect } from "react-redux";

import { closeModal, addConsumption } from "../../../store/ducks/addModal";
import AddConfirmationModal from "./addConfirmationModalContainer/AddConfirmationModal";

const mapStateToProps = state => ({
  open: state.addModal.confirmationOpen,
  gang: state.addModal.gang,
  store: state.addModal.store,
  origin: state.addModal.origin,
  article: state.addModal.article,
  amount: state.addModal.amount,
  showStoreStock: state.addModal.showStoreStock,
  sending: state.addModal.sending,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  addConsumption: () => dispatch(addConsumption()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddConfirmationModal);
