import axios, { gangs as axiosGangs } from "../../axios";
import { successTaskFilter } from "./taskListGangFilter";
import { successTaskListGangs } from "./taskListGangs";

import { parseFromApi, getGangState, getTimeAlert } from "../../utils/GangParser";

import { sortBy } from "lodash";
import { successGangsList } from "./gangList";

const types = {
  GANGS_REQUEST: "GANGS_REQUEST",
  GANGS_SUCCESS: "GANGS_SUCCESS",
  GANGS_FAILURE: "GANGS_FAILURE",
  GANGS_UPDATE: "GANGS_UPDATE",
  GANGS_TOGGLE_SOUND: "GANGS_TOGGLE_SOUND",
  GANGS_CHECK_STATES: "GANGS_CHECK_STATES",
};

const requestGangs = () => ({
  type: types.GANGS_REQUEST,
});

const successGangs = gangs => ({
  type: types.GANGS_SUCCESS,
  gangs,
});

const failedGangs = () => ({
  type: types.GANGS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las cuadrillas",
});

export const updateGang = gang => ({
  type: types.GANGS_UPDATE,
  gang,
});

export const checkGangStates = () => ({
  type: types.GANGS_CHECK_STATES,
});

export const toggleGangSound = gangId => ({
  type: types.GANGS_TOGGLE_SOUND,
  gangId,
});

export const getGangs = () => {
  return async dispatch => {
    dispatch(requestGangs());
    try {
      const [gangsResponse, tasksResponse] = await Promise.all([
        axiosGangs.get("/"),
        axios.get("/gangs"),
      ]);
      const { data } = gangsResponse;
      const { data: groupGangs } = tasksResponse;

      const gangs = data
        .filter(g => g.location && groupGangs.data.find(gang => gang.id === g._id))
        .map(gang => parseFromApi(gang, groupGangs.data));
      dispatch(successGangs(sortBy(gangs, "name")));
      dispatch(successTaskFilter(data, groupGangs));
      dispatch(successTaskListGangs(data, groupGangs));
      dispatch(successGangsList(data, groupGangs));
      return gangs;
    } catch (error) {
      dispatch(failedGangs());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.GANGS_SUCCESS:
      return action.gangs;
    case types.GANGS_CHECK_STATES:
      return state.map(gang => {
        const state = getGangState(gang);
        return {
          ...gang,
          state,
          sound: state !== "Alerta" ? false : gang.sound || gang.state === "Ejecutando",
          timeAlert: getTimeAlert(gang),
        };
      });
    case types.GANGS_TOGGLE_SOUND:
      return state.map(gang =>
        gang.id === action.gangId ? { ...gang, sound: !gang.sound } : gang
      );
    case types.GANGS_UPDATE:
      return state.map(gang =>
        gang.id === action.gang.id
          ? {
              ...gang,
              location: action.gang.location
                ? {
                    date: action.gang.location.date,
                    coordinates: [action.gang.location.lat, action.gang.location.lng],
                  }
                : null,
              alertLocation: action.gang.alertLocation ? action.gang.alertLocation : null,
              state: getGangState({
                state: action.gang.state || gang.state,
                alertLocation: action.gang.alertLocation || gang.alertLocation,
              }),
              sound:
                getGangState(action.gang) !== "Alerta"
                  ? false
                  : gang.sound || gang.state === "Ejecutando",
              timeAlert: action.gang.location
                ? getTimeAlert(action.gang)
                : gang.timeAlert,
            }
          : gang
      );
    default:
      return state;
  }
};

export default reducer;

export const getFilteredGangs = state => {
  const groups = state.groups.groups.filter(g => g.selected);
  let gangs = [];
  if (state.mapInfoPanel.gangs) {
    gangs = [...state.gangs];
  }
  return gangs.filter(gang => groups.find(g => g.id === gang.group));
};
