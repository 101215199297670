import { anexo as axios, anexoOld } from "../../axios";
import { parseFromApi } from "../../utils/GangStockParser";
import { parseFromApi as parseStoreStockFromApi } from "../../utils/StoreStockParser";
import { addArticle } from "./task";
import { enqueueError, enqueueMessage } from "./notifications";
import format from "date-fns/format";
import parse from "date-fns/parse";

const types = {
  ADD_MODAL_OPEN: "ADD_MODAL_OPEN",
  ADD_MODAL_OPEN_CONFIRMATION: "ADD_MODAL_OPEN_CONFIRMATION",
  ADD_MODAL_CLOSE: "ADD_MODAL_CLOSE",
  ADD_MODAL_CHANGE_GANG: "ADD_MODAL_CHANGE_GANG",
  ADD_MODAL_CHANGE_STORE: "ADD_MODAL_CHANGE_STORE",
  ADD_MODAL_CHANGE_ORIGIN: "ADD_MODAL_CHANGE_ORIGIN",
  ADD_MODAL_CHANGE_ARTICLE: "ADD_MODAL_CHANGE_ARTICLE",
  ADD_MODAL_CHANGE_AMOUNT: "ADD_MODAL_CHANGE_AMOUNT",
  ADD_MODAL_CHANGE_AMOUNT_ERROR: "ADD_MODAL_CHANGE_AMOUNT_ERROR",
  ADD_MODAL_CHANGE_SHOW_STORE_STOCK: "ADD_MODAL_CHANGE_SHOW_STORE_STOCK",
  ADD_MODAL_REQUEST: "ADD_MODAL_REQUEST",
  ADD_MODAL_SUCCESS: "ADD_MODAL_SUCCESS",
  ADD_MODAL_FAILURE: "ADD_MODAL_FAILURE",
  ADD_MODAL_REQUEST_STORE_STOCK: "ADD_MODAL_REQUEST_STORE_STOCK",
  ADD_MODAL_SUCCESS_STORE_STOCK: "ADD_MODAL_SUCCESS_STORE_STOCK",
  ADD_MODAL_FAILURE_STORE_STOCK: "ADD_MODAL_FAILURE_STORE_STOCK",
  ADD_MODAL_ADD_REQUEST: "ADD_MODAL_ADD_REQUEST",
  ADD_MODAL_ADD_SUCCESS: "ADD_MODAL_ADD_SUCCESS",
  ADD_MODAL_ADD_FAILURE: "ADD_MODAL_ADD_FAILURE",
};

export const openModal = () => (dispatch, getState) => {
  const {
    task: { gang },
  } = getState();
  dispatch(changeGang(gang));
  dispatch({
    type: types.ADD_MODAL_OPEN,
  });
};

export const openConfirmationModal = () => (dispatch, getState) => {
  const { addModal } = getState();
  if (validateForm(addModal, dispatch)) {
    dispatch({
      type: types.ADD_MODAL_OPEN_CONFIRMATION,
    });
  }
};

export const closeModal = () => ({
  type: types.ADD_MODAL_CLOSE,
});

export const changeGang = gang => dispatch => {
  dispatch({
    type: types.ADD_MODAL_CHANGE_GANG,
    gang,
  });
  dispatch(getGangStock(gang.id));
};

export const changeStore = store => (dispatch, getState) => {
  const {
    addModal: { showStoreStock },
  } = getState();
  if (showStoreStock) {
    dispatch(getStoreStock(store.id));
  }
  dispatch({
    type: types.ADD_MODAL_CHANGE_STORE,
    store,
  });
};

const getStoreStock = storeId => {
  return async (dispatch, getState) => {
    const { task } = getState();
    dispatch(requestStoreStock());
    try {
      const { data } = await axios.get(`/stores/${storeId}/stock`, {
        params: {
          f: task.stockDate,
        },
      });
      const stock = parseStoreStockFromApi(data);
      dispatch(successStoreStock(stock));
    } catch (error) {
      dispatch(enqueueError("Ocurrió un error al obtener el stock del depósito"));
      dispatch(failureStoreStock());
    }
  };
};

export const changeShowStoreStock = value => (dispatch, getState) => {
  if (value) {
    const {
      addModal: { store },
    } = getState();
    if (store) {
      dispatch(getStoreStock(store.id));
    }
  }
  dispatch(changeArticle(null));
  dispatch({
    type: types.ADD_MODAL_CHANGE_SHOW_STORE_STOCK,
    value,
  });
};

export const changeOrigin = origin => ({
  type: types.ADD_MODAL_CHANGE_ORIGIN,
  origin,
});

export const changeArticle = article => ({
  type: types.ADD_MODAL_CHANGE_ARTICLE,
  article,
});

export const changeAmount = amount => ({
  type: types.ADD_MODAL_CHANGE_AMOUNT,
  amount,
});

export const changeAmountError = value => ({
  type: types.ADD_MODAL_CHANGE_AMOUNT_ERROR,
  value,
});

const request = () => ({
  type: types.ADD_MODAL_REQUEST,
});

const success = stock => ({
  type: types.ADD_MODAL_SUCCESS,
  stock,
});

const failure = () => ({
  type: types.ADD_MODAL_FAILURE,
});

const requestStoreStock = () => ({
  type: types.ADD_MODAL_REQUEST_STORE_STOCK,
});

const successStoreStock = stock => ({
  type: types.ADD_MODAL_SUCCESS_STORE_STOCK,
  stock,
});

const failureStoreStock = () => ({
  type: types.ADD_MODAL_FAILURE_STORE_STOCK,
});

const addRequest = () => ({
  type: types.ADD_MODAL_ADD_REQUEST,
});

const addSuccess = stock => ({
  type: types.ADD_MODAL_ADD_SUCCESS,
  stock,
});

const addFailure = () => ({
  type: types.ADD_MODAL_ADD_FAILURE,
});

const getGangStock = gangId => {
  return async (dispatch, getState) => {
    const { task } = getState();

    const gangActions = task.actions.filter(
      c => c.estado_id === 4 && c.cuadrilla_id === gangId
    );
    const stockDate = gangActions.length
      ? format(
          new Date(
            gangActions
              .sort(
                (a, b) =>
                  new Date(b.created_at?.replace(/ /g, "T")) -
                  new Date(a.created_at?.replace(/ /g, "T"))
              )[0]
              ?.created_at?.replace(/ /g, "T")
          ),
          "yyyy-MM-dd"
        )
      : task.stockDate;

    dispatch(request());
    try {
      const { data } = await axios.get(`/gangs/${gangId}/stock`, {
        params: {
          f: stockDate,
        },
      });
      const stock = parseFromApi(data || []);
      const item = stock.find(i => i.stock.length);
      if (item) {
        dispatch(changeStore(item.store));
        dispatch(changeOrigin(item.origin));
      }
      dispatch(success(stock));
    } catch (error) {
      dispatch(enqueueError("Ocurrió un error al obtener el stock de la cuadrilla"));
      dispatch(failure());
    }
  };
};

const validateForm = (addModal, dispatch) => {
  const { gang, store, origin, article, amount } = addModal;
  if (!gang || !store || !origin || !article || !amount) {
    dispatch(enqueueError("Debe ingresar todos los campos"));
    return false;
  } else if (Number(addModal.amount) > Number(addModal.article.max)) {
    dispatch(changeAmountError(true));
    return false;
  }
  return true;
};

const createArticle = (gang, store, origin, article, amount, res, task) => ({
  id: article.id,
  name: article.name,
  unit: article.unit,
  code: article.code,
  store: store,
  origin: origin,
  amount: Number(amount),
  consumption: res.data.id_consumo,
  state: { id: 1, name: "Validado" },
  gang: task.gangs.find(g => g._id === gang.id),
  date: format(parse(res.data.fecha_consumo, "yyyy-MM-dd", new Date()), "dd/MM/yyyy"),
});

export const addConsumption = () => async (dispatch, getState) => {
  const { addModal, task } = getState();
  const { gang, store, origin, article, amount } = addModal;
  dispatch(addRequest());
  try {
    if (amount > article.amount) {
      await anexoOld.post(`/deposito/${store.id}/carga/${gang.id}`, {
        cuadrilla: gang.id,
        deposito: store.id,
        articulo: article.id,
        procedencia: origin.id,
        tarea: task.id,
        fecha: task.stockDate,
        cantidad: amount - article.amount,
      });
    }

    const res = await axios.post(
      `/tareas/${task.id}/consumos`,
      {
        cantidad: amount,
        estado_consumo: "1",
        id_deposito: store.id,
        id_cuadrilla: gang.id,
        id_articulo: article.id,
        id_procedencia: origin.id,
        fecha_consumo: task.stockDate,
      },
      { headers: { "x-api-cuadrilla-id": gang.id } }
    );
    dispatch(enqueueMessage("El consumo se agregó correctamente"));
    const a = createArticle(gang, store, origin, article, amount, res, task);
    dispatch(addArticle(a));
    dispatch(addSuccess());
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al agregar el consumo"));
    dispatch(addFailure());
  }
};

const initialState = {
  modalOpen: false,
  confirmationOpen: false,
  gang: null,
  store: null,
  origin: null,
  article: null,
  amount: "",
  amountError: false,
  loading: false,
  loadingStoreStock: false,
  sending: false,
  showStoreStock: false,
  stock: [],
  storeStock: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
        article: null,
        amount: "",
        showStoreStock: false,
      };
    case types.ADD_MODAL_OPEN_CONFIRMATION:
      return { ...state, modalOpen: false, confirmationOpen: true };
    case types.ADD_MODAL_CLOSE:
      return { ...state, modalOpen: false, confirmationOpen: false };
    case types.ADD_MODAL_CHANGE_GANG:
      return { ...state, gang: action.gang };
    case types.ADD_MODAL_CHANGE_STORE:
      return {
        ...state,
        store: action.store,
        origin: null,
        article: null,
        amount: "",
        amountError: false,
      };
    case types.ADD_MODAL_CHANGE_ORIGIN:
      return {
        ...state,
        origin: action.origin,
        article: null,
        amount: "",
        amountError: false,
      };
    case types.ADD_MODAL_CHANGE_ARTICLE:
      return { ...state, article: action.article };
    case types.ADD_MODAL_CHANGE_AMOUNT:
      return { ...state, amount: action.amount, amountError: false };
    case types.ADD_MODAL_CHANGE_AMOUNT_ERROR:
      return { ...state, amountError: action.value };
    case types.ADD_MODAL_CHANGE_SHOW_STORE_STOCK:
      return { ...state, showStoreStock: action.value };
    case types.ADD_MODAL_REQUEST:
      return { ...state, loading: true };
    case types.ADD_MODAL_SUCCESS:
      return { ...state, loading: false, stock: action.stock };
    case types.ADD_MODAL_FAILURE:
      return { ...state, loading: false };
    case types.ADD_MODAL_REQUEST_STORE_STOCK:
      return { ...state, loadingStoreStock: true };
    case types.ADD_MODAL_SUCCESS_STORE_STOCK:
      return { ...state, loadingStoreStock: false, storeStock: action.stock };
    case types.ADD_MODAL_FAILURE_STORE_STOCK:
      return { ...state, loadingStoreStock: false };
    case types.ADD_MODAL_ADD_REQUEST:
      return { ...state, sending: true };
    case types.ADD_MODAL_ADD_SUCCESS:
      return { ...state, sending: false, confirmationOpen: false };
    case types.ADD_MODAL_ADD_FAILURE:
      return { ...state, sending: false };
    default:
      return state;
  }
};

export const getStores = state => {
  return [
    ...new Map(state.addModal.stock.map(item => [item.store.id, item])).values(),
  ].map(item => ({ id: item.store.id, name: item.store.name }));
};

export const getOrigins = state => {
  return state.addModal.store
    ? state.addModal.stock
        .filter(item => item.store.id === state.addModal.store.id)
        .map(item => item.origin)
    : [];
};

export const getArticles = state => {
  const { store, stock, origin, showStoreStock, storeStock } = state.addModal;
  const { articles } = state.task;
  const notValidated = articles.filter(a => a.state.id === 0);
  if (store && origin) {
    const currentStore = stock.find(
      item => item.store.id === store.id && item.origin.id === origin.id
    );
    let materials = currentStore
      ? currentStore.stock.filter(
          a => Number(a.amount) > 0 && !notValidated.find(art => art.id === a.id)
        )
      : [];
    if (showStoreStock) {
      const currentOrigin = storeStock.find(o => o.origin.id === origin.id);
      if (currentOrigin) {
        const final = currentOrigin.stock
          .filter(a => a.amount > 0 && !notValidated.find(art => art.id === a.id))
          .map(a => ({
            ...a,
            amount: 0,
            storeAmount: a.amount,
          }));
        materials.forEach(m => {
          const article = final.find(a => a.id === m.id);
          if (article) {
            article.amount = m.amount;
            article.max = m.amount + article.storeAmount;
          } else {
            final.push({ ...m, storeAmount: 0 });
          }
        });
        return final;
      }
    }
    return materials;
  } else {
    return [];
  }
};

export default reducer;
