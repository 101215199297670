import { connect } from "react-redux";

import { closeModal, save } from "../../../../store/ducks/taskListTaskConditionsFilter";
import TasksConditionDialog from "./taskConditionsDialogContainer/taskConditionsDialog";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  openModal: state.taskListTaskConditionsFilter.openModal,
  isFailure: state.taskListTaskConditionsFilter.isFailure,
  isExpired: state.taskListTaskConditionsFilter.isExpired,
  hasPipelineInfo: state.taskListTaskConditionsFilter.hasPipelineInfo,
  isComplementary: state.taskListTaskConditionsFilter.isComplementary,
  requiresCleaning: state.taskListTaskConditionsFilter.requiresCleaning,
  requiresMaterials: state.taskListTaskConditionsFilter.requiresMaterials,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: values => {
    dispatch(save(values));
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksConditionDialog);
