import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  progress: {
    margin: "auto",
    marginTop: "150px",
  },
});

const preloader = ({ classes }) => (
  <Grid container>
    <CircularProgress className={classes.progress} size={50} />
  </Grid>
);

export default withStyles(styles)(preloader);
