import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/GetApp";
import ArticlesIcon from "@mui/icons-material/Assignment";
import CheckIcon from "@mui/icons-material/Check";
import SummaryIcon from "@mui/icons-material/PlaylistAddCheck";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import format from "date-fns/format";

const styles = theme => ({
  button: {
    margin: theme.spacing(0.2),
  },
  iconContainer: { display: "flex", justifyContent: "flex-end" },
  icon: { marginRight: 10 },
  spinner: {
    display: "flex",
    justifyContent: "center",
    width: 48,
    height: 48,
    alignItems: "center",
  },
});

const body = props => {
  const { tasks, page, rowsPerPage, classes } = props;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tasks.length - page * rowsPerPage);
  return (
    <TableBody>
      {tasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(a => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={a.id}>
            <TableCell padding="checkbox">
              <Checkbox
                color="default"
                onClick={() => props.onCheck(a.id)}
                checked={a.selected}
              />
            </TableCell>
            <TableCell component="th" scope="row" padding="none">
              {a.otCode}
            </TableCell>
            <TableCell>{a.group.name || "-"}</TableCell>
            <TableCell>{a.gang.name || "-"}</TableCell>
            <TableCell>{a.address}</TableCell>
            <TableCell>
              {a.validationDate ? format(a.validationDate, "dd/MM/yyyy") : ""}
            </TableCell>
            <TableCell>{a.task || "-"}</TableCell>
            <TableCell>{a.module || "-"}</TableCell>
            <TableCell padding="none">
              <div className={classes.iconContainer}>
                {a.sheetGenerated && (
                  <Tooltip title={`ODT descargada por ${a.sheetGenerated}`}>
                    <CheckIcon className={classes.icon} />
                  </Tooltip>
                )}
                {a.summaryGenerated && (
                  <Tooltip title={`Planilla descargada por ${a.summaryGenerated}`}>
                    <SummaryIcon className={classes.icon} />
                  </Tooltip>
                )}
              </div>
            </TableCell>
            <TableCell padding="none">
              <div className={classes.iconContainer}>
                {props.loading === a.id ? (
                  <div className={classes.spinner}>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <Tooltip title="Descargar">
                    <IconButton
                      disabled={!!props.loading}
                      onClick={() => props.onDownload(a.id)}
                      className={props.classes.button}
                      size="large">
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Ver materiales">
                  <IconButton
                    onClick={() => props.openArticlesDialog(a.id, a.otCode)}
                    className={props.classes.button}
                    size="large">
                    <ArticlesIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: (48 * emptyRows) / 2 }}>
          <TableCell colSpan={10} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default withStyles(styles)(body);
