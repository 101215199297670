import { Card, CardContent, CardHeader } from "@mui/material";

const styles = { padding: 15, width: "100%", marginBottom: 20 };

export default props => (
  <Card style={styles} variant="outlined">
    <CardHeader
      title={props.title}
      titleTypographyProps={{ variant: "h6", style: { fontWeight: 300 } }}
    />
    <CardContent>{props.children}</CardContent>
  </Card>
);
