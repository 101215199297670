import { connect } from "react-redux";

import { closeModal } from "../../../store/ducks/finishValidationModal";
import FinishValidationErrorModal from "./finishValidationErrorModalContainer/FinishValidationErrorModal";

const mapStateToProps = state => ({
  open: state.finishValidationModal.modalErrorOpen,
  task: state.task,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinishValidationErrorModal);
