import Grid from "@mui/material/Grid";

import StateChangedTasksDialog from "./StateChangedTasksDialogContainer/StateChangedTasksDialogContainer";
import TransferGangDialog from "./TransferGangDialogContainer/TransferGangDialogContainer";
import UnassignGangDialog from "./UnassignGangDialogContainer/UnassignGangDialogContainer";
import AddGangDialog from "./AddGangToGroupDialogContainer/AddGangToGroupDialogContainer";

import GangsTable from "./GroupGangsTable/GangsTable";
import Preloader from "../../components/UI/Preloader";
import withStyles from "@mui/styles/withStyles";
import { memo } from "react";

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
});

const GangList = memo(props => {
  const { classes } = props;

  return props.isFetching ? (
    <Preloader />
  ) : (
    <>
      {props.roles && props.roles.includes("asignarGrupo") ? <AddGangDialog /> : null}

      {props.roles && props.roles.includes("desasignarGrupo") ? (
        <UnassignGangDialog />
      ) : null}

      {props.roles && props.roles.includes("cambiarGrupo") ? (
        <TransferGangDialog />
      ) : null}

      <StateChangedTasksDialog />
      <Grid container spacing={1} className={classes.root}>
        <GangsTable />
      </Grid>
    </>
  );
});

export default withStyles(styles)(GangList);
