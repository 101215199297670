import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import {
  Avatar,
  Card,
  List,
  ListItem,
  CardContent,
  ListItemText,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import { TimelineOppositeContent } from "@mui/lab";

import versions from "../versions";
import { format } from "date-fns";

export default function LeftPositionedTimeline() {
  return (
    <div style={{ margin: 30 }}>
      <Typography variant="h5" gutterBottom>
        Actualizaciones
      </Typography>
      <Timeline>
        {versions.map(v => (
          <TimelineItem key={v.timestamp}>
            <TimelineOppositeContent color="text.secondary" style={{ maxWidth: 150 }}>
              <div>v{v.version}</div>
              <small>{format(new Date(v.timestamp * 1000), "dd/MM/yyyy HH:mm")}</small>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Card variant="outlined">
                <CardContent>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}>
                    {v.changes.map(c => (
                      <ListItem key={c.title}>
                        <ListItemAvatar>
                          <Avatar>{c.avatar}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={c.title} secondary={c.description} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
}
