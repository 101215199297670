import { format } from "date-fns";
import { connect } from "react-redux";

import {
  Card,
  Divider,
  Tooltip,
  IconButton,
  Typography,
  CardContent,
  ListItemSecondaryAction,
} from "@mui/material";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import { CardHeader } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import makeStyles from "@mui/styles/makeStyles";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CheckIcon from "@mui/icons-material/Check";

import clsx from "clsx";
import EditIcon from "@mui/icons-material/Edit";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getInitials } from "../detailCard";
import { openModal } from "../../../../store/ducks/taskHidrojetEditModal";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const getStateClass = state => {
  if (state === "Validado") {
    return "Materiales-validados";
  }
  return state;
};

const HidrojetModuleCard = ({ task: { hidrojets = [] }, ...props }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return hidrojets.length ? (
    <Card variant="outlined" style={{ marginTop: 10 }}>
      <CardHeader
        titleTypographyProps={{ variant: "h6", style: { fontWeight: 400 } }}
        title="Módulos de hidrojet"
        action={
          <>
            {
              <IconButton onClick={handleExpandClick} size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            }
          </>
        }
      />
      <CardContent>
        {!expanded ? (
          <Typography style={{ fontWeight: 100, marginTop: -10 }}>{`La tarea posee ${
            (hidrojets || []).length
          } ${
            (hidrojets || []).length !== 1
              ? "registros de módulos de hidrojet."
              : "registro de módulo de hidrojet."
          }`}</Typography>
        ) : null}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List>
            {(hidrojets || []).map((action, index) => (
              <>
                <ListItem
                  key={action.id}
                  style={{
                    padding: "20px 0px",
                    paddingTop: index === 0 ? 0 : 20,
                    paddingBottom: index === action.length - 1 ? 0 : 20,
                  }}>
                  <ListItemAvatar>
                    <Avatar>
                      <Tooltip title={action.state === 0 ? "Sin Validar" : "Validado"}>
                        <p
                          className={[
                            classes.stateIcon,
                            getStateClass(
                              action.state === 0 ? "Sin Validar" : "Validado"
                            ),
                          ]}>
                          {getInitials(action.state === 0 ? "Sin Validar" : "Validado")}
                        </p>
                      </Tooltip>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<div>Cuadrilla: {action.cuadrilla?.cuadrilla || "-"} </div>}
                    secondary={
                      <div>
                        <div>
                          Día:
                          {format(
                            new Date(action.created_at?.replace(/ /g, "T")),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                        </div>
                        <div>Módulos: {action.module || "0"}</div>
                      </div>
                    }
                  />
                  {action.validated_by ? (
                    <ListItemSecondaryAction
                      style={{
                        textAlign: "right",
                        marginRight: !props.roles.includes("validarHidrojet") ? 0 : 50,
                      }}>
                      <ListItemText
                        primary={<div>{action.validated_by?.name || "-"} </div>}
                        secondary={
                          <>
                            <div>
                              {format(
                                new Date(action.validated_at?.replace(/ /g, "T")),
                                "dd/MM/yyyy HH:mm:ss"
                              )}
                            </div>
                          </>
                        }
                      />
                    </ListItemSecondaryAction>
                  ) : null}

                  {props.roles.includes("validarHidrojet") ? (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => props.open(action)}
                        size="large">
                        <Tooltip
                          title={action.validated_by ? "Editar validación" : "Validar"}>
                          {action.state === 1 ? <CheckIcon /> : <EditIcon />}
                        </Tooltip>
                      </IconButton>
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
                {action.observations ? (
                  <div style={{ padding: 20 }}>{action.observations}</div>
                ) : null}
                {index !== (hidrojets || []).length - 1 ? <Divider key={index} /> : null}
              </>
            ))}
          </List>
        </Collapse>
      </CardContent>
    </Card>
  ) : null;
};

const mapStateToProps = state => ({
  roles: state.user.roles,
});

const mapDispatchToProps = dispatch => ({
  open: module => dispatch(openModal(module)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HidrojetModuleCard);
