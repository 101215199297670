import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Preloader from "../../components/UI/Preloader";
import Title from "./taskList/Title";
import TaskTableContainer from "./taskList/TaskTableContainer";
import ToolbarContainer from "./taskList/ToolbarContainer";
import DownloadDialogContainer from "./taskList/DownloadDialogContainer";
import TaskArticlesDialogContainer from "./taskList/TaskArticlesDialogContainer";
import DownloadHelpButton from "../../components/UI/DownloadHelpButton";

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

const taskList = props => {
  const { classes } = props;
  return props.isFetching ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Paper elevation={0} variant="outlined">
            <Grid container className={classes.title}>
              <Grid item xs>
                <Title />
              </Grid>
              <Grid item>
                <DownloadHelpButton file="planillas.mp4" />
              </Grid>
            </Grid>
            <ToolbarContainer />
            <div className={classes.tableContainer}>
              <TaskTableContainer />
            </div>
            <DownloadDialogContainer />
            <TaskArticlesDialogContainer />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(taskList);
