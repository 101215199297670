/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import classNames from "classnames";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListIcon from "@mui/icons-material/List";
import CheckIcon from "@mui/icons-material/PlaylistAddCheck";
import DatePicker from "@mui/lab/MobileDatePicker";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

import { useDebounce } from "../../../../utils/useDebounce";
import { TextField } from "@mui/material";

const styles = theme => ({
  title: {
    flex: "0 0 auto",
  },
  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3.5),
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  actions: {
    color: theme.palette.text.secondary,
    marginLeft: "auto",
    marginRight: theme.spacing(3),
  },
  searchIcon: {
    color: "rgba(0,0,0,0.54)",
    marginRight: 7,
  },
  groupSelect: {
    marginVertical: theme.spacing(1),
  },
});

const ToolbarComponent = props => {
  const { classes, odt, getTasks } = props;

  const debouncedOdt = useDebounce(odt, 500);
  const [prev, setPrevious] = useState();

  useEffect(() => {
    if (prev || debouncedOdt) {
      getTasks();
    }
    setPrevious(debouncedOdt);
  }, [debouncedOdt, getTasks]);

  return (
    <Fragment>
      <Toolbar
        className={classNames(classes.toolbar, {
          [classes.highlight]: false,
        })}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Grid item xs={12} lg={8}>
            <div style={{ display: "flex" }}>
              <SearchIcon className={classes.searchIcon} />
              <Input
                variant="standard"
                value={props.odt}
                onChange={e => props.changeOdt(e.target.value)}
                disableUnderline
                fullWidth
                placeholder="Buscar por odt"
              />
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={1}>
            <DatePicker
              clearable
              todayText="Hoy"
              clearText="Borrar"
              cancelText="Cancelar"
              margin="dense"
              showTodayButton
              inputFormat="dd/MM/yyyy"
              label="Fecha desde"
              InputProps={{
                disableUnderline: true,
              }}
              value={props.dateFrom}
              onChange={date => props.changeDateFrom(date)}
              DialogProps={{ disableRestoreFocus: true }}
              renderInput={params => (
                <TextField {...params} variant="standard" style={{ width: "100%" }} />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={1}>
            <DatePicker
              clearable
              todayText="Hoy"
              clearText="Borrar"
              cancelText="Cancelar"
              margin="dense"
              showTodayButton
              inputFormat="dd/MM/yyyy"
              label="Fecha hasta"
              InputProps={{
                disableUnderline: true,
              }}
              value={props.dateTo}
              onChange={date => props.changeDateTo(date)}
              DialogProps={{ disableRestoreFocus: true }}
              renderInput={params => (
                <TextField variant="standard" {...params} style={{ width: "100%" }} />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={1}>
            <FormControl style={{ width: "99%", marginBottom: 10 }}>
              <InputLabel id="group-select">Grupo</InputLabel>
              <Select
                labelId="group-select"
                disableUnderline
                variant="standard"
                value={props.group}
                onChange={e => props.changeGroup(e.target.value)}>
                <MenuItem value={0}>Todos</MenuItem>
                {props.groups.map(group => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={1}>
            <FormControl style={{ width: "99%", marginBottom: 10 }}>
              <InputLabel id="group-select">Mostrar Descargadas</InputLabel>
              <Select
                variant="standard"
                disableUnderline
                value={props.hideDonwloaded}
                onChange={event => {
                  props.changeHideDownloaded(Boolean(event.target.value));
                }}>
                <MenuItem value={false}>Si</MenuItem>
                <MenuItem value={true}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Toolbar>
      <Toolbar
        className={classNames(classes.toolbar, {
          [classes.highlight]: true,
        })}>
        <div className={classes.title}>
          <Typography color="inherit" variant="subtitle1">
            {props.selectedCount} seleccionadas
          </Typography>
        </div>
        <div className={classes.actions}>
          {props.showSelectedTasks ? (
            <Tooltip title="Ver todas las tareas">
              <IconButton onClick={props.toggleShowSelectedTasks} size="large">
                <CheckIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Ver solo las seleccionadas">
              <IconButton onClick={props.toggleShowSelectedTasks} size="large">
                <ListIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Descargar">
            <IconButton onClick={props.openDownloadModal} size="large">
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </Fragment>
  );
};

export default withStyles(styles)(ToolbarComponent);
