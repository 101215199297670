import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Autocomplete, TextField, Grid } from "@mui/material";

import Map from "./map";
import { calculateGeoPoint } from "../../../../geocode";
import { getGroups } from "../../../../store/ducks/groups";

export const EditTaskStep2 = props => {
  const {
    form: {
      control,
      setValue,
      setError,
      register,
      getValues,
      clearErrors,
      formState: { errors },
    },
  } = props;

  const dispatch = useDispatch();
  const cities = useSelector(state => state.cities);
  const [marker, setMarker] = useState({
    lat: props.task.latitud,
    lng: props.task.longitud,
  });

  useEffect(() => {
    register("step2.localidad", { required: true });
    register("step2.position", { required: true });
    register("step2.custom_geo");
  }, [register, getValues, setValue]);

  const onChange = useCallback(async () => {
    if (!errors.step2 || !Object.values(errors.step2).filter(a => a).length) {
      const fields = getValues();
      const position = await calculateGeoPoint(fields.step2);
      if (position) {
        setMarker(position);
        setValue("step2.position", position);
      }
    }
  }, [setValue, errors, getValues]);

  const updateGroups = useCallback(
    id => {
      setValue("step3.grupo", null);

      if (props.task.estado_id === 1) {
        setError("step3.grupo", {
          type: "manual",
          message: "",
        });
        dispatch(getGroups(id));
      }
    },
    [setValue, dispatch, setError, props.task]
  );

  const onMarkerMove = useCallback(
    position => {
      if (position) {
        setMarker(position);
        setValue("step2.custom_geo", true);
        setValue("step2.position", position);
      }
    },
    [setValue, setMarker]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step2.calle"
          rules={{
            required: "Debes ingresar la calle",
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              required
              fullWidth
              label="Calle"
              margin="dense"
              variant="outlined"
              onChange={e => {
                if (field.onChange) field.onChange(e);
                onChange(e);
              }}
              error={errors.step2?.calle}
              inputProps={{ maxLength: 200 }}
              helperText={errors.step2?.calle?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step2.altura"
          rules={{
            maxLength: { value: 10, message: "Debe tener menos de 10 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Altura"
              margin="dense"
              variant="outlined"
              onChange={e => {
                if (field.onChange) field.onChange(e);
                onChange(e);
              }}
              error={errors.step2?.altura}
              helperText={errors.step2?.altura?.message}
              inputProps={{ maxLength: 10 }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step2.entre"
          rules={{
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Entre"
              margin="dense"
              variant="outlined"
              onChange={e => {
                if (field.onChange) field.onChange(e);
                onChange(e);
              }}
              error={errors.step2?.entre}
              inputProps={{ maxLength: 200 }}
              helperText={errors.step2?.entre?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step2.y"
          rules={{
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Y"
              margin="dense"
              variant="outlined"
              onChange={e => {
                if (field.onChange) field.onChange(e);
                onChange(e);
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          options={cities}
          disableClearable
          control={control}
          noOptionsText="Sin Resultados"
          style={{ width: "100%" }}
          value={getValues("step2.localidad")}
          getOptionLabel={option => option.name}
          defaultValue={{
            id: props.task.localidad.id,
            name: props.task.localidad.nombre,
          }}
          onChange={(e, value) => {
            e.stopPropagation();
            if (value) {
              setValue("step2.localidad", value);
              setValue("step3.grupo", null);
              clearErrors("step2.localidad");
              updateGroups(value.id);
              onChange();
            } else {
              setError("step2.localidad", {
                type: "required",
                message: "Debes indicar la localidad de la tarea.",
              });
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              required
              margin="dense"
              label="Localidad"
              variant="outlined"
              error={errors.step2?.localidad}
              helperText={errors.step2?.localidad?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Controller
          control={control}
          name="step2.contact_phone"
          rules={{
            required: false,
            minLength: { value: 5, message: "Debe tener al menos 5 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              name="step2.contact_phone"
              label="Teléfono de contacto"
              error={errors.step2?.contact_phone}
              inputProps={{ maxLength: 200, minLength: 0 }}
              helperText={errors.step2?.contact_phone?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Controller
          control={control}
          name="step2.aclaracion"
          rules={{
            required: true,
            minLength: { value: 5, message: "Debe tener al menos 5 caracteres." },
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              fullWidth
              variant="outlined"
              label="Aclaraciones"
              error={errors.step2?.aclaracion}
              inputProps={{ maxLength: 200, minLength: 5 }}
              helperText={errors.step2?.aclaracion?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Map marker={marker} onMarkerMove={onMarkerMove} />
      </Grid>
    </Grid>
  );
};

export default EditTaskStep2;
