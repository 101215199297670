import { Fragment } from "react";
import TableBody from "@mui/material/TableBody";

import Preloader from "./Preloader";
import Content from "./Content";

const tableBody = props => {
  const { page, rowsPerPage, data } = props;

  return (
    <TableBody>
      {props.isFetchingStock ? (
        <Preloader />
      ) : (
        <Fragment>
          <Content
            data={data}
            page={page}
            group={props.group}
            rowsPerPage={rowsPerPage}
            canTransfer={props.roles && props.roles.includes("cambiarGrupo")}
            canUnassign={props.roles && props.roles.includes("desasignarGrupo")}
            setTaskListFilters={props.setTaskListFilters}
            openTransferGangModal={props.openTransferGangModal}
            openUnassignGroupModal={props.openUnassignGroupModal}
          />
        </Fragment>
      )}
    </TableBody>
  );
};

export default tableBody;
