import { connect } from "react-redux";

import {
  removeStateChanges,
  openModal,
} from "../../../../../store/ducks/taskListStateChangesFilter";
import StateChanges from "./stateChangesContainer/StateChanges";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  dateFrom: state.taskListStateChangesFilter.dateFrom,
  dateTo: state.taskListStateChangesFilter.dateTo,
  states: state.taskListStateChangesFilter.statesChanges,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeStateChanges: () => {
    dispatch(removeStateChanges());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StateChanges);
