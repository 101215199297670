import Title from "./Title";
import { Grid } from "@mui/material";
import { memo } from "react";

export default memo(({ gang }) => {
  return (
    <Grid container style={{ marginBottom: 10 }} justifyContent="space-between">
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="baseline">
          <Title text={gang?.cuadrilla} variant="h4" />
        </Grid>
      </Grid>
    </Grid>
  );
});
