import { connect } from "react-redux";

import {
  closeModal,
  confirmEdit,
  changeModule,
  changeObservations,
} from "../../../../store/ducks/taskHidrojetEditModal";

import EditModal from "./modal";

const mapStateToProps = state => ({
  loading: false,
  task: state.task,

  action: state.taskHidrojetEditModal.action,
  module: state.taskHidrojetEditModal.module,
  taskDate: state.taskHidrojetEditModal.taskDate,
  observations: state.taskHidrojetEditModal.observations,

  open: state.taskHidrojetEditModal.modalOpen,
  sending: state.taskHidrojetEditModal.sending,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  confirmEdit: () => dispatch(confirmEdit()),
  changeAmount: module => dispatch(changeModule(module)),
  changeObservations: observations => dispatch(changeObservations(observations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
