import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";

import Preloader from "../../components/UI/Preloader";
import Title from "./taskList/Title";
import Tasks from "./taskList/Tasks";
import Filters from "./taskList/Filters";

import AddButton from "./taskList/AddButton";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  left: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  right: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
}));

const TaskList = props => {
  const classes = useStyles();

  return props.isFetching ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item sm={7} className={classes.left}>
          <Tasks />
        </Grid>
        <Grid item sm={5} className={classes.right}>
          <Title paginator={props.paginator} />
          <Filters />
        </Grid>
      </Grid>

      <AddButton />
    </div>
  );
};

export default TaskList;
