import { connect } from "react-redux";

import { closeModal } from "../../../store/ducks/taskArticles";
import TaskArticlesDialog from "./taskArticlesDialogContainer/TaskArticlesDialog";

const mapStateToProps = state => ({
  open: state.taskArticles.open,
  articles: state.taskArticles.articles,
  odt: state.taskArticles.odt,
  loading: state.taskArticles.loading,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskArticlesDialog);
