import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const chips = props => {
  const { classes } = props;

  return props.types.length ? (
    <li>
      <Chip
        onClick={props.openModal}
        className={classes.chip}
        label={`Tipos: ${props.types.length}`}
        onDelete={() => {
          props.removeTypes();
        }}
      />
    </li>
  ) : null;
};

export default withStyles(styles)(chips);
