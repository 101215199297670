import axios from "../../axios";
import { getTask } from "./taskDetails";
import { enqueueError, enqueueMessage } from "./notifications";
import { getTasks } from "./hidrojetList";

const types = {
  HIDROJET_EDIT_MODAL_OPEN: "HIDROJET_EDIT_MODAL_OPEN",
  HIDROJET_EDIT_MODAL_CLOSE: "HIDROJET_EDIT_MODAL_CLOSE",
  HIDROJET_EDIT_MODAL_SENDING: "HIDROJET_EDIT_MODAL_SENDING",
  HIDROJET_EDIT_MODAL_CHANGE_MODULE: "HIDROJET_EDIT_MODAL_CHANGE_MODULE",
  HIDROJET_EDIT_MODAL_CONFIRM_EDIT: "HIDROJET_EDIT_MODAL_CONFIRM_EDIT",
  HIDROJET_EDIT_MODAL_CHANGE_OBSERVATIONS: "HIDROJET_EDIT_MODAL_CHANGE_OBSERVATIONS",
};

export const openModal = action => dispatch => {
  dispatch({
    action,
    type: types.HIDROJET_EDIT_MODAL_OPEN,
  });
};

export const closeModal = () => ({
  type: types.HIDROJET_EDIT_MODAL_CLOSE,
});

const sending = value => ({
  value,
  type: types.HIDROJET_EDIT_MODAL_SENDING,
});

export const confirmEdit =
  (module, observations, taskDate, details) => async (dispatch, getState) => {
    const { taskHidrojetEditModal, taskDetails } = getState();
    const { action } = taskHidrojetEditModal;
    const { task } = taskDetails;

    dispatch(sending(true));
    try {
      axios
        .patch(`/hidrojet/${action.id}`, {
          module,
          observations,
          task_date:
            taskDate instanceof Date ? taskDate.toISOString().split("T")[0] : taskDate,
        })
        .then(() => {
          dispatch(sending(false));

          if (details) {
            dispatch(getTask(task.id));
          } else {
            dispatch(getTasks(1));
          }

          dispatch(closeModal());
          dispatch(enqueueMessage("El registro de módulos fue validado correctamente."));
        });
    } catch (error) {
      dispatch(sending(false));
      dispatch(enqueueError("Ocurrió un error al validar los módulos."));
    }
  };

export const changeModule = module => ({
  type: types.HIDROJET_EDIT_MODAL_CHANGE_MODULE,
  module,
});

export const changeObservations = observations => ({
  type: types.HIDROJET_EDIT_MODAL_CHANGE_OBSERVATIONS,
  observations,
});

const initialState = {
  modalOpen: false,
  confirmationOpen: false,

  module: 0,
  taskDate: null,
  observations: null,

  sending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HIDROJET_EDIT_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
        ...action.action,
        action: action.action,
        taskDate: action.action.task_date,
      };

    case types.HIDROJET_EDIT_MODAL_SENDING: {
      return { ...state, sending: action.value };
    }
    case types.HIDROJET_EDIT_MODAL_OPEN_CONFIRMATION:
      return { ...state, modalOpen: false, confirmationOpen: true };

    case types.HIDROJET_EDIT_MODAL_CLOSE:
      return { ...state, modalOpen: false, confirmationOpen: false };

    case types.HIDROJET_EDIT_MODAL_CHANGE_MODULE:
      return { ...state, amount: action.amount };

    case types.HIDROJET_EDIT_MODAL_CHANGE_OBSERVATIONS:
      return { ...state, observations: action.observations };

    case types.HIDROJET_EDIT_MODAL_CONFIRM_EDIT:
      return { ...state, ...action.data, edited: true };

    default:
      return state;
  }
};

export default reducer;
