import { connect } from "react-redux";

import {
  closeModal,
  getGangs,
  changeComment,
  changeGang,
  confirm,
} from "../../../../store/ducks/taskListScheduleTask";
import ScheduleDialog from "./scheduleDialogContainer/ScheduleDialog";

const mapStateToProps = state => ({
  openModal: state.taskListScheduleTask.openModal,
  loading: state.taskListScheduleTask.loading,
  gangs: getGangs(state),
  gang: state.taskListScheduleTask.gang,
  gangError: state.taskListScheduleTask.gangError,
  task: state.taskListScheduleTask.task,
  stores: state.storesList,
  roles: state.user.roles,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  confirm: () => dispatch(confirm()),
  changeGang: gang => dispatch(changeGang(gang)),
  changeComment: comment => dispatch(changeComment(comment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDialog);
