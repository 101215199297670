/* eslint-disable react-hooks/exhaustive-deps */
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";

import Stepper from "./newTask/stepper";
import PreviewCard from "./newTask/previewCard";
import Preloader from "../../components/UI/Preloader";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 25,
  },
  fixed: {
    position: "fixed",
    top: 0,
    width: "98%",
    right: "10px",
    padding: "18px !important",
    [theme.breakpoints.down("sm")]: {
      position: "initial",
    },
  },
}));

export default props => {
  const classes = useStyles();
  const [fixed, setFixed] = useState(false);

  const { ...form } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      step1: {
        tarea: "",
        modulo: "",
        codigo_ot: "",
        tipo: undefined,
        ingreso_ot: null,
        operative_zone: undefined,
        fecha_asignada: new Date(),
        valida_materiales: true,
      },

      // step 2 - Domicilio
      step2: {
        y: "",
        entre: "",
        calle: "",
        altura: "",
        observaciones: "",
        contact_phone: "",
        localidad: undefined,
      },

      // step 3 - Grupo
      step3: {
        grupo: undefined,
        stores: [],
        for_contractor: null,
      },
    },
    shouldUnregister: false,
  });

  const data = form.watch();

  useEffect(() => {
    const onScroll = () => {
      setFixed(
        window.innerWidth >= 960 &&
          document.documentElement.scrollTop + document.body.scrollTop > 75
      );
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [fixed]);

  return props.isFetching ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Stepper form={form} />
        </Grid>

        <Grid item xs={12} md={4} className={fixed ? classes.fixed : null}>
          <PreviewCard task={data} />
        </Grid>
      </Grid>
    </div>
  );
};
