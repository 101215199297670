const getState = state => {
  switch (state) {
    case "Pendiente":
      return { name: "Pendiente", type: "Pendiente", class: "Pendiente" };
    case "Ejecución":
      return { name: "Ejecución", type: "Activa", class: "Ejecución" };
    case "Pausada":
      return { name: "Pausada", type: "Activa", class: "Pausada" };
    case "Asignada":
      return { name: "Asignada", type: "Activa", class: "Asignada" };
    case "Programada":
      return { name: "Programada", type: "Activa", class: "Programada-T" };
    default:
      break;
  }
};

export const parseFromApi = tasks => {
  return tasks.map(task => {
    const date = new Date(task.fecha_asignada?.replace(/ /g, "T"));
    const dueDate = date.setDate(date.getDate() + task.tipo.limite);
    const expired = dueDate < new Date();
    return {
      id: task.id,
      otCode: task.codigo_ot,
      state: getState(task.estado.estado),
      address: task.ubicacion,
      street: task.calle || "",
      number: task.altura || "",
      between: task.entre || "",
      and: task.y || "",
      task: task.tarea,
      comment: task.observaciones,
      user: task.created_by.name,
      type: task.tipo.tipo,
      typeId: task.tipo.id,
      group: { id: task.grupo.id, name: task.grupo.nombre },
      location: { coordinates: [task.latitud, task.longitud] },
      date: new Date(task.fecha_asignada?.replace(/ /g, "T")),
      dueDate,
      expired,
      ...(task.cuadrilla_id ? { gangId: task.cuadrilla_id } : null),
      ...(task.cuadrilla ? { gangName: task.cuadrilla.cuadrilla } : null),
    };
  });
};
