import * as ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { ConfirmProvider } from "material-ui-confirm";
import { BrowserRouter as Router } from "react-router-dom";

import Keycloak from "keycloak-js";
import * as Sentry from "@sentry/react";

import App from "./App";
import Auth from "./utils/KeycloakAuth";
import { loginUser } from "./store/ducks/user";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import axios, { gangs, anexo, anexoOld, stores } from "./axios";

import VersionAlert from "./components/VersionAlert";
import { authStart, authSuccess, authError, roleError } from "./store/ducks/keycloak";

export const keycloak = new Keycloak(`${process.env.PUBLIC_URL}/keycloak.json`);

const setAxiosInterceptos = keycloak => {
  const keycloakInt = async config => {
    try {
      const refreshed = await keycloak.updateToken();
      if (refreshed) {
        Auth.updateSavedToken(keycloak);
      }
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      return Promise.resolve(config);
    } catch (error) {
      keycloak.login();
    }
  };

  axios.interceptors.request.use(keycloakInt);
  gangs.interceptors.request.use(keycloakInt);
  stores.interceptors.request.use(keycloakInt);
  anexo.interceptors.request.use(keycloakInt);
  anexoOld.interceptors.request.use(keycloakInt);

  const retry = error => {
    // workaround for laravel
    if (error.response.status === 424) {
      return axios.request(error.config);
    }
    return Promise.reject(error);
  };

  axios.interceptors.response.use(response => response, retry);
  gangs.interceptors.response.use(response => response, retry);
  stores.interceptors.response.use(response => response, retry);
  anexo.interceptors.response.use(response => response, retry);
  anexoOld.interceptors.response.use(response => response, retry);
};

const startApp = async store => {
  store.dispatch(authStart());
  try {
    const authenticated = await keycloak.init({
      onLoad: "login-required",
      token: localStorage.getItem("tareas-token"),
      checkLoginIframe: false,
      refreshToken: localStorage.getItem("tareas-refreshToken"),
    });
    if (authenticated) {
      try {
        await keycloak.updateToken();
      } catch (error) {
        return keycloak.login();
      }
      const user = Auth.getUser(keycloak);
      store.dispatch(loginUser(user));
      if (user.roles.find(role => role === "visualizador")) {
        Auth.updateSavedToken(keycloak);
        setAxiosInterceptos(keycloak);
        store.dispatch(authSuccess(keycloak));
      } else {
        Auth.clearSavedToken();
        store.dispatch(roleError());
      }
    } else {
      return keycloak.login();
    }
  } catch (error) {
    store.dispatch(authError());
  }
};

const { store } = configureStore();
const publicUrl = document.createElement("a");
publicUrl.href = process.env.PUBLIC_URL;
const app = (
  <Provider store={store}>
    <ConfirmProvider>
      <Router basename={process.env.PUBLIC_URL ? publicUrl.pathname : ""}>
        <VersionAlert />
        <App />
      </Router>
    </ConfirmProvider>
  </Provider>
);

// keycloak integration
startApp(store);

// sentry integration
Sentry.init({
  tracesSampleRate: 0.4,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  enabled: Boolean(Number(process.env.REACT_APP_HAS_SENTRY)),
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(app);

serviceWorker.unregister();
