import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const chips = props => {
  const { classes } = props;

  return (
    <>
      {props.showHistoric && (
        <li>
          <Chip
            className={classes.chip}
            label="Ver Archivadas"
            onDelete={() => {
              props.removeHistoric();
            }}
          />
        </li>
      )}
    </>
  );
};

export default withStyles(styles)(chips);
