import { Fragment } from "react";
import withStyles from "@mui/styles/withStyles";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  icon: {
    fontSize: 350,
  },
  container: { marginTop: theme.spacing(4) },
});

const apiError = ({ error, classes }) => {
  return (
    <Fragment>
      <Grid container justifyContent="center" className={classes.container}>
        <ErrorIcon fontSize="inherit" color="disabled" className={classes.icon} />
      </Grid>
      <Grid container justifyContent="center" className={classes.container}>
        <Typography variant="h5">{error}</Typography>
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles)(apiError);
