import { enqueueError, enqueueMessage } from "./notifications";
import { getTasksFromGraphqlCurrentPage } from "./tasksList";
import axios from "../../axios";

const types = {
  TASK_LIST_CREATE_CIVIL_TASK_OPEN_MODAL: "TASK_LIST_CREATE_CIVIL_TASK_OPEN_MODAL",
  TASK_LIST_CREATE_CIVIL_TASK_CLOSE_MODAL: "TASK_LIST_CREATE_CIVIL_TASK_CLOSE_MODAL",
  TASK_LIST_CREATE_CIVIL_TASK_CHANGE_GROUP: "TASK_LIST_CREATE_CIVIL_TASK_CHANGE_GROUP",
  TASK_LIST_CREATE_CIVIL_TASK_CHANGE_TYPE: "TASK_LIST_CREATE_CIVIL_TASK_CHANGE_TYPE",
  TASK_LIST_CREATE_CIVIL_TASK_REQUEST: "TASK_LIST_CREATE_CIVIL_TASK_REQUEST",
  TASK_LIST_CREATE_CIVIL_TASK_SUCCESS: "TASK_LIST_CREATE_CIVIL_TASK_SUCCESS",
  TASK_LIST_CREATE_CIVIL_TASK_FAILURE: "TASK_LIST_CREATE_CIVIL_TASK_FAILURE",
};

export const openModal = task => async (dispatch, getState) => {
  const {
    groups: { groups },
  } = getState();
  const current = (task.grupo || task.group).id;
  const group = groups.find(g => g.id === Number(current));
  dispatch({ type: types.TASK_LIST_CREATE_CIVIL_TASK_OPEN_MODAL, task, group });
};

export const closeModal = () => ({
  type: types.TASK_LIST_CREATE_CIVIL_TASK_CLOSE_MODAL,
});

export const changeGroup = group => ({
  type: types.TASK_LIST_CREATE_CIVIL_TASK_CHANGE_GROUP,
  group,
});

export const changeType = value => ({
  type: types.TASK_LIST_CREATE_CIVIL_TASK_CHANGE_TYPE,
  value,
});

const request = () => ({
  type: types.TASK_LIST_CREATE_CIVIL_TASK_REQUEST,
});

const success = () => ({
  type: types.TASK_LIST_CREATE_CIVIL_TASK_SUCCESS,
});

const failure = () => ({
  type: types.TASK_LIST_CREATE_CIVIL_TASK_FAILURE,
});

export const confirm = () => async (dispatch, getState) => {
  const {
    newCivilTaskDialog: { task, group, type, loading },
  } = getState();
  if (loading) return;

  dispatch(request());
  try {
    const response = await axios.post(`/tasks/${task.id}/complementary`, {
      group: group.id,
      type: type.id,
    });

    const { data } = response;
    window.open(`${process.env.PUBLIC_URL}/tarea/${data.task.id}`);

    dispatch(enqueueMessage("La tarea se generó correctamente"));
    dispatch(getTasksFromGraphqlCurrentPage());
    dispatch(success());
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al generar la tarea"));
    dispatch(failure());
  }
};

const initialState = {
  openModal: false,
  loading: false,
  task: null,
  group: null,
  type: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_CREATE_CIVIL_TASK_OPEN_MODAL:
      return {
        ...state,
        openModal: true,
        task: action.task,
        group: action.group,
      };
    case types.TASK_LIST_CREATE_CIVIL_TASK_CLOSE_MODAL:
      return { ...initialState };

    case types.TASK_LIST_CREATE_CIVIL_TASK_CHANGE_TYPE:
      return { ...state, type: action.value };

    case types.TASK_LIST_CREATE_CIVIL_TASK_CHANGE_GROUP:
      return { ...state, group: action.group };

    case types.TASK_LIST_CREATE_CIVIL_TASK_REQUEST:
      return { ...state, loading: true, gangError: false };

    case types.TASK_LIST_CREATE_CIVIL_TASK_SUCCESS:
      return { ...state, loading: false, openModal: false };

    case types.TASK_LIST_CREATE_CIVIL_TASK_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
