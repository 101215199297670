/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const AddComplementaryTaskDialog = props => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    props.getGroups();
  }, []);

  const goBack = () => {
    props.onClose();
    navigate("/validacion");
  };

  const onCreate = () => {
    props.onClose();
    props.openCivilTaskModal(props.task);
  };

  const sidewalks = props.task && props.task.sidewalks ? props.task.sidewalks : [];

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {"¿Desea generar una tarea complementaria?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Las tareas complementarias son utiles cuando se tienen que realizar tareas de
          vereda o aporte a partir de lo realizado en la tarea hidráulica. Se creará una
          nueva tarea con los datos de la tarea actual la cuál podrá ser programada.
        </DialogContentText>

        {sidewalks.length ? (
          <Table aria-label="sidewalk table">
            <TableHead>
              <TableCell align="left">Tipo de Vereda</TableCell>
              <TableCell align="right">Unidad</TableCell>
              <TableCell align="right">Cantidad</TableCell>
            </TableHead>
            <TableBody>
              {sidewalks.map(sidewalk => (
                <TableRow key={1}>
                  <TableCell component="th" scope="row">
                    {sidewalk.type.name}
                  </TableCell>
                  <TableCell align="right">{sidewalk.unit.name}</TableCell>
                  <TableCell align="right">{sidewalk.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={goBack} style={{ color: "black" }}>
          Volver al listado
        </Button>
        <Button onClick={onCreate} color="primary">
          Crear tarea complementaria
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddComplementaryTaskDialog;
