/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";

import { getTasksFromGraphql } from "../../../store/ducks/tasksList";
import TaskCard from "./tasks/TaskCard";
import Grid from "@mui/material/Grid";
import TaskCardSkeleton from "./tasks/TaskCardSkeleton";
import EmptyTasks from "./tasks/EmptyTasks";
import ScheduleDialogContainer from "./tasks/ScheduleDialogContainer";
import AssignDialogContainer from "./tasks/AssignDialogContainer";
import CancelDialogContainer from "./tasks/CancelDialogContainer";
import PendingDialogContainer from "./tasks/PendingDialogContainer";
import InspectionDialogContainer from "./tasks/InspectionDialogContainer";
import ApproveDialogContainer from "./tasks/ApproveDialogContainer";
import RejectDialogContainer from "./tasks/RejectDialogContainer";
import NewCivilTaskDialogContainer from "./tasks/CivilTaskDialogContainer";
import { useCallback, useMemo } from "react";

const skeletons = (
  <>
    <Grid item xs={12}>
      <TaskCardSkeleton />
    </Grid>
    <Grid item xs={12}>
      <TaskCardSkeleton />
    </Grid>
    <Grid item xs={12}>
      <TaskCardSkeleton />
    </Grid>
    <Grid item xs={12}>
      <TaskCardSkeleton />
    </Grid>
  </>
);

const Tasks = props => {
  const tasks = useMemo(() => {
    return props.tasks.map(task => (
      <Grid key={task.id} item xs={12}>
        <TaskCard task={task} showAllInfo={props.showAllInfo} roles={props.roles} />
      </Grid>
    ));
  }, [props.tasks, props.showAllInfo, props.roles]);

  const count = useMemo(() => {
    if (props.paginator) {
      return Math.ceil(props.paginator.total / props.paginator.perPage);
    }
    return undefined;
  }, [props.paginator]);

  const handlePageChange = useCallback((e, page) => props.handlePageChange(page), []);

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        {props.isFetching || !props.paginator ? (
          skeletons
        ) : props.tasks.length ? (
          <>
            {tasks}
            <Pagination
              count={count}
              color="primary"
              style={{ marginTop: 10 }}
              onChange={handlePageChange}
              page={props.paginator.currentPage}
            />
          </>
        ) : (
          <EmptyTasks />
        )}
      </Grid>
      <ScheduleDialogContainer />
      <AssignDialogContainer />
      <CancelDialogContainer />
      <PendingDialogContainer />
      <InspectionDialogContainer />
      <ApproveDialogContainer />
      <RejectDialogContainer />
      <NewCivilTaskDialogContainer />
    </>
  );
};

const mapStateToProps = state => ({
  tasks: state.tasksList.tasks,
  roles: state.user.roles,
  paginator: state.tasksList.paginator,
  page: state.tasksList.page,
  isFetching: state.tasksList.isFetching,
  showAllInfo: state.taskListShowAllInfo.showAllInfo,
});

const mapDispatchToProps = dispatch => ({
  handlePageChange: e => dispatch(getTasksFromGraphql(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
