import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead, useMediaQuery, useTheme } from "@mui/material";
import format from "date-fns/format";
import Skeleton from "@mui/material/Skeleton";
import { useMemo } from "react";

export default ({ open, onClose, gang, isLoading, history = [] }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const skeleton = useMemo(
    () => (
      <TableRow key={0}>
        <TableCell align="left">
          <Skeleton height={15} width="80%" />
          <Skeleton height={15} />
        </TableCell>
        <TableCell align="left">
          <Skeleton height={15} width="80%" />
          <Skeleton height={15} />
        </TableCell>
        <TableCell align="left">
          <Skeleton height={15} width="80%" />
          <Skeleton height={15} />
        </TableCell>
        <TableCell align="left">
          <Skeleton height={15} width="80%" />
          <Skeleton height={15} />
        </TableCell>
      </TableRow>
    ),
    []
  );

  const data = useMemo(
    () =>
      !isLoading
        ? history.map(audit => (
            <TableRow key={audit.id}>
              <TableCell align="left">{audit.old_values?.grupo || "-"}</TableCell>
              <TableCell align="left">{audit.new_values?.grupo || "-"}</TableCell>
              <TableCell align="left">{audit.user?.name || "-"}</TableCell>
              <TableCell align="left">
                {format(
                  new Date(audit.created_at?.replace(/ /g, "T")),
                  "dd/MM/yyyy HH:mm:ss"
                )}
              </TableCell>
            </TableRow>
          ))
        : null,
    [isLoading, history]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={isMobile}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <div> Historial de Cambios de Grupos</div>
        <small style={{ fontWeight: 100 }}>{gang?.name}</small>
      </DialogTitle>

      <DialogContent>
        {history ? (
          <Table style={{ marginBottom: 20 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Grupo Origen</TableCell>
                <TableCell align="left">Grupo Destino</TableCell>
                <TableCell align="left">Usuario</TableCell>
                <TableCell align="left">Fecha</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? skeleton : null}
              {data}
              {data && data.length ? null : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    No hay cambios de grupo registrados para la cuadrilla.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
