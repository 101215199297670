import { connect } from "react-redux";

import {
  save,
  toggle,
  toggleAll,
  initialize,
  closeModal,
} from "../../../../store/ducks/taskListTypesFilter";
import TaskTypesDialog from "./taskTypesDialogContainer/TaskTypesDialog";

const mapStateToProps = state => ({
  openModal: state.taskListTypesFilter.openModal,
  types: state.taskListTypesFilter.dialogTypes,
});

const mapDispatchToProps = dispatch => ({
  save: () => dispatch(save()),
  closeModal: () => dispatch(closeModal()),
  initialize: () => dispatch(initialize()),
  toggle: event => dispatch(toggle(event)),
  toggleAll: event => dispatch(toggleAll(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskTypesDialog);
