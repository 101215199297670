import { Component } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";

const styles = { height: 20, width: 20, marginRight: 10, animation: "none" };

class StateList extends Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    return (
      <List
        component="nav"
        disablePadding
        subheader={
          <>
            <Grid container>
              <Grid item xs>
                <ListSubheader disableSticky>Referencia</ListSubheader>
              </Grid>
              <Grid item>
                <IconButton
                  disableRipple
                  onClick={this.handleClick}
                  size="small"
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                  }}>
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </Grid>
          </>
        }>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            <ListItem>
              <div className="icon Libre" style={styles}></div>
              <ListItemText primary="Cuadrilla libre" />
            </ListItem>
            <ListItem>
              <div className="icon Programada" style={styles}></div>
              <ListItemText primary="Cuadrilla programada" />
            </ListItem>
            <ListItem>
              <div className="icon Ejecutando" style={styles}></div>
              <ListItemText primary="Cuadrilla ejecutando" />
            </ListItem>
            <ListItem>
              <div className="icon Alerta gang-list" style={styles}></div>
              <ListItemText primary="Cuadrilla ejecutando (alerta)" />
            </ListItem>
            <ListItem>
              <div className="icon Activa" style={styles}></div>
              <ListItemText
                primary="Tareas activas"
                title="Tareas en ejecución, asignadas, programadas y pausadas"
              />
            </ListItem>
            <ListItem>
              <div className="icon Pendiente" style={styles}></div>
              <ListItemText primary="Tarea pendiente" />
            </ListItem>
            <ListItem>
              <div className="icon Ejecución" style={styles}></div>
              <ListItemText primary="Tarea en ejecución" />
            </ListItem>
            <ListItem>
              <div className="icon Asignada" style={styles}></div>
              <ListItemText primary="Tarea asignada" />
            </ListItem>
            <ListItem>
              <div className="icon Programada-T" style={styles}></div>
              <ListItemText primary="Tarea programada" />
            </ListItem>
            <ListItem style={{ marginBottom: 10 }}>
              <div className="icon Pausada gang-list" style={styles}></div>
              <ListItemText primary="Tarea pausada" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    );
  }
}

export default StateList;
