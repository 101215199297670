/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import axios from "../../axios";
import Form from "./components/form";
import Title from "./components/title";
import Preloader from "../../components/UI/Preloader";
import { enqueueMessage, enqueueError } from "../../store/ducks/notifications";

import parseData from "./parseData";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles(theme => ({
  left: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  right: {
    marginTop: 10,
    textAlign: "right",
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
}));

const parseForm = (task = {}, stores = []) => {
  return {
    // step 1 - ODT
    step1: {
      tarea: task.tarea,
      modulo: task.modulo,
      codigo_ot: task.codigo_ot,
      certificate: task.certificate,
      approved_by: task.approved_by,
      operative_zone: task.operative_zone,
      croquis: task.croquis.map(c => c.path),
      ingreso_ot: task.ingreso_ot?.replace(/ /g, "T"),
      fecha_asignada: task.fecha_asignada?.replace(/ /g, "T"),
      valida_materiales: !!task.valida_materiales,
      requires_cleaning: !!task.requires_cleaning,
      tipo: { id: task.tipo.id, name: task.tipo.tipo, etapa_id: task.tipo.etapa_id },
    },

    // step 2 - Domicilio
    step2: {
      y: task.y,
      entre: task.entre,
      calle: task.calle,
      altura: task.altura,
      aclaracion: task.aclaracion,
      contact_phone: task.contact_phone,
      position: { lat: task.latitud, lng: task.longitud },
      localidad: {
        id: `${task.localidad.id}`,
        name: `${task?.localidad.nombre} ${
          task?.localidad.seccion ? `(${task?.localidad.seccion})` : ""
        }`,
      },
    },

    // step 3 - Grupo
    step3: {
      for_contractor:
        typeof task.for_contractor === "number" ? !!task.for_contractor : undefined,
      requires_pipeline_information: !!task.requires_pipeline_information,
      grupo: {
        id: task.grupo.id,
        name: task.grupo.nombre,
        stores: task.grupo.stores,
        has_contractors: !!task.grupo.has_contractors,
        fills_pipeline_information: !!task.grupo.fills_pipeline_information,
      },
      stores: task.stores
        ? stores.filter(s => JSON.parse(task.stores).includes(s._id))
        : [],
    },
  };
};

export default props => {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const stores = useSelector(state => state.storesList);

  const { ...form } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: parseForm(props.task, stores),
  });

  const goBack = () => {
    navigate(`/tarea/${params.id}`);
  };

  const onSubmit = () => {
    if (isLoading || Object.keys(form.formState.errors).length) return;

    const values = form.getValues();

    if (
      (values.step1.ingreso_ot instanceof Date && isNaN(values.step1.ingreso_ot)) ||
      (values.step1.fecha_asignada instanceof Date && isNaN(values.step1.fecha_asignada))
    ) {
      return dispatch(enqueueError("Debe corregir los campos inválidos."));
    }

    setLoading(true);

    axios
      .post(`tasks/${params.id}`, parseData(values, props.task), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        dispatch(enqueueMessage("Tarea editada correctamente."));
        navigate(`/tarea/${params.id}`);
      })
      .catch(() => {
        dispatch(enqueueError("Ocurrió un error al crear la tarea"));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const listener = event => {
      const activeElement = document.activeElement;
      if (
        activeElement &&
        (["SELECT"].includes(activeElement.tagName) ||
          [...activeElement.classList].some(className =>
            [
              "MuiSelect-select",
              "MuiMenuItem-root",
              "MuiInputBase-root",
              "MuiFormControl-root",
              "MuiIconButton-edgeEnd",
            ].includes(className)
          ))
      )
        return;
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  return props.isFetching ? (
    <Preloader />
  ) : (
    <div style={{ padding: 25 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Title goBack={goBack} />
        </Grid>
      </Grid>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8} className={classes.left}>
          <Form form={form} {...props} />

          <Button
            fullWidth
            disableElevation
            onClick={goBack}
            variant="outlined"
            style={{ marginBottom: 15 }}>
            Cancelar
          </Button>

          <LoadingButton
            fullWidth
            color="primary"
            disableElevation
            onClick={onSubmit}
            variant="contained"
            loading={isLoading}
            disabled={!!Object.keys(form.formState.errors).length}>
            Guardar
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
};
