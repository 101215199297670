const types = {
  HIDROJET_LIST_FILTER_CHANGE_ODT: "HIDROJET_LIST_FILTER_CHANGE_ODT",
  HIDROJET_LIST_FILTER_CHANGE_GROUP: "HIDROJET_LIST_FILTER_CHANGE_GROUP",
  HIDROJET_LIST_FILTER_CHANGE_DATE_FROM: "HIDROJET_LIST_FILTER_CHANGE_DATE_FROM",
  HIDROJET_LIST_FILTER_CHANGE_DATE_TO: "HIDROJET_LIST_FILTER_CHANGE_DATE_TO",
  HIDROJET_LIST_FILTER_CHANGE_STATE: "HIDROJET_LIST_FILTER_CHANGE_STATE",
  HIDROJET_LIST_FILTER_TOGGLE_SHOW_SELECTED_TASKS:
    "HIDROJET_LIST_FILTER_TOGGLE_SHOW_SELECTED_TASKS",
};

export const changeOdt = odt => ({
  type: types.HIDROJET_LIST_FILTER_CHANGE_ODT,
  odt,
});

export const changeGroup = group => ({
  type: types.HIDROJET_LIST_FILTER_CHANGE_GROUP,
  group,
});

export const changeDateFrom = date => ({
  type: types.HIDROJET_LIST_FILTER_CHANGE_DATE_FROM,
  date,
});

export const changeDateTo = date => ({
  type: types.HIDROJET_LIST_FILTER_CHANGE_DATE_TO,
  date,
});

export const changeState = value => ({
  type: types.HIDROJET_LIST_FILTER_CHANGE_STATE,
  value,
});

export const toggleShowSelectedTasks = () => ({
  type: types.HIDROJET_LIST_FILTER_TOGGLE_SHOW_SELECTED_TASKS,
});

const initialState = {
  odt: "",
  group: "",
  dateFrom: null,
  dateTo: null,
  state: "0",
  showSelectedTasks: false,
};

const reducer = (state = initialState, action) => {
  let date;
  switch (action.type) {
    case types.HIDROJET_LIST_FILTER_CHANGE_ODT:
      return { ...state, odt: action.odt };
    case types.HIDROJET_LIST_FILTER_CHANGE_GROUP:
      return { ...state, group: action.group };
    case types.HIDROJET_LIST_FILTER_CHANGE_DATE_FROM:
      date = action.date;
      if (date) {
        date.setHours(0, 0, 0);
      }
      return { ...state, dateFrom: date };
    case types.HIDROJET_LIST_FILTER_CHANGE_DATE_TO:
      date = action.date;
      if (date) {
        date.setHours(23, 59, 59);
      }
      return { ...state, dateTo: date };
    case types.HIDROJET_LIST_FILTER_CHANGE_STATE:
      return { ...state, state: action.value };

    case types.HIDROJET_LIST_FILTER_TOGGLE_SHOW_SELECTED_TASKS:
      return { ...state, showSelectedTasks: !state.showSelectedTasks };
    default:
      return state;
  }
};

export default reducer;
