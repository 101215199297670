import { Component } from "react";
import DownloadMaterialsDialog from "./downloadMaterialsDialogContainer/index";

import { connect } from "react-redux";
import {
  closeModal,
  handleDownload,
} from "../../../../store/ducks/taskListDownloadMaterials";

class DownloadMaterialsContainer extends Component {
  handleSubmit = () => {
    this.props.handleDownload();
  };

  handleCancel = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <DownloadMaterialsDialog
        {...this.props}
        handleCancel={this.handleCancel}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.taskListDownloadMaterials.openModal,
  isLoading: state.taskListDownloadMaterials.isLoading,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  handleDownload: () => dispatch(handleDownload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadMaterialsContainer);
