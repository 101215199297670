import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

const styles = () => ({
  filter: {
    fontSize: 14,
    color: "#666",
    marginBottom: 7,
  },
  filterText: {
    "&:hover": {
      color: "#262626",
    },
    cursor: "pointer",
  },
});

const filterList = props => {
  const { classes } = props;
  return (
    <>
      <Typography
        variant="h6"
        style={{ fontWeight: 350, marginTop: 20, color: "#333" }}
        gutterBottom>
        Descargar
      </Typography>
      <Typography className={classes.filter} onClick={props.openDownloadAbsaReport}>
        <span className={classes.filterText}>Planilla de ABSA</span>
      </Typography>
      <Typography className={classes.filter} onClick={props.openDownloadTasksModal}>
        <span className={classes.filterText}>Planilla de Tareas</span>
      </Typography>
      <Typography className={classes.filter} onClick={props.openDownloadMaterialsModal}>
        <span className={classes.filterText}>Planilla de Materiales</span>
      </Typography>
    </>
  );
};

export default withStyles(styles)(filterList);
