import { Component } from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

class GroupFilter extends Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    return (
      <List
        component="nav"
        disablePadding
        subheader={
          <>
            <Grid container>
              <Grid item xs>
                <ListSubheader disableSticky>Grupos</ListSubheader>
              </Grid>
              <Grid item>
                <IconButton
                  disableRipple
                  onClick={this.handleClick}
                  size="small"
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                  }}>
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </Grid>
          </>
        }>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <FormGroup style={{ paddingLeft: 16 }}>
            {this.props.groups.map((group, i) => (
              <FormControlLabel
                key={group.id}
                style={this.props.groups.length === i + 1 ? { marginBottom: 10 } : {}}
                control={
                  <Checkbox
                    checked={this.props.groups.find(g => g.id === group.id).selected}
                    onChange={() => this.props.toggleGroup(group.id)}
                    color="default"
                  />
                }
                label={group.name}
              />
            ))}
          </FormGroup>
        </Collapse>
      </List>
    );
  }
}

export default GroupFilter;
