import { connect } from "react-redux";

import {
  removeStateChanges,
  openModal,
} from "../../../../../store/ducks/taskListHistoryChangesFilter";
import HistoryChanges from "./historyChangesContainer/HistoryChanges";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  street: state.taskListHistoryChangesFilter.street,
  number: state.taskListHistoryChangesFilter.number,
  between: state.taskListHistoryChangesFilter.between,
  and: state.taskListHistoryChangesFilter.and,
  odt: state.taskListHistoryChangesFilter.odt,
  task: state.taskListHistoryChangesFilter.task,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeStateChanges: () => {
    dispatch(removeStateChanges());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryChanges);
