import { useEffect } from "react";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";

import Title from "./taskList/Title";
import Preloader from "../../components/UI/Preloader";
import ToolbarContainer from "./taskList/ToolbarContainer";
import TaskTableContainer from "./taskList/TaskTableContainer";
import DownloadHelpButton from "../../components/UI/DownloadHelpButton";

import { clearFilters } from "../../store/ducks/downloadTasksFilter";

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  tableContainer: {},
});

const TaskValidationListContainer = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  return props.isFetching ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Paper elevation={0} variant="outlined">
            <Grid container className={classes.title}>
              <Grid item xs>
                <Title />
              </Grid>
              <Grid item>
                <DownloadHelpButton file="validacion.mp4" />
              </Grid>
            </Grid>
            <ToolbarContainer />
            <div className={classes.tableContainer}>
              <TaskTableContainer />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(TaskValidationListContainer);
