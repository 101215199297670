import { gql } from "apollo-boost";

export const GET_LOCATIONS = gql`
  query GET_LOCATIONS(
    $hasGrupos: WhereConditions = null
    $order_by: [LocationsOrderByOrderByClause!] = [{ field: NOMBRE, order: ASC }]
  ) {
    locations(order_by: $order_by, hasGrupos: $hasGrupos) {
      id
      nombre
      seccion
    }
  }
`;
