import { orderBy } from "lodash";
import { createSelector } from "reselect";
import escapeStringRegexp from "escape-string-regexp";

import apollo from "../../apollo";
import {
  getStateDateFilters,
  getTasksFilters,
} from "../../graphql/tasksDownload/filters";
import { parseGraphqlFromApi } from "../../utils/TaskParser";
import { GET_TASKS } from "../../graphql/tasksDownload/queries";
import { getTaskOrderCriteria } from "../../graphql/tasks/order";
import { setTotal, setLoading, setPendingPages } from "./downloadTasksPagination";

const types = {
  TASKS_REQUEST: "DOWNLOAD_TASKS_LIST_REQUEST",
  TASKS_SUCCESS: "DOWNLOAD_TASKS_LIST_SUCCESS",
  TASKS_FAILURE: "DOWNLOAD_TASKS_LIST_FAILURE",
  DOWNLOAD_TASKS_ADD_MORE: "DOWNLOAD_TASKS_LIST_ADD_MORE",
  TASKS_UPDATE_SHEET: "DOWNLOAD_TASKS_LIST_UPDATE_SHEET",
  TASKS_UPDATE_SUMMARY: "DOWNLOAD_TASKS_LIST_UPDATE_SUMMARY",
};

const parseVariables = object => {
  Object.keys(object).forEach(k => (object[`$${k}`] = object[k]));
  return object;
};

const requestTasks = () => ({
  type: types.TASKS_REQUEST,
});

const successTasks = tasks => ({
  type: types.TASKS_SUCCESS,
  tasks,
});

const addMoreTasks = tasks => ({
  type: types.DOWNLOAD_TASKS_ADD_MORE,
  tasks,
});

const failedTasks = () => ({
  type: types.TASKS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las tareas",
});

export const updateTask = task => ({
  type: types.TASKS_UPDATE_SHEET,
  task,
});

export const updateTasks = tasks => ({
  type: types.TASKS_UPDATE_SUMMARY,
  tasks,
});

export const getTasks = (page = 1, reset) => {
  return async (dispatch, getState) => {
    if (reset) dispatch(requestTasks());
    if (page === 1) dispatch(setLoading(true));

    const state = getState();

    try {
      await apollo.clearStore();
      await apollo.resetStore();

      const where = getTasksFilters(state);
      const hasTareaAccion = getStateDateFilters(state);
      const order_by = getTaskOrderCriteria("state_change", true);
      const { data } = await apollo.query({
        query: GET_TASKS,
        variables: parseVariables({ where, page, order_by, hasTareaAccion, first: 700 }),
      });
      const { tasks } = data;

      if (page === 1) {
        dispatch(setLoading(false));
        dispatch(successTasks(tasks.data.map(a => parseGraphqlFromApi(a))));

        dispatch(setTotal(tasks.paginatorInfo.total));
      } else {
        dispatch(addMoreTasks(tasks.data.map(a => parseGraphqlFromApi(a))));
      }

      if (tasks.paginatorInfo.hasMorePages) {
        dispatch(setPendingPages(true));
        dispatch(getTasks(page + 1, false));
      } else {
        dispatch(setPendingPages(false));
      }
    } catch (error) {
      if (
        error.message &&
        error.message ===
          "Store reset while query was in flight (not completed in link chain)"
      ) {
        return false;
      }
      dispatch(failedTasks());
    }
  };
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.TASKS_SUCCESS:
      return action.tasks;
    case types.DOWNLOAD_TASKS_ADD_MORE:
      return [...state, ...action.tasks];
    case types.TASKS_UPDATE_SHEET:
      return state.map(task =>
        task.id === action.task.id ? { ...task, ...action.task } : task
      );
    case types.TASKS_UPDATE_SUMMARY:
      return state.map(task => {
        const selected = action.tasks.find(t => t.id === task.id);
        return selected ? { ...task, ...selected } : task;
      });
    default:
      return state;
  }
};

const getAllTasks = state => state.downloadTaskList;
const getSelectedTasks = state => state.selectedTasks;
const getOdtFilter = state => state.downloadTasksFilter.odt;
const getGroupFilter = state => state.downloadTasksFilter.group;
const getFromFilter = state => state.downloadTasksFilter.dateFrom;
const getToFilter = state => state.downloadTasksFilter.dateTo;
const getShowSelectedTasks = state => state.downloadTasksFilter.showSelectedTasks;

export const mapSelectedTasks = createSelector(
  [getAllTasks, getSelectedTasks, getOdtFilter, getGroupFilter, getShowSelectedTasks],
  (allTasks, selectedTasks, odt, group, showSelectedTasks) => {
    const regex = new RegExp(`.*${escapeStringRegexp(odt)}.*`, "i");
    return allTasks
      .filter(
        t =>
          t.otCode.match(regex) &&
          (group ? Number(t.group.id) === Number(group) : true) &&
          (showSelectedTasks ? selectedTasks.includes(t.id) : true)
      )
      .filter(function (item, pos) {
        return allTasks.findIndex(i => i.id === item.item) === pos;
      })
      .map(task => ({
        ...task,
        selected: !!selectedTasks.find(id => id === task.id),
      }));
  }
);

export const mapSelectedTasksForDownload = createSelector(
  [
    getAllTasks,
    getSelectedTasks,
    getOdtFilter,
    getGroupFilter,
    getFromFilter,
    getToFilter,
    getShowSelectedTasks,
  ],
  (allTasks, selectedTasks, odt, group, dateFrom, dateTo, showSelectedTasks) => {
    const regex = new RegExp(`.*${escapeStringRegexp(odt)}.*`, "i");
    return orderBy(
      allTasks
        .filter(
          t =>
            t.otCode.match(regex) &&
            (!dateFrom || dateFrom < t.validationDate) &&
            (!dateTo || dateTo > t.validationDate) &&
            (group ? Number(t.group.id) === Number(group) : true) &&
            (showSelectedTasks ? selectedTasks.includes(t.id) : true)
        )
        .filter(function (item, pos) {
          return allTasks.findIndex(i => i.id === item.id) === pos;
        })
        .map(task => ({
          ...task,
          validated: task.validationDate
            ? task.validationDate.toISOString().split("T")[0]
            : undefined,
          selected: !!selectedTasks.find(id => id === task.id),
        })),
      ["validated", "updated_at"],
      ["desc", "desc"]
    );
  }
);

export default reducer;
