import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const header = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>ODT</TableCell>
        <TableCell>Grupo</TableCell>
        <TableCell>Domicilio</TableCell>
        <TableCell>Descripción</TableCell>
        <TableCell>Módulo</TableCell>
        <TableCell padding="none" />
      </TableRow>
    </TableHead>
  );
};

export default header;
