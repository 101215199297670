import apollo from "../../apollo";
import axios from "../../axios";
import {
  getGangsFilters,
  getTasksFilters,
  getAuditsFilters,
  getSidewalkFilters,
  getHidrojetFilters,
  getStateChangeFilters,
  getHasPipelinesFilters,
} from "../../graphql/tasks/filters";
import { getTaskOrderCriteria } from "../../graphql/tasks/order";
import { GET_TASKS_IDS } from "../../graphql/tasks/queries";
import { enqueueError, enqueueInfo } from "./notifications";

const types = {
  TASK_LIST_DOWNLOAD_TASKS_LOADING: "TASK_LIST_DOWNLOAD_TASKS_LOADING",
  TASK_LIST_DOWNLOAD_TASKS_OPEN_MODAL: "TASK_LIST_DOWNLOAD_TASKS_OPEN_MODAL",
  TASK_LIST_DOWNLOAD_TASKS_CLOSE_MODAL: "TASK_LIST_DOWNLOAD_TASKS_CLOSE_MODAL",
};

const parseVariables = object => {
  Object.keys(object).forEach(k => (object[`$${k}`] = object[k]));
  return object;
};

const setLoading = value => ({
  type: types.TASK_LIST_DOWNLOAD_TASKS_LOADING,
  value,
});

export const openModal = () => async (dispatch, getStore) => {
  const { paginator } = getStore().tasksList;

  if (paginator && paginator.total <= 3500) {
    dispatch({ type: types.TASK_LIST_DOWNLOAD_TASKS_OPEN_MODAL });
  } else {
    dispatch(
      enqueueInfo(
        "Para generar la planilla debes reducir el número de tareas seleccionadas."
      )
    );
  }
};

export const closeModal = () => ({
  type: types.TASK_LIST_DOWNLOAD_TASKS_CLOSE_MODAL,
});

export const handleDownload = () => async (dispatch, getStore) => {
  const store = getStore();
  const filters = getTasksFilters(store);
  const gangsFilters = getGangsFilters(store);
  const sidewalkFilters = getSidewalkFilters(store);
  const hidrojetFilters = getHidrojetFilters(store);
  const stateChangeFilters = getStateChangeFilters(store);
  const auditsFilters = getAuditsFilters(store);
  const pipelineFilters = getHasPipelinesFilters(store);

  dispatch(setLoading(true));

  const order = getTaskOrderCriteria(
    store.taskListOrder.selected,
    store.taskListOrder.descOrder
  )[0];

  order.field = order.field.toLowerCase();

  try {
    const { data } = await apollo.query({
      query: GET_TASKS_IDS,
      variables: parseVariables({
        where: filters,
        hasAudits: auditsFilters,
        hasCuadrilla: gangsFilters,
        hasSidewalks: sidewalkFilters,
        hasHidrojets: hidrojetFilters,
        hasPipelines: pipelineFilters,
        hasTareaAccion: stateChangeFilters,
      }),
    });

    const tasks = data.tasksFull.map(task => task.id);

    const response = await axios({
      method: "POST",
      url: `/tasks/task-summary/`,
      responseType: "blob",
      data: { tasks, order },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Informe - Tareas.zip");
    document.body.appendChild(link);
    link.click();
    dispatch(closeModal());
  } catch (error) {
    dispatch(
      enqueueError(
        "Ocurrió un error. Intente nuevamente o reduzca el número de tareas para la planilla."
      )
    );
  }
  dispatch(setLoading(false));
};

const initialState = {
  openModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_DOWNLOAD_TASKS_LOADING:
      return { ...state, isLoading: action.value };
    case types.TASK_LIST_DOWNLOAD_TASKS_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_DOWNLOAD_TASKS_CLOSE_MODAL:
      return { ...state, openModal: false, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
