/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import ApiError from "../components/UI/ApiError";
import EditTask from "./editTaskContainer/editTask";

import { getStores } from "../store/ducks/storesList";
import { getTaskTypes } from "../store/ducks/taskTypes";
import { clearTaskExistance } from "../store/ducks/newTask";
import { getEditTask, clearEditTask } from "../store/ducks/editTask";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

const EditTaskContainer = props => {
  const params = useParams();
  useEffect(() => {
    props.getStores();
    props.getTaskTypes();
    props.getTask(params.id);

    return () => {
      props.clearEditTask();
      props.clearTaskExistance();
    };
  }, [params]);

  const { error, task } = props;
  return error ? (
    <ApiError error="Ocurrió un error." />
  ) : task ? (
    <EditTask {...props} />
  ) : null;
};

const actionNames = ["CITIES", "STORES_LIST", "TASK_TYPES", "EDIT_TASK"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
  task: state.editTask.task,
});

const mapDispatchToProps = dispatch => ({
  getStores: () => dispatch(getStores()),
  getTask: id => dispatch(getEditTask(id)),
  getTaskTypes: () => dispatch(getTaskTypes()),
  clearEditTask: () => dispatch(clearEditTask()),
  clearTaskExistance: () => dispatch(clearTaskExistance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskContainer);
