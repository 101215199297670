import { Fragment, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from "@mui/material";
import { CardHeader } from "@mui/material";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getInitials, getStateClass } from "../detailCard";
import { format } from "date-fns";

const useStyles = makeStyles(theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default ({ actions = [] }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const width = window.innerWidth;
  const isMobile = width <= 650;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const subtitle = action => (
    <>
      <div>
        {" "}
        {format(
          new Date(action.created_at?.replace(/ /g, "T")),
          "dd/MM/yyyy HH:mm:ss"
        )}{" "}
      </div>
      {action.latitud && action.longitud ? (
        <a
          style={{ color: "#6d6d6d" }}
          href={`http://maps.google.com/maps?q=loc:${action.latitud},${action.longitud}`}
          rel="noopener noreferrer"
          target="_blank">
          Ver en mapa
        </a>
      ) : null}
    </>
  );

  return (
    <Card variant="outlined" style={{ marginTop: 10 }}>
      <CardHeader
        titleTypographyProps={{ variant: "h6", style: { fontWeight: 400 } }}
        title="Historial de cambios de estado"
        action={
          <>
            {
              <IconButton
                onClick={handleExpandClick}
                disabled={!(actions || []).length}
                size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            }
          </>
        }
      />
      <CardContent>
        {!expanded ? (
          <Typography style={{ fontWeight: 100, marginTop: -10 }}>{`La tarea posee ${
            (actions || []).length
          } ${
            (actions || []).length !== 1 ? "cambios de estados." : "cambio de estado."
          }`}</Typography>
        ) : null}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List>
            {(actions || []).map((action, index) => (
              <Fragment key={action.id}>
                <ListItem
                  key={action.id}
                  style={{
                    padding: "20px 0px",
                    paddingTop: index === 0 ? 0 : 20,
                    paddingBottom: index === action.length - 1 ? 0 : 20,
                  }}>
                  <ListItemAvatar>
                    <Avatar>
                      <Tooltip title={action.estado.estado}>
                        <p
                          className={[
                            classes.stateIcon,
                            getStateClass(action.estado.estado),
                          ]}>
                          {getInitials(action.estado.estado)}
                        </p>
                      </Tooltip>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<div>Cuadrilla: {action.cuadrilla?.cuadrilla || "-"} </div>}
                    secondary={<div>Inspector: {action.inspector || "-"}</div>}
                  />

                  {!isMobile ? (
                    <ListItemSecondaryAction
                      key={`details-${index}`}
                      style={{ textAlign: "right" }}>
                      <ListItemText
                        primary={<div>{action.registrado?.name || "-"} </div>}
                        secondary={subtitle(action)}
                      />
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>

                {isMobile ? (
                  <div
                    key={`mobile-${index}`}
                    style={{ margin: "15px 0px", textAlign: "right" }}>
                    <div>{action.registrado?.name || "-"} </div>
                    {subtitle(action)}
                  </div>
                ) : null}

                {action.observaciones ? (
                  <div key={`observations-${index}`} style={{ padding: 20 }}>
                    {action.observaciones}
                  </div>
                ) : null}
                {index !== (actions || []).length - 1 ? (
                  <Divider key={`divider-${index}`} />
                ) : null}
              </Fragment>
            ))}
          </List>
        </Collapse>
      </CardContent>
    </Card>
  );
};
