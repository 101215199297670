/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";
import { MenuItem, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DatePicker from "@mui/lab/MobileDatePicker";

const styles = theme => ({
  form: {
    marginBottom: theme.spacing(2),
  },
  input: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
});

const HidrojetDialog = props => {
  const { classes, closeModal, openModal, save } = props;

  const [dateTo, setDateTo] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);

  const [withHidrojet, setWithHidrojet] = useState(null);
  const [hidrojetState, setHidrojetState] = useState(null);
  const [hidrojetMaxModule, setHidrojetMaxModule] = useState(null);
  const [hidrojetMinModule, setHidrojetMinModule] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const initialize = () => {
    setDateTo(props.dateTo);
    setDateFrom(props.dateFrom);
    setWithHidrojet(props.withHidrojet);
    setHidrojetState(props.hidrojetState);
    setHidrojetMaxModule(props.hidrojetMaxModule);
    setHidrojetMinModule(props.hidrojetMinModule);
  };

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({
          dateTo,
          dateFrom,
          withHidrojet,
          hidrojetState,
          hidrojetMaxModule,
          hidrojetMinModule,
        });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [
    save,
    dateTo,
    dateFrom,
    openModal,
    withHidrojet,
    hidrojetState,
    hidrojetMaxModule,
    hidrojetMinModule,
  ]);

  return (
    <Dialog
      fullScreen={isMobile}
      open={openModal}
      onClose={closeModal}
      TransitionProps={{
        onEnter: initialize,
        onExited: initialize,
      }}>
      <DialogTitle>Módulos de hidrojet</DialogTitle>
      <DialogContent dividers>
        <Typography
          component="p"
          variant="body2"
          color="textSecondary"
          style={{ marginBottom: 20 }}>
          Ingrese los datos de hidrojet por los que desea filtrar.
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  clearText="Borrar"
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha desde"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  disabled={withHidrojet === "0"}
                  value={dateFrom}
                  onChange={setDateFrom}
                  DialogProps={{ disableRestoreFocus: true }}
                  todayText="Hoy"
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  clearText="Borrar"
                  disabled={withHidrojet === "0"}
                  cancelText="Cancelar"
                  fullWidth
                  inputFormat="dd/MM/yyyy"
                  label="Fecha hasta"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  showTodayButton
                  value={dateTo}
                  onChange={setDateTo}
                  DialogProps={{ disableRestoreFocus: true }}
                  todayText="Hoy"
                  renderInput={params => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Estado"
                  variant="outlined"
                  value={hidrojetState}
                  id="standard-hidrojetState"
                  SelectProps={{
                    native: isMobile,
                  }}
                  onChange={e => {
                    setHidrojetState(e.target.value);
                  }}
                  disabled={withHidrojet === "0"}>
                  {[
                    { value: null, label: "Sin especificar" },
                    { value: "0", label: "Sin validar" },
                    { value: "1", label: "Validado" },
                  ].map(option =>
                    isMobile ? (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ) : (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="standard-min"
                  variant="outlined"
                  label="Cant. Mínima"
                  sidewalkType="number"
                  inputProps={{ min: 0 }}
                  style={{ marginTop: 10 }}
                  value={hidrojetMinModule}
                  disabled={withHidrojet === "0"}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setHidrojetMinModule(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  min={0}
                  fullWidth
                  id="standard-max"
                  variant="outlined"
                  label="Cant. Máxima"
                  sidewalkType="number"
                  inputProps={{ min: 0 }}
                  style={{ marginTop: 10 }}
                  value={hidrojetMaxModule}
                  disabled={withHidrojet === "0"}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setHidrojetMaxModule(e.target.value)}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() =>
            save({
              dateTo,
              dateFrom,
              withHidrojet,
              hidrojetState,
              hidrojetMaxModule,
              hidrojetMinModule,
            })
          }>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(HidrojetDialog);
