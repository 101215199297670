import { connect } from "react-redux";

import {
  closeModal,
  changeComment,
  confirm,
} from "../../../../store/ducks/taskListPendingTask";
import PendingDialog from "./pendingDialogContainer/PendingDialog";

const mapStateToProps = state => ({
  openModal: state.taskListPendingTask.openModal,
  loading: state.taskListPendingTask.loading,
  task: state.taskListPendingTask.task,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  confirm: () => dispatch(confirm()),
  changeComment: comment => dispatch(changeComment(comment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingDialog);
