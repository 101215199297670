import { enqueueMessage, enqueueError } from "./notifications";
import axios from "../../axios";
import { updateGroup } from "./groups";

const types = {
  EDIT_GROUP_SET_GROUP: "EDIT_GROUP_SET_GROUP",
  EDIT_GROUP_MODAL_OPEN: "EDIT_GROUP_MODAL_OPEN",
  EDIT_GROUP_MODAL_CLOSE: "EDIT_GROUP_MODAL_CLOSE",
  EDIT_GROUP_MODAL_REQUEST: "EDIT_GROUP_MODAL_REQUEST",
  EDIT_GROUP_MODAL_SUCCESS: "EDIT_GROUP_MODAL_SUCCESS",
  EDIT_GROUP_MODAL_FAILURE: "EDIT_GROUP_MODAL_FAILURE",
};

export const openModal = group => ({
  type: types.EDIT_GROUP_MODAL_OPEN,
  group,
});

export const setGroup = group => ({
  type: types.EDIT_GROUP_SET_GROUP,
  group,
});

export const closeModal = () => ({
  type: types.EDIT_GROUP_MODAL_CLOSE,
});

export const getEditGroup = id => {
  return async dispatch => {
    dispatch(request());
    try {
      const { data } = await axios.get(`/groups/${id}`);
      const group = data.data;
      group.inspectors = JSON.parse(group.inspectors);
      dispatch(setGroup(group));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const editGroup = (id, data) => async dispatch => {
  dispatch(request());

  try {
    const response = await axios.patch(`/groups/${id}/`, data);
    dispatch(enqueueMessage(`El grupo fue actualizado correctamente.`));
    dispatch(success());
    dispatch(updateGroup(response.data.grupo));
  } catch (error) {
    dispatch(failure());
    dispatch(enqueueError("Ocurrió al editar el grupo."));
  }
};

const request = () => ({
  type: types.EDIT_GROUP_MODAL_REQUEST,
});

const success = () => ({
  type: types.EDIT_GROUP_MODAL_SUCCESS,
});

const failure = () => ({
  type: types.EDIT_GROUP_MODAL_FAILURE,
});

const initialState = {
  modalOpen: false,
  group: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_GROUP_MODAL_OPEN:
      return { ...state, modalOpen: true, group: action.group };
    case types.EDIT_GROUP_SET_GROUP:
      return { ...state, group: action.group };
    case types.EDIT_GROUP_MODAL_CLOSE:
      return { ...state, modalOpen: false };
    case types.EDIT_GROUP_MODAL_REQUEST:
      return { ...state, sending: true };
    case types.EDIT_GROUP_MODAL_SUCCESS:
      return { ...state, sending: false, modalOpen: false };
    case types.EDIT_GROUP_MODAL_FAILURE:
      return { ...state, sending: false };
    default:
      return state;
  }
};

export default reducer;
