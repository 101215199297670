const types = {
  TASK_LIST_HISTORIC_FILTER: "TASK_LIST_HISTORIC_FILTER",
  TASK_LIST_HISTORIC_FILTER_REMOVE: "TASK_LIST_HISTORIC_FILTER_REMOVE",
};

export const showHistoric = () => ({
  type: types.TASK_LIST_HISTORIC_FILTER,
});

export const removeHistoric = () => ({
  type: types.TASK_LIST_HISTORIC_FILTER_REMOVE,
});

const initialState = {
  showHistoric: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_HISTORIC_FILTER:
      return { ...state, showHistoric: true };
    case types.TASK_LIST_HISTORIC_FILTER_REMOVE:
      return { ...state, showHistoric: false };
    default:
      return state;
  }
};

export default reducer;
