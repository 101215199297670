import { memo } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

const styles = { padding: 15, width: "100%", marginBottom: 20 };
const titleTypographyProps = { variant: "h6", style: { fontWeight: 300 } };

const FormCard = memo(props => (
  <Card style={styles} variant="outlined">
    <CardHeader title={props.title} titleTypographyProps={titleTypographyProps} />
    <CardContent>{props.children}</CardContent>
  </Card>
));

export default FormCard;
