import { orderBy, padStart } from "lodash";
import apollo from "../../apollo";
import { anexo, nomenclador } from "../../axios";
import {
  getGangsFilters,
  getTasksFilters,
  getAuditsFilters,
  getSidewalkFilters,
  getHidrojetFilters,
  getStateChangeFilters,
  getHasPipelinesFilters,
} from "../../graphql/tasks/filters";
import { getTaskOrderCriteria } from "../../graphql/tasks/order";
import { GET_TASKS_IDS } from "../../graphql/tasks/queries";
import { enqueueError, enqueueInfo } from "./notifications";

const types = {
  TASK_LIST_DOWNLOAD_MATERIALS_LOADING: "TASK_LIST_DOWNLOAD_MATERIALS_LOADING",
  TASK_LIST_DOWNLOAD_MATERIALS_OPEN_MODAL: "TASK_LIST_DOWNLOAD_MATERIALS_OPEN_MODAL",
  TASK_LIST_DOWNLOAD_MATERIALS_CLOSE_MODAL: "TASK_LIST_DOWNLOAD_MATERIALS_CLOSE_MODAL",
};

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const parseVariables = object => {
  Object.keys(object).forEach(k => (object[`$${k}`] = object[k]));
  return object;
};

const setLoading = value => ({
  type: types.TASK_LIST_DOWNLOAD_MATERIALS_LOADING,
  value,
});

export const openModal = () => async (dispatch, getStore) => {
  const { paginator } = getStore().tasksList;

  if (paginator && paginator.total <= 3500) {
    dispatch({ type: types.TASK_LIST_DOWNLOAD_MATERIALS_OPEN_MODAL });
  } else {
    dispatch(
      enqueueInfo(
        "Para generar la planilla debes reducir el número de tareas seleccionadas."
      )
    );
  }
};

export const closeModal = () => ({
  type: types.TASK_LIST_DOWNLOAD_MATERIALS_CLOSE_MODAL,
});

export const handleDownload = () => async (dispatch, getStore) => {
  const store = getStore();
  const filters = getTasksFilters(store);
  const gangsFilters = getGangsFilters(store);
  const hidrojetFilters = getHidrojetFilters(store);
  const sidewalkFilters = getSidewalkFilters(store);
  const stateChangeFilters = getStateChangeFilters(store);
  const auditsFilters = getAuditsFilters(store);
  const pipelineFilters = getHasPipelinesFilters(store);

  dispatch(setLoading(true));

  const orderCriteria = getTaskOrderCriteria(
    store.taskListOrder.selected,
    store.taskListOrder.descOrder
  );

  try {
    const [apolloResponse, nomencladorResponse] = await Promise.all([
      apollo.query({
        query: GET_TASKS_IDS,
        variables: parseVariables({
          where: filters,
          order_by: orderCriteria,
          hasAudits: auditsFilters,
          hasCuadrilla: gangsFilters,
          hasSidewalks: sidewalkFilters,
          hasHidrojets: hidrojetFilters,
          hasPipelines: pipelineFilters,
          hasTareaAccion: stateChangeFilters,
        }),
      }),
      nomenclador.get("/articulos"),
    ]);

    const id_tareas = apolloResponse.data.tasksFull.map(task => task.id);

    let { data: consumptions } = await anexo.post(`/consumos/find`, { id_tareas });

    consumptions = consumptions
      .filter(c => [3, 4].includes(c.estado_consumo))
      .reduce((acc, c) => {
        acc[c.id_articulo] = (acc[c.id_articulo] || 0) + c.cantidad;
        return acc;
      }, {});

    const articles = orderBy(
      Object.keys(consumptions).map(id => {
        const article = nomencladorResponse.data.find(
          a => Number(a.id_articulo) === Number(id)
        );

        const codigo = article?.cod_equivalente
          ? padStart(article?.cod_equivalente, 9, "0")
          : undefined;
        return {
          Código: codigo
            ? `${codigo.slice(0, 3)}-${codigo.slice(3, 6)}-${codigo.slice(6, 9)}`
            : "",
          Descripción: article?.descripcion,
          Cantidad: consumptions[id],
          "Precio unitario": article?.precio ? Number(article?.precio) : "-",
          "Precio total": article?.precio
            ? consumptions[id] * Number(article?.precio)
            : "-",
        };
      }),
      "Descripción"
    );

    const workSheet = XLSX.utils.json_to_sheet([
      ...articles,
      {},
      {},
      {},
      {
        Código: "Firma y Aclaración del Supervisor GRAL SANEAMIENTO",
        Descripción:
          "Firma y Aclaracion del RCC del Distrito de Agua y Saneamiento Argentinos S.A",
      },
    ]);

    [
      workSheet["A1"],
      workSheet["B1"],
      workSheet["C1"],
      workSheet["D1"],
      workSheet["E1"],
    ].forEach(cell => {
      cell.s = {
        font: {
          bold: true,
        },
        border: {
          top: {
            style: "thin",
            color: "#000",
          },
          bottom: {
            style: "thin",
            color: "#000",
          },
          left: {
            style: "thin",
            color: "#000",
          },
          right: {
            style: "thin",
            color: "#000",
          },
        },
      };
    });

    Object.keys(articles).forEach(key => {
      const columns = ["A", "B", "C", "D", "E"];
      columns.forEach(column => {
        workSheet[`${column}${Number(key) + 2}`].s = {
          border: {
            top: {
              style: "thin",
              color: "#000",
            },
            bottom: {
              style: "thin",
              color: "#000",
            },
            left: {
              style: "thin",
              color: "#000",
            },
            right: {
              style: "thin",
              color: "#000",
            },
          },
        };
      });
    });

    workSheet["!cols"] = [
      { width: 35 },
      { width: 100 },
      { width: 10 },
      { width: 20 },
      { width: 20 },
    ];

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "PLANILLA"); // add worksheet to workbook

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    // Exporting the file with the desired name and extension.
    const excelBuffer = XLSX.write(workBook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, `Resumen de materiales.xlsx`);

    setLoading(false);

    dispatch(closeModal());
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueError(
        "Ocurrió un error. Intente nuevamente o reduzca el número de tareas para la planilla."
      )
    );
  }
  dispatch(setLoading(false));
};

const initialState = {
  openModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_DOWNLOAD_MATERIALS_LOADING:
      return { ...state, isLoading: action.value };
    case types.TASK_LIST_DOWNLOAD_MATERIALS_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_DOWNLOAD_MATERIALS_CLOSE_MODAL:
      return { ...state, openModal: false, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
