import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBackOutlined, MoreVert } from "@mui/icons-material";
import { Card, CardHeader, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

const Title = ({ task, roles }) => {
  const navegate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    window.open(`${process.env.PUBLIC_URL}/tarea/${task.id}/editar`, "_blank");
    handleClose();
  };

  const handleDetails = () => {
    window.open(`${process.env.PUBLIC_URL}/tarea/${task.id}`, "_blank");
    handleClose();
  };

  return (
    <Card variant="outlined" style={{ padding: 5, marginTop: -20 }}>
      <CardHeader
        title={`Validación de roturas de vereda - Tarea #${task.otCode}`}
        titleTypographyProps={{ style: { fontWeight: 400, fontSize: 18 } }}
        avatar={
          <Tooltip title="Volver">
            <IconButton
              size="small"
              component="span"
              onClick={() => {
                navegate("/validacion");
              }}
              style={{ marginRight: 5 }}>
              <ArrowBackOutlined />
            </IconButton>
          </Tooltip>
        }
        action={
          <>
            <IconButton onClick={handleClick} size="small" style={{ marginTop: 7 }}>
              <MoreVert />
            </IconButton>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              onClose={handleClose}
              open={Boolean(anchorEl)}>
              <MenuItem onClick={handleDetails}>Ver detalle</MenuItem>

              {roles && roles.find(role => role === "tecnico") ? (
                <MenuItem onClick={handleEdit}>Editar tarea</MenuItem>
              ) : null}
            </Menu>
          </>
        }
      />
    </Card>
  );
};

export default Title;
