/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-unescaped-entities */
import { Fragment, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CardHeader } from "@mui/material";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getInitials } from "../detailCard";
import { format } from "date-fns";

const useStyles = makeStyles(theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const keys = {
  codigo_ot: "Código OT",
  tarea: "Tarea",
  ubicación: "Ubicación",
  cuadrilla: "Cuadrilla",
  stores: "Depósitos Habilitados",
  grupo: "Grupo",
  tipo: "Tipo de Tarea",
  modulo: "Modulo",
  valida_materiales: "Requiere Materiales",
  requires_pipeline_information: "Requiere datos de Red",
  fecha_asignada: "Fecha de Asignación",
};

export default props => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const { stores } = props;

  const mappers = {
    codigo_ot: a => a,
    tarea: a => a,
    ubicación: a => a,
    ingreso_ot: a => format(new Date(a.slice(0, 10)), "dd/MM/yyyy"),
    grupo: a => a,
    tipo: a => a,
    cuadrilla: a => a || "Sin cuadrilla",
    modulo: a => a,
    valida_materiales: a => (!!a ? "Si" : "No"),
    requires_pipeline_information: a => (!!a ? "Si" : "No"),
    fecha_asignada: a => format(new Date(a.slice(0, 10)), "dd/MM/yyyy"),
    stores: a =>
      a
        ? JSON.parse(a)
            .map(t => {
              const s = stores.find(s => s._id === t);
              return s ? `${s.type.name} - ${s.name}` : "";
            })
            .join(", ")
        : "Sin restricción",
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const changes = (props.changes || []).filter(
    change =>
      Object.keys(change.old_values).filter(
        key =>
          Object.keys(keys).includes(key) &&
          mappers[key](change.old_values[key]) !== mappers[key](change.new_values[key])
      ).length
  );

  return (
    <Card variant="outlined" style={{ marginTop: 10 }}>
      <CardHeader
        titleTypographyProps={{ variant: "h6", style: { fontWeight: 400 } }}
        title="Historial de modificaciones"
        action={
          <>
            {
              <IconButton
                onClick={handleExpandClick}
                disabled={!(changes || []).length}
                size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            }
          </>
        }
      />
      <CardContent>
        {!expanded ? (
          <Typography style={{ fontWeight: 100, marginTop: -10 }}>{`La tarea posee ${
            (changes || []).length
          } ${
            (changes || []).length !== 1 ? "modificaciones." : "modificación."
          }`}</Typography>
        ) : null}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List>
            {(changes || []).map((change, index) => (
              <>
                <ListItem
                  key={change.id}
                  style={{
                    padding: "20px 0px",
                    paddingTop: index === 0 ? 0 : 20,
                    paddingBottom: index === change.length - 1 ? 0 : 20,
                  }}>
                  <ListItemAvatar>
                    <Avatar>
                      <Tooltip title={change.user?.name}>
                        <p className={[classes.stateIcon]}>
                          {getInitials(change.user?.name)}
                        </p>
                      </Tooltip>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<div>{change.user?.name || "-"} </div>}
                    secondary={
                      <>
                        <div>
                          {format(
                            new Date(change.created_at?.replace(/ /g, "T")),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                        </div>
                      </>
                    }
                  />
                </ListItem>
                <ul style={{ marginBottom: 20, listStyle: "none", marginLeft: 20 }}>
                  {Object.keys(change.old_values)
                    .filter(
                      key =>
                        Object.keys(keys).includes(key) &&
                        mappers[key](change.old_values[key]) !==
                          mappers[key](change.new_values[key])
                    )
                    .map(a => (
                      <li key={a}>
                        <Fragment>
                          <b>{keys[a]}</b>: De "{mappers[a](change.old_values[a])}" a "
                          {mappers[a](change.new_values[a])}"
                        </Fragment>
                      </li>
                    ))}
                </ul>
                {index !== (changes || []).length - 1 ? <Divider key={index} /> : null}
              </>
            ))}
          </List>
        </Collapse>
      </CardContent>
    </Card>
  );
};
