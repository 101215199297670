import { memo } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";

import Title from "./Title";
import TaskCard from "./executionTask/TaskCard";
import EmptyTasks from "./executionTask/EmptyTasks";
import SkeletonCard from "./executionTask/TaskCardSkeleton";

const styles = theme => ({
  container: {
    marginBottom: theme.spacing(3),
  },
});

const GangsReorderTasks = memo(props => {
  return (
    <>
      <Title text="Tarea en ejecución" variant="h6" />

      {props.isFetching ? (
        <SkeletonCard />
      ) : props.task ? (
        <Grid justifyContent="left">
          <Grid item>
            <TaskCard {...props} />
          </Grid>
        </Grid>
      ) : (
        <EmptyTasks />
      )}
    </>
  );
});

const mapStateToProps = ({ gangTasksReorder }) => ({
  task: gangTasksReorder.taskInExecution,
});

export default connect(mapStateToProps)(withStyles(styles)(GangsReorderTasks));
