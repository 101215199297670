import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import UserControll from "./UserControll";

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  flex: {
    flex: 1,
  },
  navIconHide: {
    marginRight: 20,
  },
});

const navbar = props => {
  const { classes } = props;
  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.onDrawerToggle}
          className={classes.navIconHide}
          size="large">
          <MenuIcon />
        </IconButton>
        <Typography
          data-testid="title"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.flex}>
          Tareas
        </Typography>
        <UserControll />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles, { withTheme: true })(navbar);
