import { connect } from "react-redux";

import { toggleGroup } from "../../../../../store/ducks/groups";
import GroupFilter from "./groupFilterContainer/GroupFilter";

const mapStateToProps = state => ({
  groups: state.groups.groups,
});

const mapDispatchToProps = dispatch => ({
  toggleGroup: id => dispatch(toggleGroup(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupFilter);
