import { connect } from "react-redux";

import { closeModal, save } from "../../../../store/ducks/taskListStateFilter";
import StatesDialog from "./statesDialogContainer/StatesDialog";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  openModal: state.taskListStateFilter.openModal,
  states: state.taskListStateFilter.states,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: data => {
    dispatch(save(data.states));
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StatesDialog);
