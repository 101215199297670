import apollo from "../../apollo";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

import { AbsaReportDocument } from "../../components/AbsaReport/Document";
import {
  getGangsFilters,
  getTasksFilters,
  getAuditsFilters,
  getSidewalkFilters,
  getHidrojetFilters,
  getStateChangeFilters,
  getHasPipelinesFilters,
} from "../../graphql/tasks/filters";

import { getTaskOrderCriteria } from "../../graphql/tasks/order";
import { GET_TASKS_FOR_ABSA } from "../../graphql/tasks/queries";
import { enqueueError, enqueueInfo } from "./notifications";

const types = {
  TASK_LIST_DOWNLOAD_ABSA_REPORT_LOADING: "TASK_LIST_DOWNLOAD_ABSA_REPORT_LOADING",
  TASK_LIST_DOWNLOAD_ABSA_REPORT_OPEN_MODAL: "TASK_LIST_DOWNLOAD_ABSA_REPORT_OPEN_MODAL",
  TASK_LIST_DOWNLOAD_ABSA_REPORT_CLOSE_MODAL:
    "TASK_LIST_DOWNLOAD_ABSA_REPORT_CLOSE_MODAL",
};

const parseVariables = object => {
  Object.keys(object).forEach(k => (object[`$${k}`] = object[k]));
  return object;
};

const setLoading = value => ({
  type: types.TASK_LIST_DOWNLOAD_ABSA_REPORT_LOADING,
  value,
});

export const openModal = () => async (dispatch, getStore) => {
  const { paginator } = getStore().tasksList;

  if (paginator && paginator.total <= 250) {
    dispatch({ type: types.TASK_LIST_DOWNLOAD_ABSA_REPORT_OPEN_MODAL });
  } else {
    dispatch(
      enqueueInfo(
        "Para generar la planilla debes reducir el número de tareas seleccionadas."
      )
    );
  }
};

export const closeModal = () => ({
  type: types.TASK_LIST_DOWNLOAD_ABSA_REPORT_CLOSE_MODAL,
});

export const handleDownload = () => async (dispatch, getStore) => {
  const store = getStore();
  const filters = getTasksFilters(store);
  const gangsFilters = getGangsFilters(store);
  const hidrojetFilters = getHidrojetFilters(store);
  const pipelineFilters = getHasPipelinesFilters(store);
  const sidewalkFilters = getSidewalkFilters(store);
  const stateChangeFilters = getStateChangeFilters(store);
  const auditsFilters = getAuditsFilters(store);

  dispatch(setLoading(true));

  const orderCriteria = getTaskOrderCriteria(
    store.taskListOrder.selected,
    store.taskListOrder.descOrder
  );

  try {
    const { data } = await apollo.query({
      query: GET_TASKS_FOR_ABSA,
      variables: parseVariables({
        where: filters,
        order_by: orderCriteria,
        hasAudits: auditsFilters,
        hasCuadrilla: gangsFilters,
        hasSidewalks: sidewalkFilters,
        hasHidrojets: hidrojetFilters,
        hasPipelines: pipelineFilters,
        hasTareaAccion: stateChangeFilters,
      }),
    });

    const items = (data.tasks.data || [])
      .filter(t => !!t.ultimaRecepcion && t.ultimaRecepcion.length)
      .map(t => ({
        ...t,
        reception: t.ultimaRecepcion[0],
      }));

    const blob = await pdf(<AbsaReportDocument tasks={items} />).toBlob();
    saveAs(blob, "Planilla.pdf");
    dispatch(closeModal());
  } catch (error) {
    dispatch(
      enqueueError(
        "Ocurrió un error. Intente nuevamente o reduzca el número de tareas para la planilla."
      )
    );
  }
  dispatch(setLoading(false));
};

const initialState = {
  openModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_DOWNLOAD_ABSA_REPORT_LOADING:
      return { ...state, isLoading: action.value };
    case types.TASK_LIST_DOWNLOAD_ABSA_REPORT_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_DOWNLOAD_ABSA_REPORT_CLOSE_MODAL:
      return { ...state, openModal: false, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
