import { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { flatten } from "lodash";
import { Autocomplete } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = theme => ({
  form: {
    // minWidth: 380,
    // maxHeight: 350,
    marginBottom: theme.spacing(1.5),
  },
  input: {
    marginTop: theme.spacing(3),
  },
});

const TaskDialog = props => {
  const { classes, closeModal, openModal, save } = props;
  const [odt, setOdt] = useState([]);
  const [task, setTask] = useState();
  const [comment, setComment] = useState();
  const [certificate, setCertificate] = useState();
  const [operationZone, setOperationZone] = useState();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const initialize = () => {
    setTask(props.task);
    setComment(props.comment);
    setCertificate(props.certificate);
    setOperationZone(props.operationZone);
    setOdt(props.odt ? (Array.isArray(props.odt) ? props.odt : [props.odt]) : []);
  };

  useEffect(() => {
    const listener = event => {
      if (openModal && (event.code === "Enter" || event.code === "NumpadEnter")) {
        save({
          odt,
          task,
          comment,
          certificate,
          operationZone,
        });
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [odt, task, save, comment, certificate, openModal, operationZone]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}
      TransitionProps={{
        onEnter: initialize,
        onExited: initialize,
      }}>
      <DialogTitle>Tarea</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Ingrese los datos de la tarea por los que desea filtrar.
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup style={{ marginBottom: 20 }}>
            <Autocomplete
              className={classes.input}
              label="Orden de tarea"
              value={odt.map(o => ({ title: o }))}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event, newValue) => {
                event.preventDefault();
                event.stopPropagation();

                setOdt(
                  flatten(
                    newValue.map(v => v.inputValue || v.title || v).map(c => c.split("#"))
                  )
                );
              }}
              filterOptions={(options, params) => {
                const filtered = [...options];
                const { inputValue } = params;

                const isExisting = options.some(option => inputValue === option.title);

                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              multiple
              handleHomeEndKeys
              options={[]}
              getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              sx={{ width: "100%" }}
              freeSolo
              renderInput={params => <TextField {...params} label="Orden de tarea" />}
            />

            <TextField
              value={operationZone}
              onChange={e => setOperationZone(e.target.value)}
              className={classes.input}
              label="Zona de operación"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              value={certificate}
              onChange={e => setCertificate(e.target.value)}
              className={classes.input}
              label="Certificado"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              value={task}
              onChange={e => setTask(e.target.value)}
              className={classes.input}
              label="Tarea a realizar"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              value={comment}
              onChange={e => setComment(e.target.value)}
              className={classes.input}
              label="Observaciones"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() =>
            save({
              odt,
              task,
              comment,
              operationZone,
            })
          }>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(TaskDialog);
