import { createSelector } from "reselect";
import { enqueueError, enqueueMessage } from "./notifications";
import { getTasksFromGraphqlCurrentPage } from "./tasksList";
import axios from "../../axios";

import { getTask as getTaskFromApi } from "./taskDetails";

const types = {
  TASK_LIST_SCHEDULE_TASK_OPEN_MODAL: "TASK_LIST_SCHEDULE_TASK_OPEN_MODAL",
  TASK_LIST_SCHEDULE_TASK_CLOSE_MODAL: "TASK_LIST_SCHEDULE_TASK_CLOSE_MODAL",
  TASK_LIST_SCHEDULE_TASK_CHANGE_GANG: "TASK_LIST_SCHEDULE_TASK_CHANGE_GANG",
  TASK_LIST_SCHEDULE_TASK_CHANGE_COMMENT: "TASK_LIST_SCHEDULE_TASK_CHANGE_COMMENT",
  TASK_LIST_SCHEDULE_TASK_REQUEST: "TASK_LIST_SCHEDULE_TASK_REQUEST",
  TASK_LIST_SCHEDULE_TASK_SUCCESS: "TASK_LIST_SCHEDULE_TASK_SUCCESS",
  TASK_LIST_SCHEDULE_TASK_FAILURE: "TASK_LIST_SCHEDULE_TASK_FAILURE",
  TASK_LIST_SCHEDULE_TASK_GANG_ERROR: "TASK_LIST_SCHEDULE_TASK_GANG_ERROR",
};

export const openModal = task => ({
  type: types.TASK_LIST_SCHEDULE_TASK_OPEN_MODAL,
  task,
});

export const closeModal = () => ({
  type: types.TASK_LIST_SCHEDULE_TASK_CLOSE_MODAL,
});

export const changeGang = gang => ({
  type: types.TASK_LIST_SCHEDULE_TASK_CHANGE_GANG,
  gang,
});

export const changeComment = comment => ({
  type: types.TASK_LIST_SCHEDULE_TASK_CHANGE_COMMENT,
  comment,
});

const gangError = () => ({
  type: types.TASK_LIST_SCHEDULE_TASK_GANG_ERROR,
});

const request = () => ({
  type: types.TASK_LIST_SCHEDULE_TASK_REQUEST,
});

const success = () => ({
  type: types.TASK_LIST_SCHEDULE_TASK_SUCCESS,
});

const failure = () => ({
  type: types.TASK_LIST_SCHEDULE_TASK_FAILURE,
});

export const confirm = () => async (dispatch, getState) => {
  const {
    taskListScheduleTask: { task, gang, comment },
  } = getState();
  if (!gang) {
    dispatch(gangError());
  } else {
    dispatch(request());
    try {
      await axios.post(`/tasks/${task.id}/actions`, {
        estado_id: 2,
        ...(comment && { observaciones: comment }),
        cuadrilla_id: gang.id,
      });
      dispatch(enqueueMessage("La tarea se programó correctamente"));
      dispatch(getTasksFromGraphqlCurrentPage());
      dispatch(getTaskFromApi(task.id));
      dispatch(success());
    } catch (error) {
      dispatch(enqueueError("Ocurrió un error al programar la tarea"));
      dispatch(failure());
    }
  }
};

const initialState = {
  openModal: false,
  loading: false,
  task: null,
  gang: null,
  gangError: false,
  comment: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_SCHEDULE_TASK_OPEN_MODAL:
      return {
        ...state,
        openModal: true,
        task: action.task,
        gang: action.task.cuadrilla
          ? { id: action.task.cuadrilla.id, name: action.task.cuadrilla.cuadrilla }
          : null,
        gangError: false,
        comment: "",
      };
    case types.TASK_LIST_SCHEDULE_TASK_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_SCHEDULE_TASK_CHANGE_GANG:
      return { ...state, gang: action.gang, gangError: false };
    case types.TASK_LIST_SCHEDULE_TASK_CHANGE_COMMENT:
      return { ...state, comment: action.comment };
    case types.TASK_LIST_SCHEDULE_TASK_REQUEST:
      return { ...state, loading: true, gangError: false };
    case types.TASK_LIST_SCHEDULE_TASK_SUCCESS:
      return { ...state, loading: false, openModal: false };
    case types.TASK_LIST_SCHEDULE_TASK_FAILURE:
      return { ...state, loading: false };
    case types.TASK_LIST_SCHEDULE_TASK_GANG_ERROR:
      return { ...state, gangError: true };
    default:
      return state;
  }
};

const getAllGangs = state => state.taskListGangs;
const getTask = state => state.taskListScheduleTask.task;

export const getGangs = createSelector([getAllGangs, getTask], (gangs, task) => {
  const group = task?.group || task?.grupo;
  if (task && group) {
    return gangs.filter(g => Number(g.group.id) === Number(group.id));
  }
  return [];
});

export default reducer;
