import { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { LoadingButton } from "@mui/lab";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Autocomplete, Grid } from "@mui/material";

const styles = () => ({
  text: {
    textAlign: "center",
  },

  confirmBtn: {
    width: 110,
  },
  input: {
    marginTop: 10,
    marginBottom: 20,
  },
});

const capitalize = string => {
  return string
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
    .join(" ");
};

const EditGroupDialog = props => {
  const { classes, closeModal, openModal, group, cities } = props;

  const [data, setData] = useState({ name: "", inspectors: [] });

  useEffect(() => {
    setData({
      name: group ? group.name : null,
      cities: group
        ? (cities || []).filter(c => (group.locations || []).includes(Number(c.id)))
        : [],
      inspectors:
        group && group.inspectors
          ? group.inspectors.map(({ inspector }) => inspector)
          : [],
    });
  }, [openModal, group, cities]);

  const onSubmit = () => {
    const groupData = {
      nombre: data.name,
      inspectors: data.inspectors.length
        ? data.inspectors.map(inspector => ({
            id: new Date().getTime().toString(),
            inspector,
          }))
        : null,
    };

    props.edit(group.id, groupData);
  };

  return (
    <Dialog open={openModal} onClose={closeModal} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Editar grupo</DialogTitle>

      {group ? (
        <DialogContent>
          <form autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  label="Nombre"
                  fullWidth={true}
                  variant="outlined"
                  value={data.name}
                  error={!data.name}
                  placeholder="Nombre del grupo"
                  onChange={event => setData({ ...data, name: event.target.value })}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  value={data.inspectors.map(i => ({ title: i }))}
                  label="Inspectores"
                  onChange={(event, newValue) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setData(
                      "inspectors",
                      newValue.map(v => capitalize(v.inputValue || v.title || v))
                    );
                  }}
                  filterOptions={(options, params) => {
                    const filtered = [...options];
                    const { inputValue } = params;

                    const isExisting = options.some(
                      option => inputValue === option.title
                    );

                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Agregar "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  multiple
                  handleHomeEndKeys
                  options={[]}
                  getOptionLabel={option => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.title}</li>}
                  sx={{ width: "100%" }}
                  freeSolo
                  renderInput={params => <TextField {...params} label="Inspectores" />}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      ) : null}

      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>

        <LoadingButton
          loading={props.sending}
          color="primary"
          onClick={onSubmit}
          disabled={!data.name}
          className={classes.confirmBtn}>
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EditGroupDialog);
