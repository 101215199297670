/* eslint-disable no-empty */
import axios from "../../axios";

const types = {
  SIDEWALK_EDIT_MODAL_OPEN: "SIDEWALK_EDIT_MODAL_OPEN",
  SIDEWALK_EDIT_MODAL_OPEN_CONFIRMATION: "SIDEWALK_EDIT_MODAL_OPEN_CONFIRMATION",
  SIDEWALK_EDIT_MODAL_CLOSE: "SIDEWALK_EDIT_MODAL_CLOSE",
  SIDEWALK_EDIT_MODAL_CHANGE_AMOUNT: "SIDEWALK_EDIT_MODAL_CHANGE_AMOUNT",
  SIDEWALK_EDIT_REQUIRES_FILLING: "SIDEWALK_EDIT_REQUIRES_FILLING",
  SIDEWALK_EDIT_MODAL_CHANGE_UNIT: "SIDEWALK_EDIT_MODAL_CHANGE_UNIT",
  SIDEWALK_EDIT_MODAL_CHANGE_TYPE: "SIDEWALK_EDIT_MODAL_CHANGE_TYPE",
  SIDEWALK_EDIT_MODAL_SET_DEFAULTS: "SIDEWALK_EDIT_MODAL_SET_DEFAULTS",
  SIDEWALK_EDIT_MODAL_CHANGE_OBSERVATIONS: "SIDEWALK_EDIT_MODAL_CHANGE_OBSERVATIONS",
  SIDEWALK_EDIT_MODAL_CONFIRM_EDIT: "SIDEWALK_EDIT_MODAL_CONFIRM_EDIT",
};

export const openModal = sidewalk => dispatch => {
  dispatch({
    type: types.SIDEWALK_EDIT_MODAL_OPEN,
    sidewalk,
  });
};

export const closeModal = () => ({
  type: types.SIDEWALK_EDIT_MODAL_CLOSE,
});

export const openConfirmationModal = sidewalk => dispatch => {
  dispatch(setDefaultsValues(sidewalk));
  dispatch({
    type: types.SIDEWALK_EDIT_MODAL_OPEN_CONFIRMATION,
  });
};

export const setDefaultsValues = (
  amount,
  unit,
  type,
  observations,
  requires_filling,
  article_id,
  article_description
) => ({
  type: types.SIDEWALK_EDIT_MODAL_SET_DEFAULTS,
  data: {
    unit,
    type,
    amount,
    article_id,
    observations,
    requires_filling,
    article_description,
  },
});

export const confirmEdit =
  (amount, unit, type, observations, requires_filling, article_id, article_description) =>
  async (dispatch, getState) => {
    const { taskSidewalkEditModal } = getState();
    const { sidewalk } = taskSidewalkEditModal;

    const data = {
      ...sidewalk,
      amount,
      unit,
      type,
      observations,
      requires_filling,
      article_id,
      state: 1,
      article_description,
    };
    try {
      await axios.patch(`/sidewalk/${sidewalk.id}/`, data);
    } catch (error) {}
    dispatch({
      type: types.SIDEWALK_EDIT_MODAL_CONFIRM_EDIT,
      data: {
        amount,
        unit,
        type,
        state: 1,
        observations,
        requires_filling,
        article_id,
        article_description,
      },
    });
  };
export const changeRequiresFilling = requires_filling => ({
  type: types.SIDEWALK_EDIT_REQUIRES_FILLING,
  requires_filling,
});

export const changeAmount = amount => ({
  type: types.SIDEWALK_EDIT_MODAL_CHANGE_AMOUNT,
  amount,
});

export const changeUnit = unit => ({
  type: types.SIDEWALK_EDIT_MODAL_CHANGE_UNIT,
  unit,
});

export const changeType = value => ({
  type: types.SIDEWALK_EDIT_MODAL_CHANGE_TYPE,
  value,
});

export const changeObservations = observations => ({
  type: types.SIDEWALK_EDIT_MODAL_CHANGE_OBSERVATIONS,
  observations,
});

const initialState = {
  modalOpen: false,
  confirmationOpen: false,

  amount: 0,
  unit: null,
  type: null,
  observations: null,
  requires_filling: null,

  sending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIDEWALK_EDIT_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
        ...action.sidewalk,
        sidewalk: action.sidewalk,
      };

    case types.SIDEWALK_EDIT_MODAL_OPEN_CONFIRMATION:
      return { ...state, modalOpen: false, confirmationOpen: true };

    case types.SIDEWALK_EDIT_MODAL_CLOSE:
      return { ...state, modalOpen: false, confirmationOpen: false };

    case types.SIDEWALK_EDIT_MODAL_CHANGE_AMOUNT:
      return { ...state, amount: action.amount };

    case types.SIDEWALK_EDIT_MODAL_CHANGE_UNIT:
      return { ...state, unit: action.unit };

    case types.SIDEWALK_EDIT_MODAL_CHANGE_TYPE:
      return { ...state, type: action.value };

    case types.SIDEWALK_EDIT_REQUIRES_FILLING:
      return { ...state, requires_filling: action.requires_filling };

    case types.SIDEWALK_EDIT_MODAL_CHANGE_OBSERVATIONS:
      return { ...state, observations: action.observations };

    case types.SIDEWALK_EDIT_MODAL_SET_DEFAULTS:
      return { ...state, ...action.data, edited: false };

    case types.SIDEWALK_EDIT_MODAL_CONFIRM_EDIT:
      Object.keys(action.data).forEach(k => {
        state.sidewalk[k] = action.data[k];
      });
      return { ...state, ...action.data, edited: true };

    default:
      return state;
  }
};

export default reducer;
