export const MAP = {
  center: [-34.9210593, -57.9541325],
  zoom: 13,
  maxZoom: 18,
  attribution:
    '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};

const types = {
  MAP_RESET_CENTER: "MAP_RESET_CENTER",
  MAP_HANDLE_MOVE_CLICK: "MAP_HANDLE_MOVE_CLICK",
};

export const resetCenter = () => ({
  type: types.MAP_RESET_CENTER,
});

export const handleMoveClick = coordinates => ({
  type: types.MAP_HANDLE_MOVE_CLICK,
  coordinates,
});

const initialState = {
  center: MAP.center,
  zoom: MAP.zoom,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAP_RESET_CENTER:
      return {
        ...state,
        zoom: MAP.zoom,
        center: [
          state.center[0] === MAP.center[0] ? MAP.center[0] + 0.0000001 : MAP.center[0],
          MAP.center[1],
        ],
      };
    case types.MAP_HANDLE_MOVE_CLICK:
      return {
        ...state,
        zoom: 18,
        center: [
          state.center[0] === action.coordinates[0]
            ? action.coordinates[0] + 0.0000001
            : action.coordinates[0],
          action.coordinates[1],
        ],
      };
    default:
      return state;
  }
};

export default reducer;
