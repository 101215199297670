import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import format from "date-fns/format";

const timeAlertIcon = `<span class="fa-stack update-alert">
  <i class="fas fa-circle fa-stack-1x circle-icon"></i>
  <i class="fas fa-clock fa-stack-1x alert-icon"></i>
</span>`;

const createIcon = task =>
  L.divIcon({
    className: `icon ${task.state.class}`,
    html: `<p><i class="fas fa-wrench"></i></p>${task.expired ? timeAlertIcon : ""}`,
    item: { ...task, initials: '<i class="fas fa-wrench"></i>' },
    iconSize: [32, 32],
  });

const tasks = ({ tasks }) =>
  tasks.map(task => (
    <Marker icon={createIcon(task)} key={task.id} position={task.location.coordinates}>
      <Popup direction="top" offset={L.point(0, -8)}>
        <div>
          <b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PUBLIC_URL}/tarea/${task.id}`}>
              ODT {task.otCode}
            </a>
          </b>
          <ul style={{ paddingLeft: 15, paddingRight: 15 }}>
            <li>{task.group.name}</li>
            <li>{task.address}</li>
            <li>
              {task.state.name}
              {task.gangName ? ` (${task.gangName})` : null}
            </li>
            <li>{task.type}</li>
            <li>{task.task}</li>
            <li>Fecha asignada: {format(new Date(task.date), "dd/MM/yyyy")}</li>
            <li style={{ color: task.expired ? "#f44336" : "#333" }}>
              Fecha vencimiento: {format(new Date(task.dueDate), "dd/MM/yyyy")}
            </li>
          </ul>
        </div>
      </Popup>
    </Marker>
  ));

export default tasks;
