import format from "date-fns/format";

export default ({ step1 = {}, step2 = {}, step3 = {} }) => {
  const { tipo, croquis, valida_materiales, ...other1 } = step1;
  const { localidad, position, custom_geo, contact_phone, aclaracion, ...other2 } = step2;
  const { grupo, stores, for_contractor, requires_pipeline_information, ...other3 } =
    step3;

  const data = {
    ...other1,
    ...other2,
    ...other3,
    estado_id: 1,

    latitud: position.lat,
    longitud: position.lng,
    aclaracion: aclaracion || "",

    custom_geo: custom_geo ? 1 : 0,
    contact_phone: contact_phone || "",

    tipo_id: tipo.id,
    grupo_id: grupo ? grupo.id : undefined,

    localidad_id: localidad.id,
    valida_materiales: valida_materiales ? 1 : 0,
    ingreso_ot: format(new Date(step1.ingreso_ot), "yyyy-MM-dd"),
    fecha_asignada: format(new Date(step1.fecha_asignada), "yyyy-MM-dd"),
    requires_pipeline_information: requires_pipeline_information ? 1 : 0,
  };

  if (stores && stores.length) data.stores = JSON.stringify(stores.map(s => s._id));
  if (typeof for_contractor === "boolean") data.for_contractor = for_contractor ? 1 : 0;

  const formData = new FormData();

  Object.keys(data).forEach(k => {
    if (data[k] !== undefined && data[k] !== null) formData.append(k, data[k]);
  });

  if (croquis && croquis.length) {
    croquis.forEach(c => {
      formData.append("croquis[]", c);
    });
  }

  return formData;
};
