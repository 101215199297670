/* eslint-disable no-empty */

import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { DialogContentText, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const styles = () => ({
  form: {},
});

const DownloadAbsaReportDialog = ({
  openModal,
  isLoading,
  paginator,
  handleCancel,
  handleDownload,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={openModal}
      fullScreen={isMobile}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm">
      <DialogTitle>Descargar Planilla de ABSA</DialogTitle>

      <DialogContent>
        <DialogContentText dividers>
          {paginator && paginator.total
            ? "La planilla de absa será generada teniendo encuenta los filtros actualmente establecidos en la pantalla."
            : "La planilla no puede ser generada para los filtros especificados."}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button style={{ color: "black" }} onClick={handleCancel}>
          Cancelar
        </Button>

        <LoadingButton
          loading={isLoading}
          component="div"
          title="Guardar"
          onClick={handleDownload}
          style={{ width: 100, height: 36 }}
          disabled={isLoading || !paginator || !paginator.total}>
          Descargar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(DownloadAbsaReportDialog);
