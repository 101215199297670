const types = {
  SELECTED_TASKS_CHECK: "SELECTED_TASKS_CHECK",
  SELECTED_TASKS_CHECK_ALL: "SELECTED_TASKS_CHECK_ALL",
  SELECTED_TASKS_UNCHECK_ALL: "SELECTED_TASKS_UNCHECK_ALL",
};

export const checkTask = taskId => ({
  type: types.SELECTED_TASKS_CHECK,
  taskId,
});

export const checkAll = taskIds => ({
  type: types.SELECTED_TASKS_CHECK_ALL,
  taskIds,
});

export const uncheckAll = () => ({
  type: types.SELECTED_TASKS_UNCHECK_ALL,
});

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.SELECTED_TASKS_CHECK:
      return state.find(id => id === action.taskId)
        ? state.filter(id => id !== action.taskId)
        : [...state, action.taskId];
    case types.SELECTED_TASKS_CHECK_ALL:
      return action.taskIds.length === state.length ? [] : action.taskIds;
    case types.SELECTED_TASKS_UNCHECK_ALL:
      return [];
    default:
      return state;
  }
};

export default reducer;
