import withStyles from "@mui/styles/withStyles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";

import { debounce } from "lodash";

const styles = () => ({
  root: {
    paddingLeft: 0,
  },
});

const GroupSearch = props => {
  const search = debounce(text => {
    props.onChange(text);
  }, 300);

  return (
    <Toolbar className={props.classes.root}>
      <div>
        <Tooltip title="Buscar">
          <IconButton aria-label="Buscar" size="large">
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Input
        value={props.value}
        onChange={e => search(e.target.value)}
        disableUnderline
        fullWidth
        placeholder="Buscar"
      />
    </Toolbar>
  );
};

export default withStyles(styles)(GroupSearch);
