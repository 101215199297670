import { connect } from "react-redux";

import { showHistoric } from "../../../../store/ducks/taskListHistoricFilter";
import { openModal as openTaskModal } from "../../../../store/ducks/taskListTaskFilter";
import { openModal as openTypeModal } from "../../../../store/ducks/taskListTypesFilter";
import { openModal as openGangsModal } from "../../../../store/ducks/taskListGangFilter";
import { openModal as openCitiesModal } from "../../../../store/ducks/taskListCityFilter";
import { openModal as openDatesModal } from "../../../../store/ducks/taskListDateFilters";
import { openModal as openStatesModal } from "../../../../store/ducks/taskListStateFilter";
import { openModal as openGroupsModal } from "../../../../store/ducks/taskListGroupFilter";

import { openModal as openAddressModal } from "../../../../store/ducks/taskListAddressFilter";
import { openModal as openSidewalkModal } from "../../../../store/ducks/taskListSidewalkFilter";
import { openModal as openStateChangesModal } from "../../../../store/ducks/taskListStateChangesFilter";
import { openModal as openHistoryChangesModal } from "../../../../store/ducks/taskListHistoryChangesFilter";
import { openModal as openHidrojetModal } from "../../../../store/ducks/taskListHidrojetFilter";
import { openModal as openContractorModal } from "../../../../store/ducks/taskListContractorFilter";
import { openModal as openTaskConditionsModal } from "../../../../store/ducks/taskListTaskConditionsFilter";

import FilterList from "./filterListContainer/FilterList";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";

const mapDispatchToProps = dispatch => ({
  openTaskModal: () => dispatch(openTaskModal()),
  openTypeModal: () => dispatch(openTypeModal()),
  openDatesModal: () => dispatch(openDatesModal()),
  openGangsModal: () => dispatch(openGangsModal()),
  openGroupsModal: () => dispatch(openGroupsModal()),
  openCitiesModal: () => dispatch(openCitiesModal()),
  openStatesModal: () => dispatch(openStatesModal()),
  openAddressModal: () => dispatch(openAddressModal()),
  openHidrojetModal: () => dispatch(openHidrojetModal()),
  openSidewalkModal: () => dispatch(openSidewalkModal()),
  openContractorModal: () => dispatch(openContractorModal()),
  openStateChangesModal: () => dispatch(openStateChangesModal()),
  openHistoryChangesModal: () => dispatch(openHistoryChangesModal()),
  openTaskConditionsModal: () => dispatch(openTaskConditionsModal()),
  showHistoric: () => {
    dispatch(showHistoric());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(null, mapDispatchToProps)(FilterList);
