import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/icons-material/Help";

const downloadHelpButton = ({ file }) => (
  <Tooltip title="Ayuda">
    <IconButton
      aria-label="delete"
      component="a"
      target="_blank"
      href={`${process.env.REACT_APP_IMAGES_URI}/${file}`}
      size="large">
      <Icon />
    </IconButton>
  </Tooltip>
);

export default downloadHelpButton;
