import { connect } from "react-redux";
import { setNewOrderTask, getTasks } from "../../../store/ducks/gangTasksReorder";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { SortableItem } from "./reorderTaskList/SortableItem";
import Header from "./reorderTaskList/Header";
import EmptyTasks from "./reorderTaskList/EmptyTasks";
import { Grid } from "@mui/material";
import TaskCardSkeleton from "./executionTask/TaskCardSkeleton";

const Tasks = ({ ...props }) => {
  const skeletons = (
    <>
      <Grid item xs={12}>
        <TaskCardSkeleton />
      </Grid>
      <Grid item xs={12}>
        <TaskCardSkeleton />
      </Grid>
      <Grid item xs={12}>
        <TaskCardSkeleton />
      </Grid>
      <Grid item xs={12}>
        <TaskCardSkeleton />
      </Grid>
    </>
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <>
      <Header />
      <div style={{ marginTop: -8 }}>
        {props.isFetching ? (
          skeletons
        ) : props.tasks.length ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            handle>
            <SortableContext
              items={props.tasks}
              strategy={verticalListSortingStrategy}
              handle>
              {props.tasks.map((task, index) => (
                <SortableItem
                  key={task.id}
                  id={task.id}
                  task={task}
                  position={index}
                  items={props.tasks}
                />
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <EmptyTasks />
        )}
      </div>
    </>
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = props.tasks.findIndex(i => i.id === active.id);
      const newIndex = props.tasks.findIndex(i => i.id === over.id);
      /*setItems(items => {
        return arrayMove(items, oldIndex, newIndex);
      });*/
      props.setNewOrder(arrayMove(props.tasks, oldIndex, newIndex));
    }
  }
};

const mapStateToProps = state => ({
  tasks: getTasks(state),
  hasReordered: state.gangTasksReorder.newTasks,
});

const mapDispatchToProps = dispatch => ({
  setNewOrder: tasks => dispatch(setNewOrderTask(tasks)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
