import { memo } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Card, CardHeader, Tooltip } from "@mui/material";

const Title = memo(({ parentTask }) => {
  return parentTask && parentTask ? (
    <Card variant="outlined" style={{ padding: 5, marginTop: 10 }}>
      <CardHeader
        avatar={
          <Tooltip title="Información">
            <InfoOutlinedIcon />
          </Tooltip>
        }
        title={`Información`}
        subheader={`Esta tarea fue generada a partir de la tarea #${parentTask.codigo_ot} (${parentTask.tipo.tipo}).`}
        action={
          <div style={{ flex: 1, justifyContent: "center", marginTop: 15 }}>
            <Button
              size="large"
              target="_blank"
              href={`${process.env.PUBLIC_URL}/tarea/${parentTask.id}`}>
              Ir a ver tarea
            </Button>
          </div>
        }
        titleTypographyProps={{ style: { fontWeight: 400, fontSize: 18 } }}
      />
    </Card>
  ) : null;
});

export default Title;
