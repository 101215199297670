/* eslint-disable no-prototype-builtins */
// export const removeAccents = str => {
//   return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
// };

const mapping = {};
const to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
const from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";

for (let i = 0, j = from.length; i < j; i++) mapping[from.charAt(i)] = to.charAt(i);

export const removeAccents = (str = "") => {
  if (!str) return "";
  const ret = [];
  for (let i = 0, j = str.length; i < j; i++) {
    const c = str.charAt(i);
    if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
    else ret.push(c);
  }
  return ret.join("");
};
