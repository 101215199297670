import { useCallback, useEffect, useMemo, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { InputLabel, MenuItem, Select, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const styles = () => ({
  form: {
    // minWidth: 400,
    // maxHeight: 300,
  },
});

const EditDialog = props => {
  const { closeModal, openModal, submit, gang } = props;

  const [multiple, setMultiple] = useState(0);
  const [observations, setObservations] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (openModal) {
      setMultiple(gang.multiple);
      setObservations(gang.observations);
    }
  }, [openModal, gang]);

  const options = useMemo(
    () =>
      [
        { value: 0, label: "No" },
        { value: 1, label: "Sí" },
      ].map(option =>
        isMobile ? (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ) : (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )
      ),
    [isMobile]
  );

  const handleMultiple = useCallback(event => setMultiple(event.target.value), []);
  const handleObservations = useCallback(
    event => setObservations(event.target.value),
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}
      TransitionProps={{
        onEnter: props.initialize,
      }}>
      <DialogTitle>{openModal ? `Editar cuadrilla ${gang.name}` : ""}</DialogTitle>
      <DialogContent dividers>
        <FormControl style={{ width: "100%" }} margin="dense" variant="outlined">
          <InputLabel id="input-requires-materials">Es multiple</InputLabel>
          <Select
            native={isMobile}
            label="Es multiple"
            value={multiple}
            onChange={handleMultiple}
            labelId="input-requires-materials">
            {options}
          </Select>
        </FormControl>

        <FormControl style={{ width: "100%" }} margin="dense" variant="outlined">
          <TextField
            multiline
            maxRows={4}
            variant="outlined"
            label="Observaciones"
            style={{ width: "100%" }}
            value={observations || ""}
            onChange={handleObservations}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} style={{ color: "black" }}>
          Cancelar
        </Button>

        <LoadingButton
          color="primary"
          loading={props.sending}
          onClick={() => submit({ multiple, observations })}>
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EditDialog);
