import { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";

import Header from "./taskTable/Header";
import Body from "./taskTable/Body";
import Footer from "./taskTable/Footer";

const styles = theme => ({
  rootTable: {
    width: "100%",
    marginTop: theme.spacing(),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

class TaskTable extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  render() {
    const { classes, tasks, checkTask, onDownload, loading, roles } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <div className={classes.rootTable}>
        <Table className={classes.table}>
          <Header />
          <Body
            tasks={tasks}
            page={page}
            roles={roles}
            rowsPerPage={rowsPerPage}
            onCheck={checkTask}
            onDownload={onDownload}
            loading={loading}
            openArticlesDialog={this.props.openArticlesDialog}
          />
          <Footer
            count={tasks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(TaskTable);
