import { connect } from "react-redux";

import {
  openModal,
  removeSidewalk,
} from "../../../../../store/ducks/taskListSidewalkFilter";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

import Sidewalk from "./sidewalkContainer/Sidewalk";

const mapStateToProps = state => ({
  type: state.taskListSidewalkFilter.sidewalkType,
  unit: state.taskListSidewalkFilter.sidewalkUnit,
  withSidewalk: state.taskListSidewalkFilter.withSidewalk,
  minAmount: state.taskListSidewalkFilter.sidewalkMinAmount,
  maxAmount: state.taskListSidewalkFilter.sidewalkMaxAmount,
  sidewalkState: state.taskListSidewalkFilter.sidewalkState,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeSidewalk: () => {
    dispatch(removeSidewalk());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidewalk);
