/* eslint-disable react-hooks/exhaustive-deps */
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import makeStyles from "@mui/styles/makeStyles";
import { memo, useEffect, useMemo } from "react";

import Title from "./newGroup/title";
import Stepper from "./newGroup/form";
import Preloader from "../../components/UI/Preloader";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 25,
  },
  fixed: {
    position: "fixed",
    top: 0,
    width: "98%",
    right: "10px",
    padding: "18px !important",
    [theme.breakpoints.down("sm")]: {
      position: "initial",
    },
  },
}));

export const NewGroupForm = memo(props => {
  const classes = useStyles();
  const isEdit = useMemo(() => !!props.group, [props.group]);
  const { users = [], cities = [], taskTypes = [], stores = [] } = props;

  const { ...form } = useForm({
    defaultValues: {
      name: "",
      users: [],
      cities: [],
      stores: [],
      taskTypes: [],
      inspectors: [],
      fills_sidewalks: false,
      has_contractors: false,
      fills_pipeline_information: false,
    },
    mode: "onChange",
    criteriaMode: "all",
    shouldUnregister: false,
  });

  useEffect(() => {
    if (props.group) {
      form.reset({
        name: props.group.nombre,
        has_contractors: !!props.group.has_contractors,
        fills_sidewalks: !!props.group.fills_sidewalks,
        fills_pipeline_information: !!props.group.fills_pipeline_information,
        users: users.filter(u => props.group.users.includes(Number(u.id))),
        stores: stores.filter(s =>
          props.group.stores ? props.group.stores.includes(s._id) : false
        ),
        cities: cities.filter(u => props.group.locations.includes(Number(u.id))),
        taskTypes: taskTypes.filter(u => props.group.taskTypes.includes(Number(u.id))),
        inspectors: props.group.inspectors
          ? props.group.inspectors?.map(i => i.inspector)
          : [],
      });
    }
  }, [props.group, form.reset]);

  return props.isFetching ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Title isEdit={isEdit} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Stepper form={form} isEdit={isEdit} {...props} />
        </Grid>
      </Grid>
    </div>
  );
});

export default NewGroupForm;
