import { memo, useCallback } from "react";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
});

const AddButton = memo(props => {
  const { classes } = props;
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate("/grupos/nuevo");
  }, [navigate]);

  return (
    <Tooltip title="Nuevo Grupo">
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={onClick}>
        <AddIcon />
      </Fab>
    </Tooltip>
  );
});

export default withStyles(styles)(AddButton);
