import Chip from "@mui/material/Chip";
import withStyles from "@mui/styles/withStyles";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const getShow = props => {
  return (
    props.dateTo ||
    props.dateFrom ||
    props.withHidrojet !== null ||
    props.maxModule !== null ||
    props.minModule !== null ||
    props.hidrojetState !== null
  );
};

const chips = props => {
  const { classes } = props;
  const show = getShow(props);

  return (
    show && (
      <li>
        <Chip
          onClick={props.openModal}
          className={classes.chip}
          label="Hidrojet"
          onDelete={() => {
            props.removeHidrojet();
          }}
        />
      </li>
    )
  );
};

export default withStyles(styles)(chips);
