import { connect } from "react-redux";

import {
  closeModal,
  changeType,
  changeUnit,
  changeAmount,
  changeObservations,
  openConfirmationModal,
} from "../../../store/ducks/taskSidewalkEditModal";

import EditModal from "./editSidewalkModal/EditSidewalkModal";

const mapStateToProps = state => ({
  loading: false,
  sidewalk: state.taskSidewalkEditModal.sidewalk,

  unit: state.taskSidewalkEditModal.unit,
  type: state.taskSidewalkEditModal.type,
  amount: state.taskSidewalkEditModal.amount,
  article_id: state.taskSidewalkEditModal.article_id,
  observations: state.taskSidewalkEditModal.observations,
  requires_filling: state.taskSidewalkEditModal.requires_filling,

  sidewalkTypes: state.sidewalkTypes,
  sidewalkUnits: state.sidewalkUnits,
  sidewalkTiles: state.sidewalkTiles,

  open: state.taskSidewalkEditModal.modalOpen,
  edited: state.taskSidewalkEditModal.edited,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  confirm: () => dispatch(openConfirmationModal()),
  changeType: type => dispatch(changeType(type)),
  changeUnit: unit => dispatch(changeUnit(unit)),
  changeAmount: amount => dispatch(changeAmount(amount)),
  changeObservations: observations => dispatch(changeObservations(observations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
