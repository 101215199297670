import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import ValidateIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns";

const body = props => {
  const { sidewalks } = props;

  return (
    <TableBody>
      {sidewalks.map(sidewalk => (
        <TableRow key={sidewalk.id}>
          <TableCell>
            {sidewalk.article_description ? (
              <div>
                {sidewalk.article_description}{" "}
                <div>
                  <small> {sidewalk.type?.name || "-"}</small>
                </div>
              </div>
            ) : (
              sidewalk.type?.name || "-"
            )}
          </TableCell>
          <TableCell>{sidewalk.unit?.name || "-"}</TableCell>
          <TableCell>{sidewalk.amount || "-"}</TableCell>
          <TableCell>
            {sidewalk.created_at
              ? format(
                  new Date(sidewalk.created_at?.replace(/ /g, "T")),
                  "dd/MM/yyyy HH:mm:ss"
                )
              : "-"}
          </TableCell>
          <TableCell>{sidewalk.observations || "-"}</TableCell>
          <TableCell>{sidewalk.state === 0 ? "Sin validar" : "Validado"}</TableCell>
          <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {sidewalk.state === 0 ? (
                <>
                  <Tooltip title="Editar">
                    <IconButton
                      onClick={() => props.openEditModal(sidewalk)}
                      size="large">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Confirmar">
                    <IconButton
                      onClick={() => props.openConfirmationModal(sidewalk)}
                      size="large">
                      <ValidateIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : sidewalk.state === 1 ? (
                <Tooltip title="Deshacer">
                  <IconButton onClick={() => props.openUndoModal(sidewalk)} size="large">
                    <UndoIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default body;
