import { connect } from "react-redux";

import TableInfo from "./TableInfo";
import { getFilteredGangGroups } from "../../../store/ducks/groupGangs";
import { setActiveGangs } from "../../../store/ducks/taskListGangFilter";
import { setActiveStates } from "../../../store/ducks/taskListStateFilter";
import { openModal as openTransferGangModal } from "../../../store/ducks/transferGangModal";
import { openModal as openUnassignGroupModal } from "../../../store/ducks/unnasignGangModal";

const mapStateToProps = state => ({
  gangs: getFilteredGangGroups(state),
  group: state.groupGangs.group,
  roles: state.user.roles,
});

const mapDispatchToProps = dispatch => ({
  openTransferGangModal: gang => dispatch(openTransferGangModal(gang)),
  openUnassignGroupModal: gang => dispatch(openUnassignGroupModal(gang)),

  setTaskListFilters: (gang, state) => {
    dispatch(setActiveGangs([gang]));
    dispatch(setActiveStates([state]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TableInfo);
