import { connect } from "react-redux";

import { closeModal, save } from "../../../../store/ducks/taskListStateChangesFilter";
import StateChangesDialog from "./stateChangesDialogContainer/StateChangesDialog";
import { getTasksFromGraphql } from "../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  openModal: state.taskListStateChangesFilter.openModal,
  states: state.taskListStateChangesFilter.statesChanges,
  dateFrom: state.taskListStateChangesFilter.dateFrom,
  dateTo: state.taskListStateChangesFilter.dateTo,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  save: states => {
    dispatch(save(states));
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StateChangesDialog);
