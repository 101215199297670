import { connect } from "react-redux";
import { getGangs } from "../../../../../store/ducks/mapInfoPanel";

import { search, clear } from "../../../../../store/ducks/taskFilters";
import TaskFilters from "./taskFiltersContainer/TaskFilters";

const mapStateToProps = state => ({
  types: state.taskTypes,
  gangs: getGangs(state),
});

const mapDispatchToProps = dispatch => ({
  search: fields => dispatch(search(fields)),
  clear: () => dispatch(clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskFilters);
