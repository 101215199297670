import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";

import Button from "@mui/material/Button";
import { Alert, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const styles = () => ({
  text: {
    textAlign: "center",
  },
  confirmBtn: {
    width: 110,
  },
  alert: {
    padding: 15,
    display: "flex",
    border: "1px solid #c4c4c4",
    borderRadius: 5,
    fontSize: 14,
  },
  alertIcon: {
    color: "#747474",
  },
  alertText: {
    marginBottom: 0,
    marginLeft: 10,
    fontSize: 14,
  },
  input: {
    marginTop: 10,
    marginBottom: 20,
  },
});

const GroupsDialog = props => {
  const { classes, closeModal, openModal, gang, group } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const hasTasks =
    gang &&
    (gang.tareasProgramadasCount ||
      gang.tareasAsignadasCount ||
      gang.tareasPausadasCount);

  return (
    <Dialog fullScreen={isMobile} open={openModal} onClose={closeModal}>
      <DialogTitle>Eliminar cuadrilla del grupo</DialogTitle>

      <DialogContent>
        <DialogContentText style={{ paddingBottom: hasTasks ? 10 : 0 }}>
          Estás a punto de eliminar a la cuadrilla <i>{gang ? gang.cuadrilla : ""}</i> del
          grupo <i>{group ? group.nombre : ""}</i>. ¿Estás seguro que deseas continuar?
        </DialogContentText>

        {hasTasks ? (
          <Alert severity="warning">
            <div>
              Al realizar esta acción todas las tareas en estado <i>Programadas</i>{" "}
              {gang ? `(${gang.tareasProgramadasCount})` : ""}, <i>Asignadas</i>{" "}
              {gang ? `(${gang.tareasAsignadasCount})` : ""} y <i>Pausadas</i>{" "}
              {gang ? `(${gang.tareasPausadasCount})` : ""} pasarán automáticamente a
              estado <i>Pendiente</i> y serán desasignadas de la cuadrilla.
            </div>
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal}>
          Cancelar
        </Button>

        <LoadingButton
          loading={props.sending}
          color="primary"
          className={classes.confirmBtn}
          onClick={props.unnasign}>
          Continuar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(GroupsDialog);
