/* eslint-disable no-case-declarations */
import { enqueueError } from "./notifications";
import { getTasksFromGraphql } from "./tasksList";

const types = {
  TASK_LIST_CITY_FILTER_OPEN_MODAL: "TASK_LIST_CITY_FILTER_OPEN_MODAL",
  TASK_LIST_CITY_FILTER_CLOSE_MODAL: "TASK_LIST_CITY_FILTER_CLOSE_MODAL",
  TASK_LIST_CITY_FILTER_SAVE: "TASK_LIST_CITY_FILTER_SAVE",
  TASK_LIST_CITY_FILTER_TOGGLE: "TASK_LIST_CITY_FILTER_TOGGLE",
  TASK_LIST_CITY_FILTER_TOGGLE_ALL: "TASK_LIST_CITY_FILTER_TOGGLE_ALL",
  TASK_LIST_CITY_FILTER_REMOVE: "TASK_LIST_CITY_FILTER_REMOVE",
  TASK_LIST_CITY_INITIALIZE: "TASK_LIST_CITY_INITIALIZE",
};

export const openModal = () => ({
  type: types.TASK_LIST_CITY_FILTER_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_CITY_FILTER_CLOSE_MODAL,
});

export const save = () => (dispatch, getState) => {
  const {
    taskListCityFilter: { dialogCities },
    cities,
  } = getState();
  if (dialogCities.filter(g => g.selected).length) {
    dispatch({
      type: types.TASK_LIST_CITY_FILTER_SAVE,
      citiesLength: cities.length,
    });
    dispatch(getTasksFromGraphql());
  } else {
    dispatch(enqueueError("Debe seleccionar alguna localidad"));
  }
};

export const removeCities = () => ({
  type: types.TASK_LIST_CITY_FILTER_REMOVE,
});

export const initialize = () => (dispatch, getState) => {
  const cities = getState().cities;
  dispatch({
    type: types.TASK_LIST_CITY_INITIALIZE,
    cities,
  });
};

export const toggle = event => ({
  type: types.TASK_LIST_CITY_FILTER_TOGGLE,
  city: event.target.name,
  state: event.target.checked,
});

export const toggleAll = event => ({
  type: types.TASK_LIST_CITY_FILTER_TOGGLE_ALL,
  state: event.target.checked,
});

const initialState = {
  openModal: false,

  dialogCities: [],
  selectedCities: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_CITY_FILTER_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_CITY_FILTER_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_CITY_FILTER_SAVE:
      const selected = state.dialogCities.filter(g => g.selected).map(g => g.id);
      return {
        ...state,
        openModal: false,
        selectedCities: selected.length === action.citiesLength ? [] : selected,
      };
    case types.TASK_LIST_CITY_FILTER_TOGGLE:
      return {
        ...state,
        dialogCities: state.dialogCities.map(g =>
          g.id === action.city ? { ...g, selected: action.state } : g
        ),
      };
    case types.TASK_LIST_CITY_FILTER_TOGGLE_ALL:
      return {
        ...state,
        dialogCities: state.dialogCities.map(c => ({
          ...c,
          selected: action.state,
        })),
      };
    case types.TASK_LIST_CITY_FILTER_REMOVE:
      return {
        ...state,
        selectedCities: [],
      };
    case types.TASK_LIST_CITY_INITIALIZE:
      return {
        ...state,
        dialogCities: action.cities.map(c =>
          state.selectedCities.length
            ? { ...c, selected: state.selectedCities.includes(c.id) }
            : { ...c, selected: true }
        ),
      };
    default:
      return state;
  }
};

export default reducer;
