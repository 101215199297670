/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import { confirmEdit } from "../../../../store/ducks/taskHidrojetEditModal";
import DatePicker from "@mui/lab/MobileDatePicker";
import { LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = theme => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

const EditModal = props => {
  const dispatch = useDispatch();

  const [module, setModule] = useState(props.module);
  const [taskDate, setTaskDate] = useState(props.taskDate);
  const [observations, setObservations] = useState(props.observations);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isValid = !!module;

  const onSubmit = () => {
    dispatch(confirmEdit(module, observations, taskDate, props.isDetails));
  };

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isValid) {
          onSubmit();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isValid]);

  useEffect(() => {
    setModule(props.module);
    setTaskDate(props.taskDate);
    setObservations(props.observations);
  }, [props.open, props.module, props.observations, props.taskDate]);

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Validar Módulos de hidrojet</DialogTitle>
      <DialogContent>
        {props.loading ? (
          <div className={props.classes.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {props.action && props.action.task ? (
              <TextField
                disabled
                fullWidth
                margin="dense"
                label={"Código OT de tarea"}
                value={props.action.task.codigo_ot}
              />
            ) : null}

            <DatePicker
              disabled
              clearable
              fullWidth
              margin="dense"
              clearText="Borrar"
              inputFormat="dd/MM/yyyy"
              cancelText="Cancelar"
              label="Fecha de Carga"
              showTodayButton
              onChange={() => {}}
              DialogProps={{ disableRestoreFocus: true }}
              value={props.action ? props.action.created_at : null}
              todayText="Hoy"
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
                />
              )}
            />

            <DatePicker
              clearable
              fullWidth
              margin="dense"
              value={taskDate}
              clearText="Borrar"
              inputFormat="dd/MM/yyyy"
              cancelText="Cancelar"
              label="Fecha de Tarea"
              showTodayButton
              onChange={date => setTaskDate(date)}
              DialogProps={{ disableRestoreFocus: true }}
              todayText="Hoy"
              renderInput={params => (
                <TextField {...params} style={{ width: "100%", marginBottom: 15 }} />
              )}
            />

            <TextField
              fullWidth
              type="number"
              margin="dense"
              value={module}
              style={{ marginBottom: 10 }}
              label={"Cantidad de módulos"}
              onChange={e => setModule(e.target.value)}
            />

            <TextField
              fullWidth
              margin="dense"
              value={observations}
              label={"Observaciones"}
              style={{ marginBottom: 10 }}
              onChange={e => setObservations(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={props.onClose}>
          Cancelar
        </Button>

        <LoadingButton
          loading={props.sending}
          onClick={onSubmit}
          disabled={!isValid}
          color="primary">
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EditModal);
