import { connect } from "react-redux";

import { getGroups } from "../../../store/ducks/groups";
import { closeModal } from "../../../store/ducks/addComplementaryModal";
import { openModal as openCivilTaskModal } from "../../../store/ducks/newCivilTaskDialog";

import AddConfirmationModal from "./addComplementaryTaskModalContainer/index";

const mapStateToProps = state => ({
  task: state.task,
  open: state.addComplementaryModal.modalOpen,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  getGroups: () => dispatch(getGroups()),
  openCivilTaskModal: task => dispatch(openCivilTaskModal(task)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddConfirmationModal);
