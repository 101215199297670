import { connect } from "react-redux";

import {
  getTasks,
  mapSelectedHidrojetsForDownload,
} from "../../../store/ducks/hidrojetList";
import { openModal } from "../../../store/ducks/downloadTasks";
import {
  changeOdt,
  changeState,
  changeGroup,
  changeDateTo,
  changeDateFrom,
  toggleShowSelectedTasks,
} from "../../../store/ducks/hidrojetListFilter";

import Toolbar from "./toolbarContainer/Toolbar";

const mapStateToProps = state => ({
  tasks: mapSelectedHidrojetsForDownload(state),
  selectedCount: state.selectedTasks.length,
  odt: state.hidrojetListFilter.odt,
  group: state.hidrojetListFilter.group,
  dateFrom: state.hidrojetListFilter.dateFrom,
  dateTo: state.hidrojetListFilter.dateTo,
  groups: state.groups.groups,
  state: state.hidrojetListFilter.state,
  showSelectedTasks: state.hidrojetListFilter.showSelectedTasks,
});

const mapDispatchToProps = dispatch => ({
  openDownloadModal: () => dispatch(openModal()),
  getTasks: () => dispatch(getTasks(1, false)),
  changeOdt: odt => dispatch(changeOdt(odt)),
  changeGroup: group => {
    dispatch(changeGroup(group));
    dispatch(getTasks(1, false));
  },
  changeDateFrom: date => {
    dispatch(changeDateFrom(date));
    dispatch(getTasks(1, false));
  },
  changeState: boolean => {
    dispatch(changeState(boolean));
    dispatch(getTasks(1, false));
  },
  changeDateTo: date => {
    dispatch(changeDateTo(date));
    dispatch(getTasks(1, false));
  },
  toggleShowSelectedTasks: () => dispatch(toggleShowSelectedTasks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
