import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";

import Title from "./task/Title";
import Header from "./task/header";
import TaskInfo from "./task/TaskInfo";
import TaskImages from "./task/TaskImages";
import Preloader from "../../components/UI/Preloader";

import TaskSidewalk from "./task/TaskSidewalkContainer";
import AddSidewalkModal from "./task/AddSidewalkModalContainer";
import EditSidewalkModal from "./task/EditSidewalkModalContainer";
import UndoValidationModal from "./task/UndoValidationModalContainer";
import ConfirmValidationModal from "./task/ConfirmValidationModalContainer";
import ChangeState from "../taskValidationContainer/task/FinishValidationModalContainer";
import ScheduleDialog from "../taskListContainer/taskList/tasks/ScheduleDialogContainer";

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
});

const task = props => {
  const { classes } = props;

  return props.isFetching || !props.task.otCode ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        style={{ padding: 20 }}>
        <Grid item xs={12}>
          <Header {...props} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Paper variant="outlined" style={{ padding: "15px 0", marginBottom: 10 }}>
            <Title odt={props.task.otCode} />
            <TaskInfo task={props.task} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper variant="outlined" style={{ marginBottom: 10, padding: 10 }}>
            <TaskImages
              images={
                props.task.parentTask
                  ? props.task.sidewalk_photos.concat(props.task.parentTask.fotos)
                  : props.task.sidewalk_photos
              }
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <TaskSidewalk task={props.task} />
        </Grid>
      </Grid>
      <ScheduleDialog />
      <ChangeState />
      <AddSidewalkModal />
      <EditSidewalkModal />
      <UndoValidationModal />
      <ConfirmValidationModal />
    </div>
  );
};

export default withStyles(styles)(task);
