import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

const header = props => {
  const { onCheck, rows, selectedCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="default"
            indeterminate={selectedCount > 0 && selectedCount < rows}
            checked={selectedCount === rows && rows !== 0}
            onChange={onCheck}
            disabled={rows === 0}
          />
        </TableCell>
        <TableCell padding="none">ODT</TableCell>
        <TableCell>Grupo</TableCell>
        <TableCell>Cuadrilla actual</TableCell>
        <TableCell>Domicilio</TableCell>
        <TableCell title="Fech validación">Fecha val.</TableCell>
        <TableCell>Descripción</TableCell>
        <TableCell>Módulo</TableCell>
        <TableCell padding="none" />
        <TableCell padding="none" />
      </TableRow>
    </TableHead>
  );
};

export default header;
