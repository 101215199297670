import axios from "../../axios";
import { enqueueError, enqueueMessage } from "./notifications";
import { openModal as openStateChangedTasksModal } from "./statedChangedTasksModal";
import { getGroupGangs } from "./groupGangs";

const types = {
  UNASSIGN_GANG_MODAL_OPEN: "UNASSIGN_GANG_MODAL_OPEN",
  UNASSIGN_GANG_MODAL_CLOSE: "UNASSIGN_GANG_MODAL_CLOSE",
  UNASSIGN_GANG_MODAL_REQUEST: "UNASSIGN_GANG_MODAL_REQUEST",
  UNASSIGN_GANG_MODAL_SUCCESS: "UNASSIGN_GANG_MODAL_SUCCESS",
  UNASSIGN_GANG_MODAL_FAILURE: "UNASSIGN_GANG_MODAL_FAILURE",
};

export const openModal = gang => ({
  type: types.UNASSIGN_GANG_MODAL_OPEN,
  gang,
});

export const closeModal = () => ({
  type: types.UNASSIGN_GANG_MODAL_CLOSE,
});

const request = () => ({
  type: types.UNASSIGN_GANG_MODAL_REQUEST,
});

const success = () => ({
  type: types.UNASSIGN_GANG_MODAL_SUCCESS,
});

const failure = () => ({
  type: types.UNASSIGN_GANG_MODAL_FAILURE,
});

export const unnasignGang = () => async (dispatch, getState) => {
  const {
    unnasignGangModal: { gang },
    groupGangs: { group: grupo },
  } = getState();

  dispatch(request());
  try {
    const { data } = await axios.patch(`/gangs/${gang.id}/unassignGroup`, {});

    const { tasks } = data;
    dispatch(getGroupGangs(grupo.id, true));

    if (tasks && tasks.length) {
      dispatch(openStateChangedTasksModal(tasks));
    }

    dispatch(enqueueMessage(`La cuadrilla fue eliminada correctamente del grupo.`));
    dispatch(success());
  } catch (error) {
    dispatch(enqueueError("Ocurrió un error al eliminar a la cuadrilla del grupo."));
    dispatch(failure());
  }
};

const initialState = {
  modalOpen: false,
  sending: false,
  gang: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UNASSIGN_GANG_MODAL_OPEN:
      return { ...state, modalOpen: true, gang: action.gang };
    case types.UNASSIGN_GANG_MODAL_CLOSE:
      return { ...state, modalOpen: false };
    case types.UNASSIGN_GANG_MODAL_REQUEST:
      return { ...state, sending: true };
    case types.UNASSIGN_GANG_MODAL_SUCCESS:
      return { ...state, sending: false, modalOpen: false };
    case types.UNASSIGN_GANG_MODAL_FAILURE:
      return { ...state, sending: false };
    default:
      return state;
  }
};

export default reducer;
