import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { useMediaQuery, useTheme } from "@mui/material";

const styles = theme => ({
  form: {
    marginBottom: theme.spacing(1.5),
  },
  input: {
    marginTop: theme.spacing(3),
  },
  confirmBtn: {
    width: 110,
  },
});

const CancelDialog = props => {
  const { classes, closeModal, openModal } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog fullScreen={isMobile} open={openModal} onClose={closeModal}>
      <DialogTitle>Cancelar tarea {props.task?.codigo_ot}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Dirección: {props.task?.ubicacion}
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <TextField
              value={props.comment}
              onChange={e => props.changeComment(e.target.value)}
              className={classes.input}
              label="Observaciones"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal} disabled={props.loading}>
          Cerrar
        </Button>

        <LoadingButton loading={props.loading} color="primary" onClick={props.confirm}>
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CancelDialog);
