import { connect } from "react-redux";

import TaskTypes from "./taskTypesContainer/TaskTypes";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";
import { removeTypes, openModal } from "../../../../../store/ducks/taskListTypesFilter";

const mapStateToProps = state => ({
  types: state.taskListTypesFilter.selectedTypes,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeTypes: () => {
    dispatch(removeTypes());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskTypes);
