import escapeStringRegexp from "escape-string-regexp";

const types = {
  ARTICLES_FILTERS_TOGGLE_SHOW_ALL: "ARTICLES_FILTERS_TOGGLE_SHOW_ALL",
  ARTICLES_FILTERS_CHANGE_NAME: "ARTICLES_FILTERS_CHANGE_NAME",
  ARTICLES_FILTERS_CHANGE_PAGE: "ARTICLES_FILTERS_CHANGE_PAGE",
  ARTICLES_FILTERS_CHANGE_PAGE_ROWS: "ARTICLES_FILTERS_CHANGE_PAGE_ROWS",
};

export const toggleShowAll = () => ({
  type: types.ARTICLES_FILTERS_TOGGLE_SHOW_ALL,
});

export const changeArticleFilter = article => ({
  type: types.ARTICLES_FILTERS_CHANGE_NAME,
  article,
});

export const changePage = (event, page) => ({
  type: types.ARTICLES_FILTERS_CHANGE_PAGE,
  page,
});

export const changePageRows = event => ({
  type: types.ARTICLES_FILTERS_CHANGE_PAGE_ROWS,
  rows: event.target.value,
});

const initialState = {
  page: 0,
  rowsPerPage: 10,
  showAll: false,
  name: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ARTICLES_FILTERS_TOGGLE_SHOW_ALL:
      return { ...state, showAll: !state.showAll, page: 0 };
    case types.ARTICLES_FILTERS_CHANGE_NAME:
      return { ...state, name: action.article };
    case types.ARTICLES_FILTERS_CHANGE_PAGE:
      return { ...state, page: action.page };
    case types.ARTICLES_FILTERS_CHANGE_PAGE_ROWS:
      return { ...state, rowsPerPage: action.rows };
    default:
      return state;
  }
};

export default reducer;

export const getFilteredArticles = state => {
  const regex = new RegExp(`.*${escapeStringRegexp(state.articlesFilters.name)}.*`, "i");
  return state.task.articles
    .filter(
      a => a.name.match(regex) && (state.articlesFilters.showAll || a.state.id === 0)
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};
