import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";

import Header from "./gangsReorderTasks/Header";
import Preloader from "../../components/UI/Preloader";
import ExecutionTask from "./gangsReorderTasks/ExecutionTask";
import ReorderTaskList from "./gangsReorderTasks/ReorderTaskList";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  container: {
    marginBottom: theme.spacing(3),
  },
});

const gangsReorderTasks = props => {
  const { classes } = props;

  return props.isFetching ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Header {...props} />
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <ExecutionTask />
          </Grid>

          <Grid item xs={12} md={8}>
            <ReorderTaskList {...props} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(gangsReorderTasks);
