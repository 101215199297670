import axios from "../../axios";
import apollo from "../../apollo";

import escapeStringRegexp from "escape-string-regexp";
import { GET_GANGS } from "../../graphql/gangs/queries";
import { removeAccents } from "../../utils/removeAccents";

const types = {
  GROUP_GANGS_REQUEST: "GROUP_GANGS_REQUEST",
  GROUP_GANGS_SUCCESS: "GROUP_GANGS_SUCCESS",
  GROUP_GANGS_FAILURE: "GROUP_GANGS_FAILURE",
  GROUP_GANG_CHANGE_SEARCH: "GROUP_GANG_CHANGE_SEARCH",
};

const parseVariables = object => {
  Object.keys(object).forEach(k => (object[`$${k}`] = object[k]));
  return object;
};

const requestGroupGangs = () => ({
  type: types.GROUP_GANGS_REQUEST,
});

const successGroupGangs = (group, gangs) => ({
  type: types.GROUP_GANGS_SUCCESS,
  group,
  gangs,
});

const failedGroupGangs = () => ({
  type: types.GROUP_GANGS_FAILURE,
  message: "Ha ocurrido un error al intentar obtener las cuadrillas del grupo",
});

export const changeSearchGroupGangs = search => ({
  type: types.GROUP_GANG_CHANGE_SEARCH,
  search,
});

export const getGroupGangs = (id, setLoading) => {
  return async dispatch => {
    if (!setLoading) {
      dispatch(requestGroupGangs());
    }

    try {
      const promises = [
        axios.get(`/groups/${id}`),

        apollo.query({
          query: GET_GANGS,
          variables: parseVariables({
            where: { column: "GRUPO", operator: "EQ", value: id },
          }),
        }),
      ];
      const [groupData, gangsData] = await Promise.all(promises);

      const group = groupData.data.data;

      const gangs = gangsData.data.gangs.sort((a, b) =>
        a.cuadrilla.localeCompare(b.cuadrilla)
      );

      if (group.status === "NotFound") {
        dispatch(failedGroupGangs());
      } else {
        dispatch(successGroupGangs(group, gangs));
      }
    } catch (error) {
      dispatch(failedGroupGangs());
    }
  };
};

const initialState = { search: "", gangs: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROUP_GANGS_SUCCESS:
      return { ...state, gangs: action.gangs, group: action.group };

    case types.GROUP_GANG_CHANGE_SEARCH:
      return { ...state, search: action.search };

    default:
      return state;
  }
};

export default reducer;

export const getFilteredGangGroups = state => {
  const { search, gangs } = state.groupGangs;
  const regex = new RegExp(`.*${escapeStringRegexp(removeAccents(search))}.*`, "i");
  return gangs.filter(({ cuadrilla }) => removeAccents(cuadrilla).match(regex));
};
