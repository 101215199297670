import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
});

const title = ({ odt, classes }) => {
  return (
    <Typography variant="h5" component="h3" className={classes.title}>
      ODT {odt}
    </Typography>
  );
};

export default withStyles(styles)(title);
