import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const getShow = props => {
  return (
    props.dateFrom ||
    props.dateTo ||
    Object.keys(props.states).filter(k => props.states[k]).length !==
      Object.keys(props.states).length
  );
};

const chips = props => {
  const { classes } = props;
  const show = getShow(props);

  return (
    show && (
      <li>
        <Chip
          onClick={props.openModal}
          className={classes.chip}
          label="Cambios de estado"
          onDelete={() => {
            props.removeStateChanges();
          }}
        />
      </li>
    )
  );
};

export default withStyles(styles)(chips);
