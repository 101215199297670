import { Component } from "react";
import DownloadTasksDialog from "./downloadTaskDialogContainer";

import { connect } from "react-redux";
import {
  closeModal,
  handleDownload,
} from "../../../../store/ducks/taskListDownloadTasks";

class DownloadTasksContainer extends Component {
  handleSubmit = () => {
    this.props.handleDownload();
  };

  handleCancel = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <DownloadTasksDialog
        {...this.props}
        handleCancel={this.handleCancel}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.taskListDownloadTasks.openModal,
  isLoading: state.taskListDownloadTasks.isLoading,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  handleDownload: () => dispatch(handleDownload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadTasksContainer);
