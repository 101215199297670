import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import GangIcon from "@mui/icons-material/Person";
import ListItem from "@mui/material/ListItem";
import DateIcon from "@mui/icons-material/DateRange";
import ModuleIcon from "@mui/icons-material/Category";
import InspectorIcon from "@mui/icons-material/HowToReg";
import AddressIcon from "@mui/icons-material/LocationOn";
import GroupIcon from "@mui/icons-material/LocationCity";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DescriptionIcon from "@mui/icons-material/Description";

const taskInfo = props => {
  return (
    <List component="nav" disablePadding style={{ marginBottom: 30, marginTop: 30 }}>
      <ListItem dense>
        <Tooltip title="Grupo">
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.group.name} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Última cuadrilla">
          <ListItemIcon>
            <GangIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.gang.name} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Dirección">
          <ListItemIcon>
            <AddressIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.address} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Tarea">
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.task} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Fecha finalización">
          <ListItemIcon>
            <DateIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.task.date} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Módulo">
          <ListItemIcon>
            <ModuleIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={`Modulo: ${props.task.module || "-"}`} />
      </ListItem>
      <ListItem dense>
        <Tooltip title="Inspector">
          <ListItemIcon>
            <InspectorIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={`Inspector: ${props.task.inspector || "-"}`} />
      </ListItem>
    </List>
  );
};

export default taskInfo;
