import { connect } from "react-redux";
import EditGroupDialog from "./EditGroupDialog";
import { closeModal, editGroup } from "../../../store/ducks/editGroupModal";

const mapStateToProps = state => ({
  group: state.editGroupModal.group,
  sending: state.editGroupModal.sending,
  openModal: state.editGroupModal.modalOpen,
  cities: state.cities,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  edit: (id, data) => dispatch(editGroup(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupDialog);
