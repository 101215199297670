import { connect } from "react-redux";

import {
  closeModal,
  getGangs,
  changeComment,
  changeGang,
  confirm,
} from "../../../../store/ducks/taskListAssignTask";
import AssignDialog from "./assignDialogContainer/AssignDialog";

const mapStateToProps = state => ({
  openModal: state.taskListAssignTask.openModal,
  loading: state.taskListAssignTask.loading,
  gangs: getGangs(state),
  gang: state.taskListAssignTask.gang,
  gangError: state.taskListAssignTask.gangError,
  task: state.taskListAssignTask.task,
  stores: state.storesList,
  roles: state.user.roles,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  confirm: () => dispatch(confirm()),
  changeGang: gang => dispatch(changeGang(gang)),
  changeComment: comment => dispatch(changeComment(comment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignDialog);
