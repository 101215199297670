import { connect } from "react-redux";

import {
  openModal,
  removeOdt,
  removeTask,
  removeComment,
  removeOperationZone,
  removeCertificate,
} from "../../../../../store/ducks/taskListTaskFilter";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

import Task from "./taskContainer/Task";

const mapStateToProps = state => ({
  odt: state.taskListTaskFilter.odt,
  comment: state.taskListTaskFilter.comment,
  task: state.taskListTaskFilter.task,
  certificate: state.taskListTaskFilter.certificate,
  operationZone: state.taskListTaskFilter.operationZone,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeOdt: () => {
    dispatch(removeOdt());
    dispatch(getTasksFromGraphql());
  },
  removeTask: () => {
    dispatch(removeTask());
    dispatch(getTasksFromGraphql());
  },
  removeComment: () => {
    dispatch(removeComment());
    dispatch(getTasksFromGraphql());
  },
  removeOperativeZone: () => {
    dispatch(removeOperationZone());
    dispatch(getTasksFromGraphql());
  },
  removeCertificate: () => {
    dispatch(removeCertificate());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Task);
