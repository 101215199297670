import { Component } from "react";
import { connect } from "react-redux";

import ApiError from "../components/UI/ApiError";
import { getGroups } from "../store/ducks/groups";
import { getTasksSidewalkValidation } from "../store/ducks/tasksValidation";
import { createLoadingSelector, createErrorMessageSelector } from "../utils/selectors";

import TaskList from "./taskSidewalkValidationListContainer/TaskList";

class TaskSidewalkValidationListContainer extends Component {
  componentDidMount() {
    this.props.getTasks();
    this.props.getGroups();
  }

  render() {
    const { error } = this.props;
    return !error ? <TaskList {...this.props} /> : <ApiError error={error} />;
  }
}

const actionNames = ["TASKS_VALIDATION", "GROUPS"];
const loadingSelector = createLoadingSelector(actionNames);
const errorSelector = createErrorMessageSelector(actionNames);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(getGroups()),
  getTasks: () => dispatch(getTasksSidewalkValidation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskSidewalkValidationListContainer);
