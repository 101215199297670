const types = {
  TASK_LIST_DATE_FILTERS_OPEN_MODAL: "TASK_LIST_DATE_FILTERS_OPEN_MODAL",
  TASK_LIST_DATE_FILTERS_CLOSE_MODAL: "TASK_LIST_DATE_FILTERS_CLOSE_MODAL",
  TASK_LIST_DATE_FILTER_SAVE: "TASK_LIST_DATE_FILTER_SAVE",
  TASK_LIST_DATE_FILTER_REMOVE_CREATION: "TASK_LIST_DATE_FILTER_REMOVE_CREATION",
  TASK_LIST_DATE_FILTER_REMOVE_INCOME: "TASK_LIST_DATE_FILTER_REMOVE_INCOME",
  TASK_LIST_DATE_FILTER_REMOVE_ASSIGNED: "TASK_LIST_DATE_FILTER_REMOVE_ASSIGNED",
};

export const openModal = () => ({
  type: types.TASK_LIST_DATE_FILTERS_OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.TASK_LIST_DATE_FILTERS_CLOSE_MODAL,
});

export const save = filters => ({
  type: types.TASK_LIST_DATE_FILTER_SAVE,
  filters,
});

export const removeCreationDate = () => ({
  type: types.TASK_LIST_DATE_FILTER_REMOVE_CREATION,
});

export const removeIncomeDate = () => ({
  type: types.TASK_LIST_DATE_FILTER_REMOVE_INCOME,
});

export const removeAssignedDate = () => ({
  type: types.TASK_LIST_DATE_FILTER_REMOVE_ASSIGNED,
});

export const removeDates = () => async dispatch => {
  dispatch(removeAssignedDate());
  dispatch(removeIncomeDate());
  dispatch(removeCreationDate());
};

const initialState = {
  openModal: false,
  creationFrom: null,
  creationTo: null,
  incomeFrom: null,
  incomeTo: null,
  assignedFrom: null,
  assignedTo: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_LIST_DATE_FILTERS_OPEN_MODAL:
      return { ...state, openModal: true };
    case types.TASK_LIST_DATE_FILTERS_CLOSE_MODAL:
      return { ...state, openModal: false };
    case types.TASK_LIST_DATE_FILTER_SAVE:
      return { ...state, openModal: false, ...action.filters };
    case types.TASK_LIST_DATE_FILTER_REMOVE_CREATION:
      return { ...state, creationFrom: null, creationTo: null };
    case types.TASK_LIST_DATE_FILTER_REMOVE_INCOME:
      return { ...state, incomeFrom: null, incomeTo: null };
    case types.TASK_LIST_DATE_FILTER_REMOVE_ASSIGNED:
      return { ...state, assignedFrom: null, assignedTo: null };
    default:
      return state;
  }
};

export default reducer;
