import { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab";
import { useMediaQuery, useTheme } from "@mui/material";

const styles = () => ({
  text: {
    textAlign: "center",
  },

  confirmBtn: {
    width: 110,
  },
  input: {
    marginTop: 10,
    marginBottom: 20,
  },
});

const GroupsDialog = props => {
  const { classes, closeModal, openModal, group } = props;

  const [selected, setSelected] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setSelected(false);
  }, [openModal]);

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth={true}>
      <DialogTitle>Agregar Cuadrilla al grupo</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Seleccione la cuadrilla a agregar al grupo <i>{group ? group.nombre : ""}</i>.
          En este listado solo se muestran las cuadrillas que actualmente no se encuentran
          asignadas a ningun grupo.
        </DialogContentText>

        <Autocomplete
          options={props.gangs}
          loading={props.loading}
          loadingText="Cargando..."
          getOptionLabel={option => option.name}
          noOptionsText="No se encontraron cuadrillas"
          onChange={(_, value) => {
            setSelected(value);
          }}
          renderInput={params => (
            <TextField
              {...params}
              className={classes.input}
              label="Cuadrilla"
              variant="outlined"
            />
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeModal} style={{ color: "black" }}>
          Cancelar
        </Button>

        <LoadingButton
          color="primary"
          disabled={!selected}
          loading={props.sending}
          className={classes.confirmBtn}
          onClick={() => props.add(selected)}>
          Agregar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(GroupsDialog);
