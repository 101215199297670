import Chips from "./filters/Chips";
import TaskDialogContainer from "./filters/TaskDialogContainer";
import FilterListContainer from "./filters/FilterListContainer";
import GangsDialogContainer from "./filters/GangsDialogContainer";
import ViewOptionsContainer from "./filters/ViewOptionsContainer";
import DatesDialogContainer from "./filters/DatesDialogContainer";
import OrderDialogContainer from "./filters/OrderDialogContainer";
import DownloadListContainer from "./filters/DownloadListContainer";
import StatesDialogContainer from "./filters/StatesDialogContainer";
import GroupsDialogContainer from "./filters/GroupsDialogContainer";
import CitiesDialogContainer from "./filters/CitiesDialogContainer";
import AddressDialogContainer from "./filters/AddressDialogContainer";
import HidrojetDialogContainer from "./filters/HidrojetDialogContainer";
import SidewalkDialogContainer from "./filters/SidewalkDialogContainer";
import TaskTypesDialogContainer from "./filters/TaskTypesDialogContainer";
import ContractorDialogContainer from "./filters/ContractorDialogContainer";
import StateChangesDialogContainer from "./filters/StateChangesDialogContainer";
import DownloadAbasaDialogContainer from "./filters/DownloadAbsaDialogContainer";
import DownloadTasksDialogContainer from "./filters/DownloadTasksDialogContainer";
import HistoryChangesDialogContainer from "./filters/HistoryChangesDialogContainer";
import TaskConditionsDialogContainer from "./filters/TaskConditionsDialogContainer";
import DonwloadMaterialsDialogContainer from "./filters/DownloadMaterialsDialogContainer";

const filters = () => {
  return (
    <>
      <Chips />
      <TaskDialogContainer />
      <FilterListContainer />
      <ViewOptionsContainer />
      <GangsDialogContainer />
      <DatesDialogContainer />
      <OrderDialogContainer />
      <DownloadListContainer />
      <GroupsDialogContainer />
      <StatesDialogContainer />
      <CitiesDialogContainer />
      <AddressDialogContainer />
      <HidrojetDialogContainer />
      <SidewalkDialogContainer />
      <TaskTypesDialogContainer />
      <ContractorDialogContainer />
      <StateChangesDialogContainer />
      <DownloadAbasaDialogContainer />
      <DownloadTasksDialogContainer />
      <TaskConditionsDialogContainer />
      <HistoryChangesDialogContainer />
      <DonwloadMaterialsDialogContainer />
    </>
  );
};

export default filters;
