import { useEffect, useRef, useState } from "react";
import { Map, TileLayer, Marker } from "react-leaflet";

import "./style.css";

export const MAP = {
  zoom: 14,
  maxZoom: 18,
  center: [-34.9210593, -57.9541325],
  attribution:
    '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  style: {
    height: 300,
    width: "100%",
    borderRadius: 5,
  },
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default ({ marker, onMarkerMove }) => {
  const [center, setCenter] = useState(MAP.center);
  const [position, setPosition] = useState(marker);

  const prevCount = usePrevious(marker);

  useEffect(() => {
    if (!prevCount || prevCount === marker) {
      setCenter(marker);
      setPosition(marker);
    }
  }, [marker, prevCount]);

  const onMoveEnd = () => {
    onMarkerMove(position);
  };

  const onMove = ({ latlng }) => {
    const data = { lat: latlng.lat, lng: latlng.lng };
    setPosition(data);
  };

  return (
    <>
      <Map zoom={MAP.zoom} center={center} style={MAP.style} maxZoom={MAP.maxZoom}>
        <TileLayer attribution={MAP.attribution} url={MAP.url} />
        {marker ? (
          <Marker draggable position={marker} onmove={onMove} onmoveend={onMoveEnd} />
        ) : null}
      </Map>
      {position ? (
        <div className="positionRef">
          <div>
            <small>Latitud: {position.lat}</small>
          </div>
          <div>
            <small>Longitud: {position.lng}</small>
          </div>
        </div>
      ) : null}
    </>
  );
};
