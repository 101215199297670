import { connect } from "react-redux";
import { closeModal, finishEdition } from "../../../../../store/ducks/editGang";

import EditDialog from "./editDialog";

const mapStateToProps = state => ({
  gang: state.editGang.gang,
  sending: state.editGang.sending,
  openModal: state.editGang.modalOpen,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  submit: data => dispatch(finishEdition(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);
