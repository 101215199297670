import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";

import TableSearchContainer from "../ToolbarContainer/ToolbarContainer";
import TableInfoContainer from "./TableInfoContainer";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
});

const GangsTable = ({ classes }) => {
  return (
    <Paper className={classes.root} elevation={0} variant="outlined">
      <TableSearchContainer />
      <TableInfoContainer />
    </Paper>
  );
};

export default withStyles(styles)(GangsTable);
