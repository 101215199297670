import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
});

const parseAddress = props => {
  let address = "";
  if (props.street) address += `Calle ${props.street}`;
  if (props.number) address += ` n° ${props.number}`;
  if (props.between) address += ` entre ${props.between}`;
  if (props.and) address += ` y ${props.and}`;
  return address;
};

const chips = props => {
  const { classes } = props;
  const address = parseAddress(props);

  return (
    address && (
      <li>
        <Chip
          onClick={props.openModal}
          className={classes.chip}
          label={address}
          onDelete={() => {
            props.removeAddress();
          }}
        />
      </li>
    )
  );
};

export default withStyles(styles)(chips);
