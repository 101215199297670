import { connect } from "react-redux";
import TransferGangDialog from "./TransferGangDialog";
import UntransferableGangDialog from "./UntranferableGangDialog";
import { closeModal, transferGang } from "../../../store/ducks/transferGangModal";
import { getGroups } from "../../../store/ducks/groups";
import { Component } from "react";

const mapStateToProps = state => ({
  openModal: state.transferGangModal.modalOpen,
  sending: state.transferGangModal.sending,
  gang: state.transferGangModal.gang,
  groups: state.groups.groups,
  group: state.groupGangs.group,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  transfer: group => dispatch(transferGang(group)),
  getGroups: () => dispatch(getGroups(undefined, true)),
});

class TransferGangDialogContainer extends Component {
  componentDidMount() {
    this.props.getGroups();
  }

  render() {
    const { gang } = this.props;

    const Dialog =
      gang && gang.tareasEnEjecucionCount ? UntransferableGangDialog : TransferGangDialog;

    return <Dialog {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferGangDialogContainer);
