/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import makeStyles from "@mui/styles/makeStyles";
import { CloudUpload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  IconButton,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { useDebounce } from "../../../../utils/useDebounce";

// redux actions
import { resetFilters } from "../../../../store/ducks/tasksList";
import { showHistoric } from "../../../../store/ducks/taskListHistoricFilter";
import { save as setODT } from "../../../../store/ducks/taskListTaskFilter";
import { checkTaskExistance, clearTaskExistance } from "../../../../store/ducks/newTask";

// Components
import CroquisItem from "./croquisItem";

const useStyles = value =>
  makeStyles(() => ({
    root: {
      "& .Mui-error": {
        color: acquireValidationColor(value),
      },
      "& .MuiFormHelperText-root": {
        color: acquireValidationColor(value),
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": { borderColor: acquireValidationColor(value) },
      },
    },
  }));

const acquireValidationColor = message => {
  if (!message) return "#c4c4c4";
  switch (message) {
    case "Tené en cuenta que este código tambien fue usado en otras tareas.":
      return "orange";
    default:
      return "red";
  }
};

export const EditTaskStep1 = props => {
  const {
    form: {
      watch,
      control,
      register,
      setValue,
      setError,
      setFocus,
      getValues,
      clearErrors,
      formState: { errors },
    },
  } = props;

  const taskTypes = useSelector(state => state.taskTypes);

  useEffect(() => {
    register("step1.croquis");
    // For Autocomplete - According to https://react-hook-form.com/get-started#IntegratingwithUIlibraries
    if (props.task.estado_id === 1) {
      register("step1.tipo", { required: true });
    }

    setFocus("step1.codigo_ot");
  }, [register]);

  const type = watch("step1.tipo");
  const odt = watch("step1.codigo_ot");
  const croquis = watch("step1.croquis");
  const operativeZone = watch("step1.operative_zone");

  const dispatch = useDispatch();
  const debouncedOdt = useDebounce(odt, 1000);
  const existTask = useSelector(({ newTask }) => newTask.existTask);

  useEffect(() => {
    if (debouncedOdt) {
      dispatch(checkTaskExistance(debouncedOdt, props.task.id));
    } else {
      dispatch(clearTaskExistance());
    }
  }, [debouncedOdt]);

  const handleFileSelected = useCallback(
    e => {
      let files = Array.from(e.target.files);
      if (files[0] && files[0].type === "application/pdf") {
        files = [files[0]];
      }
      setValue("step1.croquis", files);
    },
    [setValue]
  );

  const errorMessage = useCallback(() => {
    if (errors.step1?.codigo_ot?.message) return errors.step1?.codigo_ot?.message;
    if (existTask)
      return "Tené en cuenta que este código tambien fue usado en otras tareas.";
    return "";
  }, [errors, existTask]);

  const getAction = useCallback(
    message => {
      switch (message) {
        case "Tené en cuenta que este código tambien fue usado en otras tareas.":
          return (
            <Link
              to="route"
              target="_blank"
              style={{
                marginLeft: 4,
                color: "orange",
                cursor: "pointer",
              }}
              onClick={async e => {
                e.preventDefault();
                await dispatch(resetFilters());
                await dispatch(showHistoric());
                await dispatch(setODT({ odt }));
                setTimeout(() => {
                  window.open(`${process.env.PUBLIC_URL}`);
                }, 1001);
              }}>
              Ir a verlas.
            </Link>
          );
        default:
          return null;
      }
    },
    [dispatch]
  );
  const classes = useStyles(errorMessage())();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={props.task.estado_id === 1 ? 6 : 12}>
        <Controller
          control={control}
          rules={{
            required: "Debes ingresar un código OT",
            minLength: { value: 7, message: "Debe tener al menos 7 caracteres." },
            maxLength: { value: 50, message: "Debe tener menos de 50 caracteres." },
          }}
          name="step1.codigo_ot"
          render={({ field }) => (
            <TextField
              required
              fullWidth
              autoFocus
              margin="dense"
              label="Código OT"
              variant="outlined"
              className={classes.root}
              helperText={
                <div>
                  <span>{errorMessage()}</span>
                  {getAction(errorMessage())}
                </div>
              }
              error={errors.step1?.codigo_ot}
              inputProps={{ maxLength: 50, minLength: 7, autoFocus: true }}
              {...field}
            />
          )}
        />
      </Grid>

      {props.task.estado_id === 9 ? (
        <Grid item xs={12} lg={6}>
          <Controller
            control={control}
            name="step1.certificate"
            render={({ field }) => (
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                label="Certificado"
                className={classes.root}
                {...field}
              />
            )}
          />
        </Grid>
      ) : null}

      {props.task.estado_id === 1 ? (
        <Grid item xs={12} lg={6}>
          <Autocomplete
            variant="outlined"
            control={control}
            options={taskTypes}
            style={{ width: "100%" }}
            noOptionsText="Sin Resultados"
            value={getValues("step1.tipo")}
            getOptionLabel={option => option.name}
            defaultValue={{ id: props.task.tipo.id, name: props.task.tipo.tipo }}
            onChange={(e, value) => {
              e.stopPropagation();
              if (value) {
                setValue("step1.tipo", value);
                clearErrors("step1.tipo");
                if (value.id === 9) {
                  setValue("step1.valida_materiales", false);
                }
                if (value.id === 10) {
                  setValue("step1.requires_cleaning", false);
                }
              } else {
                setError("step1.tipo", {
                  type: "required",
                  message: "Debes indicar el tipo de Tarea.",
                });
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                required
                margin="dense"
                variant="outlined"
                label="Tipo de Tarea"
                error={errors.step1?.tipo}
                helperText={errors.step1?.tipo?.message}
              />
            )}
          />
        </Grid>
      ) : null}

      <Grid item xs={12} lg={6}>
        <Controller
          required
          control={control}
          name="step1.ingreso_ot"
          render={({ field }) => (
            <DatePicker
              {...field}
              fullWidth
              showTodayButton
              margin="dense"
              todayText="Hoy"
              clearText="Borrar"
              cancelText="Cancelar"
              inputVariant="outlined"
              label="Fecha de Ingreso"
              inputFormat="dd/MM/yyyy"
              renderInput={params => <TextField {...params} style={{ width: "100%" }} />}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          required
          control={control}
          name="step1.fecha_asignada"
          render={({ field }) => (
            <DatePicker
              {...field}
              fullWidth
              showTodayButton
              margin="dense"
              todayText="Hoy"
              clearText="Borrar"
              cancelText="Cancelar"
              inputVariant="outlined"
              label="Fecha de Asignación"
              inputFormat="dd/MM/yyyy"
              renderInput={params => <TextField {...params} style={{ width: "100%" }} />}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <Controller
          control={control}
          name="step1.modulo"
          render={({ field }) => (
            <TextField
              fullWidth
              label="Módulo"
              margin="dense"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">MOD.</InputAdornment>,
              }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <FormControl
          margin="dense"
          variant="outlined"
          style={{ width: "100%" }}
          disabled={type ? type.id === 9 : false}>
          <InputLabel id="input-requires-materials">Requiere materiales</InputLabel>
          <Controller
            render={({ field }) => (
              <Select
                {...field}
                labelId="input-requires-materials"
                label="Requiere materiales">
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Si</MenuItem>
              </Select>
            )}
            name="step1.valida_materiales"
            control={control}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <FormControl
          margin="dense"
          variant="outlined"
          style={{ width: "100%" }}
          disabled={type ? type.id === 10 : false}>
          <InputLabel id="input-requires-cleaning">Requiere limpieza</InputLabel>
          <Controller
            render={({ field }) => (
              <Select
                {...field}
                labelId="input-requires-cleaning"
                label="Requiere limpieza">
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Si</MenuItem>
              </Select>
            )}
            name="step1.requires_cleaning"
            control={control}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          defaultValue=""
          control={control}
          name="step1.operative_zone"
          render={({ field }) => (
            <TextField
              fullWidth
              variant="outlined"
              label="Zona operativa"
              error={operativeZone ? !/^[A-Z0-9]+$/.test(operativeZone) : false}
              helperText={
                operativeZone && !/^[A-Z0-9]+$/.test(operativeZone)
                  ? "Solo puede contener caracteres alfanuméricos y en mayúscula."
                  : undefined
              }
              inputProps={{ maxLength: 10, minLength: 6 }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          control={control}
          name="step1.approved_by"
          render={({ field }) => (
            <TextField
              fullWidth
              variant="outlined"
              label="Autorizado por"
              inputProps={{ maxLength: 200, minLength: 5 }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Controller
          control={control}
          name="step1.tarea"
          rules={{
            required: "Debes ingresar una descripción.",
            minLength: { value: 5, message: "Debe tener al menos 5 caracteres." },
            maxLength: { value: 200, message: "Debe tener menos de 200 caracteres." },
          }}
          render={({ field }) => (
            <TextField
              required
              multiline
              fullWidth
              variant="outlined"
              error={errors.step1?.tarea}
              label="Descripción de la tarea"
              helperText={errors.step1?.tarea?.message}
              inputProps={{ maxLength: 200, minLength: 5 }}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name="step1.croquis"
          render={({ field }) => (
            <TextField
              disabled
              fullWidth
              label="Croquis"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <input
                      type="file"
                      multiple
                      name="step1.croquis"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      onChange={handleFileSelected}
                      accept="image/jpeg,image/gif,image/png,application/pdf"
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        size="large">
                        <CloudUpload />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
              {...field}
            />
          )}
        />

        {croquis && croquis.length
          ? croquis.map((item, index) => {
              return <CroquisItem {...props} key={index} item={item} />;
            })
          : null}
      </Grid>
    </Grid>
  );
};

export default EditTaskStep1;
