/* eslint-disable react/no-unescaped-entities */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const UndoValidationModal = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      open={props.open}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Deshacer validación de vereda</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontSize: 18 }}>
          Al confirmar esta acción, la información de vereda quedará nuevamente sin
          validar.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "black" }}
          onClick={props.onClose}
          disabled={props.sending}>
          Cancelar
        </Button>
        <Button color="primary" onClick={props.confirm} disabled={props.sending}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UndoValidationModal;
