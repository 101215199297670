import { connect } from "react-redux";

import {
  openModal,
  removeHidrojet,
} from "../../../../../store/ducks/taskListHidrojetFilter";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

import Hidrojet from "./hidrojetContainer/Hidrojet";

const mapStateToProps = state => ({
  dateTo: state.taskListHidrojetFilter.dateTo,
  dateFrom: state.taskListHidrojetFilter.dateFrom,
  withHidrojet: state.taskListHidrojetFilter.withHidrojet,
  minModule: state.taskListHidrojetFilter.hidrojetMinModule,
  maxModule: state.taskListHidrojetFilter.hidrojetMaxModule,
  hidrojetState: state.taskListHidrojetFilter.hidrojetState,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeHidrojet: () => {
    dispatch(removeHidrojet());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Hidrojet);
