import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";

import Title from "./task/Title";
import Header from "./task/header";
import TaskInfo from "./task/TaskInfo";
import TaskImages from "./task/TaskImages";
import TaskComment from "./task/TaskComment";
import Preloader from "../../components/UI/Preloader";
import AddModalContainer from "./task/AddModalContainer";
import EditModalContainer from "./task/EditModalContainer";
import UndoModalContainer from "./task/UndoModalContainer";
import RejectModalContainer from "./task/RejectModalContainer";
import TaskArticlesContainer from "./task/TaskArticlesContainer";
import ValidateModalContainer from "./task/ValidateModalContainer";
import AddConfirmationModalContainer from "./task/AddConfirmationModalContainer";
import EditConfirmationModalContainer from "./task/EditConfirmationModalContainer";
import FinishValidationModalContainer from "./task/FinishValidationModalContainer";
import AddComplementaryTaskModalContainer from "./task/AddComplementaryTaskModalContainer";
import FinishValidationErrorModalContainer from "./task/FinishValidationErrorModalContainer";
import NewCivilTaskDialogContainer from "../taskListContainer/taskList/tasks/CivilTaskDialogContainer";

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
});

const task = props => {
  const { classes } = props;
  return props.isFetching || !props.task.otCode ? (
    <Preloader />
  ) : (
    <div className={classes.root}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Header {...props} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Paper variant="outlined" style={{ marginBottom: 20 }}>
            <Title odt={props.task.otCode} />
            <TaskInfo task={props.task} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper variant="outlined" style={{ marginBottom: 20, padding: 10 }}>
            <TaskImages images={props.task.images} />
          </Paper>
        </Grid>
      </Grid>

      {props.task.comment ? (
        <Paper variant="outlined" className={classes.paper} style={{ marginBottom: 20 }}>
          <TaskComment text={props.task.comment} />
        </Paper>
      ) : null}
      {!props.task.error ? (
        <Paper variant="outlined" className={classes.paper}>
          <TaskArticlesContainer />
        </Paper>
      ) : null}
      <ValidateModalContainer />
      <RejectModalContainer />
      <EditModalContainer />
      <EditConfirmationModalContainer />
      <AddModalContainer />
      <AddConfirmationModalContainer />
      <UndoModalContainer />
      <FinishValidationModalContainer />
      <AddComplementaryTaskModalContainer />
      <FinishValidationErrorModalContainer />
      <NewCivilTaskDialogContainer shouldRedirect />
    </div>
  );
};

export default withStyles(styles)(task);
