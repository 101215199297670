import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";

import Header from "./Header";
import Body from "./Body";

const styles = theme => ({
  rootTable: {
    width: "96%",
    margin: "0 auto",
    overflowX: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
});

const sidewalkTable = props => {
  const { classes, sidewalks } = props;
  return (
    <div className={classes.rootTable}>
      <Table className={classes.table}>
        <Header />
        <Body
          page={1}
          rowsPerPage={100}
          sidewalks={sidewalks}
          openEditModal={props.openEditModal}
          openUndoModal={props.openUndoModal}
          openConfirmationModal={props.openConfirmationModal}
        />
      </Table>
    </div>
  );
};

export default withStyles(styles)(sidewalkTable);
