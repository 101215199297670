import { connect } from "react-redux";

import { closeModal, validateArticle } from "../../../store/ducks/validateModal";
import ValidateModal from "./validateModalContainer/ValidateModal";

const mapStateToProps = state => ({
  open: state.validateModal.modalOpen,
  article: state.validateModal.article,
  sending: state.validateModal.sending,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  confirm: () => dispatch(validateArticle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateModal);
