import { Card, CardContent } from "@mui/material";
import { Map, TileLayer, Marker } from "react-leaflet";

import "./style.css";

export const MAP = {
  zoom: 15,
  maxZoom: 18,
  center: [-34.9210593, -57.9541325],
  attribution:
    '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  style: {
    height: 330,
    width: "100%",
    borderRadius: 5,
  },
};

export default ({ task }) => {
  const position = { lat: task.latitud, lng: task.longitud };
  return (
    <Card variant="outlined" style={{ width: "100%" }}>
      <CardContent>
        <Map zoom={MAP.zoom} style={MAP.style} maxZoom={MAP.maxZoom} center={position}>
          <TileLayer attribution={MAP.attribution} url={MAP.url} />
          {position ? <Marker position={position} /> : null}
        </Map>
        <div className="positionRef">
          <div>
            <small>Latitud: {position.lat}</small>
          </div>
          <div>
            <small>Longitud: {position.lng}</small>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
