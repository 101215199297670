import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";

import Title from "./gangsList/Title";
import Gangs from "./gangsList/Gangs";
import Search from "./gangsList/search/SearchContainer";
import GroupsHistoryDialog from "./GroupsHistoryDialogContainer";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
});

const gangsList = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <GroupsHistoryDialog />
      <Grid container spacing={1}>
        <Grid item xs={12} key="title">
          <Title isFetching={props.isFetching} />
        </Grid>

        <Grid item xs={12} key="search">
          <Search onSearchChange={props.onSearchChange} />
        </Grid>
      </Grid>
      <Gangs isFetching={props.isFetching} />
    </div>
  );
};

export default withStyles(styles)(gangsList);
