export const parseFromApi = stock => {
  return stock.map(store => {
    return {
      store: {
        id: store.id_deposito,
        name: store.deposito,
      },
      origin: {
        id: store.id_procedencia,
        name: store.procedencia,
      },
      stock: store.stock.map(article => {
        const max = Math.min(
          Number(article.stock),
          Number(article.disponible),
          Number(article.cargado)
        );

        return {
          id: Number(article.id_articulo),
          name: article.descripcion,
          max: max >= 0 ? max : 0,
          code: article.cod_equivalente,
          amount: Number(article.stock),
          unit: article.unidad_medida,
        };
      }),
    };
  });
};
