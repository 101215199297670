import { Fragment } from "react";
import Typography from "@mui/material/Typography";

const title = () => {
  return (
    <Fragment>
      <Typography variant="h5" component="h3">
        Revisión de datos civiles
      </Typography>
      <Typography style={{ marginTop: 10 }} component="p">
        Desde esta sección se puede revisar la información cargada por la cuadrilla
        respecto al aspecto civil de las tareas.
      </Typography>
    </Fragment>
  );
};

export default title;
