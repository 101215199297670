import parse from "date-fns/parse";

const types = {
  STATEMENT_MODAL_OPEN: "STATEMENT_MODAL_OPEN",
  STATEMENT_MODAL_CLOSE: "STATEMENT_MODAL_CLOSE",
};

export const openModal = statement => ({
  type: types.STATEMENT_MODAL_OPEN,
  statement,
});

export const closeModal = () => ({
  type: types.STATEMENT_MODAL_CLOSE,
});

const initialState = {
  modalOpen: false,
  statement: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STATEMENT_MODAL_OPEN:
      return { ...state, modalOpen: true, statement: action.statement };
    case types.STATEMENT_MODAL_CLOSE:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export const getStatements = ({ statements, statementsFilters }) => {
  let st;
  if (statementsFilters.gang) {
    st = statements.filter(s => s.gang.id === statementsFilters.gang.id);
  } else {
    st = statements;
  }
  return [...st].sort(
    (a, b) =>
      parse(b.date, "dd/MM/yyyy", new Date()) - parse(a.date, "dd/MM/yyyy", new Date())
  );
};

export default reducer;
