import { connect } from "react-redux";

import { removeCities, openModal } from "../../../../../store/ducks/taskListCityFilter";
import Cities from "./citiesContainer/Cities";
import { getTasksFromGraphql } from "../../../../../store/ducks/tasksList";

const mapStateToProps = state => ({
  cities: state.taskListCityFilter.selectedCities,
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  removeCities: () => {
    dispatch(removeCities());
    dispatch(getTasksFromGraphql());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Cities);
