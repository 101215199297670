import { connect } from "react-redux";

import {
  closeModal,
  changeAmount,
  openConfirmationModal,
} from "../../../store/ducks/editModal";
import EditModal from "./editModalContainer/EditModal";

const mapStateToProps = state => ({
  open: state.editModal.modalOpen,
  loading: state.editModal.gangStockLoading || state.editModal.storeStockLoading,
  sending: state.editModal.sending,
  article: state.editModal.article,
  amount: state.editModal.amount,
  storeAmount: state.editModal.storeStock,
  gangAmount: state.editModal.gangStock,
  amountError: state.editModal.amountError,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  changeAmount: amount => dispatch(changeAmount(amount)),
  confirm: () => dispatch(openConfirmationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
