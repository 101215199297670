import { connect } from "react-redux";
import UnassignGangDialog from "./UnassignGangDialog";
import NotUnassignableGangDialog from "./NotUnassignableGangDialog";
import { unnasignGang, closeModal } from "../../../store/ducks/unnasignGangModal";
import { Component } from "react";

const mapStateToProps = state => ({
  openModal: state.unnasignGangModal.modalOpen,
  sending: state.unnasignGangModal.sending,
  gang: state.unnasignGangModal.gang,
  groups: state.groups.groups,
  group: state.groupGangs.group,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  unnasign: () => dispatch(unnasignGang()),
});

class TransferGangDialogContainer extends Component {
  render() {
    const { gang } = this.props;

    const Dialog =
      gang && gang.tareasEnEjecucionCount
        ? NotUnassignableGangDialog
        : UnassignGangDialog;

    return <Dialog {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferGangDialogContainer);
