import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupIcon from "@mui/icons-material/LocationCity";
import GangIcon from "@mui/icons-material/Person";
import TaskIcon from "@mui/icons-material/Description";
import CommentsIcon from "@mui/icons-material/Assignment";
import DatesIcon from "@mui/icons-material/Today";
import TimerIcon from "@mui/icons-material/Timer";
import IconButton from "@mui/material/IconButton";
import { format, addMinutes } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

const dateParser = date => {
  date = new Date(date.replace(/ /g, "T"));
  return format(addMinutes(date, date.getTimezoneOffset()), "dd/MM/yyyy");
};

const styles = theme => ({
  title: {
    fontWeight: 400,
  },
  container: {
    paddingBottom: 0,
  },
  dropdown: {
    minWidth: 80,
  },
  contentContainer: {
    paddingBottom: 0,
    paddingTop: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  listIcon: {
    minWidth: 40,
  },
  actions: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 15,
    paddingLeft: 15,
  },
  stateIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    fontSize: 13,
    userSelect: "none",
    fontWeight: 500,
    border: "1px solid",
    borderRadius: "50%",
  },
});

const getInitials = type => {
  if (type.trim().indexOf(" ") === -1) {
    return type.substring(0, 2).toUpperCase();
  }
  let initials = type.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

const getStateClass = state => {
  if (state === "Programada") {
    return "Programada-T";
  } else if (state === "Validada") {
    return "Materiales-validados";
  }
  return state;
};

const TaskCard = ({ task, classes, showAllInfo }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleShowTask = useCallback(() => {
    navigate(`/tarea/${task.id}`);
  }, [task, navigate]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let taskText = "";
  if (task.modulo) taskText += `MOD ${task.modulo}. `;
  taskText += task.tarea;
  return (
    <Card variant="outlined">
      <CardHeader
        className={classes.container}
        title={
          <Typography variant="h6" className={classes.title}>
            {task.ubicacion}
          </Typography>
        }
        subheader={
          <>
            <Tooltip title="Código OT">
              <span> {task.codigo_ot}</span>
            </Tooltip>{" "}
            <Tooltip title="Fecha de asignación">
              <span>{dateParser(task.fecha_asignada)}</span>
            </Tooltip>
            {(expanded || showAllInfo) && (
              <>
                <span> - </span>
                <Typography variant="caption">
                  Ult mod {dateParser(task.updated_at)}
                </Typography>
              </>
            )}
          </>
        }
        action={
          <>
            {!showAllInfo && (
              <IconButton onClick={handleExpandClick} size="large">
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            )}
          </>
        }
      />
      <CardContent className={classes.contentContainer}>
        <List component="nav" disablePadding dense>
          <ListItem dense disableGutters>
            <Tooltip title="Grupo">
              <ListItemIcon className={classes.listIcon}>
                <GroupIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.grupo ? task.grupo.nombre : "-"} />
          </ListItem>
          <ListItem dense disableGutters>
            <Tooltip title="Cuadrilla">
              <ListItemIcon className={classes.listIcon}>
                <GangIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={task.cuadrilla ? task.cuadrilla.cuadrilla : "-"} />
          </ListItem>
          <ListItem dense disableGutters>
            <Tooltip title="Tarea a realizar">
              <ListItemIcon className={classes.listIcon}>
                <TaskIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={taskText} />
          </ListItem>
          <Collapse in={expanded || showAllInfo} timeout="auto" unmountOnExit>
            <ListItem dense disableGutters>
              <Tooltip title="Fecha de creación">
                <ListItemIcon className={classes.listIcon}>
                  <DatesIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={dateParser(task.created_at)} />
            </ListItem>
            <ListItem dense disableGutters>
              <Tooltip title="Fecha de vencimiento">
                <ListItemIcon className={classes.listIcon}>
                  <TimerIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={dateParser(task.vencimiento)} />
            </ListItem>
            <ListItem dense disableGutters>
              <Tooltip title="Observaciones">
                <ListItemIcon className={classes.listIcon}>
                  <CommentsIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={task.observaciones || "-"} />
            </ListItem>
          </Collapse>
        </List>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button size="small" style={{ color: "black" }} onClick={handleShowTask}>
          Ver detalles
        </Button>
        <div style={{ marginLeft: "auto" }} />
        {task.parent_id ? (
          <Tooltip title="Tarea Complementaria">
            <p className={[classes.stateIcon, "complementary"]}>CO</p>
          </Tooltip>
        ) : null}
        {task.fallida ? (
          <Tooltip title="Fallida">
            <p className={[classes.stateIcon, "failed"]}>F</p>
          </Tooltip>
        ) : null}
        <Tooltip title={task.tipo.tipo}>
          <p className={[classes.stateIcon, "type"]}>{getInitials(task.tipo.tipo)}</p>
        </Tooltip>
        <Tooltip title={task.estado.estado}>
          <p className={[classes.stateIcon, getStateClass(task.estado.estado)]}>
            {getInitials(task.estado.estado)}
          </p>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(TaskCard);
