import { connect } from "react-redux";

import {
  changeOdt,
  changeGroup,
  changeTaskType,
} from "../../../store/ducks/downloadTasksFilter";

import Toolbar from "./toolbarContainer/Toolbar";

const mapStateToProps = state => ({
  groups: state.groups.groups,

  odt: state.downloadTasksFilter.odt,
  group: state.downloadTasksFilter.group,
  taskType: state.downloadTasksFilter.taskType,
});

const mapDispatchToProps = dispatch => ({
  changeOdt: odt => dispatch(changeOdt(odt)),
  changeGroup: group => dispatch(changeGroup(group)),
  changeTaskType: taskType => dispatch(changeTaskType(taskType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
