import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const styles = theme => ({
  form: {
    marginBottom: theme.spacing(1.5),
  },
  input: {
    marginTop: theme.spacing(3),
  },
  confirmBtn: {
    width: 110,
  },
});

const DEFAULT_OPTIONS = [
  { name: "Aporte", id: 8 },
  { name: "Vereda", id: 5 },
  { name: "Limpieza", id: 10 },
];

const getOptions = (typeId, subtasks = []) => {
  let options = [];
  switch (typeId) {
    case 5:
      options = [{ name: "Limpieza", id: 10 }];
      break;
    case 8:
      options = [
        { name: "Vereda", id: 5 },
        { name: "Limpieza", id: 10 },
      ];
      break;
    default:
      options = DEFAULT_OPTIONS;
  }
  return options.filter(o => !subtasks.includes(o.id));
};

const CivilTaskDialog = props => {
  const { classes, closeModal, openModal, shouldRedirect } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const typeId = useMemo(
    () => Number(props.task?.typeId) || Number(props.task?.tipo_id),
    [props.task]
  );
  const subtasksTypesId = useMemo(() => props.task?.subtasks_types || [], [props.task]);

  const options = useMemo(
    () => getOptions(typeId, subtasksTypesId),
    [typeId, subtasksTypesId]
  );

  const requiresCleaning = useMemo(() => props.task?.requires_cleaning, [props.task]);
  const requiresFilling = useMemo(
    () => (props.task?.sidewalks || []).some(s => s.requires_filling),
    [props.task]
  );
  const alertMessage = useMemo(() => {
    switch (true) {
      case subtasksTypesId.length === 1:
        return `La tarea ya cuenta con una tarea complementaria creada de "${
          DEFAULT_OPTIONS.find(o => o.id === subtasksTypesId[0])?.name
        }".`;

      case subtasksTypesId.length > 1:
        return `La tarea ya cuenta con  tareas complementaria creadas de ${DEFAULT_OPTIONS.filter(
          o => o.id === subtasksTypesId[0]
        )
          .map(o => `"${o.name}"`)
          .join(", ")}.`;

      case requiresFilling && requiresCleaning && ![8, 5].includes(typeId):
        return "La cuadrilla indicó que la tarea requiere tareas de Aporte y de Limpieza.";

      case requiresFilling:
        return "La cuadrilla indicó que la tarea requiere aporte.";

      case requiresCleaning:
        return "La cuadrilla indicó que la tarea requiere limpieza.";

      default:
        return "";
    }
  }, [requiresFilling, requiresCleaning, typeId, subtasksTypesId]);

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      maxWidth="sm"
      open={openModal}
      onClose={closeModal}>
      <DialogTitle>
        Generar tarea complementaria para {props.task?.codigo_ot || props.task?.otCode}
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="textSecondary" component="p">
          Dirección: {props.task?.ubicacion}
        </Typography>
        <FormControl fullWidth component="fieldset" className={classes.form}>
          <FormGroup>
            <Autocomplete
              options={options}
              value={props.type}
              noOptionsText="No hay tipos disponibles"
              onChange={(_, value) => {
                props.changeType(value);
              }}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tipo"
                  error={props.gangError}
                  className={classes.input}
                  helperText={props.gangError ? "Seleccione un tipo" : ""}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </FormGroup>

          <FormGroup>
            <Autocomplete
              value={props.group}
              options={props.groups}
              noOptionsText="No hay grupos disponibles"
              onChange={(_, value) => {
                props.changeGroup(value);
              }}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Grupo"
                  error={props.gangError}
                  className={classes.input}
                  helperText={props.gangError ? "Seleccione un grupo" : ""}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </FormGroup>
        </FormControl>
        {alertMessage ? <Alert severity="info">{alertMessage}</Alert> : null}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={closeModal} disabled={props.loading}>
          Cancelar
        </Button>

        <LoadingButton
          loading={props.loading}
          color="primary"
          onClick={() => {
            props.confirm(shouldRedirect);
            navigate("/validacion");
          }}
          disabled={!props.group || !props.type || props.loading}>
          Generar Tarea
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CivilTaskDialog);
