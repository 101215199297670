/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import versions from "../../versions";

const message = (
  <div>
    <span style={{ marginLeft: 15 }}>Esta versión incorpora:</span>
    <div>
      <List>
        {versions[0].changes.map(c => (
          <ListItem key={c.title}>
            <ListItemAvatar>
              <Avatar>{c.avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={c.title} secondary={c.description} />
          </ListItem>
        ))}
      </List>
    </div>
  </div>
);

const VersionAlertDialog = () => {
  const [open, setOpen] = React.useState(false);

  const user = useSelector(state => state.user);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    localStorage.setItem(`${user.id}-${versions[0].version}`, "1");
    setOpen(false);
  };

  React.useEffect(() => {
    if (user && user.id) {
      const lastVersionSeen = localStorage.getItem(`${user.id}-${versions[0].version}`);
      if (!lastVersionSeen) {
        setOpen(true);
      }
    }
  }, [user]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {`Nueva actualización - Versión ${versions[0].version}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Entendido</Button>
      </DialogActions>
    </Dialog>
  );
};

export default VersionAlertDialog;
