import axios from "../../axios";
import { getTask } from "./taskDetails";
import { enqueueError, enqueueMessage } from "./notifications";

const types = {
  PIPELINE_EDIT_MODAL_OPEN: "PIPELINE_EDIT_MODAL_OPEN",
  PIPELINE_EDIT_MODAL_CLOSE: "PIPELINE_EDIT_MODAL_CLOSE",
  PIPELINE_EDIT_MODAL_CHANGE: "PIPELINE_EDIT_MODAL_CHANGE",
  PIPELINE_EDIT_MODAL_SENDING: "PIPELINE_EDIT_MODAL_SENDING",
  PIPELINE_EDIT_MODAL_CONFIRM_EDIT: "PIPELINE_EDIT_MODAL_CONFIRM_EDIT",
};

export const openModal = pipeline => dispatch => {
  dispatch({
    pipeline,
    type: types.PIPELINE_EDIT_MODAL_OPEN,
  });
};

export const closeModal = () => ({
  type: types.PIPELINE_EDIT_MODAL_CLOSE,
});

const sending = value => ({
  value,
  type: types.PIPELINE_EDIT_MODAL_SENDING,
});

export const confirmEdit =
  (
    depth,
    diameter,
    material,
    has_sidewalk,
    street_orientation,
    municipal_line_distance
  ) =>
  async (dispatch, getState) => {
    const { taskPipelineEditModal, taskDetails } = getState();
    const { pipeline } = taskPipelineEditModal;
    const { task } = taskDetails;

    dispatch(sending(true));
    try {
      axios
        .patch(`/pipeline/${pipeline.id}`, {
          depth,
          diameter,
          material,
          has_sidewalk,
          street_orientation,
          municipal_line_distance,
        })
        .then(() => {
          dispatch(getTask(task.id));
          dispatch(sending(false));
          dispatch(closeModal());
          dispatch(enqueueMessage("Se realizó la validación de datos correctamente."));
        });
    } catch (error) {
      dispatch(sending(false));
      dispatch(enqueueError("Ocurrió un error al validar los datos de la red."));
    }
  };

export const change = pipeline => ({
  type: types.PIPELINE_EDIT_MODAL_CHANGE,
  pipeline,
});

const initialState = {
  modalOpen: false,
  confirmationOpen: false,

  depth: null,
  material: null,
  diameter: null,
  has_sidewalk: false,
  street_orientation: null,
  municipal_line_distance: null,

  sending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PIPELINE_EDIT_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
        ...action.pipeline,
        pipeline: action.pipeline,
      };

    case types.PIPELINE_EDIT_MODAL_SENDING: {
      return { ...state, sending: action.value };
    }
    case types.PIPELINE_EDIT_MODAL_OPEN_CONFIRMATION:
      return { ...state, modalOpen: false, confirmationOpen: true };

    case types.PIPELINE_EDIT_MODAL_CLOSE:
      return { ...state, modalOpen: false, confirmationOpen: false };

    case types.PIPELINE_EDIT_MODAL_CHANGE:
      return { ...state, ...action.pipeline };

    case types.PIPELINE_EDIT_MODAL_CONFIRM_EDIT:
      return { ...state, ...action.data, edited: true };

    default:
      return state;
  }
};

export default reducer;
