import { gql } from "apollo-boost";

export const GET_HIDROJET_TASKS = gql`
  query GET_HIDROJET_TASKS(
    $page: Int = 0
    $first: Int = 25
    $hasTask: WhereConditions = null
    $where: HidrojetsWhereWhereConditions = null
    $order_by: [HidrojetsOrderByOrderByClause!] = [{ field: CREATED_AT, order: DESC }]
  ) {
    hidrojets(
      page: $page
      first: $first
      where: $where
      hasTask: $hasTask
      order_by: $order_by
    ) {
      data {
        task {
          id
          codigo_ot
          created_at
          tarea
          ubicacion
          fecha_asignada
          grupo {
            id
            nombre
          }
        }
        id
        task_date
        module
        state
        cuadrilla {
          id
          cuadrilla
        }
        observations
        created_at
        validated_at
        validated_by {
          name
        }
      }
      paginatorInfo {
        hasMorePages
        currentPage
        perPage
        total
      }
    }
  }
`;
