import { connect } from "react-redux";

import {
  changeOdt,
  changeGroup,
  changeFilling,
  changeSidewalk,
  changeCleaning,
} from "../../../store/ducks/downloadTasksFilter";

import Toolbar from "./toolbarContainer/Toolbar";

const mapStateToProps = state => ({
  odt: state.downloadTasksFilter.odt,
  group: state.downloadTasksFilter.group,

  requiresFilling: state.downloadTasksFilter.requiresFilling,
  requiresSidewalk: state.downloadTasksFilter.requiresSidewalk,
  requiresCleaning: state.downloadTasksFilter.requiresCleaning,

  groups: state.groups.groups,
});

const mapDispatchToProps = dispatch => ({
  changeOdt: odt => dispatch(changeOdt(odt)),
  changeGroup: group => dispatch(changeGroup(group)),
  changeFilling: boolean => dispatch(changeFilling(boolean)),
  changeSidewalk: boolean => dispatch(changeSidewalk(boolean)),
  changeCleaning: boolean => dispatch(changeCleaning(boolean)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
