/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import EditIcon from "@mui/icons-material/Edit";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import ImportExportIcon from "@mui/icons-material/ImportExport";

const styles = _ => ({
  dropdown: {
    minWidth: 80,
  },
});

const GangCardButtons = memo(({ gang, classes, gangsWithStatements, ...props }) => {
  const navigate = useNavigate();

  const goToReorderTasks = useCallback(() => {
    navigate(`/cuadrillas/${gang.id}/tareas/reordenar`);
  }, [navigate, gang]);

  return (
    <>
      {props.roles.includes("editarCuadrilla") ? (
        <Tooltip title="Editar Cuadrilla" onClick={() => props.openEditModal(gang)}>
          <IconButton aria-label="reorder" className={classes.margin} size="large">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}

      {props.roles.includes("auditarCuadrilla") ? (
        <Tooltip title="Auditorias de cambios">
          <IconButton
            aria-label="audits"
            className={classes.margin}
            onClick={() => props.openModal(gang)}
            size="large">
            <RestorePageIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}

      {props.roles.includes("reordenarTareas") ? (
        <Tooltip title="Reordenar tareas programadas" onClick={goToReorderTasks}>
          <IconButton aria-label="reorder" className={classes.margin} size="large">
            <ImportExportIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
});

export default withStyles(styles)(GangCardButtons);
