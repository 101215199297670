/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import { confirmEdit } from "../../../../store/ducks/taskPipelineEditModal";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const styles = theme => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

const EditModal = props => {
  const dispatch = useDispatch();

  const [depth, setDepth] = useState(props.depth);
  const [diameter, setDiameter] = useState(props.diameter);
  const [material, setMaterial] = useState(props.material);
  const [has_sidewalk, setHasSidewalk] = useState(props.has_sidewalk);
  const [street_orientation, setStreetOrientation] = useState(props.street_orientation);
  const [municipal_line_distance, setMunicipalLineDistance] = useState(
    props.municipal_line_distance
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isValid = !!module;

  const onSubmit = () => {
    dispatch(
      confirmEdit(
        depth,
        diameter,
        material,
        has_sidewalk,
        street_orientation,
        municipal_line_distance
      )
    );
  };

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isValid) {
          onSubmit();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isValid]);

  useEffect(() => {
    if (props.open) {
      setDepth(props.depth);
      setDiameter(props.diameter);
      setMaterial(props.material);
      setHasSidewalk(props.has_sidewalk);
      setStreetOrientation(props.street_orientation);
      setMunicipalLineDistance(props.municipal_line_distance);
    }
  }, [
    props.open,
    props.depth,
    props.diameter,
    props.material,
    props.has_sidewalk,
    props.street_orientation,
    props.municipal_line_distance,
  ]);

  return (
    <Dialog
      fullWidth
      open={props.open}
      fullScreen={isMobile}
      onClose={props.onClose}
      disableRestoreFocus
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Validar datos de Red</DialogTitle>
      <DialogContent>
        {props.loading ? (
          <div className={props.classes.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <FormControl style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
              <InputLabel id="pipeline-tipo-label">Tipo</InputLabel>
              <Select
                fullWidth
                value={has_sidewalk}
                id="pipeline-tipo-select"
                labelId="pipeline-tipo-label"
                onChange={e => {
                  setHasSidewalk(e.target.value);
                }}>
                <MenuItem value={0}>Calzada</MenuItem>
                <MenuItem value={1}>Vereda</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              type="number"
              margin="dense"
              value={diameter}
              label={"Diámetro"}
              style={{ marginBottom: 20 }}
              onChange={e => setDiameter(e.target.value)}
            />

            <TextField
              fullWidth
              margin="dense"
              value={material}
              label={"Material"}
              style={{ marginBottom: 20 }}
              onChange={e => setMaterial(e.target.value)}
            />

            <TextField
              fullWidth
              value={depth}
              type="number"
              margin="dense"
              label={"Profundidad"}
              style={{ marginBottom: 20 }}
              onChange={e => setDepth(e.target.value)}
            />

            <TextField
              fullWidth
              margin="dense"
              label={"Alejamiento LM"}
              style={{ marginBottom: 20 }}
              value={municipal_line_distance}
              onChange={e => setMunicipalLineDistance(e.target.value)}
            />

            <TextField
              fullWidth
              margin="dense"
              label={"Fondo a Calle"}
              value={street_orientation}
              style={{ marginBottom: 20 }}
              onChange={e => setStreetOrientation(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "black" }} onClick={props.onClose}>
          Cancelar
        </Button>

        <LoadingButton
          loading={props.sending}
          onClick={onSubmit}
          disabled={!isValid}
          color="primary">
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EditModal);
