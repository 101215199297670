import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { memo } from "react";

const search = memo(props => {
  return (
    <ListItem style={{ paddingTop: 0 }}>
      <TextField
        fullWidth
        margin="none"
        placeholder="Buscar"
        value={props.value}
        onChange={e => props.changeSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </ListItem>
  );
});

export default search;
