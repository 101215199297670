import { connect } from "react-redux";

import { closeModal, confirm } from "../../../store/ducks/taskSidewalkAddModal";

import AddSidewalkModal from "./addSidewalkModal/AddSidewalkModal";

const mapStateToProps = state => ({
  loading: false,
  task: state.task,
  sidewalkTypes: state.sidewalkTypes,
  sidewalkUnits: state.sidewalkUnits,
  sidewalkTiles: state.sidewalkTiles,
  open: state.taskSidewalkAddModal.modalOpen,
});

const mapDispatchToProps = dispatch => ({
  confirm: () => dispatch(confirm()),
  onClose: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSidewalkModal);
