const types = {
  STATEMENT_FILTERS_CHANGE_GANG: "STATEMENT_FILTERS_CHANGE_GANG",
  STATEMENT_FILTERS_RESET: "STATEMENT_FILTERS_RESET",
};

export const changeGang = gang => ({
  type: types.STATEMENT_FILTERS_CHANGE_GANG,
  gang,
});

export const resetGang = () => ({
  type: types.STATEMENT_FILTERS_RESET,
});

const initialState = {
  gang: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STATEMENT_FILTERS_RESET:
      return { ...initialState };

    case types.STATEMENT_FILTERS_CHANGE_GANG:
      return { ...state, gang: action.gang };
    default:
      return state;
  }
};

export default reducer;

export const getGangs = state =>
  state.statements.reduce(
    (ac, cv) => (ac.find(g => g.id === cv.gang.id) ? ac : [...ac, cv.gang]),
    []
  );
// .sort((a, b) => a.name.localeCompare(b.name));

export const getGangsWithStatements = state =>
  state.statements.reduce(
    (ac, cv) => (ac.find(g => g.id === cv.gang.id) ? ac : [...ac, cv.gang]),
    []
  );
// .sort((a, b) => a.name.localeCompare(b.name));
