import { Component } from "react";
import Table from "@mui/material/Table";
import { CircularProgress } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import Body from "./hidrojetTable/Body";
import Header from "./hidrojetTable/Header";
import Footer from "./hidrojetTable/Footer";

const styles = theme => ({
  rootTable: {
    width: "100%",
    marginTop: theme.spacing(),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

class HidrojetTable extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  render() {
    const { classes, hidrojets, checkTask, onDownload, loading } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <div className={classes.rootTable}>
        {/* Table */}
        <Table className={classes.table}>
          {this.props.loadingPage ? (
            <div style={{ margin: "250px 10px", textAlign: "center" }}>
              <CircularProgress disableShrink />
            </div>
          ) : (
            <>
              <Header
                rows={hidrojets.length}
                selectedCount={this.props.selectedCount}
                onCheck={() => this.props.checkAll(hidrojets.map(t => t.id))}
              />
              <Body
                page={page}
                loading={loading}
                onCheck={checkTask}
                hidrojets={hidrojets}
                onDownload={onDownload}
                rowsPerPage={rowsPerPage}
                openEditModal={this.props.openEditModal}
              />
              <Footer
                page={page}
                count={this.props.total}
                rowsPerPage={rowsPerPage}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(HidrojetTable);
